// (uncontrolled) Search Input - with 'clear' icon
//
// Works for both 'live' searching - just pass onInput or onKeyUp/Down handlers.
// Or for regular search, just pass an onEnter handler.
//
import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styled from '@emotion/styled'

import * as Keys from 'constants/keys'
import { runOnNextTick } from 'util/functions'

class SearchBox extends React.Component {
  state = {
    inputText: '',
  }

  onInput = value => {
    const { onSearchChange } = this.props
    this.setState({ inputText: value })
    if (onSearchChange) onSearchChange(value)
  }

  onFocus = () => {
    this.setState({ focused: true })
  }

  onBlur = () => {
    this.setState({ focused: false })
  }

  setRefAndFocus = node => {
    if (node) {
      this.inputRef = node
      runOnNextTick(() => node.focus())
      // Place cursor at the end of the input if there's text in it
      this.inputRef.selectionEnd = this.inputRef.value.length
      this.inputRef.selectionStart = this.inputRef.value.length
    }
  }

  handleInput = evt => this.onInput(evt.target.value)

  handleClearInput = evt => {
    const { onClearInput, skipFocusInput } = this.props
    if (onClearInput) onClearInput(evt)

    this.onInput('')
    ReactDOM.findDOMNode(this.inputRef).value = '' // eslint-disable-line react/no-find-dom-node

    // This flag enables us to embed this component inside a DD - since DDs often
    // close onBlur
    if (skipFocusInput) return false

    if (!this.inputRef) return false

    this.inputRef.focus()

    return true
  }

  handleKeyUp = evt => {
    const { onKeyUp, onEnter } = this.props

    if (onEnter && evt.keyCode === Keys.ENTER) {
      evt.preventDefault()
      evt.stopPropagation()
      onEnter(evt.target.value)
    }

    if (onKeyUp) onKeyUp(evt)
  }

  // Make a clickable icon if an onEnter callback is passed (for non-live)
  // searching. This allow the user to also use the mouse to initiate a search.
  // If the search is 'live' this is redudant.
  handleIconClick = () => {
    const { onEnter } = this.props
    const { inputText } = this.state

    if (!onEnter) return false
    // Note, live searches excepted, we never disable this click handler, since
    // user may want to refresh the results of their current search (empty or
    // otherwise).
    onEnter(inputText)
    this.inputRef.focus()
    return true
  }

  render() {
    const {
      className,
      placeholder,
      value,
      onKeyDown,
      onInputClick,
      onEnter,
      styleVersion,
    } = this.props
    const { inputText, focused } = this.state
    const isLive = !onEnter
    const isSearching = isLive ? !!inputText : inputText === value

    return (
      <div className={cn(className, styleVersion, { focused, isSearching })}>
        <div
          className={cn('Icon Icon-search', {
            // If the search is live, the icon will show as blue if any search
            // term.
            //
            // If the search is not live, the icon will only show as blue if the
            // term matches the currently searched one, as indicated by the
            // props.value.  Similarly, if the user then modifies the search
            // term, it shows as gray again (with pointer + hover states to
            // indicate it is clickable).
            isSearching,
            clickable: !isLive && !!inputText,
          })}
          onClick={this.handleIconClick}
        />
        <input
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          className="searchInput"
          onClick={onInputClick}
          onKeyDown={onKeyDown}
          onKeyUp={this.handleKeyUp}
          onInput={this.handleInput}
          placeholder={placeholder}
          defaultValue={value}
          ref={this.setRefAndFocus}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          spellCheck="off"
        />
        {inputText !== '' && (
          <span className="Icon Icon-close" onClick={this.handleClearInput} />
        )}
      </div>
    )
  }
}

SearchBox.propTypes = {
  // Callbacks
  onEnter: PropTypes.func, // called with value of input field
  onKeyDown: PropTypes.func, // called with raw event
  onKeyUp: PropTypes.func, // called with raw event
  onSearchChange: PropTypes.func, // called with value of input field
  onClearInput: PropTypes.func,

  placeholder: PropTypes.string,

  // default search term
  value: PropTypes.string,

  styleVersion: PropTypes.string,
}

SearchBox.defaultProps = {
  onKeyDown: undefined,
  onKeyUp: undefined,
  onEnter: undefined,
  onClearInput: undefined,
  onSearchChange: undefined,
  placeholder: 'Start typing to search...',
  value: '',
  styleVersion: 'v1',
}

SearchBox.displayName = 'SearchBox'

export default styled(SearchBox)`
  position: relative;
  transition: border-color 0.25s ease-in-out;

  &.v2 {
    border: solid 1px ${props => props.theme.color.monochrome.medium};
    padding: 0 8px 0 8px;
    border-radius: ${props => props.theme.spacing['3']};
    display: inline-block;
    width: 100%;

    &.isSearching,
    &.focused {
      border: solid 1px ${props => props.theme.color.primary.brand};
    }

    .searchInput {
      padding: 0px 24px 0px 24px;
      line-height: 2.4;
    }

    .Icon {
      top: 0px;
      line-height: 2.4;
      padding: 0;
    }

    .Icon-close {
      right: 8px;
    }

    .Icon-search {
      top: 2px;
    }
  }

  .Icon {
    align-items: center;
    bottom: 0;
    display: flex;
    font-size: 18px;
    position: absolute;
    top: 6px;
    color: ${props => props.theme.color.monochrome.mediumDark};
    transition: color 0.25s ease-in-out;

    &.isSearching {
      color: ${props => props.theme.color.primary.brand};
    }

    &.clickable {
      cursor: pointer;

      &:hover {
        color: ${props => props.theme.color.primary.brand};
      }
    }
  }

  .searchInput {
    line-height: normal;
    appearance: none;
    outline: 0;
    width: 100%;
    padding: 8px 24px 4px 24px;
    font-size: 13px;
    border: 0;
    color: ${props => props.theme.color.monochrome.dark};
    border-radius: 99em;

    ::placeholder {
      color: inherit;
      opacity: 0.5;
    }
  }

  .Icon-close {
    align-items: center;
    bottom: 0;
    color: ${props => props.theme.color.primary.brand};
    cursor: pointer;
    display: flex;
    font-size: 14px;
    padding: 4px;
    position: absolute;
    right: 20px;
    top: 8px;
  }
`
