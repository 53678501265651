import * as pages from 'constants/pages'

import { doSetupAuthCallbackPage } from 'actions/auth'
import { doLoadSearchTickets } from 'actions/search/doLoadSearchTickets'
import { doLoadSearchTicketsOnNewConversationPage } from 'actions/search/doLoadSearchTicketsOnNewConversationPage'

import ducksRoutesMap from 'ducks/routesMap'
import { routes as settingsRoutes } from 'subapps/settings/routes'
import { routes as onboardingRoutes } from 'subapps/onboarding/routes'
import { routes as subAppRoutes } from 'subapps'
import { decodeURIIfNecessary } from 'util/url'

const routesMap = {
  ...ducksRoutesMap,
  ...settingsRoutes,
  ...onboardingRoutes,
  ...subAppRoutes,
}

routesMap[pages.AUTH_CALLBACK_PAGE] = {
  path: '/auth/callback',
  thunk: doSetupAuthCallbackPage,
}

routesMap[pages.FOLDERS_PAGE] = {
  path: '/folders',
}

routesMap[pages.FOLDER_PAGE] = {
  path: '/folders/:folderId',
  fromPath: segment => `${segment}`, // dont convert id to a Number.
}

routesMap[pages.LOGIN_PAGE] = {
  path: '/login',
}

routesMap[pages.LOGIN_SELECTOR] = {
  path: '/login/selector',
}

routesMap[pages.LOGIN_MULTIPLE_ACCOUNTS_PAGE] = {
  path: '/login/accounts',
}

routesMap[pages.LOGIN_AGENT_MISSING_PAGE] = {
  path: '/login/not-found',
}

routesMap[pages.OTP_CHALLENGE_PAGE] = {
  path: '/login/otp',
}

routesMap[pages.FORGOT_PASSWORD_PAGE] = {
  path: '/users/password/new',
}

routesMap[pages.FORGOT_SUBDOMAIN_PAGE] = {
  path: '/users/subdomain/new',
}

routesMap[pages.CHANGE_PASSWORD_PAGE] = {
  path: '/users/password/edit',
}

routesMap[pages.LOGOUT_PAGE] = {
  path: '/logout',
}

routesMap[pages.MAIN_PAGE] = {
  path: '/',
}

routesMap[pages.MAILBOX_DEFAULT_PAGE] = {
  path: '/mailbox/',
}

routesMap[pages.MAILBOX_PAGE] = {
  path: '/mailbox/:mailboxId',
  fromPath: segment => `${segment}`, // dont convert id to a Number.
}

routesMap[pages.MAILBOX_FOLDER_PAGE] = {
  path: '/mailbox/:mailboxId/folders/:folderId',
  fromPath: segment => `${segment}`, // dont convert ids to Numbers.
}

routesMap[pages.NEW_CONVERSATION_PAGE] = {
  path: '/tickets/new',
  thunk: doLoadSearchTicketsOnNewConversationPage(),
}

routesMap[pages.LOG_CONVERSATION_PAGE] = {
  path: '/tickets/log-conversation',
  thunk: doLoadSearchTicketsOnNewConversationPage(),
}

routesMap[pages.RAW_EMAIL_PAGE] = {
  path: '/raw_email/:id',
  fromPath: segment => `${segment}`, // dont convert id to a Number.
}

routesMap[pages.SEARCH_PAGE] = {
  // workaround https://github.com/faceyspacey/redux-first-router/issues/183
  fromPath: decodeURIIfNecessary,
  toPath: (value, key) => {
    // React-first-router won't encode % symbols in the search term,
    // so we need to do it ourselves. Otherwise the url shows % intead of %25 in the browser url bar.
    // Reloading page with % in the url bar will cause 400 Bad Request.

    // encoding it manually causes an issue where the pathname is double encoded
    // even though the RFR developer said that if we use fromPath/toPath, they won't decode/encode: https://github.com/faceyspacey/redux-first-router/issues/183#issuecomment-369144348
    // We need to use the search term instead of pathname to get the query id.
    if (key === 'term') return encodeURIComponent(value)
    return value
  },
  path: '/search/:term*',
  thunk: doLoadSearchTickets(),
}

routesMap[pages.TICKET_PAGE] = {
  path: '/tickets/:id',
  thunk: doLoadSearchTickets(),
}

routesMap[pages.TICKET_COMMENT_PAGE] = {
  path: '/tickets/:id/comment',
  thunk: doLoadSearchTickets(),
}

routesMap[pages.TICKET_REPLY_PAGE] = {
  path: '/tickets/:id/reply-all',
  thunk: doLoadSearchTickets(),
}

routesMap[pages.TICKET_REPLY_CHANGESET_PAGE] = {
  path: '/tickets/:id/reply-all/:changesetId',
  thunk: doLoadSearchTickets(),
}

routesMap[pages.TICKET_REPLY_DIRECT_PAGE] = {
  path: '/tickets/:id/reply',
  thunk: doLoadSearchTickets(),
}

routesMap[pages.TICKET_REPLY_DIRECT_CHANGESET_PAGE] = {
  path: '/tickets/:id/reply/:changesetId',
  thunk: doLoadSearchTickets(),
}

routesMap[pages.TICKET_FORWARD_CHANGESET_PAGE] = {
  path: '/tickets/:id/forward/:changesetId',
  thunk: doLoadSearchTickets(),
}

routesMap[pages.TICKET_FORWARD_PAGE] = {
  path: '/tickets/:id/forward',
  thunk: doLoadSearchTickets(),
}

routesMap[pages.TICKET_CHANGESET_PAGE] = {
  path: '/tickets/:id/changeset/:changesetId/:hashId',
  thunk: doLoadSearchTickets(),
}

routesMap[pages.OAUTH_ERROR_PAGE] = {
  path: '/oauth-error',
}

export default routesMap
