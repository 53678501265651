import { selectIsChatBootstrapped } from 'ducks/chat/selectors/bootstrap'
import { createBasicSelector } from 'util/redux'
import { selectIsInChat, selectIsInSettings } from 'selectors/location'
import { selectIsBillingDataLoaded } from 'ducks/billing/selectors/selectIsBillingDataLoaded'
import { isBoostrappedSelector } from './base'

const selectIsCurrentAppBootstrapped = createBasicSelector(
  selectIsInChat,
  isBoostrappedSelector,
  selectIsChatBootstrapped,
  selectIsInSettings,
  selectIsBillingDataLoaded,
  (
    isInChat,
    isInboxBootstrapped,
    isChatBootstrapped,
    isInSettings,
    isBillingDataLoaded
  ) => {
    if (isInChat) return isChatBootstrapped
    if (isInSettings) return isBillingDataLoaded
    return isInboxBootstrapped
  }
)

export default selectIsCurrentAppBootstrapped
