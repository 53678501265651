import Bugsnag from '@bugsnag/js'
import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'
import { any, getLength, findFirst, emptyArr, last } from 'util/arrays'
import { deepCopy, emptyObj } from 'util/objects'
import { getActor } from 'util/actors'
import { getAgentUsername } from 'util/agents'
import {
  isIncomingEmail,
  isMessage,
  isTwitterComment,
  isUnsnooze,
  isWithCollaborator,
  isWithCustomer,
  isLabel,
  isStateChangeOpen,
  embedAttachments,
  isCollapsable,
  hasMultipleMessageActions,
  parseBody,
} from 'util/changesets'
import { shiftedDate } from 'util/date'
import { capitalize } from 'util/strings'
import { objectHashSerializer, memoize } from 'util/memoization'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'

const actionTypePriority = type => {
  switch (type) {
    case 'Message':
      return 2 // highest prio
    case 'Snooze::State':
      return 5
    case 'Unsnooze::State':
      return 5
    case 'Agent':
      return 7 // AgentAndGroup lookahead ordering
    case 'Group':
      return 8
    case 'Ticket::Merger':
      return 1 // always force merge to sort to bottom so that it is always above first merged action
    case 'Deleted':
      return 4
    default:
      return 10 // lowest prio (value is arbitrary)
  }
}

const compareActionTypePriority = (a, b) => {
  // if two actions are of the same type, sort by date
  if (a.change_type === b.change_type) {
    return a.created_at < b.created_at ? -1 : 1
  }
  return actionTypePriority(a.change_type) < actionTypePriority(b.change_type)
    ? -1
    : 1
}

// Sort the actions in the changesets, in a way that our views expect (i.e.
// Message actions come first before status changes etc)
const sortActions = (changesets = []) =>
  changesets.map(changeset =>
    Object.assign(changeset, {
      actions: changeset.actions.sort(compareActionTypePriority),
    })
  )

// If we have any UndoSend changesets, then suppress the corresponding Message
// changesets (optimistic or otherwise). Also suppresses any UndoSend
// changesets so they dont appear in the UI
function deleteUndoneMessages(originalChangesets) {
  const undoSendIds = originalChangesets
    .map(c => {
      return c.id && c.id.match(/^undoSend-.*/)
    })
    .filter(e => e)
    .map(match => match[0])

  // the changeset ID of the message is the suffix of the UndoSend changeset ID.
  const correspondingMessageIds = undoSendIds
    .map(id => id.match(/^undoSend-(.*)/))
    .map(match => match[1])

  // Optimistic message changesets are prefixed
  const optimisticMessageIds = correspondingMessageIds.map(
    id => `optimistic-${id}`
  )

  if (getLength(undoSendIds) <= 0) return originalChangesets

  return originalChangesets.filter(
    c =>
      !undoSendIds.includes(c.id) &&
      !optimisticMessageIds.includes(c.id) &&
      !correspondingMessageIds.includes(c.id)
  )
}

function removeLabelChanges(originalChangesets) {
  return originalChangesets.filter(c => !any(isLabel, c.actions))
}

// If the unsnooze has an associated Open action, this shows up as 'and Unsnoozed'
//  in the UI. No other action e.g. 'and marked as Open' is
// shown. Its implied.
//
// However, if the Unsnooze does NOT have an open
// action, then we assume that the user 'closed' the ticket (via
// doUnsnoozeAndKeepClosed. Since we close tickets when we snooze them,
// we will never have an explicit 'close' action in this changeset here to
// show. Instead we treat the the absence of an Open action to mean we do
// want to show 'and marked as Closed' .
const addDummyClosedActionToUnsnoozes = originalChangesets => {
  const changesets = originalChangesets.slice(0)
  changesets.forEach((changeset, index) => {
    const unsnoozeAction = findFirst(changeset.actions, isUnsnooze)

    if (unsnoozeAction) {
      if (!findFirst(changeset.actions, isStateChangeOpen)) {
        // No Open action, so create a dummy Closed action to appear afterwards
        changesets[index].actions.push({
          ...unsnoozeAction,
          change_type: 'State',
          changeset: `dummy-${unsnoozeAction.changeset}`,
          change: {
            type: 'State',
            state: 'closed',
          },
        })
      }
    }
  })
  return changesets
}

// Merge any actions with a minute of each other
const combineCloseChangeSets = originalChangesets => {
  const changesets = originalChangesets.slice(0)
  let previousChangeset = null

  changesets.forEach((changeset, index) => {
    if (previousChangeset && changeset && changeset.actions) {
      const action = changeset.actions[0]
      const previousAction = previousChangeset.actions[0]
      const withinAMinute =
        new Date(action.created_at) - new Date(previousAction.created_at) <
        1000 * 60
      const sameActor = action.actor.id === previousAction.actor.id
      const assignmentChanges = ['Agent', 'Group']
      const assignmentChange =
        assignmentChanges.indexOf(action.change_type) > -1 &&
        assignmentChanges.indexOf(previousAction.change_type) > -1
      // If a state change happened right after a message and there was no state change in the message
      // jam the state change into that message
      if (
        withinAMinute &&
        action.change_type === 'State' &&
        previousAction.change_type === 'Message'
      ) {
        const previousChangesetActionTypes = previousChangeset.actions.map(
          act => act.change_type
        )
        const noStateChangeInPreviousChangeset =
          previousChangesetActionTypes.indexOf('State') === -1
        if (noStateChangeInPreviousChangeset) {
          previousChangeset.actions.unshift(action)
          changesets[index] = undefined
        } else {
          previousChangeset = changeset
        }
      } else if (withinAMinute && sameActor && assignmentChange) {
        changeset.actions.unshift(previousAction)
        changesets[index - 1] = undefined
      } else {
        previousChangeset = changeset
      }
    } else {
      previousChangeset = changeset
    }
  })
  return changesets.filter(e => e)
}

// Map the changesets, merging any subsequent Agent and Group changes into one
// change, thus enabling this
//
//    and Assigned to @sumeet
//    and Assigned to Sumeet Group
//
// to be instead rendered like this
//
//    and Assigned to Sumeet Group (@sumeet)
//
export const combineAgentAndGroupActions = changeset => {
  if (!changeset.actions.length) return changeset

  let combineWithNextChangeset = false
  let agentChange

  const actions = changeset.actions.map((action, index) => {
    const nextAction = changeset.actions[index + 1]
    const isAgentAssignmentChange = action.change_type === 'Agent'
    const nextActionIsGroupChange =
      nextAction && nextAction.change_type === 'Group'

    if (isAgentAssignmentChange && nextActionIsGroupChange) {
      // set flag and exclude this changeset
      combineWithNextChangeset = true
      agentChange = action.change
      return undefined // since we're using map(), this will create an undef entry
    }

    if (combineWithNextChangeset) {
      // change the Group change into a AgentAndGroup change
      combineWithNextChangeset = false
      const combinedAction = {
        ...action,
        change_type: 'AgentAndGroup',
        agentChange, // add this prop so we know what kind of Agent change
      }
      agentChange = undefined
      return combinedAction
    }

    return action // otherwise do no mapping
  })

  // get rid of any undefs
  const squashed = actions.filter(e => {
    return e
  })

  return { ...changeset, actions: squashed }
}

export const labelForCurrentUser = (actor = {}, currentUser = {}) => {
  if (actor.id === currentUser.id || actor.email === currentUser.email) {
    return 'You'
  }
  return undefined
}

export const getTwitterHandle = actor =>
  actor.twitter && `@${actor.twitter.username}`

export const actorLabel = (
  actor,
  currentUser,
  fromTwitter = false,
  useFullName = false
) => {
  if (!actor) return ''
  try {
    if (actor.type === 'Rule') return 'Rule'
    if (actor.type === 'Integration') return actor.name
    return (
      labelForCurrentUser(actor, currentUser) ||
      capitalize(getAgentUsername(actor)) ||
      (useFullName ? actor.name : actor.first_name) ||
      (fromTwitter && getTwitterHandle(actor)) ||
      actor.email
    )
  } catch (err) {
    Bugsnag.notify(err, event => {
      event.addMetadata('metaData', {
        meta: { meta: { actor, currentUser, fromTwitter, useFullName } },
      })
    })
    return ''
  }
}

// If the change is a reassignment to an agent, derive the label field for the
// assigned agent
const targetAgentLabel = (type, change, agentsById, currentUser) => {
  if (!(type === 'Agent') || !change || !change.id) return undefined
  const changeActor = getActor({ type: 'Agent', ...change }, agentsById)
  return actorLabel(changeActor, currentUser, isTwitterComment(change))
}

const decorateActor = memoize(
  (
    actionActor,
    agentsById,
    currentUser,
    fromTwitter = false,
    customersById
  ) => {
    const actor = getActor(actionActor, agentsById, customersById)
    try {
      if (actor.labelFull) {
        // NOTE (jscheel): No need to modify, as the decoration has already happened.
        return actor
      }
      return {
        ...actor, // pull in all the cached agent fields, if any
        label: actorLabel(actor, currentUser, fromTwitter), // and add this one
        labelFull: actorLabel(actor, currentUser, fromTwitter, true), // for bylines
      }
    } catch (err) {
      // TODO: remove this when we figure out the root cause of this error
      // (intended to catch 'Cannot convert undefined or null to object' errors in
      // 'Function.assign')
      Bugsnag.notify(new Error('Cannot decorate actor'), event => {
        event.addMetadata('metaData', {
          meta: {
            err,
            failingFunction: 'decorateActor',
            actionActor,
            agentsById,
            currentUser,
            fromTwitter,
            actor,
          },
        })
      })
      return {}
    }
  },
  {
    serializer: objectHashSerializer,
  }
)

const getLastCustomerAction = (
  messageId,
  ratingsByMessageId,
  readReceiptsByMessageId
) => ratingsByMessageId[messageId] || readReceiptsByMessageId[messageId]

const decorateChange = memoize(
  (
    action,
    agentsById,
    currentUser,
    ratingsByMessageId,
    readReceiptsByMessageId,
    customersById
  ) => {
    const { change, agentChange, change_type: changeType } = action

    Object.assign(change, {
      targetLabel: targetAgentLabel(
        changeType,
        change,
        agentsById,
        currentUser
      ),
    })

    if (agentChange) {
      Object.assign(agentChange, {
        targetLabel: targetAgentLabel(
          'Agent',
          agentChange,
          agentsById,
          currentUser
        ),
      })
    }

    if (isMessage(action)) {
      const preferences = currentUser.preferences || {}
      const { timezone } = preferences
      if (!timezone) {
        Bugsnag.notify(new Error('timezone is missing in preferences'))
      }
      const decorateGivenActor = recipient => {
        return decorateActor(
          recipient,
          agentsById,
          currentUser,
          isTwitterComment(change),
          customersById
        )
      }
      const { parts, attachments } = change
      const bodyParts =
        parts && parts.length > 0
          ? embedAttachments(parts, attachments)
          : embedAttachments(parseBody(change.body), attachments)
      Object.assign(change, {
        from: action.actor,
        body: change.body,
        parsedBody: bodyParts,
        to: (change.to || []).map(decorateGivenActor),
        cc: (change.cc || []).map(decorateGivenActor),
        bcc: (change.bcc || []).map(decorateGivenActor),
        isIncomingEmail: isIncomingEmail(change),
        isWithCollaborator: isWithCollaborator(change),
        isWithCustomer: isWithCustomer(change),
        createdAt: action.created_at,
        createdAtInAccountTimezone: shiftedDate(
          action.created_at,
          timezone ? timezone.account.offset : 0
        ),
        createdAtInAgentTimezone: shiftedDate(
          action.created_at,
          timezone ? timezone.agent.offset : 0
        ),
        lastCustomerAction: getLastCustomerAction(
          action.change.id,
          ratingsByMessageId,
          readReceiptsByMessageId
        ),
      })
    }

    return change
  },
  {
    serializer: objectHashSerializer,
  }
)

const decoratePreviousGroup = memoize((action, previousGroup) => {
  if (action.change_type === 'Group' || action.change_type === 'Agent') {
    return { previousGroup }
  }
  return undefined
})

const getUpdatedPreviousGroup = (action, previousGroup) => {
  if (action.change_type === 'Group') {
    return action.change.id
  }
  return previousGroup
}

const isSameActorByIdAndType = (a, b) => {
  return a && b && a.type === b.type && a.id === b.id
}

const decorate = memoize(
  (
    changesets = [],
    agentsById,
    currentUser,
    ratingsByMessageId,
    readReceiptsByMessageId,
    customersById
  ) => {
    let previousGroup = null
    let previousActor = null

    return changesets.map(changeset =>
      Object.assign(changeset, {
        actions: changeset.actions.map(action => {
          const decorated = {
            ...action,
            isSubsequentActionByActor: isSameActorByIdAndType(
              action.actor,
              previousActor
            ),
            actor: decorateActor(
              action.actor,
              agentsById,
              currentUser,
              isTwitterComment(action.change),
              customersById
            ),
            change: decorateChange(
              action,
              agentsById,
              currentUser,
              ratingsByMessageId,
              readReceiptsByMessageId,
              customersById
            ),
            ...decoratePreviousGroup(action, previousGroup),
          }
          previousGroup = getUpdatedPreviousGroup(action, previousGroup)
          previousActor = action.actor
          return decorated
        }),
      })
    )
  },
  {
    serializer: objectHashSerializer,
  }
)

export const buildChangesets = memoize(
  (
    originalChangesets = [],
    agentsById = {},
    currentUser,
    ratingsByMessageId,
    readReceiptsByMessageId,
    customersById
  ) => {
    let changesets = [].concat(originalChangesets.map(deepCopy))
    changesets = removeLabelChanges(changesets)
    changesets = deleteUndoneMessages(changesets)
    changesets = addDummyClosedActionToUnsnoozes(changesets)
    changesets = combineCloseChangeSets(changesets)
    // reorder changesets so Message actions always first
    changesets = sortActions(changesets)
    changesets = changesets.map(combineAgentAndGroupActions)
    changesets = decorate(
      changesets,
      agentsById,
      currentUser,
      ratingsByMessageId,
      readReceiptsByMessageId,
      customersById
    )

    return changesets
  },
  {
    serializer: objectHashSerializer,
  }
)

export const prepareChangesets = (originalChangesets = []) => {
  let changesets = [].concat(originalChangesets.map(deepCopy))
  changesets = removeLabelChanges(changesets)
  changesets = deleteUndoneMessages(changesets)
  changesets = addDummyClosedActionToUnsnoozes(changesets)
  changesets = combineCloseChangeSets(changesets)
  // reorder changesets so Message actions always first
  changesets = sortActions(changesets)
  changesets = changesets.map(combineAgentAndGroupActions)

  return changesets
}

export const decorateChangesetsForInspector = memoize(
  (
    agentsById,
    currentUser,
    ratingsByMessageId,
    readReceiptsByMessageId,
    customersById
  ) => {
    return memoize(
      ticketChangesets => {
        const changesets = buildChangesets(
          ticketChangesets,
          agentsById,
          currentUser,
          ratingsByMessageId,
          readReceiptsByMessageId,
          customersById
        )
        const messageChangesets = changesets.filter(
          (changeset, index) =>
            changeset.actions.filter(action => isCollapsable(action, index))
              .length > 0
        )

        const changesetIds = changesets.map(changeset => changeset.id)
        const messageChangesetIndexes = messageChangesets.map(
          messageChangeset => changesetIds.indexOf(messageChangeset.id)
        )

        const changesetsGroupedByMessage = messageChangesetIndexes.map(
          (changesetIndex, index) => {
            const nextChangesetIndex = messageChangesetIndexes[index + 1]

            // put separators in their own group
            if (changesets[changesetIndex].id === 'separator') {
              return changesets.slice(changesetIndex, nextChangesetIndex)
            }

            if (changesets[changesetIndex].id.match('collapsed')) {
              return changesets.slice(changesetIndex, nextChangesetIndex)
            }

            const groupedChangesets = changesets.slice(
              changesetIndex,
              nextChangesetIndex
            )

            // If the first changeset is from a merge then we need to grab actions
            // from subsequent changesets and roll them up into the merge
            // changeset. This avoids stray ticket state or assignment actions
            // hanging around between collapsed messages
            if (groupedChangesets[0] && groupedChangesets[0].isFromMerge) {
              const actions = groupedChangesets.map(
                changeset => changeset.actions
              )
              const newChangeset = [
                {
                  ...groupedChangesets[0],
                  actions: [].concat(...actions),
                },
              ]

              return newChangeset
            }

            return groupedChangesets
          }
        )

        return {
          count: changesets.length,
          raw: changesets,
          changesetsGroupedByMessage,
          hasMultipleMessages: hasMultipleMessageActions(ticketChangesets),
        }
      },
      {
        serializer: objectHashSerializer,
      }
    )
  },
  {
    serializer: objectHashSerializer,
  }
)

export const selectChangesetsByTicketId = state =>
  state.tickets.changesetsById || emptyObj

export const selectChangesetsForTicketId = (state, ticketId) => {
  return selectChangesetsByTicketId(state)[ticketId] || emptyObj
}

export const selectCurrentTicketChangesetCollection = createSelector(
  selectCurrentTicketId,
  selectChangesetsByTicketId,
  (ticketId, changesets) => {
    return changesets[ticketId]
  }
)

export const selectCurrentTicketRawChangesets = createSelector(
  selectCurrentTicketChangesetCollection,
  collection => {
    return collection ? collection.changesets : emptyArr
  }
)

export const selectCurrentTicketRawActions = createSelector(
  selectCurrentTicketChangesetCollection,
  collection => {
    return collection ? collection.actions : emptyArr
  }
)

export const selectCurrentRawActionsForTicket = (state, ticketId) => {
  const collection = selectChangesetsByTicketId(state)[ticketId]
  if (!collection) return null
  return collection.actions
}

export const selectCurrentRawChangesetsForTicket = (state, ticketId) => {
  const collection = selectChangesetsByTicketId(state)[ticketId]
  if (!collection) return null
  return collection.changesets
}

export const selectCurrentTicketChangesets = createSelector(
  selectCurrentTicketChangesetCollection,
  collection => {
    return collection || emptyObj
  }
)

export const selectCurrentTicketMessages = createSelector(
  selectCurrentTicketChangesetCollection,
  collection => {
    return collection ? collection.messages : emptyArr
  }
)

export const selectCurrentTicketChangesetsLoaded = createSelector(
  selectCurrentTicketId,
  selectChangesetsByTicketId,
  (ticketId, changesets) => {
    return !!changesets[ticketId]
  }
)

export const selectHasTicketLoadedChangesets = (state, ticketId) => {
  const byId = selectChangesetsByTicketId(state)
  return !!byId[ticketId]
}

export const selectLastChangeset = createSelector(
  selectCurrentTicketRawChangesets,
  changesets => last(changesets, {})
)

export const selectLastChangesetId = createSelector(
  selectLastChangeset,
  changeset => (changeset ? changeset.id : undefined)
)

export const selectFetchedChangesets = state => state.tickets.fetchedChangesets

export const selectIsChangesetFetched = (state, id) =>
  !!(state.tickets.fetchedChangesets && state.tickets.fetchedChangesets[id])

export const selectLastNote = createSelector(
  selectCurrentTicketChangesetCollection,
  collection => collection && collection.lastNoteAction
)

export const selectMessageCount = createSelector(
  selectCurrentTicketChangesetCollection,
  collection => collection && collection.messageCount
)

export const selectLastMessage = createSelector(
  selectCurrentTicketChangesetCollection,
  collection => collection && collection.lastMessage
)

export const selectLastMessageId = createSelector(
  selectLastMessage,
  message => message && message.changeset
)

export const selectComputedReactions = createSelector(
  selectCurrentTicketChangesetCollection,
  collection => collection.computedReactions || emptyArr
)

export const selectReactionsForCommentId = createCachedSelector(
  selectComputedReactions,
  (state, commentId) => commentId,
  (reactionsForTicket, commentId) => reactionsForTicket[commentId] || emptyObj
)((state, commentId) => commentId || 'unknown')

export default {
  combineAgentAndGroupActions,
  buildChangesets,
  getActor,
  actorLabel,
  isTwitterComment,
  getTwitterHandle,
}
