// Simple text-based 'Loading...' component.
import React from 'react'
import cn from 'classnames'
import styled from '@emotion/styled'

const TextLoader = ({ className, text, fullScreen, style }) => (
  <div className={cn(className, 'loader', { fullScreen })} style={style}>
    {text || 'Loading...'}
  </div>
)

export default styled(TextLoader)`
  text-align: center;
  margin: 0 auto;
  color: ${props => props.theme.color.monochrome.superDark};
  padding: ${props => props.theme.spacing['4']};

  &.fullScreen {
    margin-top: 150px;
  }
`
