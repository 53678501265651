import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'

import { DEFAULT_SORT_ORDER } from 'constants/defaults'

import {
  isAuthenticatedSelector,
  fetchingStatusesSelector,
  selectCurrentSortOrder,
} from 'selectors/app'
import {
  selectCurrentPath as currentPathSelector,
  selectIsInInbox,
  selectIsRFRBeingStupid,
} from 'selectors/location'
import { selectAccountPreferenceSortByCollaboratorCommentEnabled } from 'selectors/app/selectAccountPreferences'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { selectPrefersUnifiedInbox } from 'ducks/currentUser/selectors/preferences/selectPrefersUnifiedInbox'
import { actorLabel, labelForCurrentUser } from 'selectors/currentChangesets'
import { selectAgentsById, selectAgents } from 'selectors/agents/base'
import { selectCustomersById } from 'ducks/customers/selectors'

import { selectRawById } from 'selectors/tickets/byId/selectRawById'
import { selectTicketsById } from 'selectors/tickets/byId/selectTicketsById'

import { findFirst, isEmpty } from 'util/arrays'
import { getActor } from 'util/actors'
import debug from 'util/debug'
import {
  constructSearchQueryObject,
  constructSearchQueryString,
  constructEncodedSearchQueryString,
  constructGraphQLSearchQueryObject,
  constructSearchQueryTitle,
  reverseSearchIdValueMap,
  buildSearchPath,
} from 'util/search'
import isQueryStringComplete from 'util/search/isQueryStringComplete'
import isSearchValid from 'util/search/isSearchValid'
import { toGraphQL } from 'util/search/sorting'
import { isCapitalized } from 'util/strings'
import { objectHashSerializer, memoize } from 'util/memoization'
import { equals } from 'util/objects'
import { SEARCHES } from 'constants/action_types'
import { selectRequestByType } from 'ducks/requests/selectors'

import {
  selectCurrentTicketSearchQueryString,
  selectTicketSearchResultsByQueryId,
  selectTicketSearchOperatorValueMap,
  selectCurrentTicketSearchQueryObject,
  selectCurrentTicketSearchQueryId,
  selectCurrentTicketSearch,
  selectCurrentTicketSearchResultTicketIds,
  selectHasCurrentTicketSearchLoaded,
} from './base'

export const selectIsCurrentTicketSearchQueryValid = createSelector(
  selectCurrentTicketSearchQueryString,
  queryString => isSearchValid(queryString)
)

export const selectIsCurrentTicketSearchQueryStringComplete = createSelector(
  selectCurrentTicketSearchQueryString,
  queryString => isQueryStringComplete(queryString)
)

const emptyArray = []

export const selectCurrentTicketSearchAssigneeIds = createSelector(
  selectCurrentTicketSearchQueryObject,
  queryObject => queryObject.assignee || emptyArray
)

export const selectCurrentTicketSearchGroupIds = createSelector(
  selectCurrentTicketSearchQueryObject,
  queryObject => queryObject.assigned_group || emptyArray
)

export const selectCurrentSearchIsFolderSearch = createSelector(
  selectCurrentTicketSearchQueryObject,
  queryObject => !!queryObject.folder
)

export const selectCurrentTicketSearchLabelNames = createSelector(
  selectCurrentTicketSearchQueryObject,
  selectTicketSearchOperatorValueMap,
  (queryObject, valueMap) => {
    const reversedMap = reverseSearchIdValueMap(valueMap) || {}
    const labelMap = reversedMap.label
    const queryLabels = queryObject.label || []
    return queryLabels.map(label => {
      return labelMap[label] || label
    })
  }
)

export const selectCurrentTicketSearchDeleted = createSelector(
  selectCurrentTicketSearchQueryObject,
  queryObject => queryObject.deleted || []
)

export const selectCurrentTicketSearchQueryObjectForAPI = createSelector(
  selectCurrentTicketSearchQueryObject,
  selectTicketSearchOperatorValueMap,
  (queryObject, valueIdMap) => {
    if (!queryObject) return {}
    return constructGraphQLSearchQueryObject(
      Object.assign({}, queryObject),
      valueIdMap
    )
  }
)

// NOTE (jscheel): Peformance fix. Our root reducer is rather complex and builds
// new objects all in the searches.byQueryId. However, this selector is important
// and used/composed in several places that could benefit from getting _just_
// the counts back. Because the input to this selector gets invalidated often by
// our root reducer, we instead invert the memoization and only return a new
// counts object if anything has actually changed in the output of this selector.
// eslint-disable-next-line no-underscore-dangle
let _lastSelectTicketSearchTotalCountsByQueryIdResult = null
export const selectTicketSearchTotalCountsByQueryId = createSelector(
  selectTicketSearchResultsByQueryId,
  byQueryId => {
    const retVal = Object.keys(byQueryId).reduce((counts, queryString) => {
      // eslint-disable-next-line no-param-reassign
      counts[queryString] = byQueryId[queryString].totalCount
      return counts
    }, {})
    if (!equals(retVal, _lastSelectTicketSearchTotalCountsByQueryIdResult)) {
      _lastSelectTicketSearchTotalCountsByQueryIdResult = retVal
    }
    return _lastSelectTicketSearchTotalCountsByQueryIdResult
  }
)

export const selectCurrentTicketSearchResultTotalCount = createSelector(
  selectCurrentTicketSearch,
  search => search && search.totalCount
)

export const selectHasCurrentTicketSearchErrored = createSelector(
  selectCurrentTicketSearch,
  search => {
    if (!search) return false
    return !!search.errored
  }
)

export const selectCurrentTicketSearchResultsStale = createSelector(
  selectCurrentTicketSearch,
  search => {
    if (!search) return null
    return !!search.resultsStale
  }
)

export const selectAreCurrentTicketSearchResultsIncomplete = createSelector(
  selectCurrentTicketSearch,
  search => {
    if (!search) return null
    return !!search.resultsIncomplete
  }
)

export const selectIsFetchingSearchResults = createSelector(
  fetchingStatusesSelector,
  statuses => {
    return statuses.fetchTicketSearch || statuses.fetchTicketByNumberForSearch
  }
)

export const selectCurrentTicketSearchSummaries = createSelector(
  selectCurrentTicketSearch,
  searchObject => {
    if (!searchObject) return {}
    const { highlights } = searchObject
    if (!highlights) return {}
    return highlights
  }
)

const highlightExtraKeywords = memoize((body, keywords) => {
  // tokenize
  const tokenSplitter = ' '
  const tokens = body.split(tokenSplitter)

  // build a lookup table to quickly check if token should be highlighted
  const lookup = {}
  keywords.forEach(keyword => (lookup[keyword.toLowerCase()] = true))

  const converted = tokens.map(token => {
    const lowerCaseToken = token.toLowerCase()
    return lookup[lowerCaseToken] ? `<highlight>${token}</highlight>` : token
  })

  return converted.join(tokenSplitter)
})

const decorateSummaryAuthor = memoize(
  (summaryActor, currentUser, isTwitterTicketActor) => {
    const actor = { ...summaryActor }
    if (actor.type === 'Agent') {
      actor.label =
        labelForCurrentUser(actor, currentUser) ||
        (actor.username || '').replace('@', '')
    } else {
      actor.label = actorLabel(summaryActor, currentUser, isTwitterTicketActor)
    }
    return actor
  },
  {
    serializer: objectHashSerializer,
  }
)

const decorateSearchResultSummary = memoize(
  (baseBody, keywords) => {
    let body = baseBody ? baseBody.trim() : ''
    const taglessBody = body.replace(/<\/*[^>]+>/g, '')
    if (!isCapitalized(taglessBody)) body = `...${body}`
    if (keywords) {
      body = highlightExtraKeywords(body, keywords)
    }
    body = body.replace(/<highlight>/g, '<b class="highlight">')
    body = body.replace(/<\/highlight>/g, '</b>')
    return body
  },
  {
    serializer: objectHashSerializer,
  }
)

const decorateSearchResult = memoize(
  (baseTicket, summary, summaryActor, currentUser, currentSearchObject) => {
    const ticket = { ...baseTicket }
    if (summary) {
      const keywords = currentSearchObject ? currentSearchObject.keywords : null
      const body = decorateSearchResultSummary(summary.body, keywords)
      const author = decorateSummaryAuthor(
        summaryActor,
        currentUser,
        ticket.isTwitterTicket
      )
      ticket.bodyAuthor = author
      ticket.bodyType = summary.note ? 'internal' : 'enduser'
      ticket.body = body
      ticket.bodyParsed = body
    }

    return ticket
  },
  {
    serializer: objectHashSerializer,
  }
)

function getSummaryActor(agentsById, customersById, summary) {
  if (!summary || summary.author || summary.author.id) return null
  return getActor(summary.author, agentsById, customersById)
}

function selectSearchResult(
  byId,
  summaries,
  agentsById,
  customersById,
  currentUser,
  currentSearchObject,
  searchLabelNames
) {
  return id => {
    const ticket = byId[id]
    const summary = summaries[id]
    return decorateSearchResult(
      ticket,
      summary,
      getSummaryActor(agentsById, customersById, summary),
      currentUser,
      currentSearchObject,
      searchLabelNames
    )
  }
}

export const mapTickets = (...args) => {
  const [
    byId,
    results,
    summaries,
    agentsById,
    customersById,
    currentUser,
    currentSearchObject = null,
    searchLabelNames = [],
  ] = args
  if (!results) return null
  const resultIds = [].concat(results)
  const mapFn = selectSearchResult(
    byId,
    summaries,
    agentsById,
    customersById,
    currentUser,
    currentSearchObject,
    searchLabelNames
  )
  const mappedResults = resultIds.map(mapFn)
  const orderedResults = [].concat(mappedResults)
  return orderedResults
}

export const selectCurrentTicketSearchResultTickets = createSelector(
  selectTicketsById,
  selectCurrentTicketSearchResultTicketIds,
  selectCurrentTicketSearchSummaries,
  selectAgentsById,
  selectCustomersById,
  selectCurrentUser,
  selectCurrentTicketSearchQueryObject,
  selectCurrentTicketSearchLabelNames,
  (
    byId,
    results,
    summaries,
    agentsById,
    customersById,
    currentUser,
    currentSearchObject,
    searchLabelNames
  ) => {
    return mapTickets(
      byId,
      results,
      summaries,
      agentsById,
      customersById,
      currentUser,
      currentSearchObject,
      searchLabelNames
    )
  }
)

export const selectCurrentTicketSearchResultRawTickets = createSelector(
  selectRawById,
  selectCurrentTicketSearchResultTicketIds,
  (byId, results) => results.map(id => byId[id])
)

const withId = id => e => e && e.id === id

export const selectSearchResultTicket = createCachedSelector(
  selectCurrentTicketSearchResultTickets,
  (_state, id) => id,
  (results, id) => findFirst(results, withId(id))
)((_state, id) => id || 'unknown')

export const selectRawSearchResultTicket = (state, id) => {
  const byId = selectRawById(state)
  return byId[id] || null
}

export const selectCurrentTicketSearchQueryTitle = createSelector(
  selectCurrentTicketSearchQueryString,
  selectTicketSearchOperatorValueMap,
  (currentSearch, valueIdMap) => {
    return constructSearchQueryTitle(currentSearch, valueIdMap)
  }
)

export const selectLatestTicketSearchQueryId = state =>
  state.search && state.search.lastSearchQueryId

export const selectLatestTicketSearchQueryObject = createSelector(
  selectLatestTicketSearchQueryId,
  selectTicketSearchOperatorValueMap,
  (queryId, valueMap) => {
    return constructSearchQueryObject(queryId, valueMap)
  }
)

export const selectLatestTicketSearchQueryString = createSelector(
  selectLatestTicketSearchQueryObject,
  queryObject => {
    return constructSearchQueryString(queryObject)
  }
)

export const selectLatestTicketSearchEncodedQueryString = createSelector(
  selectLatestTicketSearchQueryObject,
  queryObject => {
    return constructEncodedSearchQueryString(queryObject)
  }
)

// Returns null for free form searches, or the title of a named search.
// Returns undefined if no current search at all.
export const selectLatestTicketSearchQueryTitle = createSelector(
  selectLatestTicketSearchQueryString,
  selectTicketSearchOperatorValueMap,
  selectLatestTicketSearchQueryId,
  (currentSearch, valueIdMap, queryId) => {
    if (!queryId) return undefined
    return constructSearchQueryTitle(currentSearch, valueIdMap)
  }
)

export const selectLatestTicketSearchQueryObjectString = createSelector(
  selectLatestTicketSearchQueryString,
  selectTicketSearchOperatorValueMap,
  (queryString, operatorValueMap) => {
    return constructSearchQueryString(queryString, operatorValueMap)
  }
)

export const selectCurrentTicketSearchSortOrder = createSelector(
  selectCurrentTicketSearch,
  currentSearch => {
    return (currentSearch && currentSearch.sortOrder) || DEFAULT_SORT_ORDER
  }
)

export const selectCurrentTicketSearchIsLoaded = createSelector(
  selectCurrentTicketSearch,
  currentSearch => {
    return (currentSearch && currentSearch.loaded) || false
  }
)

// Note we pull the sort order out of the app.currentTicketList and not the
// search byQueryId as the latter wont be present on direct page loads
export const selectCurrentTicketSearchSortOrderForGraphQL = createSelector(
  selectCurrentSortOrder,
  selectAccountPreferenceSortByCollaboratorCommentEnabled,
  (sorting, sortByCollaboratorCommentAtEnabled) => {
    return toGraphQL(sorting, {
      sortByCollaboratorCommentAtEnabled,
    })
  }
)

export const shouldFetchTicketSearchByQueryString = createCachedSelector(
  selectCurrentTicketSearchQueryId,
  selectCurrentTicketSearchResultTicketIds,
  selectCurrentTicketSearchResultsStale,
  selectHasCurrentTicketSearchErrored,
  selectIsCurrentTicketSearchQueryStringComplete,
  selectCurrentTicketSearchIsLoaded,
  selectIsCurrentTicketSearchQueryValid,
  selectCurrentSortOrder,
  selectCurrentTicketSearchSortOrder,
  selectIsRFRBeingStupid,
  (_state, isRefreshBtnClick) => isRefreshBtnClick,
  (
    queryID,
    ticketIds,
    resultsStale,
    errored,
    complete,
    isLoaded,
    valid,
    currentSortOrder,
    ticketListSortOrder,
    beingStupid,
    isRefreshBtnClick
  ) => {
    if (beingStupid && !isRefreshBtnClick) {
      debug('redundant page fetch detected')
      return false
    }
    if (!isEmpty(ticketIds) && currentSortOrder !== ticketListSortOrder)
      return true
    if (isLoaded && !resultsStale) return false
    if (!isEmpty(ticketIds) && !resultsStale) return false
    if (!complete) return false
    if (errored) return false
    if (!valid) return false
    return !!queryID
  }
)((_state, isRefreshBtnClick) => (isRefreshBtnClick ? 'refresh' : 'no-refresh'))

export const shouldUpdateLatestTicketSearch = createSelector(
  selectLatestTicketSearchQueryId,
  selectCurrentTicketSearchQueryId,
  selectIsInInbox,
  (latest, current, inInbox) => {
    return inInbox && current && latest !== current
  }
)

export const selectIsTypedSearch = state => state.search?.isTypedSearch || false

export const selectIsFreeFormSearch = createSelector(
  selectLatestTicketSearchQueryTitle,
  title => title === null
)

export const shouldFetchDefaultPinnedSearch = createSelector(
  selectIsInInbox,
  isAuthenticatedSelector,
  currentPathSelector,
  fetchingStatusesSelector,
  selectAgents,
  selectHasCurrentTicketSearchLoaded,
  selectCurrentTicketSearchResultsStale,
  selectHasCurrentTicketSearchErrored,
  (
    inInbox,
    auth,
    path,
    statuses,
    agents,
    hasSearchLoaded,
    resultsStale,
    errored
  ) => {
    if (!inInbox) return false
    if (!auth) return false
    if (path !== '/') return false
    if (statuses.fetchTicketSearch) return false // if we're currently fetching, we should never trigger another fetch
    if (!agents || agents.length === 0) return false // we need agents to search by assignee
    if (hasSearchLoaded && !resultsStale) return false
    if (errored) return false
    return true
  }
)

export const shouldFetchIncompleteSearch = createSelector(
  selectIsInInbox,
  isAuthenticatedSelector,
  currentPathSelector,
  fetchingStatusesSelector,
  selectAgents,
  selectHasCurrentTicketSearchLoaded,
  selectAreCurrentTicketSearchResultsIncomplete,
  selectHasCurrentTicketSearchErrored,
  (
    inInbox,
    auth,
    path,
    statuses,
    agents,
    hasSearchLoaded,
    resultsIncomplete,
    errored
  ) => {
    if (!inInbox) return false
    if (!auth) return false
    if (statuses.fetchTicketSearch) return false // if we're currently fetching, we should never trigger another fetch
    if (!agents || agents.length === 0) return false // we need agents to search by assignee
    if (errored) return false
    if (hasSearchLoaded && resultsIncomplete) return true
    return false
  }
)

export const selectMailboxFolderSearchPath = createCachedSelector(
  selectPrefersUnifiedInbox,
  (_state, mailboxId) => mailboxId,
  (_state, _mailboxId, folderId) => folderId,
  (prefersUnifiedInbox, mailboxId, folderId) => {
    const query = {}

    if (!prefersUnifiedInbox) {
      query.inbox = mailboxId
    }

    if (folderId) {
      query.folder = folderId
    }

    return buildSearchPath({})(query)
  }
)(
  (_state, mailboxId, folderId) =>
    `${mailboxId || 'unknown'}-${folderId || 'unknown'}`
)

export const selectSearchesRequestState = state =>
  selectRequestByType(state, SEARCHES)
