/* global tinymce */

import themeVars from 'ui_theme/site/globals/site.variables'

let styleLoaded = false

const style = `
  .groove-inline-placeholder:before {
    content: attr(placeholder);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    color: ${themeVars.lightTextColor};
    cursor: text;
    font-style: italic;
  }
`

tinymce.PluginManager.add('groove-inline-placeholder', editor => {
  editor.on('init', () => {
    const placeholder = editor.settings.placeholder
    if (!placeholder) return

    const el = editor.getElement()
    if (el) {
      el.setAttribute('placeholder', placeholder)
    }
    if (!styleLoaded) {
      tinymce.DOM.addStyle(style)
      styleLoaded = true
    }
    togglePlaceholder()
    editor.on('focus', onFocus)
    editor.on('blur', onBlur)
    editor.on('change', onBlur)
    editor.on('setContent', onBlur)
    editor.on('keydown', onKeydown)
    editor.on('keyup', onKeyUp)
  })

  function togglePlaceholder() {
    if (!editor.getContent()) {
      showPlaceholder()
    } else {
      hidePlaceholder()
    }
  }

  function onFocus() {
    editor.execCommand('mceFocus', false)
  }

  function onBlur() {
    togglePlaceholder()
  }

  function onKeydown() {
    hidePlaceholder()
  }

  function onKeyUp() {
    if (!editor.getContent()) {
      showPlaceholder()
    }
  }

  function showPlaceholder() {
    const el = editor.getElement()
    tinymce.DOM.addClass(el, 'groove-inline-placeholder')
  }

  function hidePlaceholder() {
    const el = editor.getElement()
    tinymce.DOM.removeClass(el, 'groove-inline-placeholder')
  }
})
