import * as types from 'constants/action_types'
import { reduceActor } from 'util/actors'

const defaultState = {
  byMessageId: {},
}

const reducers = {}

const captureRatings = function(state, array) {
  const byMessageId = Object.assign({}, state.byMessageId)
  let hasChanges = false

  array.forEach(record => {
    if (record.change_type === 'Rating') {
      hasChanges = true
      byMessageId[record.change.message] = {
        actor: reduceActor(record.actor),
        grade: record.change.grade,
        feedback: record.change.feedback,
        change_type: 'Rating',
      }
    }
  })

  if (!hasChanges) return state

  return Object.assign({}, state, { byMessageId })
}

reducers[types.FETCH_TICKET_ACTIONS_SUCCESS] = function(state, action) {
  const { actions } = action.data
  return captureRatings(state, actions.records)
}

reducers[types.FETCH_CHANGESET_TICKET_ACTIONS_SUCCESS] = function(
  state,
  action
) {
  const { actions } = action.data
  return captureRatings(state, actions)
}

reducers[types.FETCH_TICKET_SUCCESS] = function(state, action) {
  const { ticket } = action.data
  return captureRatings(state, ticket.actions.records)
}

export default function reducer(state = defaultState, action) {
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
