import tinymce from 'tinymce/tinymce' //eslint-disable-line no-unused-vars
import { Editor } from './tinymce-react'

require('tinymce/themes/modern/theme')
require('tinymce/plugins/link/plugin')
require('tinymce/plugins/image/plugin')
require('tinymce/plugins/code/plugin')
require('tinymce/plugins/lists/plugin')
require('shared/editor/plugins/paste')
require('tinymce/plugins/autolink/plugin')
require('shared/editor/plugins/placeholder')
require('shared/editor/plugins/charactercount')

import skin from './skin' // eslint-disable-line
import styles from './Basic.css' // eslint-disable-line

export default Editor
