/**
 * Twitter chars remaining.
 * Partial Credit: https://amystechnotes.com/2015/05/06/tinymce-add-character-count/
 */

// eslint-disable-next-line no-undef
tinymce.PluginManager.add('charactercount', editor => {
  const TWITTER_CHAR_LIMIT = 280
  let getCountPrefix = () => {}

  function update() {
    editor.theme.panel.find('#charactercount').text(['{0}', getRemaining()])
  }

  function getCount() {
    const tx = editor.getContent({ format: 'raw' })
    const decoded = decodeHtml(tx)
    const decodedStripped = decoded.replace(/(<([^>]+)>)/gi, '')
    const prefix = getCountPrefix && getCountPrefix()
    const prefixCount = prefix ? prefix.length : 0
    return decodedStripped.length + prefixCount
  }

  function getRemaining() {
    return TWITTER_CHAR_LIMIT - getCount()
  }

  function decodeHtml(html) {
    const txt = document.createElement('textarea')
    txt.innerHTML = html
    return txt.value
  }

  editor.on('init', () => {
    getCountPrefix = editor.getParam('getCountPrefix')

    const statusbar =
      editor.theme.panel && editor.theme.panel.find('#statusbar')[0]

    if (statusbar) {
      window.setTimeout(() => {
        statusbar.insert(
          {
            type: 'label',
            name: 'charactercount',
            text: TWITTER_CHAR_LIMIT,
            classes: 'charactercount',
            disabled: editor.settings.readonly,
          },
          0
        )

        editor.on('setcontent beforeaddundo keyup', update)
        update()
      }, 0)
    }
  })
})
