import cn from 'classnames'
import React from 'react'
import styled from '@emotion/styled'

import { Portal } from 'util/ui'

import TooltipPopup from './TooltipPopup'

function tooltipPositionStyles(
  position,
  boundingBox,
  offsetX = 0,
  offsetY = 0
) {
  const { left, width, top, height } = boundingBox
  switch (position) {
    case 'top':
      return {
        top: Math.floor(top + offsetY),
        left: Math.floor(left + Math.floor(width / 2) + offsetX),
      }
    case 'left':
      return {
        top: Math.floor(top + Math.floor(height / 2) + offsetY),
        left: Math.floor(left + offsetX),
      }
    case 'right':
      return {
        top: Math.floor(top + Math.floor(height / 2) + offsetY),
        left: Math.floor(left + width + offsetX),
      }
    case 'bottom':
    default:
      return {
        top: Math.floor(top + height + offsetY),
        left: Math.floor(left + Math.floor(width / 2) + offsetX),
      }
  }
}

function Tooltip({
  as: Component,
  boundingBox,
  children,
  className,
  color,
  offsetX,
  offsetY,
  onMouseEnter,
  onMouseLeave,
  passedProps,
  portal,
  position,
  showTooltip,
  takeRef,
  tooltip,
  tooltipClassName,
  tooltipCss,
  disabled = false,
}) {
  const { className: passedClass, id, onClick, style } = passedProps
  const Wrapper = portal ? Portal : React.Fragment
  const wrapperProps = portal ? { open: true } : {}
  return (
    <Component
      id={id}
      style={style}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cn(className, passedClass)}
      ref={takeRef}
    >
      {showTooltip &&
        !disabled && (
          <Wrapper {...wrapperProps}>
            <TooltipPopup
              style={{
                ...tooltipPositionStyles(
                  position,
                  boundingBox,
                  offsetX,
                  offsetY
                ),
              }}
              css={tooltipCss}
              className={cn(
                'tooltip',
                color,
                position || 'bottom',
                tooltipClassName
              )}
            >
              {tooltip}
            </TooltipPopup>
          </Wrapper>
        )}
      {children}
    </Component>
  )
}

export default styled(Tooltip)`
  position: relative;
`
