/* eslint-disable no-multi-assign */ // ok in reducers
import * as types from 'constants/action_types'
import * as pages from 'constants/pages'

import { DEFAULT_SORT_ORDER } from 'constants/defaults'

import {
  FETCH_CONVERSATION_CONTACT_PAGE_SUCCESS,
  CHANGE_CONVERSATION_CONTACT_SUCCESS,
  FETCH_CONTACT_SUCCESS,
} from 'ducks/crm/contacts/types'
import { reduceActor } from 'util/actors'
import deepEqual from 'fast-deep-equal'
import { any, diff, isEmpty, emptyArr, wrapInArray } from 'util/arrays'
import { isMessage, isNote } from 'util/changesets'

import { timeInHuman } from 'util/date'

import { isMobile } from 'util/media_query'
import { deepCopy, mapObject, emptyObj } from 'util/objects'
import { reduceLabels, isTwitter, isFacebook } from 'util/ticket'
import {
  buildMessageCollection,
  getUpdatedTicketBodyAttributes,
} from 'util/messages'
import { SNOOZED_INDEFINITELY, snoozeOptions } from 'util/snooze'
import {
  getStateLabel,
  isOpen,
  isClosed,
  isSpam,
  isUnread,
  isDeleted,
  isCloseable,
  isStarred,
} from 'util/ticketState'
import {
  MESSAGE,
  COMMENT_DELETION,
  COMMENT_EDIT,
} from 'constants/changesetActionChangeTypes'
import {
  MERGE_TICKET_SUCCESS,
  FETCH_MERGEABLE_TICKETS_SUCCESS,
} from 'ducks/merging/types'

// HACK (jscheel): Part of doMarkAsRead hack below.
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'
import { buildDraftDefaults, buildNoteDefaults } from 'ducks/drafts2/util'
import { UPDATE_PREFERENCES_SUCCESS } from 'ducks/currentUser/types'
// END HACK

const defaultState = {
  byId: {
    new: { id: 'new', actions: { records: [] }, priority: 'low' },
  },
  isBulkSelectionMode: false,
  isMerging: false,
  optimisticMergeTicketsById: {},
  previousSortBy: DEFAULT_SORT_ORDER,
  randomSeed: Math.random(),
  selected: [],
  showMergeSnippets: false,
  snippetsById: {},
  sortBy: DEFAULT_SORT_ORDER,
  dirtyTicketIds: emptyObj,
  gcCandidates: emptyArr,
  lastSnoozedDate: null,
}

const defaultLoadedTicketState = {
  actions: { records: emptyArr },
  changesets: emptyArr,
}

const reducers = {}

const buildTicket = (original = {}, updated) => {
  const ticket = {
    ...original,
    ...updated,
    ...(updated && reduceLabels(updated.labels)),
  }

  const stateLabel = getStateLabel(
    ticket.state,
    ticket.snoozedUntil,
    ticket.deleted_at
  )

  if (deepEqual(original.bodyAuthor, ticket.bodyAuthor)) {
    ticket.bodyAuthor = original.bodyAuthor
  }

  if (deepEqual(original.labelIds, ticket.labelIds)) {
    ticket.labelIds = original.labelIds // maintain old array
  }

  return {
    ...ticket,
    assigneeId: ticket.assignee && ticket.assignee.id,
    assignedGroupId: ticket.assigned_group_id,
    updatedAtInHuman: timeInHuman(ticket.updated_at),
    latestCollaboratorCommentAtInHuman: timeInHuman(
      ticket.latest_collaborator_comment_at
    ),
    actions: null,
    titleLabel: ticket.title,
    customerId: ticket.customer && ticket.customer.id,
    stateLabel,
    snoozedUntil: ticket.snoozedUntil,
    hasAttachments: ticket.attachment_count > 0,
    isStarred: isStarred(ticket),
    isOpen: isOpen(ticket),
    isClosed: isClosed(ticket),
    isSpam: isSpam(ticket),
    isUnread: isUnread(ticket),
    isDeleted: isDeleted(ticket),
    isCloseable: isCloseable(stateLabel),
    isTwitterTicket: isTwitter(ticket),
    isFacebookTicket: isFacebook(ticket),
    linkedExternalResources: {
      ...original.linkedExternalResources,
      records:
        updated?.linkedExternalResources?.records ||
        original?.linkedExternalResources?.records,
    },
    channelType: 'email',
  }
}

reducers[types.SEARCH_SUCCESS] = reducers[FETCH_MERGEABLE_TICKETS_SUCCESS] = (
  state,
  action
) => {
  const data = action.data || action.payload
  const byId = state.byId || {}
  const newById = Object.assign({}, byId)
  const { tickets } = data
  // HACK (jscheel): Part of doMarkAsRead hack below.
  let currentTicketId
  try {
    if (app) selectCurrentTicketId(app.store.getState())
  } catch (e) {
    currentTicketId = null
  }
  // END HACK

  tickets.forEach(ticket => {
    const original = byId[ticket.id] || {}

    // HACK (jscheel): doMarkAsRead sends optimistic update in-flight, which
    // then gets overwritten by FETCH_MERGEABLE_TICKETS_SUCCESS. This causes a
    // temporary old status of unread which makes the ticket list item flash
    // blue when it shouldn't. This will be permanently fixed when this card
    // is completed:
    // When removing this hack, you will also need to remove the references to
    // currentTicketId above.
    if (
      action.type === FETCH_MERGEABLE_TICKETS_SUCCESS &&
      ticket.id === currentTicketId
    ) {
      return
    }
    // END HACK

    const newTicket = buildTicket(original, ticket)

    // we don't need search_summary in regular ticket storage, remove it
    delete newTicket.search_summary

    newById[newTicket.id] = newTicket
  })

  return Object.assign({}, state, {
    byId: newById,
  })
}

reducers[types.UPDATE_CURRENT_MAILBOX] = (state, action) => {
  const data = action.data
  const mailbox = data.currentMailbox
  const byId = state.byId || {}
  const newById = Object.assign({}, byId)
  const edges = mailbox.tickets.edges || []
  edges.forEach(edge => {
    const ticket = edge.node
    newById[ticket.id] = ticket
  })
  return Object.assign({}, state, {
    byId: newById,
  })
}

reducers[types.UPDATE_TICKETS] = (state, action) => {
  const updatedTickets = action.data.tickets
  const newById = { ...state.byId }
  const dirtyTicketIds = { ...state.dirtyTicketIds }
  let hasChanges = false
  updatedTickets.forEach(updatedTicket => {
    const originalTicket = newById[updatedTicket.id] || {}
    const newTicket = buildTicket(originalTicket, updatedTicket)
    if (!deepEqual(originalTicket, newTicket)) {
      hasChanges = true
      newById[newTicket.id] = newTicket
      dirtyTicketIds[newTicket.id] = true
    }
  })
  if (!hasChanges) return state
  return {
    ...state,
    dirtyTicketIds,
    byId: newById,
  }
}

reducers[types.DELETE_TICKETS_SUCCESS] = reducers[
  types.DELETE_TICKETS_STARTED
] = (state, action) => {
  const data = action.data
  const tickets = data.tickets
  const byId = state.byId || {}
  const newById = Object.assign({}, byId)
  tickets.forEach(ticket => {
    delete newById[ticket.id]
  })
  return Object.assign({}, state, {
    byId: newById,
  })
}

reducers[types.CURRENT_FOLDER_WITH_TICKETS_NEXT_PAGE_SUCCESS] = reducers[
  types.ADD_PAGE_TO_CURRENT_FOLDER
] = reducers[types.FETCH_FOLDER_WITH_TICKETS_SUCCESS] = (state, action) => {
  const data = action.data
  const folder = data.folder
  const byId = state.byId || {}
  const newById = Object.assign({}, byId)
  const tickets = folder.tickets
  const ticketRecords = tickets ? tickets.records : []
  const metadata = tickets ? tickets.metadata : {}
  const sortBy = metadata.sortBy
  const sortOrder = metadata.sortOrder

  ticketRecords.forEach(ticket => {
    newById[ticket.id] = {
      ...byId[ticket.id],
      ...ticket,
      ...reduceLabels(ticket.labels),
      customer: reduceActor({
        ...ticket.customer,
        type: 'Customer',
      }),
    }
  })

  return Object.assign({}, state, {
    currentPage: metadata.currentPage,
    totalPages: metadata.totalPages,
    byId: newById,
    previousSortBy: [sortBy, sortOrder].join(':'),
  })
}

reducers[types.FETCH_TICKET_SUCCESS] = (state, action) => {
  const data = action.data
  const byId = state.byId || {}
  const newById = { ...byId }
  const newCommentMap = Object.assign({}, state.commentMap)
  const original = byId[data.ticket.id]

  let actions = deepCopy(data.ticket.actions)

  const changesetsById = state.changesetsById || {}
  const ticket = buildTicket(original, data.ticket)

  if (actions) {
    actions.records.forEach(ticketAction => {
      const { change_type: type, change } = ticketAction
      if (change) {
        if (change.is_forward) change.isWithCollaborator = true
        if (change.has_attachments) change.hasAttachment = true
      }
      if (type === MESSAGE && change) {
        const commentId = change.id
        newCommentMap[commentId] = ticket.id
      }
    })

    const changesetCollection = changesetsById[ticket.id]
    if (changesetCollection) {
      const previousActions = changesetCollection.actions
      actions = mergeActions(actions, { records: previousActions })
    }
  }

  const newChangesetsById = { ...changesetsById }
  newChangesetsById[ticket.id] = buildMessageCollection(actions)

  if (state.dirtyTicketIds) {
    // eslint-disable-next-line no-param-reassign
    delete state.dirtyTicketIds[ticket.id]
  }

  ticket.customer.type = 'Customer'
  ticket.customer = reduceActor(ticket.customer)

  const newTicket = {
    ...ticket,
    actions: null,
  }

  const collection = newChangesetsById[ticket.id]
  newTicket.draftDefaults = buildDraftDefaults(state, newTicket, collection)
  newTicket.noteDefaults = buildNoteDefaults(state, newTicket, collection)

  newById[newTicket.id] = newTicket

  return {
    ...state,
    commentMap: newCommentMap,
    byId: newById,
    changesetsById: newChangesetsById,
  }
}

reducers[types.FETCH_TICKET_ACTIONS_SUCCESS] = (state, action) => {
  const { ticketId, actions } = action.data
  const newCommentMap = Object.assign({}, state.commentMap)

  if (actions) {
    actions.records.forEach(ticketAction => {
      const { change_type: type, change } = ticketAction

      if (change) {
        if (change.is_forward) change.isWithCollaborator = true
        if (change.has_attachments) change.hasAttachment = true
      }

      if (type === MESSAGE && change) {
        const commentId = change.id
        newCommentMap[commentId] = ticketId
      }
    })
  }

  const changesetsById = state.changesetsById || {}
  const newChangesetsById = { ...changesetsById }
  newChangesetsById[ticketId] = buildMessageCollection(actions)

  const ticket = state.byId[ticketId]
  const newTicket = {
    ...ticket,
  }

  const collection = newChangesetsById[ticket.id]
  newTicket.draftDefaults = buildDraftDefaults(state, newTicket, collection)
  newTicket.noteDefaults = buildNoteDefaults(state, newTicket, collection)

  const newState = {
    ...state,
    byId: {
      ...state.byId,
      [ticketId]: newTicket,
    },
    commentMap: newCommentMap,
    changesetsById: newChangesetsById,
  }

  return newState
}

reducers[types.FETCH_TICKET_LINKED_RESOURCES_REQUEST] = (state, action) => {
  const { meta: { ticketId } = {} } = action
  return {
    ...state,
    byId: {
      ...state.byId,
      [ticketId]: {
        ...state.byId[ticketId],
        linkedExternalResources: {
          ...state.byId[ticketId].linkedExternalResources,
          loading: true,
        },
      },
    },
  }
}

reducers[types.INTEGRATIONS_ATTACH_TO_TICKET_SUCCESS] = (state, action) => {
  const { item: { id } = {} } = action
  const actions = action.item.actions
  if (!actions) return {}

  const records = actions.records
  if (!records) return {}

  const firstRecord = records[0]
  if (!firstRecord) return {}

  const change = firstRecord.change
  const newRecord = {
    externalId: change.external_id,
    linkedAt: firstRecord.created_at,
    provider: change.provider,
    title: change.title,
    url: change.url,
  }

  return {
    ...state,
    byId: {
      ...state.byId,
      [id]: {
        ...state.byId[id],
        linkedExternalResources: {
          ...state.byId[id].linkedExternalResources,
          records: [
            ...(state.byId[id].linkedExternalResources.records || []),
            newRecord,
          ],
        },
      },
    },
  }
}

reducers[types.FETCH_TICKET_LINKED_RESOURCES_SUCCESS] = (state, action) => {
  const { meta: { ticketId } = {}, payload: linkedExternalResources } = action
  return {
    ...state,
    byId: {
      ...state.byId,
      [ticketId]: {
        ...state.byId[ticketId],
        linkedExternalResources: {
          records: linkedExternalResources,
          errored: false,
          loading: false,
        },
      },
    },
  }
}

reducers[types.FETCH_TICKET_LINKED_RESOURCES_FAIL] = (state, action) => {
  const { meta: { ticketId } = {} } = action
  return {
    ...state,
    byId: {
      ...state.byId,
      [ticketId]: {
        ...state.byId[ticketId],
        linkedExternalResources: {
          ...state.byId[ticketId].linkedExternalResources,
          errored: true,
          loading: false,
        },
      },
    },
  }
}

reducers[types.BULK_FETCH_TICKET_ACTIONS_SUCCESS] = (state, action) => {
  const payloads = action.data

  const newCommentMap = Object.assign({}, state.commentMap)
  const changesetsById = state.changesetsById || {}
  const byId = state.byId || {}
  const newChangesetsById = { ...changesetsById }
  const newById = { ...byId }

  payloads.forEach(single => {
    const { ticketId, actions } = single
    if (!actions) return
    if (actions) {
      if (!actions.records) return
      actions.records.forEach(ticketAction => {
        const { change_type: type, change } = ticketAction

        if (change) {
          if (change.is_forward) change.isWithCollaborator = true
          if (change.has_attachments) change.hasAttachment = true
        }

        if (type === MESSAGE && change) {
          const commentId = change.id
          newCommentMap[commentId] = ticketId
        }
      })
    }
    newChangesetsById[ticketId] = buildMessageCollection(actions)

    const ticket = byId[ticketId]
    const newTicket = { ...ticket }
    const collection = newChangesetsById[ticket.id]

    newTicket.draftDefaults = buildDraftDefaults(state, newTicket, collection)
    newTicket.noteDefaults = buildNoteDefaults(state, newTicket, collection)

    newById[newTicket.id] = newTicket
  })

  const newState = {
    ...state,
    byId: newById,
    commentMap: newCommentMap,
    changesetsById: newChangesetsById,
  }

  return newState
}

reducers[types.FETCH_CHANGESET_TICKET_ACTIONS_REQUEST] = (state, action) => {
  const { sourceChangesetId } = action.data
  const oldFetchedChangesets = state.fetchedChangesets || {}
  const fetchedChangesets = { ...oldFetchedChangesets }

  fetchedChangesets[sourceChangesetId] = true

  return {
    ...state,
    fetchedChangesets,
  }
}

reducers[types.FETCH_CHANGESET_TICKET_ACTIONS_SUCCESS] = (state, action) => {
  const { ticketId, sourceChangesetId, actions } = action.data
  const collapsedSourceChangesetId = `${sourceChangesetId}-collapsed`
  const changesetsById = state.changesetsById || {}
  const collection = changesetsById[ticketId]

  // something is wrong, we fetched a changeset for a
  // ticket which has no changesets. Do not panic, simply
  // ignore this action
  if (!collection) return state

  const newActions = [].concat(collection.actions)

  // find the index of the action which caused the fetch
  // and replace it with actions that were fetched
  const collapsedIndex = newActions.findIndex(
    a => a.changeset === collapsedSourceChangesetId
  )
  if (collapsedIndex < 0) {
    return state
  }
  const collapsedChangeset = newActions[collapsedIndex]
  actions.forEach(singleAction => {
    // eslint-disable-next-line no-param-reassign
    singleAction.collapsedChangeset = collapsedChangeset
  })
  const spliceArgs = [collapsedIndex, 1].concat(actions)
  Array.prototype.splice.apply(newActions, spliceArgs)

  const newChangesetsById = { ...changesetsById }
  newChangesetsById[ticketId] = buildMessageCollection({
    records: newActions,
    oldCollection: collection,
  })

  const oldFetchedChangesets = state.fetchedChangesets || {}
  const fetchedChangesets = { ...oldFetchedChangesets }
  delete fetchedChangesets[sourceChangesetId]

  return {
    ...state,
    fetchedChangesets,
    changesetsById: newChangesetsById,
  }
}

reducers[types.BULK_FETCH_CHANGESET_TICKET_ACTIONS_SUCCESS] = (
  state,
  action
) => {
  const { ticketId, payloads } = action.data
  const changesetsById = state.changesetsById || {}
  const collection = changesetsById[ticketId]

  // something is wrong, we fetched a changeset for a
  // ticket which has no changesets. Do not panic, simply
  // ignore this action
  if (!collection) return state

  const newActions = [].concat(collection.actions)
  const oldFetchedChangesets = state.fetchedChangesets || {}
  const fetchedChangesets = { ...oldFetchedChangesets }

  payloads.forEach(single => {
    const { sourceChangesetId, actions } = single
    // find the index of the action which caused the fetch
    // and replace it with actions that were fetched
    const collapsedIndex = newActions.findIndex(
      a => a.changeset === sourceChangesetId
    )
    const collapsedChangeset = newActions[collapsedIndex]
    actions.forEach(singleAction => {
      // eslint-disable-next-line no-param-reassign
      singleAction.collapsedChangeset = collapsedChangeset
    })
    const spliceArgs = [collapsedIndex, 1].concat(actions)
    Array.prototype.splice.apply(newActions, spliceArgs)
    delete fetchedChangesets[sourceChangesetId]
  })

  const newChangesetsById = { ...changesetsById }
  newChangesetsById[ticketId] = buildMessageCollection({
    records: newActions,
  })

  return {
    ...state,
    fetchedChangesets,
    changesetsById: newChangesetsById,
  }
}

reducers[types.BULK_FETCH_CHANGESET_TICKET_ACTIONS_REQUEST] = (
  state,
  action
) => {
  const { changesetIds } = action.data
  const oldFetchedChangesets = state.fetchedChangesets || {}
  const fetchedChangesets = { ...oldFetchedChangesets }

  changesetIds.forEach(id => {
    fetchedChangesets[id] = true
  })

  return {
    ...state,
    fetchedChangesets,
  }
}

reducers[types.FETCH_CHANGESET_TICKET_ACTIONS_FAIL] = (state, action) => {
  const { sourceChangesetId } = action.data
  const oldFetchedChangesets = state.fetchedChangesets || {}
  const fetchedChangesets = { ...oldFetchedChangesets }
  delete fetchedChangesets[sourceChangesetId]

  return {
    ...state,
    fetchedChangesets,
  }
}

function mergeActions(newActions, stateActions) {
  if (!(newActions && newActions.records)) return newActions
  if (!(stateActions && stateActions.records)) return newActions

  const stateLookup = stateActions.records.reduce((lookup, action) => {
    if (!lookup[action.changeset]) {
      // eslint-disable-next-line no-param-reassign
      lookup[action.changeset] = []
    }
    lookup[action.changeset].push(action)
    return lookup
  }, {})

  let mergedActions = []
  // Track if any actions have been merged
  let hasChanged = false
  newActions.records.forEach(na => {
    const subchangesetIds = na.change && na.change.subchangeset_ids
    // Tracks if the current actions has been replaced with a more detailed
    // version from the state
    let hasMerged = false
    if (subchangesetIds && subchangesetIds.length > 0) {
      subchangesetIds.forEach(cid => {
        if (stateLookup[cid]) {
          mergedActions = mergedActions.concat(stateLookup[cid])
          hasMerged = true
          hasChanged = true
        }
      })
    }
    if (!hasMerged) mergedActions.push(na)
  })
  if (hasChanged) return { records: mergedActions }
  return newActions
}

reducers[types.FETCH_TICKET_FAIL] = (state, action) => {
  const { ticketId, err } = action.data
  const byId = state.byId || {}
  const newById = { ...byId }
  const ticket = {
    id: ticketId,
    loadFailed: true,
    errors: err?.errors,
  }
  newById[ticket.id] = ticket

  return {
    ...state,
    byId: newById,
  }
}

reducers[types.ADD_CREATED_TICKET] = (state, action) => {
  const data = action.data
  const ticket = data.ticket
  const byId = state.byId || {}
  const newById = Object.assign({}, byId)
  newById[ticket.id] = ticket
  return Object.assign({}, state, {
    byId: newById,
  })
}

reducers[types.MARK_BULK_SELECTION_MODE] = (state, { data }) => {
  const { selected } = state
  const { ticketIds } = data

  return Object.assign({}, state, {
    isBulkSelectionMode: true,
    selected: ticketIds.length === 1 ? ticketIds : selected,
  })
}

const deselectAll = state => {
  return {
    ...state,
    selected: [],
    isBulkSelectionMode: false,
    selectedPivotId: null,
    selectedRangeEndId: null,
  }
}

reducers[types.UNMARK_BULK_SELECTION_MODE] = reducers[
  types.ASSIGN_TICKETS_TO_AGENT
] = deselectAll

reducers[types.TOGGLE_TICKET_SELECTION] = (state, action) => {
  const { selected } = state
  const { id } = action.data
  const isTicketSelected = selected.indexOf(id) > -1

  if (isTicketSelected) {
    const withoutTicket = selected.filter(selectedId => selectedId !== id)
    const isNowEmpty = isEmpty(withoutTicket)
    return {
      ...state,
      isBulkSelectionMode: !isNowEmpty,
      selected: withoutTicket,
      selectedPivotId: isNowEmpty ? null : state.selectedPivotId,
      selectedRangeEndId: isNowEmpty ? null : state.selectedRangeEndId,
    }
  }

  return {
    ...state,
    isBulkSelectionMode: true,
    selected: [...selected, id],
    selectedPivotId: id,
    selectedRangeEndId: null,
  }
}

const getRange = (arr, startIndex, endIndex) => {
  if (startIndex < endIndex) {
    return arr.slice(startIndex + 1, endIndex + 1)
  }
  return arr.slice(endIndex, startIndex).reverse()
}

reducers[types.RANGED_TICKET_SELECTION] = (state, action) => {
  const { selected } = state
  const { id, pivotId, rangeEndId, sortedMailboxTicketIds } = action.data
  const pivotIndex = sortedMailboxTicketIds.indexOf(pivotId)
  const rangeEndIndex = sortedMailboxTicketIds.indexOf(rangeEndId)
  const newEndIndex = sortedMailboxTicketIds.indexOf(id)

  // clear the old range
  const oldRange = getRange(sortedMailboxTicketIds, pivotIndex, rangeEndIndex)
  const newRange = getRange(sortedMailboxTicketIds, pivotIndex, newEndIndex)

  return {
    ...state,
    selected: diff(selected, oldRange).concat(newRange),
    selectedRangeEndId: id,
  }
}

reducers[types.SELECT_TICKET] = (state, action) => {
  const { selected } = state
  const { id } = action.data
  const isTicketSelected = selected.indexOf(id) > -1

  if (isTicketSelected) {
    return state
  }
  return Object.assign({}, state, {
    selected: [...selected, id],
  })
}

reducers[types.DESELECT_TICKET] = (state, action) => {
  const { selected } = state
  const { id } = action.data
  const isTicketSelected = selected.indexOf(id) > -1

  if (!isTicketSelected) {
    return state
  }
  return Object.assign({}, state, {
    selected: selected.filter(selectedId => selectedId !== id),
  })
}

reducers[types.DESELECT_ALL_TICKETS] = state => {
  return Object.assign({}, state, {
    selected: [],
  })
}

function unique(value, index, self) {
  return self.indexOf(value) === index
}

reducers[types.SELECT_TICKETS] = (state, action) => {
  const { selected } = state
  const { ticketIds } = action.data
  let newSelected = selected || []
  newSelected = newSelected.concat(ticketIds || [])
  newSelected = newSelected.filter(unique)
  return Object.assign({}, state, {
    selected: newSelected,
    isBulkSelectionMode: true,
  })
}

function ensureActionsChangeToFieldIsArray(actions) {
  return actions.forEach(action => {
    if (action.change) {
      // eslint-disable-next-line no-param-reassign
      action.change.to = wrapInArray(action.change.to)
    }
  })
}

const applyChangeset = (initialState, data) => {
  let state = { ...initialState }

  const { ticketId, ticketData, changesetId, actions, actionsData } = data
  const commentDeletion = actions.find(
    action => action.change_type === COMMENT_DELETION
  )
  const commentEdit = actions.find(
    action => action.change_type === COMMENT_EDIT
  )
  // PK: for some reason initialState here is an old state, took even before CREATE_CHANGESET_REQUEST
  // it should be the newest state but it isn't. Changing it for all other actions would break them
  if (commentDeletion || commentEdit) {
    state = app.store.getState().tickets
  }

  const byId = state.byId || {}
  const changesetsById = state.changesetsById || {}
  // Ensure that the change object returned from the api has a to field
  // in the array format
  ensureActionsChangeToFieldIsArray(actions)
  const newById = { ...byId }
  let newChangesetsById = changesetsById || {}
  const found = changesetsById[ticketId]
  const currentActions = found ? found.actions : []

  const oldTicket = byId[ticketId] || defaultLoadedTicketState
  const newTicket = buildTicket(oldTicket, ticketData)

  if (currentActions && actions && actions.length > 0) {
    if (changesetId) {
      const records = currentActions.filter(
        action => action.changeset !== changesetId
      )
      const skippedChangeTypes = ['Rating', 'Ticket::CustomerActionOpen']
      const filteredActions = actions.filter(
        a => !skippedChangeTypes.includes(a.change_type)
      )

      const newActions = records.concat(filteredActions)

      const messageAction = filteredActions.find(isMessage)
      if (messageAction) {
        const { body, bodyType } = getUpdatedTicketBodyAttributes(messageAction)
        if (body) newTicket.body = body
        if (bodyType) newTicket.bodyType = bodyType
      }

      newChangesetsById = { ...changesetsById }
      newChangesetsById[ticketId] = buildMessageCollection({
        records: newActions,
        recordChanges: actionsData,
        oldCollection: found,
      })
    }
  }

  delete newTicket.changesets
  delete newTicket.actions

  const ticket = oldTicket
  const collection = newChangesetsById[ticket.id]

  newTicket.draftDefaults = buildDraftDefaults(state, newTicket, collection)
  newTicket.noteDefaults = buildNoteDefaults(state, newTicket, collection)

  newById[newTicket.id] = newTicket

  // if no prior changeset data is found mark it as dirty ticket
  // as the new changeset data will be incomplete.
  const dirtyTicketIds = { ...state.dirtyTicketIds }
  if (!found) {
    dirtyTicketIds[ticketId] = true
  }

  return {
    ...state,
    byId: newById,
    changesetsById: newChangesetsById,
    dirtyTicketIds,
  }
}

reducers[types.CREATE_CHANGESET_REQUEST] = reducers[
  types.CREATE_CHANGESET_SUCCESS
] = reducers[types.ADD_CHANGESET] = (state, { type, data, meta }) => {
  if (!data || !data.ticketId || !data.ticketData) return state
  let newState = applyChangeset(state, data)
  if (type === types.CREATE_CHANGESET_SUCCESS && meta?.isForNewTicket) {
    newState = {
      ...newState,
      byId: {
        ...newState.byId,
        new: {
          ...newState.byId.new,
          contactId: undefined,
        },
      },
    }
  }

  return newState
}

reducers[types.CREATE_BULK_CHANGESET_REQUEST] = (
  state,
  { data: { tickets } }
) => {
  if (!tickets) return state
  let newState = { ...state }
  const single = reducers[types.CREATE_CHANGESET_REQUEST]
  tickets.forEach(ticketData => {
    newState = single(newState, { data: ticketData })
  })
  return deselectAll(newState)
}

reducers[types.CREATE_BULK_CHANGESET_SUCCESS] = (
  state,
  { data: { tickets } }
) => {
  if (!tickets) return state
  const newState = { ...state }
  return deselectAll(newState)
}

reducers[MERGE_TICKET_SUCCESS] = (state, action) => {
  if (!action.payload) return state
  const {
    payload: { ticket },
  } = action
  if (!ticket) return state
  const byId = state.byId || {}
  const newTicket = Object.assign({}, byId[ticket.id], ticket.ticket, {
    actions: ticket.actions, // in case ticket.actions is empty
    linkedExternalResources: undefined, // We can't use the existing tickets linked external resource for the "new ticket", these will be merged in correctly by the FETCH_TICKET_SUCCESS
  })
  if (ticket.actions.records && ticket.actions.records.length > 0) {
    newTicket.actions.records = ticket.actions.records
  }

  const reducerPayload = {
    data: {
      ticket: newTicket,
    },
  }

  return reducers[types.FETCH_TICKET_SUCCESS](state, reducerPayload)
}

// Apply bulk actions with a payload that has multiple tickets.
reducers[types.STAR_TICKETS_STARTED] = reducers[
  types.SNOOZE_TICKETS_REQUEST
] = (state, action) => {
  if (!action.data && !action.data.tickets) return state
  const { tickets } = action.data
  let newState = { ...state }
  tickets.forEach(ticket => {
    newState = applyChangeset(newState, ticket)
  })
  return newState
}

// When we kick off an Undo Send request, we do a two things. 1. We apply the
// optimistic UndoSend changeset that suppresses the (possibly in-flight)
// Message changeset, and 2. We restore the draft associated with this undo. The
// second step has been offloaded to the draft duck.
reducers[types.UNDO_SEND_REQUEST] = reducers[types.UNDO_SEND_SUCCESS] = (
  state,
  action
) => {
  const changesetFn = reducers[types.CREATE_CHANGESET_SUCCESS]
  return changesetFn(state, action)
}

reducers[types.TOGGLE_TICKETS_SORTING] = state => {
  const { sorting } = state

  if (sorting) {
    return Object.assign({}, state, {
      sorting: false,
    })
  }
  return Object.assign({}, state, {
    sorting: true,
  })
}

reducers[pages.TICKET_PAGE] = state => {
  // on desktop, its the same as a reply page
  if (!isMobile()) return state

  // on mobile, we have various janky jankness...
  return {
    ...state,
    // jank. isReplying already available in the route/location but desktop
    // doesnt use it (yet!)
    isReplying: false,
    isMerging: false, // FIXME - comes from page props now
  }
}

reducers[pages.TICKET_REPLY_PAGE] = reducers[
  pages.TICKET_REPLY_CHANGESET_PAGE
] = reducers[pages.TICKET_REPLY_DIRECT_PAGE] = reducers[
  pages.TICKET_REPLY_DIRECT_CHANGESET_PAGE
] = reducers[pages.TICKET_FORWARD_CHANGESET_PAGE] = reducers[
  pages.TICKET_FORWARD_PAGE
] = reducers[pages.TICKET_FORWARD_PAGE] = state => {
  return {
    ...state,
    isMerging: false, // DEPRECATED: comes from page props now
  }
}

function clearSelected(state) {
  return {
    ...state,
    isBulkSelectionMode: false,
    selected: [],
    selectedPivotId: null,
    selectedRangeEndId: null,
  }
}

reducers[pages.FOLDER_PAGE] = reducers[pages.MAILBOX_FOLDER_PAGE] = reducers[
  pages.MAILBOX_PAGE
] = reducers[pages.MAIN_PAGE] = reducers[pages.SEARCH_PAGE] = reducers[
  pages.NEW_CONVERSATION_PAGE
] = reducers[pages.LOG_CONVERSATION_PAGE] = (state, action) => {
  const {
    type,
    payload,
    meta: {
      location: { prev: { type: prevType, payload: prevPayload } = {} } = {},
    } = {},
  } = action

  if (type !== prevType || !deepEqual(payload, prevPayload)) {
    // only clear selected if there was an actual page/type change
    return clearSelected({
      ...state,
      // jank. isReplying already available in the route/location but desktop
      // doesnt use it (yet!)
      isReplying: false,
      randomSeed: Math.random(),
    })
  }

  return state
}

reducers[types.TOGGLE_SHOW_CC_BCC] = state => {
  const { showCcBcc } = state

  if (showCcBcc) {
    return Object.assign({}, state, {
      showCcBcc: false,
    })
  }
  return Object.assign({}, state, {
    showCcBcc: true,
  })
}

reducers[types.UPDATE_NOTE_FORM_HEIGHT] = (state, action) => {
  const newState = {}
  const { height } = action.data
  newState.noteFormHeight = height
  return Object.assign({}, state, newState)
}

reducers[types.UPDATE_TICKET_DIRTY_STATUS] = (state, action) => {
  const { ticketId, dirty } = action.data
  return updateTicketDirtyState(state, ticketId, dirty)
}

export function updateTicketDirtyState(state, ticketId, isDirty) {
  const dirtyTicketIds = { ...state.dirtyTicketIds }
  if (isDirty) {
    dirtyTicketIds[ticketId] = true
  } else {
    delete dirtyTicketIds[ticketId]
  }
  return {
    ...state,
    dirtyTicketIds,
  }
}

reducers[types.FETCH_OPTIMISTIC_MERGE_TICKETS_SUCCESS] = (state, action) => {
  const { tickets } = action.data
  if (!tickets) return state

  const changesetsById = state.changesetsById || {}
  const newChangesetsById = { ...changesetsById }

  Object.values(tickets).forEach(ticket => {
    const actions = deepCopy(ticket.actions)

    if (actions && actions.records) {
      newChangesetsById[ticket.id] = buildMessageCollection(actions)
    }
  })

  return {
    ...state,
    changesetsById: newChangesetsById,
    optimisticMergeTicketsById: {
      ...state.optimisticMergeTicketsById,
      ...tickets,
    },
  }
}

reducers[types.FETCH_TICKET_SNIPPETS_SUCCESS] = (state, action) => {
  const { lastComments } = action.data
  if (!lastComments) return state
  const newSnippetsById = Object.assign({}, state.snippetsById || {})
  lastComments.map(snippet => {
    newSnippetsById[snippet.ticketId] = snippet.body
    return null
  })
  return Object.assign({}, state, {
    snippetsById: newSnippetsById,
  })
}

reducers[types.UPDATE_MERGE_SNIPPETS_VISIBILITY] = (state, action) => {
  const { visible } = action.data
  return Object.assign({}, state, {
    showMergeSnippets: visible,
  })
}

reducers[types.REMOVE_MAILBOX_LOCALLY] = (state, action) => {
  const { id } = action.data
  const byId = state.byId || {}
  const newById = Object.assign({}, byId)
  Object.values(byId).forEach(ticket => {
    if (ticket.mailboxId === id) {
      delete newById[ticket.id]
    }
  })
  return Object.assign({}, state, {
    byId: newById,
  })
}

reducers[types.UPDATE_NOTE_REQUEST] = (state, action) => {
  const {
    data: { ticketId, noteId, noteText },
  } = action
  const ticket = state.byId[ticketId]
  const changesetCollection = state.changesetsById[ticketId]
  if (!changesetCollection) return state
  const changesets = changesetCollection.changesets
  if (!changesets) return state
  const changesetIndex = changesets.findIndex(changeset => {
    return any(
      ticketAction => ticketAction.change && ticketAction.change.id === noteId,
      changeset.actions
    )
  })
  const actions = ticket.changesets[changesetIndex].actions
  const actionIndex = actions.findIndex(ticketAction => {
    return ticketAction.change.id === noteId
  })
  const ticketAction = actions[actionIndex]
  if (ticketAction && ticketAction.change.body === noteText) return state

  const newActions = [].concat(actions)
  const existingAction = actions[actionIndex]
  newActions[actionIndex] = {
    ...existingAction,
    change: {
      ...existingAction.change,
      body: noteText,
    },
  }
  const newChangesets = [].concat(changesets)
  newChangesets[changesetIndex] = {
    ...changesets[changesetIndex],
    actions: newActions,
  }
  return {
    ...state,
    byId: {
      ...state.byId,
      [ticketId]: {
        ...ticket,
        changesets: newChangesets,
      },
    },
  }
}
reducers[types.REALTIME_NOTE_UPDATE] = (state, action) => {
  const {
    data: { noteId, noteText },
  } = action
  const { byId } = state
  let actionIndex
  let changesetActionIndex
  const ticket = Object.values(byId).find(t => {
    if (!t.actions) return false
    actionIndex = t.actions.records.findIndex(a => {
      if (!isNote(a)) return false
      const { change } = a
      return change && change.id === noteId
    })
    return actionIndex >= 0
  })
  if (!ticket) return state
  const ticketAction = ticket.actions.records[actionIndex]
  if (ticketAction.change.body === noteText) return state
  const changesetIndex = ticket.changesets.findIndex(c => {
    changesetActionIndex = c.actions.findIndex(ca => {
      const { change } = ca
      return change && change.id === noteId
    })
    return changesetActionIndex >= 0
  })
  const changeset = ticket.changesets[changesetIndex]
  const changesetAction = changeset.actions[changesetActionIndex]

  return {
    ...state,
    byId: {
      ...state.byId,
      [ticket.id]: {
        ...ticket,
        changesets: Object.values({
          ...ticket.changesets,
          [changesetIndex]: {
            ...changeset,
            actions: Object.values({
              ...changeset.actions,
              [changesetActionIndex]: {
                ...changesetAction,
                change: {
                  ...changesetAction.change,
                  body: noteText,
                },
              },
            }),
          },
        }),
        actions: {
          ...ticket.actions,
          records: Object.values({
            ...ticket.actions.records,
            [actionIndex]: {
              ...ticketAction,
              change: {
                ...ticketAction.change,
                body: noteText,
              },
            },
          }),
        },
      },
    },
  }
}

const removeLabel = id => ticket => ({
  ...ticket,
  labelIds: (ticket.labelIds || []).filter(e => e != id), // eslint-disable-line eqeqeq
})

// Adds a 'shadow' list of labelIds based on the labeling action. For when the
// labeling isnt applied immediately but we want it to reflect in the ticket/lists.
const applyPendingLabelings = (
  ticketIds = [],
  addedIds = [],
  removedIds = []
) => ticket => {
  if (!ticketIds.includes(ticket.id)) return ticket

  return {
    ...ticket,
    labelIdsWithPendingEdits: (ticket.labelIds || [])
      .concat(addedIds)
      .filter(id => !removedIds.includes(id)),
  }
}

const removePendingLabelings = (ticketIds = []) => ticket => {
  if (!ticketIds.includes(ticket.id)) return ticket

  return {
    ...ticket,
    labelIdsWithPendingEdits: null,
  }
}

reducers[types.LABEL_SELECTION_CLICK] = (
  state,
  { data: { label, ticketIds, applyAndClose } }
) => {
  if (!applyAndClose) return state

  return {
    ...state,
    byId: mapObject(applyPendingLabelings(ticketIds, [], [label.id]))(
      state.byId
    ),
  }
}

reducers[types.UPDATE_LABELING_SUCCESS] = (state, { data: { ticketId } }) => {
  return {
    ...state,
    byId: mapObject(removePendingLabelings([ticketId]))(state.byId),
  }
}

reducers[types.BULK_UPDATE_LABELING_REQUEST] = reducers[
  types.BULK_UPDATE_LABELING_FAIL
] = state => {
  return deselectAll(state)
}

reducers[types.BULK_UPDATE_LABELING_SUCCESS] = (
  state,
  { data: { ticketIds } }
) => {
  return deselectAll({
    ...state,
    byId: mapObject(removePendingLabelings(ticketIds))(state.byId),
  })
}

reducers[types.DELETE_LABEL_SUCCESS] = reducers[types.DELETE_LABEL_REQUEST] = (
  state,
  { data: { id } }
) => {
  const { byId } = { ...state }

  return {
    ...state,
    byId: mapObject(removeLabel(id))(byId),
  }
}

reducers[FETCH_CONVERSATION_CONTACT_PAGE_SUCCESS] = (
  state,
  { payload: { conversation: { contact, id, number } = {} } = {} }
) => {
  if (contact && contact.id) {
    return {
      ...state,
      byId: {
        ...state.byId,
        [number]: {
          ...state.byId[number],
          gqlId: id,
          contactId: contact.id,
        },
      },
    }
  }
  return state
}

reducers[FETCH_CONTACT_SUCCESS] = (
  state,
  { meta: { contactId, isForNewTicket } }
) => {
  if (isForNewTicket) {
    return {
      ...state,
      byId: {
        ...state.byId,
        new: {
          ...state.byId.new,
          contactId,
        },
      },
    }
  }

  return state
}

reducers[CHANGE_CONVERSATION_CONTACT_SUCCESS] = (
  state,
  {
    payload: {
      conversationChangeContact: {
        conversation: {
          number: conversationNumber,
          contact: { id: contactId } = {},
        } = {},
      } = {},
    } = {},
  }
) => {
  if ((conversationNumber && contactId) || conversationNumber === 'new') {
    return {
      ...state,
      byId: {
        ...state.byId,
        [conversationNumber]: {
          ...state.byId[conversationNumber],
          contactId,
        },
      },
    }
  }
  return state
}

reducers[types.SAVE_LAST_SNOOZED_DATE] = (
  state,
  { data: { lastSnoozedDate } }
) => {
  // only saving custom snooze time that doesn't match the available predefined options
  if (!lastSnoozedDate || !(lastSnoozedDate instanceof Date)) {
    return {
      ...state,
      lastSnoozedDate: null,
    }
  }

  const predefinedSnoozeTimes = snoozeOptions()
    .filter(o => o.showOption() && o.value !== SNOOZED_INDEFINITELY)
    .map(o => o.timestamp())

  if (predefinedSnoozeTimes.includes(lastSnoozedDate.getTime())) {
    return {
      ...state,
      lastSnoozedDate: null,
    }
  }

  return {
    ...state,
    lastSnoozedDate: lastSnoozedDate.getTime(),
  }
}

reducers[types.UPDATE_APP_DATA] = (state, action) => {
  const { currentUser } = action.data
  if (!currentUser) return state
  const prefs = currentUser.preferences
  if (!prefs) return state
  return {
    defaultReplyState: prefs.defaultReplyState,
    reassignTicketOnReply: prefs.reassign_ticket_on_reply,
    reassignTicketOnNote: prefs.reassign_ticket_on_note,
    currentUserId: currentUser.id,
    ...state,
  }
}

reducers[UPDATE_PREFERENCES_SUCCESS] = (state, { payload }) => {
  return {
    ...state,
    reassignTicketOnReply: payload.reassign_ticket_on_reply,
    reassignTicketOnNote: payload.reassign_ticket_on_note,
  }
}

reducers[types.UPDATE_ACCOUNT_SUCCESS] = (state, { payload }) => {
  const newDefaultReplyState = payload?.updateAccount?.preferences?.reply_button

  if (typeof newDefaultReplyState !== 'boolean') {
    return state
  }

  return {
    ...state,
    defaultReplyState: newDefaultReplyState,
  }
}

export default function reducer(state = defaultState, action) {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
