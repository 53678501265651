// SUI doesnt support Search-in-menu DDs, despite what their doco suggests...
// https://github.com/Semantic-Org/Semantic-UI-React/issues/1593
//
// So we use a long-hand DD to build our own reusable Search-in-menu
// dropdown, using our own SearchBox, and a few hacks!;
//
//  - for search to work, props.options each need `text[/description]` props
//    (not just `content`).
//
//  - we have to track onUp/onDown key events ourselves. We can't rely on SUI
//    ArrowUp/Down. This creates a controlled DD - from which we cannot expect
//    onChange handlers (which withSelectedItemIndicator relies on)
//
//  - as a result, we also can't rely on SUI's `scrollSelectedItemIntoView`, so
//    that has to be handled here.
//
//  We also dont noResultsMessage (as again, that only works for pure 'search'
//  DDs) but rather have our separate one 'noResultsText', to hopefully avoid
//  confusion.
//
import React from 'react'
import styled from '@emotion/styled'
import { compose } from 'redux'

import { Dropdown as SUIDropdown } from 'util/ui'
import { isEmpty } from 'util/arrays'
import { stopEvent } from 'util/keys'
import Divider from 'shared/ui/Divider'
import SearchBox from 'shared/ui/SearchBox'
import withOpenState from 'shared/ui/withOpenState'

import NoResults from './NoResults'
import withArrowKeys from './withArrowKeys'
import withKeyboardHandler from './withKeyboardHandler'
import withSelectedItemIndicator from './withSelectedItemIndicator'
import withDefaultActiveOption from './withDefaultActiveOption'
import withSimpleFiltering from './withSimpleFiltering'
import withScrollIntoView from './withScrollIntoView'

const SearchDropdown = ({
  className,
  header,
  onSearchChange,
  options,
  placeholder,
  noResultsText = null,
  ...rest
}) => {
  return (
    <SUIDropdown className={className} {...rest}>
      <SUIDropdown.Menu>
        <SUIDropdown.Header content={header} />
        <SearchBox
          className="grui ml-12"
          /* Unfortunately SUI DDs will toggle on click events by default (see
           * https://github.com/Semantic-Org/Semantic-UI-React/issues/1593
           * The workaround is to not bubble click events.  */
          onClearInput={stopEvent}
          onIconClick={stopEvent}
          onInputClick={stopEvent}
          onSearchChange={onSearchChange}
          placeholder={placeholder}
          skipFocusInput // otherwise the focus event triggers DD onBlur.
        />
        <Divider className="grui mt-4" />
        <SUIDropdown.Menu scrolling>
          {noResultsText !== null &&
            isEmpty(options) && <NoResults text={noResultsText} />}
          {options.map(option => (
            <SUIDropdown.Item key={option.value} {...option} />
          ))}
        </SUIDropdown.Menu>
      </SUIDropdown.Menu>
    </SUIDropdown>
  )
}

const Styled = styled(SearchDropdown)`
  &&& {
    > .left.menu .menu.scrolling {
      /* Overrides "margin: 0 -.5em 0 0!important;" and fixes scrollbar */
      margin: 0px !important;
    }
  }
`

// Uses redux's handly compose() function to, er, compose the additional
// dropdown behaviours.  https://reactjs.org/docs/higher-order-components.html
export default compose(
  withSimpleFiltering, // must be before options.text/description are munged
  withDefaultActiveOption,
  withOpenState,
  withKeyboardHandler,
  withArrowKeys,
  // Note we dont need withOnChangeTracking here since we control the DD with
  // withArrowKeys.
  withSelectedItemIndicator, // must go after withArrowKeys
  withScrollIntoView()
)(Styled)
