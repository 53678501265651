import styled from '@emotion/styled'
import Flex from 'shared/ui/Flex'

export default styled(Flex)`
  color: white;
  margin-left: -46px;

  > .Icon.big {
    /* this is a hack for the positioning */
    margin-left: -4px;
  }
`
