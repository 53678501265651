import { createTypeElement, createPropTypes } from './util'

const MindfulText = props => createTypeElement('MindfulText', props)

MindfulText.propTypes = createPropTypes()

MindfulText.defaultProps = {
  as: 'div',
}

export default MindfulText
