import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'

const paragraphExtraSmall = () => theme => css`
  font-size: 13px;
  line-height: 1.46;
  color: ${theme.color.monochrome.dark};
`
const paragraphSmall = () => theme => css`
  font-size: 14px;
  line-height: 1.5;
  color: ${theme.color.monochrome.dark};
`
const paragraphRegular = () => theme => css`
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.color.monochrome.dark};
`

export default function Paragraph({ children, size, className }) {
  const styles = {
    'extra-small': paragraphExtraSmall,
    small: paragraphSmall,
    regular: paragraphRegular,
  }

  return (
    <div css={styles[size]} className={className}>
      {children}
    </div>
  )
}

Paragraph.propTypes = {
  size: PropTypes.oneOf(['extra-small', 'small', 'regular']),
}
Paragraph.defaultProps = {
  size: 'regular',
}
