// Wrapper around SUI Dropdown that scrolls to the selected item in props.options
//
// For <DD.Menu scrolling /> looks for '.menu.visible > .menu.scrolling'
//
// TLDR is once you blur the menu items, like what happens when we have a SearchBox
// insde the DD, we gotta do everything ourselves...
//
// As soon as you blur - or have something that blurs the DD menu - in this case
// our SearchBox - then all bets are off regarding SUIs keyboard (and scrolling)
// handling.
//
// And without hooks to control SUI's keyboard handling, we end up having to
// reimplement it ourselves. I wish I could find a better way, but at least its
// localized and explained in the HOC.
//
// In this case, we duplicate SUIs scrolling function (since that wont work
// out of the box with our searchInMenu DDs)
//
import React from 'react'
import ReactDOM from 'react-dom'

import { getDisplayName } from 'util/hoc'
import { throttle } from 'util/functions'

function withScrollIntoView(menuSelector = '.menu.visible > .menu.scrolling') {
  return WrappedDropdown => {
    class WithScrollIntoView extends React.Component {
      componentDidMount() {
        // not great, but an easy way of getting the DD DOM node.
        // eslint-disable-next-line react/no-find-dom-node
        this.ref = ReactDOM.findDOMNode(this)
      }

      // track selected item changes passed in from props.options
      componentDidUpdate(prevProps) {
        if (this.props.options !== prevProps.options) {
          this.throttledScroll()
        }
      }

      scrollSelectedItemIntoView = () => {
        if (!this.ref) return
        const menu = this.ref.querySelector(menuSelector)
        if (!menu) return
        const item = menu.querySelector('.item.selected')
        if (!item) return
        const isOutOfUpperView = item.offsetTop < menu.scrollTop
        const isOutOfLowerView =
          item.offsetTop + item.clientHeight >
          menu.scrollTop + menu.clientHeight

        if (isOutOfUpperView) {
          menu.scrollTop = item.offsetTop
        } else if (isOutOfLowerView) {
          // eslint-disable-next-line no-mixed-operators
          menu.scrollTop =
            item.offsetTop + item.clientHeight - menu.clientHeight
        }
      }

      throttledScroll = throttle(this.scrollSelectedItemIntoView.bind(this), 20)

      render() {
        return <WrappedDropdown {...this.props} ref={this.handleRef} />
      }
    }

    WithScrollIntoView.displayName = `WithScrollIntoView(${getDisplayName(
      WrappedDropdown
    )})`

    return WithScrollIntoView
  }
}

export default withScrollIntoView
