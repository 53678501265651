import { useCallback, useState } from 'react'

export default function useManageArrayOfStringsWithPrimary(
  initialRecords,
  maxRecords
) {
  const [rawRecords, doSetRecords] = useState(
    (initialRecords || [])
      .filter(x => !!x && x !== '')
      .map((value, i) => ({ isPrimary: i === 0, value }))
  )

  const records =
    rawRecords.length < 2
      ? [{ isPrimary: true, value: rawRecords[0]?.value || '' }]
      : rawRecords

  return {
    doAdd: useCallback(
      () => {
        doSetRecords(existingRecords => {
          if (existingRecords.length < maxRecords) {
            return [...existingRecords, { value: '' }]
          }
          return existingRecords
        })
      },
      [doSetRecords, maxRecords]
    ),

    doRemove: useCallback(
      i => {
        doSetRecords(existingRecords => {
          const { isPrimary: wasPrimary } = existingRecords[i]
          const newRecords = [...existingRecords]
          newRecords.splice(i, 1)
          if (newRecords.length === 0) return [{ isPrimary: true, value: '' }]
          if (wasPrimary) {
            const newPrimaryI =
              i + 1 === existingRecords.length ? newRecords.length - 1 : i
            newRecords[newPrimaryI] = {
              ...newRecords[newPrimaryI],
              isPrimary: true,
            }
          }
          return newRecords
        })
      },
      [doSetRecords]
    ),

    doSetRecord: useCallback(
      (i, value) => {
        doSetRecords(existingRecords => {
          const newRecords = [...existingRecords]
          newRecords[i] = { ...newRecords[i], value }
          return newRecords
        })
      },
      [doSetRecords]
    ),

    doSetPrimary: useCallback(
      i => {
        doSetRecords(existingRecords => {
          const newRecords = [...existingRecords]
          const currentPrimaryI = existingRecords.findIndex(
            ({ isPrimary }) => isPrimary
          )
          newRecords[currentPrimaryI] = {
            ...newRecords[currentPrimaryI],
            isPrimary: false,
          }
          newRecords[i] = {
            ...newRecords[i],
            isPrimary: true,
          }
          return newRecords
        })
      },
      [doSetRecords]
    ),
    records,
  }
}
