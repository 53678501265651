import { createSelector } from 'reselect'
import { selectKnownMailboxes } from './selectKnownMailboxes'

export const selectMailboxesByEmail = createSelector(
  selectKnownMailboxes,
  mailboxes =>
    mailboxes.reduce((hash, mailbox) => {
      hash[mailbox.email] = mailbox // eslint-disable-line no-param-reassign
      return hash
    }, {})
)
