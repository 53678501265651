import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'
import { emptyArr } from 'util/arrays'
import { sortByKey } from 'util/arrays/sorting'
import { emptyObj, mapObject } from 'util/objects'

export const MAXIMUM_DISPLAYED_LABELS = 50

export const selectLabelsById = state => (state.labels || emptyObj).byId
export const selectLabelsByHashedIdMap = state =>
  (state.labels || emptyObj).byHashedId

export const selectLabelsByHashedId = createSelector(
  selectLabelsById,
  selectLabelsByHashedIdMap,
  (byId, byHashedId) => {
    return Object.keys(byHashedId).reduce((result, hashedId) => {
      // eslint-disable-next-line no-param-reassign
      result[hashedId] = byId[byHashedId[hashedId]]
      return result
    }, {})
  }
)

const selectLabelIdsByMailboxId = state => state.labels.byMailboxId || emptyObj

export const selectLabelsByMailboxId = createSelector(
  selectLabelIdsByMailboxId,
  selectLabelsById,
  (byMailbox, byId) => mapObject(ids => ids.map(id => byId[id]))(byMailbox)
)

export const selectLabelIds = createSelector(
  selectLabelsById,
  labels => labels && Object.keys(labels)
)

export const selectLabelHashedIds = createSelector(
  selectLabelsByHashedId,
  labels => labels && Object.keys(labels)
)

export const selectLabels = createSelector(selectLabelsById, labels => {
  if (!labels) return emptyArr
  return sortByKey(Object.values(labels), 'name')
})

export const selectCounts = state => state.labels.counts || emptyObj

// return array of labels that match the ids array filter
export const selectLabelsByIds = createCachedSelector(
  selectLabelsById,
  (_, ids) => ids,
  (labelsById, ids) => {
    if (!labelsById || !ids) return emptyArr
    return Object.values(labelsById).filter(l => ids.includes(l.id))
  }
)((_, ids) => (ids || ['unknown']).join(','))

export const selectLabelById = createCachedSelector(
  selectLabelsById,
  (_state, labelId) => labelId,
  (labelsById, labelId) => labelsById[labelId]
)((_state, labelId) => labelId || 'unknown')

export const selectLabelingDropdownDisplayMoreLabels = createSelector(
  selectLabelsById,
  byId => {
    return Object.keys(byId).length > MAXIMUM_DISPLAYED_LABELS
  }
)
