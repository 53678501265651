import { createSelector } from 'reselect'
import { selectCurrentMailbox } from './selectCurrentMailbox'

export const selectCurrentMailboxName = createSelector(
  selectCurrentMailbox,
  mailbox => {
    if (!mailbox) return null
    return mailbox.name
  }
)
