// This is an uncontrolled editor.
// Prop api is locked down, do not modify to pass on {...rest} of props.
import globalConfig from 'config'
import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { compose } from 'redux'
import { css } from '@emotion/react'

import debug from 'util/debug'
import themeVars from 'ui_theme/site/globals/site.variables'
import withEscKeyUnfocus from './withEscKeyUnfocus'
import SkinnedEditor from './Skinned'

// To not have any delays in loading styles, we inject the styles into the editor
// This css must not have any imports - these should go into
// shared/editor/skins/groove/content.css
// that css file gets loaded and parsed, so might have a tiny delay
const defaultContentStyle = `
html {
  padding: 0;
  margin: 0;
}

body {
  font-family: ${themeVars.pageFont};
  font-size: 14px;
  color: var(--color-monochrome-black);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  line-height: 1.4285em;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
  padding: 0;
}

a {
  color: var(--color-primary-brand);
  text-decoration: none;
}

span.mention {
  font-weight: 500;
  border: 1px solid #ccc;
  background-color: #eee;
  padding: 2px;
  padding-top: 0px;
  border-radius: 5px;
}

p {
  margin-top: 0;
  line-height: 17px;
  margin-bottom: 17px;
}
`

const defaultPlaceholderStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '0px',
  fontSize: '14px',
  color: 'var(--color-monochrome-mediumDark)',
  fontFamily: themeVars.pageFont,
  fontStyle: 'oblique',
}

class BasicEditor extends React.Component {
  static propTypes = {
    /**
       tinymce config object to be passed to the editor on init
    */
    config: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    /**
       class name to give to the div surrounding the editor
    */
    className: PropTypes.string,

    /**
       Whether or not to focus to the end of any existing text (default: true)
    */
    focusWhenInitialized: PropTypes.bool,

    /**
       Ref callback to forward a reference to this editor. Sidesteps any
       shenanigans with Emotion/styled innerRef. Ideally replaced by real
       React.forwardRef() call but that needs 16.3
    */
    forwardRef: PropTypes.func,

    /**
       initial content of the editor
       The Basic editor component _only_ operates in uncontrolled mode.
    */
    initialValue: PropTypes.string,

    /**
       onChange(content)
       callback to be executed when editor contents change
       is called with one argument, the string contents of the editor
    */
    onChange: PropTypes.func,
    /**
       onInit(editor)
       callback to be executed when editor is initializing
    */
    onInit: PropTypes.func,
    /**
       onSetup(editor)
       callback to be executed when editor setup is happening
    */
    onSetup: PropTypes.func,
    /**
       onKeyDown(event)
       callback to be executed when a keydown event happens in editor
    */
    onKeyDown: PropTypes.func,
    /**
       onKeyUp(event)
       callback to be executed when a keyup event happens in editor
    */
    onKeyUp: PropTypes.func,
    /**
       onBlur(event)
       callback to be executed when a blur event happens in editor
    */
    onBlur: PropTypes.func,
    /**
       onFocus(event)
       callback to be executed when a focus event happens in editor
    */
    onFocus: PropTypes.func,
    /**
       String of styles to inject into the content iframe. Overrides default style.
       Pass `false` to disable all content styles. This value is always false in `inline` mode.
    */
    contentStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    /**
       String of styles to join with default styles and inject into the content iframe.
    */
    extraStyle: PropTypes.string,
    /**
       Placeholder to display when editor is empty
       This value is always false in `inline` mode.
    */
    placeholder: PropTypes.string,
    /**
       Placeholder style override. Expects an object of style key/value pairs.
    */
    placeholderStyle: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    /**
       Render as inline editor instead of iframe.
       Enabling this will cause your editor to inherit styles from the page, and will therefore ignore `contentStyle` and `extraStyle` props.
     */
    inline: PropTypes.bool,
    /**
       Use raw to store the content. Default is `false`, ie to escape special characters.
       See: https://www.tiny.cloud/docs-3x/reference/configuration/Configuration3x@entity_encoding/
     */
    raw: PropTypes.bool,
  }

  static defaultProps = {
    className: '',
    config: undefined,
    contentStyle: undefined,
    extraStyle: '',
    focusWhenInitialized: true,
    forwardRef: undefined,
    initialValue: '',
    inline: false,
    onBlur: undefined,
    onFocus: undefined,
    onChange: undefined,
    onInit: undefined,
    onKeyDown: undefined,
    onKeyUp: undefined,
    onSetup: undefined,
    placeholder: '',
    placeholderStyle: {},
    raw: false,
  }

  state = { content: '' }

  componentDidMount() {
    const { initialValue } = this.props
    this.content = initialValue
  }

  componentWillUnmount() {
    this.editorRef = null
    this.editor = null
    if (this.removeEventOnUnmount) {
      this.removeEventOnUnmount()
      this.removeEventOnUnmount = null
    }
  }

  onChange = content => {
    // Tiny is too liberal with its onChange events. They will fire on
    // mousedown and on focusout - i.e. without content actually changing.
    const old = this.content
    const changed = old !== content

    if (changed) {
      const firstEdit = !!(!old && content)
      const deletedAll = !!(old && !content)
      this.content = content
      if (this.props.onChange) {
        this.props.onChange(content, {
          firstEdit,
          deletedAll,
        })
      }
    } else {
      debug('skipping update: editor detected no content change', {
        old,
        content,
        editorRef: this.editor,
      })
    }
  }

  generateContentStyle() {
    const { inline, contentStyle, extraStyle } = this.props
    if (inline || contentStyle === false) {
      return null
    }
    return `${contentStyle || defaultContentStyle}\n${extraStyle}`
  }

  generateInlineContentStyle() {
    const { extraStyle } = this.props
    if (!extraStyle) {
      return null
    }
    return css`
      & .mce-content-body {
        ${extraStyle};
      }
    `
  }

  handleInit = (event, editor) => {
    const { focusWhenInitialized, onInit, onResize } = this.props

    if (focusWhenInitialized) {
      editor.focus()
      editor.selection.select(editor.getBody(), true)
      editor.selection.collapse(false)
    }

    // Tinymce doenst seem to allow us to hook into an `autoresize` event so we
    // have to listen for the editor iframe window's 'resize' event
    if (onResize) {
      const iframe = editor.getWin()
      if (iframe) {
        iframe.addEventListener('resize', onResize)
        this.removeEventOnUnmount = () => {
          iframe.removeEventListener('resize', onResize)
        }
      }
    }

    if (onInit) onInit(editor)
  }

  saveRefs = node => {
    this.editorRef = node
    this.editor = node && node.editor
    const { forwardRef } = this.props
    if (forwardRef) forwardRef(this.editor)
  }

  render() {
    const {
      className,
      config,
      contentStyle,
      extraStyle,
      id,
      initialValue,
      onKeyDown,
      onKeyUp,
      onSetup,
      onBlur,
      onFocus,
      placeholder,
      placeholderHeight,
      placeholderStyle,
      toolbar,
    } = this.props

    const inline = this.props.inline || config.inline
    const raw = this.props.raw || config.raw

    let pluginString = 'link autolink image lists paste'
    if (config && config.plugins) pluginString = config.plugins

    if (placeholder) {
      pluginString = `${pluginString} placeholder`
    }

    let skinUrl = `${app.pathPrefix}/tinymce/skins/groove`
    if (
      globalConfig.isDevelopment ||
      globalConfig.isAlpha ||
      globalConfig.isDocker
    ) {
      // on alpha and dev, pass, don't mess around with skin url
    } else if (globalConfig.isStaging) {
      // on staging, set it to app.groovehq.com
      skinUrl = `https://app.groovehqstaging.com${skinUrl}`
    } else {
      // on production, set it to app.groovehq.com
      skinUrl = `https://app.groovehq.com${skinUrl}`
    }

    const initConfig = {
      branding: false,
      height: 400,
      content_css: false,
      browser_spellcheck: true,
      link_assume_external_targets: true,
      menubar: false,
      statusbar: !!pluginString.match(/charactercount/),
      skin_url: skinUrl,
      skin: 'groove',
      relative_urls: false,
      remove_script_host: false,
      convert_urls: false,
      toolbar:
        toolbar !== undefined
          ? toolbar
          : 'bold italic underline strike | formatselect | numlist bullist | alignleft aligncenter alignright | link code',
      ...config,
      // These go last since we handle them differently.
      plugins: pluginString,
      setup: editor => {
        if (onKeyUp) editor.on('keyup', onKeyUp)
        if (onKeyDown) editor.on('keydown', onKeyDown)
        if (onBlur) editor.on('blur', onBlur)
        if (onFocus) editor.on('focus', onFocus)
        if (onSetup) onSetup(editor)
        if (config.setup) config.setup(editor)
      },
    }

    if (inline) {
      // NOTE (jscheel): Prop takes precedence over config value.
      initConfig.inline = true
    }

    if (!inline) {
      initConfig.content_style =
        contentStyle ||
        (extraStyle
          ? `${defaultContentStyle}\n${extraStyle}`
          : defaultContentStyle)
    }

    if (raw) {
      initConfig.entity_encoding = 'raw'
    }

    if (placeholder) {
      initConfig.placeholder = placeholder
      initConfig.placeholder_attrs = {
        style: Object.assign(
          {},
          defaultPlaceholderStyle,
          placeholderStyle || {}
        ),
      }
    }

    return (
      <div
        className={cn('groove-editor', className)}
        css={inline ? this.generateInlineContentStyle() : null}
      >
        <SkinnedEditor
          id={id}
          init={initConfig}
          initialValue={initialValue}
          onEditorChange={this.onChange}
          onInit={this.handleInit}
          placeholderHeight={placeholderHeight}
          ref={this.saveRefs}
          inline={inline}
        />
      </div>
    )
  }
}

export default compose(withEscKeyUnfocus)(BasicEditor)
