import React, { Fragment } from 'react'
import { Form, Icon } from 'util/ui'
import { Tip } from 'shared/components/ui/Typography'

export default function FormSuccess({ message }) {
  return (
    <Fragment>
      <Form.Field>
        <Tip color="groovy">
          <Icon name="check circle outline" />
          {message}
        </Tip>
      </Form.Field>
      <Form.Field>
        <hr />
      </Form.Field>
    </Fragment>
  )
}
