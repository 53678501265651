import { createSelector } from 'reselect'
import { selectCurrentUserIsAdminOrOwner } from 'ducks/currentUser/selectors'
import { selectKnownMailboxes } from './selectKnownMailboxes'
import { selectInaccessibleMailboxes } from './selectInaccessibleMailboxes'

/**
 * only includes inaccessible if the user is an owner/admin
 */

export const selectMailboxesIncludingInaccessible = createSelector(
  selectCurrentUserIsAdminOrOwner,
  selectKnownMailboxes,
  selectInaccessibleMailboxes,
  (currentUserIsAdminOrOwner, knownMailboxes, inaccessibleMailboxes) => {
    if (!currentUserIsAdminOrOwner || !inaccessibleMailboxes) {
      return knownMailboxes
    }

    return [...knownMailboxes, ...inaccessibleMailboxes]
  }
)
