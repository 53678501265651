import { emptyObj } from 'util/objects'
import createCachedSelector from 're-reselect'
import { selectTicketsById } from './selectTicketsById'

export const selectTicketById = createCachedSelector(
  selectTicketsById,
  (_state, ticketId) => ticketId,
  (ticketsById, ticketId) => {
    return ticketsById[ticketId] || emptyObj
  }
)((_state, ticketId) => ticketId || 'unknown')
