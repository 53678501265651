/* eslint-disable no-multi-assign */
import * as pages from 'constants/pages'

import {
  BLUR_EDIT_NOTE,
  CANCEL_EDIT_NOTE,
  EDIT_NOTE,
  UPDATE_EDITING_NOTE,
  UPDATE_NOTE_REQUEST,
} from 'constants/action_types'

const defaultState = {
  editingNoteId: null,
  editingNoteFocused: false,
  editingNoteText: null,
}

const reducers = {}

reducers[EDIT_NOTE] = (state, { data: { noteId, noteText } }) => {
  if (noteId === state.editingNoteId) return state
  return {
    ...state,
    editingNoteId: noteId,
    editingNoteFocused: true,
    editingNoteText: noteText,
  }
}

reducers[CANCEL_EDIT_NOTE] = reducers[UPDATE_NOTE_REQUEST] = reducers[
  pages.TICKET_PAGE
] = state => {
  if (!state.editingNoteId && !state.editingNoteText) return state
  return {
    ...state,
    editingNoteId: null,
    editingNoteFocused: false,
    editingNoteText: null,
  }
}

reducers[BLUR_EDIT_NOTE] = state => {
  if (!state.editingNoteFocused) return state
  return {
    ...state,
    editingNoteFocused: false,
  }
}

reducers[UPDATE_EDITING_NOTE] = (state, { data: { noteId, noteText } }) => {
  if (noteId !== state.editingNoteId) return state
  return {
    ...state,
    editingNoteText: noteText,
  }
}

export default function reducer(state = defaultState, action) {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
