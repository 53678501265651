export const mailboxesByStatesCacheKey = (
  _state,
  mailboxStates,
  _createdAfter,
  channelTypes
) =>
  [
    (mailboxStates || ['empty']).join(','),
    (channelTypes || ['all']).join(','),
  ].join('|')
