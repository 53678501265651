import { createSelector } from 'reselect'
import { emptyArr } from 'util/arrays'
import { _selectSortedIds } from './_selectSortedIds'
import { selectMailboxesById } from './selectMailboxesById'

export const selectKnownMailboxes = createSelector(
  selectMailboxesById,
  _selectSortedIds,
  (byId, sortedIds) => {
    if (sortedIds == null) {
      return emptyArr
    }
    return sortedIds.map(id => byId[id])
  }
)
