import { createSelector } from 'reselect'
import { selectDefaultTicketSearch } from 'selectors/search/default'
import { selectTicketSearchOperatorValueMap } from 'selectors/search/base'
import { constructEncodedSearchQueryString } from 'util/search'
import { selectCurrentPath } from '.'

// HACK (jscheel): This is ghetto, but we have some paths that are partially
// encoded, and we need to check against them to make sure the right leftnav
// item is highlighted on page load. There appears to be a problem with the way
// spaces are stored and retrieved, so we just force what we need.

export const selectCurrentPathWithSearchEncoded = createSelector(
  selectCurrentPath,
  selectDefaultTicketSearch,
  selectTicketSearchOperatorValueMap,
  (path, defaultSearch, idValueMap) => {
    if (path === '/')
      return `/search/${constructEncodedSearchQueryString(
        defaultSearch,
        idValueMap
      )}`

    const trimmedPath = path.replace(/^\/search\//, '')
    return `/search/${encodeURIComponent(decodeURIComponent(trimmedPath))}`
  }
)
// END HACK
