import { createSelector } from 'reselect'

import { selectCurrentSortOrder } from 'selectors/app'
import {
  selectCurrentTicketSearchQueryObject,
  selectTicketSearchOperatorValueMap,
} from 'selectors/search/base'
import { mergeSearchQueryString, areSearchesEquivalent } from 'util/search'
import { toParam } from 'util/search/sorting/toParam'

export const selectIsSearchModifierOpen = state => state.searchModifier.isOpen

const SEARCH_OPTIONS = {
  state: {
    Any: { state: null, deleted: null },
    Open: { state: 'open', deleted: null },
    Snoozed: { state: 'snoozed', deleted: null },
    Closed: { state: 'closed', deleted: null },
  },
  drafts: {
    'Reply Drafts': { draft_type: 'reply', deleted: null },
    'Note Drafts': { draft_type: 'note', deleted: null },
  },
}

function makeSelectModifiedSearchQueries(operator) {
  const options = SEARCH_OPTIONS[operator]
  return createSelector(
    selectCurrentTicketSearchQueryObject,
    selectTicketSearchOperatorValueMap,
    selectCurrentSortOrder,
    (currentSearch, valueMap, sortOrder) => {
      return Object.keys(options).reduce((modified, key) => {
        const queryString = mergeSearchQueryString(currentSearch, options[key])
        // eslint-disable-next-line no-param-reassign
        modified[key] = {
          url: `/search/${encodeURIComponent(queryString)}${toParam(
            sortOrder
          )}`,
          label: key,
          queryString,
          current: areSearchesEquivalent(currentSearch, queryString, valueMap),
        }
        return modified
      }, {})
    }
  )
}

export const selectModifiedSearchQueriesForState = makeSelectModifiedSearchQueries(
  'state'
)

export const selectModifiedSearchQueriesForDrafts = makeSelectModifiedSearchQueries(
  'drafts'
)
