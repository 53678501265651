import { createSelector } from 'reselect'
import { selectCurrentFolderId } from 'selectors/app'
import { foldersByIdSelector } from './foldersByIdSelector'

export const selectCurrentFolder = createSelector(
  foldersByIdSelector,
  selectCurrentFolderId,
  (byId, folderId) => {
    if (!folderId) return null
    return byId[folderId] || null
  }
)
