import { actorLabel } from 'selectors/currentChangesets'

import Assignment from 'inbox/assignment'

import { sortByKey } from 'util/arrays'
import { timeUntilInWords } from 'util/date'

import { removeInitialTwitterMention, replaceSpaces } from 'util/strings'
import { getStateLabel } from 'util/ticketState'

import { objectHashSerializer, memoize } from 'util/memoization'
import { isTwitter, isWidget } from 'util/ticket'

// if the given actor is defined, returns an object keyed by label
export const addActorLabelIfPresent = memoize(
  (actor, currentUser, fromTwitter = false) => {
    if (!actor) return null

    return {
      ...actor,
      label: actorLabel(actor, currentUser, fromTwitter),
      labelFull: actorLabel(actor, currentUser, fromTwitter, true),
    }
  },
  {
    serializer: objectHashSerializer,
  }
)

const emptyArray = []

const produceLabels = memoize((labelIds, labelsById) => {
  return sortByKey(labelIds.map(id => labelsById[id]).filter(e => e), 'name')
})

export const decorate = memoize(
  (agentsById, groupsById, currentUser, customersById, labelsById) => {
    return memoize(
      ticket => {
        const stateLabel = getStateLabel(
          ticket.state,
          ticket.snoozedUntil,
          ticket.deleted_at
        )
        // Denormalize the labels, decorating the ticket directly with them.
        const labelIds =
          ticket.labelIdsWithPendingEdits || ticket.labelIds || emptyArray
        const labels = produceLabels(labelIds, labelsById)
        const ticketBody =
          ticket.bodyType === 'internal' // Notes' initial mentions are not Twitter mentions but Groove mentions
            ? ticket.body
            : removeInitialTwitterMention(ticket.body)

        const assignment = Assignment.fromTicket(
          ticket,
          agentsById,
          groupsById,
          currentUser
        )

        const customer =
          customersById[ticket.customer && ticket.customer.id] ||
          ticket.customer

        return {
          ...ticket,
          assignment,
          bodyParsed: replaceSpaces(ticketBody),
          customer: addActorLabelIfPresent(
            customer,
            currentUser,
            isTwitter(ticket)
          ),
          bodyAuthor: addActorLabelIfPresent(
            ticket.bodyAuthor ? agentsById[ticket.bodyAuthor.id] : null,
            currentUser,
            isTwitter(ticket)
          ),
          labels,
          stateLabel,
          titleLabel: ticketListLabel(ticket),
          starred: ticket.priority === 'urgent',
        }
      },
      {
        serializer: objectHashSerializer,
      }
    )
  },
  {
    serializer: objectHashSerializer,
  }
)

export const decorateForInspector = memoize(
  (agentsById, currentUser, groupsById) => {
    return memoize(
      ticket => {
        const assignment = Assignment.fromTicket(
          ticket,
          agentsById,
          groupsById,
          currentUser
        )

        return Object.assign({}, ticket, {
          assignment,
          isTwitterTicket: isTwitter(ticket),
          isWidgetTicket: isWidget(ticket),
          snoozedLabel: timeUntilInWords(new Date(), ticket.snoozedUntil),
        })
      },
      {
        serializer: objectHashSerializer,
      }
    )
  },
  {
    serializer: objectHashSerializer,
  }
)

function ticketListLabel(ticket) {
  return ticket.title
}
