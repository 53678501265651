import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'

import { queryStringToQueryId } from 'ducks/searches/utils/query'
import { emptyObj, isDeepEqual } from 'util/objects'
import {
  LOG_CONVERSATION_PAGE,
  SEARCH_PAGE,
  TICKET_COMMENT_PAGE,
  TICKET_PAGE,
} from 'constants/pages'

const base = state => state.location || emptyObj

export const selectCurrentPath = state => base(state).pathname

export const selectCurrentPage = state => base(state).type

const selectCurrentSearch = state => base(state).search

export const selectCurrentPayload = state => base(state).payload || emptyObj

export const selectQueryParams = state => base(state).query || emptyObj

export const selectQueryParamsQueryId = createCachedSelector(
  selectQueryParams,
  (_state, options) => options,
  (queryParams, options) => queryStringToQueryId(queryParams, options)
)((_state, options) => JSON.stringify(options || {}))

export const selectPrevious = state => base(state).prev

const selectPreviousSearch = state => (base(state).prev || emptyObj).search

export const selectCurrentTicketAction = createSelector(
  selectCurrentPage,
  selectCurrentPayload,
  selectQueryParams,
  (type, payload, query) => {
    return {
      type,
      payload,
      meta: { query },
    }
  }
)

export const selectCurrentPageWithCollapsedHeaderAction = createSelector(
  selectCurrentTicketAction,
  currentTicketAction => {
    const update = {
      meta: {
        ...currentTicketAction.meta,
        query: { ...currentTicketAction.meta.query, expand: false },
      },
    }
    return { ...currentTicketAction, ...update }
  }
)

export const selectCurrentId = createSelector(
  selectCurrentPayload,
  payload => payload.id
)

function fullPath(path, search) {
  if (!search) return path
  return `${path}?${search}`
}

export const selectCurrentPathWithSearch = createSelector(
  selectCurrentPath,
  selectCurrentSearch,
  fullPath
)

export const selectPreviousPath = createSelector(
  selectPrevious,
  prev => prev.pathname
)

export const selectPreviousPathWithSearch = createSelector(
  selectPreviousPath,
  selectPreviousSearch,
  fullPath
)

// Jank workaround for https://github.com/faceyspacey/redux-first-router/issues/183
// TLDR; we have to live with double dispatches, but we can avoid the big
// perf hit caused by fetching tickets twice, if we just check for the
// double dispatch.
//
// What happens is we see the first dispatch with a current path like
//   "/search/inbox%3A0927513294 folder%3A3114099215"
//
// and the same previous path, except with more encoding!
//   "/search/inbox%3A0927513294%20folder%3A3114099215"
//
// Then we see a second dispatch (with the same current path, but the 'real'
// previous path.)
//
export const selectIsRFRBeingStupid = createSelector(
  selectCurrentPayload,
  selectQueryParams,
  selectCurrentPage,
  selectPrevious,
  (currentPayload, currentQuery, currentPage, prevLocation) => {
    if (currentPage !== prevLocation.type) return false
    if (currentPage !== SEARCH_PAGE) return false
    return (
      // We can't deep compare payload, because RFR will remove the properties in the payload that aren't showing in the URL at the duplicate dispatch.
      currentPayload.term === prevLocation.payload.term &&
      isDeepEqual(currentQuery, prevLocation.query)
    )
  }
)

export function isInboxPath(path) {
  if (path === '/') return true
  if (path.match(/^\/search/)) return true
  if (path.match(/^\/tickets/)) return true
  return false
}

export function isSettingsPath(path) {
  if (path === '/') return true
  if (path.match(/^\/settings/)) return true
  return false
}

export function isLiveChatPath(path) {
  if (path.match(/^\/chats/)) return true
  return false
}

export function isSocialChatPath(path) {
  if (path.match(/^\/social/)) return true
  return false
}

export function isChatPath(path) {
  return isLiveChatPath(path) || isSocialChatPath(path)
}

export function isOnboardingPath(path) {
  if (path.match(/^\/setup/)) return true
  if (path.match(/^\/signup/)) return true
  return false
}

function isSettingsRuleTemplatesPath(path) {
  if (path.match(/^\/settings\/rules\/templates/)) return true

  return false
}

export const selectIsInInbox = createSelector(selectCurrentPath, isInboxPath)

export const selectIsInSettings = createSelector(
  selectCurrentPath,
  isSettingsPath
)

export const selectIsInSettingsRuleTemplates = createSelector(
  selectCurrentPath,
  isSettingsRuleTemplatesPath
)

export const selectIsInChat = createSelector(selectCurrentPath, isChatPath)

export const selectIsInLiveChat = createSelector(
  selectCurrentPath,
  isLiveChatPath
)

export const selectIsInSocialChat = createSelector(
  selectCurrentPath,
  isSocialChatPath
)

export const selectIsLoggedInPath = createSelector(
  selectCurrentPath,
  path => isInboxPath(path) || isSettingsPath(path) || isChatPath(path)
)

export const selectIsInOnboarding = createSelector(
  selectCurrentPath,
  isOnboardingPath
)

export const selectIsOnSearchPage = createSelector(selectCurrentPath, path => {
  if (path === '/') return true
  if (path.match(/^\/search/)) return true
  return false
})

export const selectIsOnSearchPageWithoutFirstTicketLoaded = createSelector(
  selectIsOnSearchPage,
  selectQueryParams,
  (isOnSearchPage, params) => {
    if (isOnSearchPage && !params.conversationId) return true
    return false
  }
)

// Don't really need state for selectSubdomain, selectHost and selectIsAccountSubdomain
// but im adding it for future proofing. We should probably create reducer which adds
// this information into the state and selects it from there.
export function selectHost() {
  return window.location.host
}

export const selectSubdomain = createSelector(
  selectHost,
  host => host.split('.')[0]
)

export const selectIsAccountSubdomain = createSelector(
  selectHost,
  selectSubdomain,
  (host, subdomain) =>
    !host.match(/\.alpha\./) &&
    !subdomain.match(/^(app|mobile|mobile-staging|beta|www|mobile-frontend)$/)
)

export const selectAccountSubdomainFromLocation = createSelector(
  selectIsAccountSubdomain,
  selectSubdomain,
  (isAccountSubdomain, subdomain) => {
    if (isAccountSubdomain) {
      return subdomain
    }
    return null
  }
)

export const selectIsInKb = createSelector(selectCurrentPath, path => {
  if (path.match(/^\/kb/)) return true
  return false
})

export function selectLocationKind(state) {
  return state.location.kind
}

// true if you logging a conversation or typing a ticket note
export const selectIsInNoteMode = createSelector(
  selectCurrentPage,
  currentPage =>
    [LOG_CONVERSATION_PAGE, TICKET_COMMENT_PAGE].includes(currentPage)
)

export const selectIsTicketPagePush = createSelector(
  selectPrevious,
  selectLocationKind,
  (previousLocation, locationKind) =>
    previousLocation.type === TICKET_PAGE && locationKind === 'push'
)
