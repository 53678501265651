import CursorManagedInput from './CursorManagedInput'

class ParsedInput extends CursorManagedInput {
  constructor(props) {
    super(props)
    this.originalHandleChange = this.handleChange
    this.handleChange = this.handleParsedChange
    this.originalPartitionProps = this.partitionProps
    this.partitionProps = this.partitionParsedProps
  }

  handleParsedChange = e => {
    const { parse } = this.props
    // NOTE (jscheel): We have to re-manage the cursor position a bit here
    // because of the way that the change event triggers on the input.
    if (parse) {
      let caretPositionIndex = e.target.selectionStart
      const value = e.target.value
      const parsedValue = parse(e.target.value)
      caretPositionIndex += parsedValue.length - value.length
      e.target.value = parsedValue
      e.target.setSelectionRange(caretPositionIndex, caretPositionIndex)
    }
    this.originalHandleChange(e)
  }

  partitionParsedProps = () => {
    const paritionedProps = this.originalPartitionProps()
    const withoutParse = paritionedProps[1]
    delete withoutParse.parse
    return [paritionedProps[0], withoutParse]
  }
}

export default ParsedInput
