import * as types from 'constants/action_types'

// started_at, updated_at, priority, state

const defaultState = {}
const reducers = {}

reducers[types.UPDATE_RAW_EMAIL] = function(state, action) {
  const byId = state.byId || {}
  const newById = Object.assign({}, byId)
  const { rawEmail } = action.data
  if (rawEmail.headers) rawEmail.headers = JSON.parse(rawEmail.headers)
  newById[rawEmail.commentId] = rawEmail
  return Object.assign({}, state, {
    byId: newById,
  })
}

export default function reducer(state = defaultState, action) {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
