// Wrapper around SUI Dropdown that filters props.options based on a simple
// match of the option.text and option.description.
//
// Uses a simple caseless match function.
//
import React from 'react'

import { getDisplayName } from 'util/hoc'
import { curry } from 'util/functions'
import { caselessMatch } from 'util/strings'

function withSimpleFiltering(WrappedDropdown) {
  class WithSimpleFiltering extends React.Component {
    state = { searchQuery: undefined, filteredOptions: [] }

    componentWillReceiveProps(nextProps) {
      if (nextProps.options !== this.props.options) {
        const { searchQuery } = this.state

        this.setState({
          filteredOptions: this.filter(nextProps.options, searchQuery),
        })
      }
    }

    // Returns the filtered options in local state, if present.
    // Otherwise returns given `options` from props (if present)
    getOptions = () => {
      const { searchQuery, filteredOptions } = this.state
      if (searchQuery) return filteredOptions
      return this.props.options
    }

    handleSearchChange = searchQuery => {
      this.setState({
        searchQuery,
        filteredOptions: this.filter(this.props.options, searchQuery),
      })
    }

    filter = (options = [], searchQuery = '') => {
      if (!searchQuery) return options
      const matches = curry(caselessMatch)(searchQuery)
      return options.filter(
        opt => matches(opt.text) || matches(opt.description)
      )
    }

    render() {
      const { ...rest } = this.props

      return (
        <WrappedDropdown
          {...rest}
          onSearchChange={this.handleSearchChange}
          options={this.getOptions()} // order important. override props.options
        />
      )
    }
  }

  WithSimpleFiltering.displayName = `WithSimpleFiltering(${getDisplayName(
    WrappedDropdown
  )})`

  return WithSimpleFiltering
}

export default withSimpleFiltering
