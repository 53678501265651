// NOTE (jscheel): This is a simplified flexbox container. See the proptypes
// of Container and Item to get an idea of what you can do.

import Container from './Container'
import Item from './Item'

export default {
  Item,
  Container,
}
