import { createSelector } from 'reselect'
import { selectPrefersUnifiedInbox } from 'ducks/currentUser/selectors/preferences/selectPrefersUnifiedInbox'
import { selectMailboxes } from 'selectors/mailboxes/selectMailboxes'
import { selectTicketSearchOperatorValueMap } from 'selectors/search/base'
import { foldersByIdSelector } from './foldersByIdSelector'
import { selectFolders } from './selectFolders'
import { queryFor } from './queryFor'

export const selectAllFolderQueries = createSelector(
  selectMailboxes,
  selectFolders,
  foldersByIdSelector,
  selectTicketSearchOperatorValueMap,
  selectPrefersUnifiedInbox,
  (mailboxes, folders, foldersById, valueMap, prefersUnified) => {
    const result = []
    folders.forEach(folder => {
      result.push(queryFor(valueMap, folder))
    })
    if (!prefersUnified)
      mailboxes.forEach(mailbox => {
        mailbox.folders.forEach(folderId => {
          const folder = foldersById[folderId]
          if (folder) {
            result.push(queryFor(valueMap, folder, mailbox))
          }
        })
      })
    return result
  }
)
