// Wrapper around SUI Button that adds spacing (and annoying SUI wrapper)
import styled from '@emotion/styled'

import { Button as SUIButton } from 'util/ui'

const Button = styled(SUIButton)`
  &&& {
    margin: 0px; /* unlike SUI, let container control margin */
  }
`

Button.displayName = 'Button'

export default Button
