import { createSelector } from 'reselect'

import { selectModalProps } from 'selectors/modals_base'

export * from 'selectors/modals_base'

export const selectCurrentModalTab = createSelector(
  selectModalProps,
  props => props.activeTab
)
