import * as types from 'constants/action_types'
import { reduceActor } from 'util/actors'

const defaultState = {
  byMessageId: {}, // read receipts by message id
  byChangesetId: {}, // read receipts by changeset id
  messageChangesets: {}, // helper map to store the message id -> changeset relation
}

const reducers = {}

const captureReadReceipts = (state, array) => {
  const byMessageId = { ...state.byMessageId }
  const byChangesetId = { ...state.byChangesetId }
  const messageChangesets = { ...state.messageChangesets }

  let hasChanges = false

  array.forEach(record => {
    if (record.change_type === 'Message') {
      // If we found a message we add it to our message id -> changeset id map
      // to be able to tell easily which read receipt corresponding to which message
      const messageId = record.change.id
      const changesetId = record.changeset
      messageChangesets[messageId] = changesetId

      // If we already have a read receipt saved to the current message id,
      // we update the list by changeset list as well because now
      // we know its changeset
      if (byMessageId[messageId]) {
        byChangesetId[changesetId] = byMessageId[messageId]
      }
      hasChanges = true
    }
    if (record.change_type === 'Ticket::CustomerActionOpen') {
      const { messageId } = record.change
      const changesetId = messageChangesets[messageId]
      hasChanges = true
      const action = {
        actor: reduceActor(record.actor),
        change_type: record.change_type,
        created_at: record.created_at,
      }
      byMessageId[messageId] = action

      if (changesetId) {
        byChangesetId[changesetId] = action
      }
    }
  })

  if (!hasChanges) return state

  return { ...state, byMessageId, byChangesetId, messageChangesets }
}

reducers[types.FETCH_TICKET_ACTIONS_SUCCESS] = (state, action) => {
  const { actions } = action.data
  return captureReadReceipts(state, actions.records)
}

reducers[types.FETCH_CHANGESET_TICKET_ACTIONS_SUCCESS] = (state, action) => {
  const { actions } = action.data
  // Here we're saving the changesetId to use this when saving receipts
  // the changeset.id in the receipt is usually different than the one we're fetching
  return captureReadReceipts(state, actions)
}

reducers[types.FETCH_TICKET_SUCCESS] = (state, action) => {
  const { ticket } = action.data
  return captureReadReceipts(state, ticket.actions.records)
}

export default function reducer(state = defaultState, action) {
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
