import { createSelector } from 'reselect'
import { isEmpty } from 'util/arrays'
import { selectCurrentChannelAccessList } from 'ducks/accessList/selectors'
import { selectCurrentUser } from 'ducks/currentUser/selectors/base'
import selectGroups from 'ducks/teams/selectors/selectGroups'

export const selectGroupsFilteredByCurrentChannelAccessList = createSelector(
  selectGroups,
  selectCurrentChannelAccessList,
  (groups, accessList) => {
    if (!accessList || !accessList.groupIds || isEmpty(accessList.groupIds)) {
      return groups
    }
    const lookup = new Set(accessList.groupIds)
    return groups.filter(group => lookup.has(group.id))
  }
)

export const selectGroupsFilteredByCurrentChannelAccessListById = createSelector(
  selectGroupsFilteredByCurrentChannelAccessList,
  groups => {
    return groups.reduce((memo, group) => {
      // eslint-disable-next-line no-param-reassign
      memo[group.id] = group
      return memo
    }, {})
  }
)

export const selectGroupsFilteredByCurrentUserGroupsAccess = createSelector(
  selectGroups,
  selectCurrentUser,
  (groups, currentUser) =>
    groups.filter(group =>
      group.agents.some(agent => agent.id === currentUser.id)
    )
)
