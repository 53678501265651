import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'
import { selectAgentsSortedByUsername } from 'selectors/agents/base'
import { selectCanMentionAnyAgent } from 'selectors/app'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import {
  selectCurrentMailboxAgents,
  selectNewNoteDraftMailboxAgents,
} from 'ducks/accessList/selectors'
import { selectIsLoggingNewConversation } from 'selectors/page'
import { selectCurrentTicket } from 'selectors/tickets/current/selectCurrentTicket'
import { selectCurrentTicketRawActions } from 'selectors/currentChangesets'
import { selectCurrentTicketDraftByInferredType } from 'ducks/drafts2/selectors'
import { filterAgentsByTerm } from 'util/agents'
import {
  emptyArr,
  sortByName,
  intersection,
  difference,
  sortByKey,
} from 'util/arrays'
import { createBasicSelector } from 'util/redux'
import { emptyObj } from 'util/objects'
import { selectSuggestedAgents } from './assignments'

export const selectAgentMention = state => state.app.agentMention
// Store mentions in the current session
export const selectMentions = state => state.app.mentions || emptyObj

export const selectIsMentioningAgent = state => {
  if (!state.app.agentMention) return false
  return state.app.agentMention.length > 0
}

export const selectSuppressMentionSelector = state =>
  !!state.app.suppressMentionSelector

const selectAgentsWithAccess = createSelector(
  selectCurrentUser,
  state => selectCurrentMailboxAgents(state, true),
  (currentUser, agents) => {
    return agents.filter(agent => agent.id !== currentUser.id)
  }
)

// Ticket Note form mentions
//
// Extra layer of logic whereby the `mention_any_agent` preference skips the
// access_list logic entirely.
export const selectMentionableAgents = createSelector(
  selectCanMentionAnyAgent,
  selectAgentsSortedByUsername,
  selectAgentsWithAccess,
  (canMentionAnyone, allAgents, agentsWithAccess) => {
    if (!canMentionAnyone) return agentsWithAccess

    return allAgents
  }
)

const selectFilteredMentionableAgents = createSelector(
  selectMentionableAgents,
  selectAgentMention,
  (_state, showAllAtStart) => showAllAtStart || false,
  (agents, mention, showAllAtStart) => {
    const word = mention ? mention.word : null
    if (word && word.charAt(0) === '@') {
      return showAllAtStart && word.length === 1
        ? agents
        : filterAgentsByTerm(agents, word.substr(1))
    }
    return agents
  }
)

const selectNewNoteDraftAgentsWithAccess = createSelector(
  selectCurrentUser,
  selectNewNoteDraftMailboxAgents,
  (currentUser, agents) => {
    return agents.filter(agent => agent.id !== currentUser.id)
  }
)

// Log Conversation (i.e. New Note) mentions
export const selectNewNoteDraftMentionableAgents = createSelector(
  selectCanMentionAnyAgent,
  selectAgentsSortedByUsername,
  selectNewNoteDraftAgentsWithAccess,
  (canMentionAnyone, allAgents, agentsWithAccess) => {
    if (!canMentionAnyone) return agentsWithAccess

    return allAgents
  }
)

const selectNewNoteDraftFilteredMentionableAgents = createSelector(
  selectNewNoteDraftMentionableAgents,
  selectAgentMention,
  (_state, showAllAtStart) => showAllAtStart || false,
  (agents, mention, showAllAtStart) => {
    const word = mention ? mention.word : null
    if (word && word.charAt(0) === '@') {
      return showAllAtStart && word.length === 1
        ? agents
        : filterAgentsByTerm(agents, word.substr(1))
    }
    return agents
  }
)

// Main entrypoint for both Note and Log Conversation forms.
export const selectTicketOrNoteDraftFilteredMentionableAgents = createSelector(
  selectIsLoggingNewConversation,
  selectNewNoteDraftFilteredMentionableAgents,
  selectFilteredMentionableAgents,
  (onNewNotePage, draftAgents, ticketAgents) => {
    if (!onNewNotePage) return ticketAgents

    return draftAgents
  }
)

export const selectShowMentionSelector = createBasicSelector(
  selectIsMentioningAgent,
  selectSuppressMentionSelector,
  selectFilteredMentionableAgents,
  (isMentioning, isSuppressing, filtered) => {
    return isMentioning && !isSuppressing && filtered.length > 0
  }
)

const selectSuggestedMentionableAgents = createSelector(
  selectMentionableAgents,
  selectCurrentTicket, // could return null
  selectCurrentTicketDraftByInferredType,
  selectCurrentTicketRawActions,
  (agents, ticket, draft, actions) => {
    const shortList = {}
    // Assignee added in draft
    if (draft && draft.assigneeId) shortList[draft.assigneeId] = true
    // Assignee
    if (ticket && ticket.assignee) shortList[ticket.assignee.id] = true
    // Author of a comment on this ticket (note or reply)
    const records = actions
    if (records) {
      records.forEach(action => {
        const actor = action.actor
        if (actor.username) {
          shortList[actor.id] = true
        }
      })
    }
    // Agent that assigned ticket to current assignee
    const filtered = agents.filter(agent => shortList[agent.id])
    return filtered.length === 0 ? agents : filtered
  }
)

const selectFirstMentionableAgent = createSelector(
  selectMentionableAgents,
  selectSuggestedMentionableAgents,
  selectFilteredMentionableAgents,
  selectAgentMention,
  (sorted, suggested, filtered, mention) => {
    if (!mention) return null
    let result = null
    if (mention.word === '@') {
      result = suggested[0]
    } else {
      result = filtered[0]
    }
    if (!result) result = sorted[0]
    return result
  }
)

export const selectSelectedMentionSelectorAgentId = createSelector(
  state => state.app.selectedMentionSelectorAgentId,
  selectFirstMentionableAgent,
  (selectedAgentId, firstMentionableAgent) => {
    if (!selectedAgentId && firstMentionableAgent) {
      return firstMentionableAgent.id
    }
    return selectedAgentId
  }
)

export const selectTicketOrNoteDraftFilteredMentionableAgentsWithSuggested = createCachedSelector(
  selectTicketOrNoteDraftFilteredMentionableAgents,
  selectSuggestedMentionableAgents,
  selectSuggestedAgents,
  selectMentions,
  (agents, suggestedMentionableAgents, topAssignedAgents, mentions) => {
    if (!agents || agents.length === 0) {
      return {
        agents: emptyArr,
        suggestedAgents: emptyArr,
      }
    }
    const mentionsArray = Object.keys(mentions).map(key => ({
      id: key,
      count: mentions[key],
    }))
    const sortedMentionsArray = sortByKey(mentionsArray, 'count').reverse()
    const sortedMentionAgents = sortedMentionsArray.reduce(
      (accumulator, currentItem) => {
        accumulator.push(agents.find(agent => agent.id === currentItem.id))
        return accumulator
      },
      []
    )
    const suggestedMentionableAgentsInTicket =
      suggestedMentionableAgents.length >= agents.length
        ? []
        : suggestedMentionableAgents
    // Get the suggested agents from sortedMentionAgents + suggestedMentionableAgentsInTicket + topAssignedAgents
    const suggestedAgents = [
      ...sortedMentionAgents,
      ...suggestedMentionableAgentsInTicket,
      ...topAssignedAgents,
    ]

    // Get the first two suggested agents that exist in agents list
    const suggestedFilteredAgents = intersection(suggestedAgents, agents).slice(
      0,
      2
    )

    return {
      agents: [
        ...suggestedFilteredAgents,
        ...sortByName([...difference(agents, suggestedFilteredAgents)]),
      ],
      suggestedAgents: suggestedFilteredAgents,
    }
  }
)((_state, showAllAtStart) => `${showAllAtStart}`)
