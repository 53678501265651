import { createSelector } from 'reselect'
import { selectRawById } from 'selectors/tickets/byId/selectRawById'
import { selectTicketsById } from 'selectors/tickets/byId/selectTicketsById'
import { selectCurrentTicketUndecorated } from 'selectors/tickets/current/selectCurrentTicketUndecorated'
import { emptyArr } from 'util/arrays'
import { selectSelectedTicketIds } from './base'

export const selectSelectedTickets = createSelector(
  selectTicketsById,
  selectSelectedTicketIds,
  (byId, ticketIds) => {
    if (!ticketIds || ticketIds.length === 0) return emptyArr // perf: same empty array
    return ticketIds.map(id => byId[id])
  }
)

export const selectSelectedTicketsUndecorated = createSelector(
  selectRawById,
  selectSelectedTicketIds,
  (byId, ticketIds) => {
    if (!ticketIds || ticketIds.length === 0) return emptyArr // perf: same empty array
    return ticketIds.map(id => byId[id])
  }
)

export const selectCurrentOrSelectedTickets = createSelector(
  selectSelectedTickets,
  selectCurrentTicketUndecorated,
  (selected, current) => {
    return selected.length > 0 ? selected : [current]
  }
)

export const selectCurrentOrSelectedTicketsUndecorated = createSelector(
  selectSelectedTicketsUndecorated,
  selectCurrentTicketUndecorated,
  (selected, current) => {
    return selected.length > 0 ? selected : [current]
  }
)

export const selectCurrentOrSelectedTicketIds = createSelector(
  selectSelectedTicketIds,
  selectCurrentTicketUndecorated,
  (selected, current) => {
    return selected.length > 0 ? selected : [current]
  }
)
