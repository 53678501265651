import { createSelector } from 'reselect'
import { isTwitter, isDirectMessage, isFacebook } from 'util/ticket'
import { selectCurrentTicketUndecorated } from './current/selectCurrentTicketUndecorated'

export const selectIsTwitterTicket = createSelector(
  selectCurrentTicketUndecorated,
  ticket => isTwitter(ticket)
)

export const selectIsTwitterDM = createSelector(
  selectCurrentTicketUndecorated,
  ticket => isDirectMessage(ticket)
)

export const selectIsFacebookTicket = createSelector(
  selectCurrentTicketUndecorated,
  ticket => isFacebook(ticket)
)
