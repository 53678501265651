import { UPDATE_APP_DATA } from 'constants/action_types'
import { FETCH_GROUPS_SUCCESS } from 'constants/actionTypes/groups'
import { emptyArr, without } from 'util/arrays'
import { SAVE_TEAM_SUCCESS, DELETE_TEAM_SUCCESS } from 'ducks/teams/actionTypes'
import { createActionTypeReducer } from 'util/reducers'
import { getRawId } from 'util/globalId'

const defaultState = []

const groupReducer = (draftState, action) => {
  const { groups = emptyArr } = action.payload || action.data
  draftState.length = 0
  groups.forEach(group => draftState.push(group))
  return draftState
}

export default createActionTypeReducer(
  {
    [UPDATE_APP_DATA]: groupReducer,
    [FETCH_GROUPS_SUCCESS]: groupReducer,
    [SAVE_TEAM_SUCCESS]: (
      draftState,
      {
        entities: {
          current: {
            update: { team: teamsById },
          },
        },
      }
    ) => {
      Object.values(teamsById).forEach(team => {
        const group = draftState.find(g => g.id === getRawId(team.id))
        if (group) {
          group.name = team.name
          group.agents = team.agents
        } else {
          draftState.push({
            id: getRawId(team.id),
            name: team.name,
            agents: team.agents,
          })
        }
      })
      return draftState
    },
    [DELETE_TEAM_SUCCESS]: (
      draftState,
      {
        entities: {
          current: {
            remove: { team: teamsById },
          },
        },
      }
    ) => {
      Object.keys(teamsById).forEach(teamId => {
        const group = draftState.find(g => g.id === getRawId(teamId))
        without(draftState, group)
      })
      return draftState
    },
  },
  defaultState
)
