import { createTypeElement, createPropTypes } from './util'

const BoxHeadline = props => createTypeElement('BoxHeadline', props)

BoxHeadline.propTypes = createPropTypes()

BoxHeadline.defaultProps = {
  as: 'span',
}

export default BoxHeadline
