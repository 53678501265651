// Stripped down Checkbox based on Semantic UI.
// Configurable input box outline+color, as well as separate events for label
// and checkbox clicking
import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styled from '@emotion/styled'

import debug from 'util/debug'

import BoxHeadline from './BoxHeadline'

/**
 * A simple colored checkbox
 */
class Checkbox extends React.Component {
  static propTypes = {
    /** Whether or not checkbox is checked. */
    checked: PropTypes.bool,

    /** Additional classes. */
    className: PropTypes.string,

    /** A unique identifier. */
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    /** The text of the associated label element. */
    label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),

    /** The HTML input name. */
    name: PropTypes.string,

    /**
     * Called when the checkbox is clicked.
     *
     * @param {SyntheticEvent} event - React's original SyntheticEvent.
     * @param {object} data - All props and current checked state.
     */
    onCheckboxClick: PropTypes.func,

    /**
     * Called when the checkbox is clicked.
     *
     * @param {SyntheticEvent} event - React's original SyntheticEvent.
     * @param {object} data - All props and current checked state.
     */
    onLabelClick: PropTypes.func,

    /** The HTML input value. */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  static defaultProps = {
    checked: false,
    className: undefined,
    id: undefined,
    label: undefined,
    name: undefined,
    onCheckboxClick: undefined,
    onLabelClick: undefined,
    value: undefined,
  }

  constructor(props) {
    super(props)
    this.state = { checked: props.checked }
  }

  handleCheckboxClick = e => {
    debug('handleCheckboxClick()')
    const { onCheckboxClick } = this.props
    this.handleClick(e, onCheckboxClick)
  }

  handleLabelClick = e => {
    debug('handleLabelClick()')
    const { onLabelClick } = this.props
    this.handleClick(e, onLabelClick)
  }

  handleClick = (e, handlerFn) => {
    const { checked } = this.state

    if (handlerFn) handlerFn(e, { ...this.props, checked: !checked })

    this.setState({ checked: !checked })
  }

  render() {
    const {
      className,
      id,
      inputColor, // eslint-disable-line (we want to ignore this prop)
      label,
      name,
      onCheckboxClick, // eslint-disable-line (we want to ignore this prop)
      onLabelClick, // eslint-disable-line (we want to ignore this prop)
      value,
      indeterminate,
      checked: checkedProp, // eslint-disable-line (we want to ignore this prop)
      ...rest
    } = this.props
    const { checked } = this.state

    // the <span> (not the label) is where we attach the pseudo classes to render
    // the styled checkbox. Its also where we attach the onCheckboxClick handler
    return (
      <div
        {...rest}
        className={cn(className, {
          checked: checked && !indeterminate,
          indeterminate,
        })}
      >
        <input
          checked={checked}
          className="hidden"
          id={id}
          name={name}
          readOnly
          type="checkbox"
          value={value}
        />
        <span className="box" onClick={this.handleCheckboxClick} />
        <BoxHeadline as="label" htmlFor={id} onClick={this.handleLabelClick}>
          {label}
        </BoxHeadline>
      </div>
    )
  }
}

const Styled = styled(Checkbox)`
  &&& {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: inline-block;
    line-height: 24px;
    min-height: 24px;
    outline: 0;
    position: relative;
    vertical-align: baseline;
    width: 100%;

    input.hidden {
      z-index: -1;
    }

    input {
      cursor: pointer;
      height: 17px;
      left: 0;
      opacity: 0 !important;
      outline: 0;
      position: absolute;
      top: 0;
      width: 17px;
      z-index: 3;
    }

    > .box:before {
      border-width: 1px;
      border-style: solid;
      border-color: ${props => props.theme.color.monochrome.medium};
      border-radius: 2px;
      content: '';
      cursor: pointer;
      height: 17px;
      left: 0;
      line-height: 24px;
      position: absolute;
      top: 4px;
      transition: border 0.3s ease, opacity 0.3s ease, box-shadow 0.3s ease,
        -webkit-transform 0.3s ease;
      transition: border 0.3s ease, opacity 0.3s ease, transform 0.3s ease,
        box-shadow 0.3s ease, -webkit-transform 0.3s ease;
      transition: border 0.3s ease, opacity 0.3s ease, transform 0.3s ease,
        box-shadow 0.3s ease;
      width: 17px;
    }

    .box:hover {
      &:before {
        border-color: ${props =>
          props.inputColor || props.theme.color.primary.brand};
      }
    }

    input:hover ~ .box:before {
      border-color: ${props =>
        props.inputColor || props.theme.color.primary.brand};
    }

    input:checked ~ .box:after {
      color: ${props => props.theme.color.monochrome.white};
      content: '\\e800';
      opacity: 1;
    }

    &.indeterminate .box:after {
      color: ${props => props.theme.color.monochrome.white};
      content: '\\e801';
      opacity: 1;
      top: 2px;
    }

    input:checked ~ .box:before,
    &.indeterminate .box:before {
      background: ${props =>
        (props.checked || props.indeterminate) &&
        (props.inputColor || props.theme.color.primary.brand)};
      border-color: ${props =>
        props.inputColor || props.theme.color.primary.brand};
    }

    > .box:after {
      color: transparent;
      font-family: Checkbox;
      font-size: 11px;
      height: 17px;
      left: 0.04em;
      line-height: 24px;
      opacity: 0;
      position: absolute;
      text-align: center;
      top: 1px;
      transition: border 0.3s ease, opacity 0.3s ease, box-shadow 0.3s ease,
        -webkit-transform 0.3s ease;
      transition: border 0.3s ease, opacity 0.3s ease, transform 0.3s ease,
        box-shadow 0.3s ease, -webkit-transform 0.3s ease;
      transition: border 0.3s ease, opacity 0.3s ease, transform 0.3s ease,
        box-shadow 0.3s ease;
      width: 17px;
    }

    > label {
      cursor: pointer;
      display: block;
      left: 28px;
      outline: 0;
      position: absolute;
      user-select: none;
    }
  }
`

export default Styled
