// Props Proxy HOC that tracks the open state of component. Used so other HOCs
// can know the state of the component without tracking it themselves.
import React, { useCallback, useState } from 'react'
import { getDisplayName } from 'util/hoc'
import { useLocalStorageState } from 'util/hooks'

export default function withOpenState(
  WrappedComponent,
  {
    defaultInitiallyOpen = false,
    persist = false,
    persistenceKey,
    persistenceKeyProp = 'persistenceKey',
  } = {}
) {
  const useStateMaybePersisted = persist
    ? useLocalStorageState
    : (unusedKey, defaultOpen) => useState(defaultOpen)
  function WithOpenState({
    initiallyOpen = defaultInitiallyOpen,
    onClose,
    onOpen,
    open: openProp,
    ...props
  }) {
    const key = props[persistenceKeyProp] || persistenceKey
    if (!key && persist) {
      throw new Error(
        'If withOpenState is persisted a key must be provided (through `persistenceKey` by default)'
      )
    }
    const isOpenPropProvided = [true, false].includes(openProp)
    const defaultOpen = isOpenPropProvided ? openProp : initiallyOpen
    const [openState, setOpenState] = useStateMaybePersisted(
      `open-${key}`,
      defaultOpen
    )

    const open = isOpenPropProvided ? openProp : openState
    const onCloseCallback = useCallback(
      () => {
        setOpenState(false)
        if (onClose) onClose()
      },
      [onClose, setOpenState]
    )
    const onOpenCallback = useCallback(
      () => {
        setOpenState(true)
        if (onOpen) onOpen()
      },
      [onOpen, setOpenState]
    )
    const onToggleOpen = useCallback(
      () => {
        setOpenState(!openState)
        if (openState) {
          if (onOpen) onOpen()
        } else if (onClose) onClose()
      },
      [onClose, onOpen, openState, setOpenState]
    )

    return (
      <WrappedComponent
        {...props}
        onClose={onCloseCallback}
        onOpen={onOpenCallback}
        onToggleOpen={onToggleOpen}
        open={open}
      />
    )
  }

  WithOpenState.displayName = `WithOpenState(${getDisplayName(
    WrappedComponent
  )})`

  return WithOpenState
}
