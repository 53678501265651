import { createSelector } from 'reselect'

import { selectHost, selectIsInChat } from 'selectors/location'
import selectCurrentConversationId from 'ducks/searches/selectors/selectCurrentConversationId'

import { selectCurrentTicketUndecorated } from './selectCurrentTicketUndecorated'
import { selectCurrentTicketUrl } from './selectCurrentTicketUrl'

export const selectCurrentTicketGqlId = createSelector(
  selectCurrentTicketUndecorated,
  ticket => (ticket ? ticket.gqlId : null)
)

export const selectCurrentRoomUrl = createSelector(
  selectCurrentConversationId,
  selectHost,
  (conversationId, host) =>
    `https://${host}/chats?conversationId=${conversationId}`
)

export const selectCurrentConversationUrl = createSelector(
  selectIsInChat,
  selectCurrentTicketUrl,
  selectCurrentRoomUrl,
  (isInChat, currentTicketUrl, currentRoomUrl) =>
    isInChat ? currentRoomUrl : currentTicketUrl
)
