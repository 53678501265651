import styled from '@emotion/styled'
import { Input } from 'util/ui'

export default styled(Input)`
  &&& > input {
    border: unset;
    border-radius: unset;
  }

  &&& > input:focus ~ i.icon,
  &&&.focus > i.icon {
    color: ${props => props.theme.color.primary.brand};
    opacity: 1;
  }
`
