import { createSelector } from 'reselect'

import { fetchingStatusesSelector, isBoostrappedSelector } from 'selectors/app'
import { selectCurrentMailboxName } from 'selectors/mailboxes/selectCurrentMailboxName'
import { selectCurrentMailboxId } from 'selectors/mailboxes/selectCurrentMailboxId'
import { selectIsTicketSelectionMode } from 'selectors/ticket_list/base'

import { addPath } from 'util/labels'
import { sortByKey, uniqByProp } from 'util/arrays'
import {
  MAXIMUM_DISPLAYED_LABELS,
  selectLabels,
  selectLabelsByMailboxId,
} from './base'

const selectLabelIdsForCurrentMailbox = createSelector(
  selectCurrentMailboxId,
  selectLabelsByMailboxId,
  (currentMailboxId, byMailboxId) => byMailboxId[currentMailboxId]
)

export const shouldFetchTopLabelsForSelection = createSelector(
  isBoostrappedSelector,
  selectIsTicketSelectionMode,
  fetchingStatusesSelector,
  selectLabelIdsForCurrentMailbox,
  (bootstrapped, isSelecting, statuses, labelIds) => {
    return (
      bootstrapped &&
      isSelecting &&
      !statuses.fetchTopLabelsForSelection &&
      !labelIds
    )
  }
)

export const selectTopLabels = createSelector(
  selectLabels,
  selectLabelsByMailboxId,
  selectCurrentMailboxId,
  (allLabels, byMailboxId, mailboxId) => {
    const mailboxTopLabels = byMailboxId[mailboxId]
    const mailboxHasTopLabels = mailboxTopLabels && mailboxTopLabels.length > 0
    if (mailboxHasTopLabels) {
      // merge the top 3 labels with the sorted allLabels and remove duplications
      const labels = uniqByProp(
        mailboxTopLabels.slice(0, 3).concat(sortByKey(allLabels, 'name'))
      ).slice(0, MAXIMUM_DISPLAYED_LABELS)

      return labels
    }
    return allLabels.slice(0, MAXIMUM_DISPLAYED_LABELS)
  }
)

export const selectTopLabelsAreLoaded = createSelector(
  selectLabelsByMailboxId,
  selectCurrentMailboxId,
  (byMailboxId, mailboxId) => {
    return !!byMailboxId[mailboxId]
  }
)

export const selectTopLabelNames = createSelector(selectTopLabels, labels =>
  labels.map(label => label.name)
)

export const selectTopLabelsWithPath = createSelector(
  selectTopLabels,
  selectCurrentMailboxName,
  (labels, mailboxName) => labels.map(addPath(mailboxName))
)
