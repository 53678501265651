import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Link from 'redux-first-router-link'
import { themeValByPropKeyOr } from '@groovehq/internal-design-system/lib/util/theme'
import cn from 'classnames'
import LegacyLink from 'components/LegacyLink'
import themeVars from 'ui_theme/site/globals/site.variables'

import Tooltip from './Tooltip'
import Icon from './Icon'
import SVGIcon from './SVGIcon'

const mapIconSize = size => {
  if (size === 'tiny') return 'xsmall'
  return size
}

const StyledIcon = styled(Icon)`
  transition: color 175ms ease-in-out;
`

const StyledSVGIcon = styled(SVGIcon)`
  transition: color 175ms ease-in-out;
`

const StyledLabel = styled('div')`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 64px;
  max-width: 200px;
  color: ${props => props.theme.color.iconButton.text};
  font-family: ${props => props.theme.font.primary};
  cursor: pointer;
  font-weight: ${props => props.theme.fontWeight.medium};
  display: inline-block;
  font-size: ${props => props.theme.fontSize.base};
  background: ${props => props.theme.color.monochrome.light};
  border: solid 1px ${props => props.theme.color.monochrome.medium};
  border-radius: 6px;
  padding: 8px;
  line-height: 1;
  text-align: center;

  &&:hover {
    color: ${themeVars.newGray};
  }
`

const IconButton = ({
  active,
  as: ElementType,
  bgColor, // dont passthru
  children,
  className,
  color,
  name,
  bordered, // dont passthru
  colorHover, // dont passthru
  size,
  iconSize,
  svg,
  label,
  labelClassName,
  onlyIcon,
  to, // <Link>
  isLegacy, // <LegacyLink>
  iconRef,
  disabled,
  onClick,
  ...rest
}) => {
  const handleClick = !disabled ? onClick : null

  const icon = svg ? (
    <StyledSVGIcon
      color={active ? color : undefined}
      name={name}
      size={iconSize}
      ref={iconRef}
    />
  ) : (
    <StyledIcon
      color={active ? color : 'color.iconButton.text'}
      name={name}
      size={iconSize || mapIconSize(size)}
      ref={iconRef}
    />
  )

  if (to && !isLegacy) {
    return (
      <Link
        className={cn('IconLink', className, { disabled })}
        to={to}
        onClick={handleClick}
        {...rest}
      >
        {label && <StyledLabel className={labelClassName}>{label}</StyledLabel>}
        {children && children}
        {!children && !label && icon}
      </Link>
    )
  }

  if (to && isLegacy) {
    return (
      <LegacyLink className={cn('IconLink', className, { disabled })} path={to}>
        {label && <StyledLabel className={labelClassName}>{label}</StyledLabel>}
        {children && children}
        {!children && !label && icon}
      </LegacyLink>
    )
  }

  return (
    <ElementType
      className={cn('IconButton', className, { disabled })}
      onClick={handleClick}
      {...rest}
    >
      {label && <StyledLabel className={labelClassName}>{label}</StyledLabel>}
      {children && children}
      {!children && !label && icon}
    </ElementType>
  )
}

IconButton.propTypes = {
  // Shows a highlighted state  of the button (i.e. outlined)
  active: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types

  /* Type of component to render. E.g. 'span', or Link. False wil render div */
  as: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.symbol,
    PropTypes.bool,
    PropTypes.object,
  ]),

  /* Set a background color (omitting defaults to `inherit`) */
  bgColor: PropTypes.string,

  /* Whether to render button with border */
  bordered: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types

  color: PropTypes.string.isRequired,

  /* name of the corresponding .svg file in assets/icons */
  name: PropTypes.string.isRequired,

  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large', 'fill']),

  label: PropTypes.string,

  disabled: PropTypes.bool,
}

IconButton.defaultProps = {
  active: false,
  as: 'div',
  bgColor: undefined,
  bordered: false,
  color: 'color.monochrome.mediumDark',
  size: 'medium',
  label: undefined,
  disabled: false,
}

const sizes = ({ size, theme: { fontSize, spacing } }) => {
  const tiny = {
    fontSize: fontSize.base,
    // apparently 24px is a non-standard input height (?!)...
    height: '24px',
    width: '24px',
  }

  const small = {
    fontSize: fontSize.x2large,
    height: spacing['14'],
    width: spacing['14'],
  }

  const medium = {
    fontSize: fontSize.x3large,
    height: spacing['16'],
    width: spacing['16'],
  }

  const large = {
    fontSize: fontSize.x5large,
    height: spacing['18'],
    width: spacing['18'],
  }

  const fill = {
    fontSize: fontSize.x3large,
    height: '100%',
    width: '100%',
  }

  switch (size) {
    case 'tiny':
      return tiny
    case 'small':
      return small
    case 'medium':
      return medium
    case 'large':
      return large
    default:
      return fill
  }
}

const StyledIconButton = styled(IconButton)`
  cursor: pointer;
  align-items: center;
  border: none;
  display: inline-flex;
  justify-content: center;
  outline: none;
  position: relative;
  z-index: 1;
  -webkit-font-smoothing: antialiased;
  background: none;
  border-radius: 4px;
  white-space: pre-wrap;
  padding: 11px;
  color: ${props => props.theme.color.iconButton.text};
  overflow: hidden;

  &&:not(.disabled)::before {
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    z-index: -1;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: ${props => props.theme.color.iconButton.backgroundHover};
    border-radius: 4px;
    transform: scale(0);
    transition-property: transform, opacity;
  }

  &&:not(.disabled) svg.v2Icon path {
    fill: ${props => props.theme.color.iconButton.text};
  }

  &&:not(.disabled):hover svg.v2Icon path {
    fill: ${props => props.theme.color.iconButton.textHover};
  }

  &&.IconLink {
    color: ${props => props.theme.color.iconButton.text}; /*Overwrite 'SUI a'*/
  }

  &&.disabled {
    cursor: default;
    opacity: ${props => props.theme.color.iconButton.opacityDisabled};
  }

  && svg {
    ${props => (props.iconSize ? '' : 'width: 100%')};
    ${props => (props.iconSize ? '' : 'height: 100%')};
  }

  &&:not(.disabled):not(.active):hover {
    color: ${themeValByPropKeyOr('colorHover', 'color.iconButton.textHover')};
  }

  &&:not(.disabled):not(.active):hover::before {
    opacity: 1;
    transform: scale(1);

    color: ${props =>
      props.theme.color.iconButton.textHover} !important; /* fix SUI a:hover */
    .Icon {
      color: inherit;
    }
  }

  ${sizes};
  ${props => props.label && 'width: auto'};
  ${props => props.label && 'height: auto'};
  ${props => props.label && 'padding: 0'};
`

class WithTooltip extends React.PureComponent {
  render() {
    const {
      tooltip,
      tooltipPosition,
      tooltipDisabled,
      tooltipPortal,
      ...rest
    } = this.props
    return (
      <Tooltip
        position={tooltipPosition}
        tooltip={tooltip}
        disabled={tooltipDisabled}
        portal={tooltipPortal}
      >
        <StyledIconButton {...rest} />
      </Tooltip>
    )
  }
}

WithTooltip.propTypes = {
  tooltip: PropTypes.node,
  tooltipPosition: PropTypes.string,
}

WithTooltip.defaultProps = {
  tooltipPosition: 'bottom',
  tooltip: '',
}

export default WithTooltip
