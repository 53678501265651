import { css } from '@emotion/react'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

// Make sure the buttons styles be the same as the ones in the Drawer
const footer = theme => css`
  &&& a,
  &&& button {
    border-radius: ${rem(6)} !important;
    font-family: ${theme.font.primary};
    height: ${rem(40)};

    &.primary,
    &.positive {
      ${button.styles.info(theme)};
      font-weight: ${theme.fontWeight.medium};
    }

    &,
    &.primary,
    &.positive {
      ${button.sizes.big(theme)};
    }
  }
`

export const styles = {
  footer,
}
