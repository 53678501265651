import { createSelector } from 'reselect'
import {
  selectIsInboxBootstrapped,
  selectCurrentOpenNavSections,
} from 'selectors/app/base'
import { selectIsInInbox } from 'selectors/location'
import { selectPrefersAgentsCanMoveToInaccessibleMailboxes } from 'ducks/currentUser/selectors/preferences/selectPrefersAgentsCanMoveToInaccessibleMailboxes'

import { selectCurrentTicketMailboxId } from 'selectors/tickets'
import { selectRawById } from 'selectors/tickets/byId/selectRawById'
import {
  selectSelectedTicketIds,
  selectIsTicketSelectionMode,
} from 'selectors/ticket_list/base'

import {
  compact,
  emptyArr,
  isEmpty,
  sortByKeyInsensitive,
  uniq,
} from 'util/arrays'
import { createBasicSelector } from 'util/redux'
import {
  selectCurrentOpenDrawerResourceId,
  selectIsChannelOnboardingDrawerOpen,
} from 'ducks/drawers/selectors'
import { selectMailboxIds } from './selectMailboxIds'
import { selectKnownMailboxes } from './selectKnownMailboxes'
import { selectMailboxesById } from './selectMailboxesById'
import { selectInaccessibleMailboxesRaw } from './selectInaccessibleMailboxesRaw'
import { selectCurrentMailbox } from './selectCurrentMailbox'
import { selectCurrentMailboxId } from './selectCurrentMailboxId'
import { selectDemoMailboxId } from './selectDemoMailboxId'
import { selectInaccessibleMailboxes } from './selectInaccessibleMailboxes'

export const selectMailboxIdFromTicketOrCurrent = createBasicSelector(
  selectCurrentMailboxId,
  selectCurrentTicketMailboxId,
  (currentMailboxId, currentTicketMailboxId) => {
    return currentMailboxId || currentTicketMailboxId || null
  }
)

export const selectIsMailboxInaccessible = (state, mailboxId) => {
  if (!mailboxId) return false
  const inaccessibleMailboxes = selectInaccessibleMailboxesRaw(state)
  const inaccessibleMailboxIds = inaccessibleMailboxes.map(m => m.id)
  return inaccessibleMailboxIds.indexOf(mailboxId) !== -1
}

export const selectInaccessibleMailbox = (state, mailboxId) => {
  if (!mailboxId) return null
  const inaccessibleMailboxes = selectInaccessibleMailboxesRaw(state)
  return inaccessibleMailboxes.find(m => m.id === mailboxId)
}

const selectSelectedTicketsMailboxIds = createSelector(
  selectSelectedTicketIds,
  selectRawById,
  (ids, byId) => {
    if (!ids || isEmpty(ids)) return emptyArr
    return uniq(
      compact(
        ids.filter(id => {
          return byId[id] ? byId[id].mailboxId : undefined
        })
      )
    )
  }
)

export const selectSelectedTicketsMailboxes = createSelector(
  selectSelectedTicketsMailboxIds,
  selectMailboxesById,
  (mailboxIds, mailboxesById) => {
    if (isEmpty(mailboxIds)) return emptyArr
    return compact(mailboxIds.map(id => mailboxesById[id]))
  }
)

export const selectPossibleMailboxesForChange = createSelector(
  selectCurrentTicketMailboxId,
  selectKnownMailboxes,
  selectSelectedTicketsMailboxes,
  selectInaccessibleMailboxes,
  selectPrefersAgentsCanMoveToInaccessibleMailboxes,
  selectIsTicketSelectionMode,
  (
    currentMailboxId,
    knownMailboxes,
    selectedTickets,
    inaccessibleMailboxes,
    canMoveToInaccessible,
    isBulkMode
  ) => {
    let possibleMailboxes
    if (isBulkMode) {
      possibleMailboxes = knownMailboxes
    } else {
      let mailboxId = currentMailboxId
      if (!mailboxId && selectedTickets && selectedTickets.length === 1) {
        mailboxId = selectedTickets[0].id
      }
      possibleMailboxes = knownMailboxes.filter(
        mailbox => mailbox.id !== mailboxId
      ) // exclude draft mailbox
    }
    const joined =
      canMoveToInaccessible && inaccessibleMailboxes
        ? possibleMailboxes.concat(inaccessibleMailboxes)
        : possibleMailboxes
    const mailboxes = sortByKeyInsensitive(joined, 'label')
    return mailboxes.length > 0 ? mailboxes : emptyArr
  }
)

export const selectOtherMailbox = createSelector(
  selectPossibleMailboxesForChange,
  mailboxes => (mailboxes.length === 1 ? mailboxes[0] : undefined)
)

const navSectionSearches = {
  open: { state: 'opened' },
  closed: { state: 'closed' },
  snoozed: { state: 'snoozed' },
}

// Helper function that we can call from outside the Left Nav context to build
// the navigation search objects.
export function buildNavSearch(mailboxId, navSection) {
  return {
    mailbox: mailboxId,
    ...navSectionSearches[navSection],
  }
}

export const selectCurrentNavigationSearches = createSelector(
  selectCurrentMailboxId,
  selectCurrentOpenNavSections,
  (mailboxId, navSections) => {
    return navSections
      .map(navSection => buildNavSearch(mailboxId, navSection))
      .concat({ mailbox: mailboxId })
  }
)

export const selectShowDemoMailboxBar = createSelector(
  selectIsInboxBootstrapped,
  selectKnownMailboxes,
  selectIsInInbox,
  // eslint-disable-next-line no-unused-vars
  (bootstrapped, mailboxes, isInInbox) => {
    // TODO: temp: Nick wants this hidden for now
    return false
    /* if (!bootstrapped || !mailboxes || !isInInbox) {
      return false
    }

    // If the agent has no mailbox at all we don't want to show the demo bar
    if (isEmpty(mailboxes)) return false

    const hasRealMailbox = mailboxes.some(mailbox => {
      return mailbox.state !== 'demo'
    })
    return !hasRealMailbox */
  }
)

// Is the current mailbox in creation process
export const selectIsSettingUpTheCurrentMailBox = createSelector(
  selectCurrentMailboxId,
  selectIsChannelOnboardingDrawerOpen,
  selectCurrentOpenDrawerResourceId,
  (mailboxId, isChannelOnboardingDrawerOpen, currentOpenDrawerResourceId) => {
    return (
      isChannelOnboardingDrawerOpen && currentOpenDrawerResourceId === mailboxId
    )
  }
)

export const selectCanConvertDemoMailboxToNewMailbox = createSelector(
  selectMailboxIds,
  selectDemoMailboxId,
  (allMailboxIds, demoMailboxId) => {
    const allMailboxesCount = (allMailboxIds || []).length

    return !!demoMailboxId && allMailboxesCount === 1
  }
)

export const selectCurrentMailboxErrorMessage = createBasicSelector(
  selectCurrentMailbox,
  mailbox => {
    if (!mailbox) return null
    if (!mailbox.email_integration_error) return null

    return mailbox.email_integration_error.error_message
  }
)
