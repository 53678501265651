// A https://github.com/jxnblk/styled-system compatible theme
//
// NOTE: Experimental! Please default to using SUI components + themeing before
// building your own here.
//

// Spacing Scale (used for margin and padding)
//
// E.g. if you want to use a 16px margin-left you can pass this prop:
//
//   <div className="grui ml-10" />
//
// i.e. the index in the scale
//
// Or inside a `styled` definition:
//
//   padding-left: ${th('space.3')}px,
//

import themeVars from 'ui_theme/site/globals/site.variables'

export * from './utils'

// TODO: On migration, include 12 20 etc and update classnames
export const space = [0, 4, 8, 16, 24, 32, 64, 128, 256, 512]

export const inputHeights = [32, 40, 48]

export const fontSizes = [10, 12, 13, 16, 18, 24, 32, 42]
export const fontSizeNames = {
  tiny: fontSizes[0],
  small: fontSizes[1],
  medium: fontSizes[2],
  large: fontSizes[3],
  larger: fontSizes[4],
  big: fontSizes[5],
  huge: fontSizes[6],
  massive: fontSizes[7],
}

// styled-system's `fontWeight` function can hook into the `fontWeights` object
export const regular = 400
export const medium = 500
export const bold = 600
export const fontWeights = {
  regular,
  normal: regular,
  medium,
  bold,
}

export const fonts = {
  sans: themeVars.pageFont,
  mono: '"SF Mono", "Roboto Mono", Menlo, monospace',
}

// color palette
const blue = '#02a2af' // primary 'teal blue'
const blueHover = 'hsl(200, 70%, 60%)' // ~ #44ADE2

export const colors = {
  ...themeVars,
  black: 'hsl(0, 0, 10%)', // ≈ #1b1b1b
  white: '#fff',

  text: '#353f44',

  lightGray: '#bfc7cf',
  gray: 'hsl(210, 15%, 55%)', // ≈ #7b8c9d
  borderGray: '#e4e8ed',
  blueishGray: '#dfe5ee',
  brightSuperDark: '#f7f9fa',
  slate: '#4f5d6a',

  blue,
  sky: blue,
  blueHover,
  tealBlue: blue,
  lightGroovy: '#02aabb', // 5% brighter
  brightGroovy: '#edfafa',
  labelDefault: '#58a2fb',
  newGray: '#45525e',

  oldBlue: '#4cc1fc', // our older 'bright' blue

  darkBlue: `color-mod(${blue} s(-45%) l(-20%))`,
  darkerBlue: `color-mod(${blue} h(+15) s(-60%) l(-40%))`,
  darkBlue3: 'hsl(200, 12%, 23%)',
  darkBlue4: 'hsl(200, 12%, 30%)',
  darkBlue2: 'hsl(200, 14%, 55%)',

  lightBlue: 'hsl(192, 20%, 93%)',
  lightBlue2: 'hsl(200, 28%, 90%)',
  lightBlue4: 'hsl(200, 16%, 91%)',
  lightBlue3: 'hsl(200, 15%, 76%)',
  lightBlue5: 'hsl(200, 27%, 98%)',
  lightBlue6: 'hsl(200, 32%, 96%)',
  lightBlue7: 'hsl(200, 60%, 96%)',
  lightBlue8: 'hsl(200, 55%, 98%)',
  lightBlue9: 'hsl(200, 7%, 85%)',
  lightBlue10: 'hsl(200, 4%, 90%)',
  lightBlue11: 'hsl(200, 2%, 93%)',
  lightBlue12: 'hsl(209, 15%, 56%)',

  lightestBlue: 'hsl(216, 33%, 97%)',
  lightestBlue2: 'hsl(216, 31%, 90%)',
  lightestBlue3: 'hsl(220, 43%, 99%)',

  // Cyan deprecated. Backwards compat with old postcss variable naming.
  cyan: blue,
  cyanHover: 'hsl(200, 93%, 70%)',
  cyanActive: blueHover,

  red: 'var(--color-primary-negativeAlt)',
  darkRed: '#c64b31',

  pink: '#eb6195', // maps to "juicy" (??) in our SUI theme
  brightPink: '#f56f9f', // hsl(339, 87%, 70%)
  lightPink: '#fff1f6',
  purple: '#8573bf',
  lightPurple: '#8b7ac2', // 2% lighter

  yellow: '#f7c042',
  darkerYellow: '#f8bf32',
  note: '#fffcf3',
  forward: 'hsl(162, 81%, 94%)', // ~ #c7fae980

  inherit: 'inherit',
}

export const radius = '4px'

const theme = {
  colors,
  fontSizeNames,
  fontSizes,
  fontWeights,
  fonts,
  inputHeights,
  radius,
  space,
}

export default theme
