import React, { Fragment, createRef, PureComponent } from 'react'
import ReactDOM from 'react-dom'
import cn from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import {
  parseUserTime,
  to12HourTime,
  minuteIntervalsIn24Hours,
  mergeDateAndTime,
  strftime,
} from 'util/date'
import { isBlank } from 'util/strings'
import { Menu } from 'util/ui'
import { browserLanguage, localeLongDateFormat } from 'util/locale'
import { propFunc, debounce } from 'util/functions'
import DatePicker from 'shared/components/ui/DatePicker'
import { Divider, Flex, InputBorderless } from 'shared/ui'
import styles from './styles.less'

const DATE_PICKER = 'DATE'
const TIME_PICKER = 'TIME'
const DATE_FORMAT = localeLongDateFormat('L')
const TIME_FORMAT = '%H:%M'

export default class DateTimeInputSinglePane extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    dateInputError: PropTypes.bool,
    timeInputError: PropTypes.bool,
    onChange: PropTypes.func,
    onSetInitialValue: PropTypes.func,
    value: PropTypes.instanceOf(Date),
    fromDate: PropTypes.instanceOf(Date),
    toDate: PropTypes.instanceOf(Date),
    warningFromDate: PropTypes.instanceOf(Date),
    initialTime: PropTypes.string,
    canEditTimeInput: PropTypes.bool,
    // Should let value control the inputs
    isControlled: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
    justDate: false,
    justTime: false,
    dateInputError: false,
    timeInputError: false,
    onChange: null,
    onSetInitialValue: undefined,
    value: null,
    fromDate: null,
    toDate: null,
    warningFromDate: null,
    initialTime: null,
    canEditTimeInput: true,
    isControlled: false,
  }

  constructor(props) {
    super(props)
    const {
      justTime,
      value,
      initialTime, // overwritten by 'value' if defined
    } = props

    this.state = {
      focusedPicker: justTime ? TIME_PICKER : DATE_PICKER,
      selectedDate: {
        value,
        content: this.dateObjToDateString(value),
        resetOnNextBlur: false,
      },
      selectedTime: {
        value: value ? this.dateObjToTimeString(value) : initialTime,
        content: to12HourTime(
          value ? this.dateObjToTimeString(value) : initialTime
        ),
        resetOnNextBlur: false,
      },
    }

    this.timePickerRef = createRef()
  }

  componentDidMount = () => {
    const { onSetInitialValue } = this.props
    const { selectedDate, selectedTime } = this.state
    if (onSetInitialValue) {
      onSetInitialValue({
        date: selectedDate.value,
        time: selectedTime.value,
      })
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { focusedPicker: previousFocusedPicker } = prevState
    const { focusedPicker: currentFocusedPicker } = this.state
    const { value } = prevProps
    const { value: newValue, isControlled } = this.props

    if (previousFocusedPicker !== currentFocusedPicker) {
      this.scrollToSelectedTimeMenuItem()
    }

    if (isControlled && value?.getTime() !== newValue?.getTime()) {
      this.setState({
        selectedDate: {
          value: newValue,
          content: this.dateObjToDateString(newValue),
        },
        selectedTime: {
          value: newValue ? this.dateObjToTimeString(newValue) : null,
          content: to12HourTime(
            newValue ? this.dateObjToTimeString(newValue) : null
          ),
        },
      })
    }
  }

  // eslint-disable-next-line react/sort-comp
  scrollToSelectedTimeMenuItem = debounce(() => {
    const {
      state: { focusedPicker },
      timePickerRef: { current } = {},
    } = this
    if (current && focusedPicker === TIME_PICKER) {
      // eslint-disable-next-line react/no-find-dom-node
      const active = ReactDOM.findDOMNode(current).querySelector('.active')
      if (active) {
        active.scrollIntoViewIfNeeded()
      }
    }
  }, 50)

  onDatePickerChange = (value, modifiers = {}) => {
    if (modifiers.disabled) {
      return
    }

    this.setState({
      selectedDate: {
        value,
        content: new Intl.DateTimeFormat(browserLanguage()).format(value),
        resetOnNextBlur: false,
      },
    })
    this.triggerOnDateChange(value)
  }

  onTimeInputFocus = () => {
    this.setState({
      focusedPicker: TIME_PICKER,
    })
  }

  onDateInputFocus = () => {
    this.setState({
      focusedPicker: DATE_PICKER,
    })
  }

  onTimeInputBlur = e => {
    const {
      selectedTime: { value, resetOnNextBlur },
    } = this.state

    const { onInputBlur } = this.props

    const parsedValue = parseUserTime(value)

    if (resetOnNextBlur) {
      this.setState({
        selectedTime: {
          value: parsedValue,
          content: to12HourTime(parsedValue),
          resetOnNextBlur: false,
        },
      })
    }

    this.triggerOnTimeChange(parsedValue)
    if (onInputBlur) {
      onInputBlur(e)
    }
  }

  onTimeInputChange = (e, { value }) => {
    const valueClean = value.toUpperCase().trim()
    const parsedValue = valueClean === '' ? null : parseUserTime(value)
    this.setState({
      selectedTime: {
        value: parsedValue,
        content: valueClean,
        resetOnNextBlur: true,
      },
    })

    this.triggerOnTimeChange(parsedValue)
  }

  onTimePickerClick = (e, { value, content }) => {
    this.setState({
      selectedTime: {
        value,
        content,
        resetOnNextBlur: false,
      },
    })

    this.triggerOnTimeChange(value)
  }

  onDateInputBlur = e => {
    const {
      selectedDate: { value, resetOnNextBlur },
    } = this.state

    const { onInputBlur } = this.props

    if (resetOnNextBlur) {
      // reset input back to last valid selected date
      this.setState({
        selectedDate: {
          value,
          content: '',
          resetOnNextBlur: false,
        },
      })
    }

    this.triggerOnDateChange(value)
    if (onInputBlur) {
      onInputBlur(e)
    }
  }

  onDateInputChange = (e, { value }) => {
    const valueClean = value.trim()
    const isValid = this.isValidDateString(valueClean)
    const date = moment(valueClean, DATE_FORMAT, true)
    const jsDate = date.toDate()
    const resetOnNextBlur = !isValid && !isBlank(valueClean)
    const sel = isValid ? jsDate : null

    this.setState({
      selectedDate: {
        value: sel,
        content: valueClean,
        resetOnNextBlur,
      },
    })

    this.triggerOnDateChange(resetOnNextBlur ? null : sel)
  }

  triggerOnTimeChange = time => {
    const { onChange } = this.props
    const { selectedDate } = this.state

    if (onChange) {
      onChange({
        date: selectedDate.value,
        time,
      })
    }
  }

  triggerOnDateChange = date => {
    const { onChange } = this.props
    const { selectedTime } = this.state

    if (onChange) {
      onChange({
        date,
        time: selectedTime.value,
      })
    }
  }

  isDisabledTimeOption = time => {
    const { fromDate, toDate } = this.props
    const { selectedDate: { value: date } = {} } = this.state

    if ((!fromDate && !toDate) || !time || !date) return false

    const newDate = mergeDateAndTime(date, time)

    if (!newDate) return false

    if (fromDate) {
      const shouldDisable = newDate <= fromDate
      if (shouldDisable) return shouldDisable
    }

    if (toDate) {
      return newDate >= toDate
    }

    return false
  }

  timeInputOptions = onClick => {
    const { selectedTime } = this.state
    const selectedTimeIn24HourFormat = parseUserTime(selectedTime.value)

    return minuteIntervalsIn24Hours(15)
      .filter(x => !this.isDisabledTimeOption(x))
      .map(time => {
        return {
          key: time,
          content: to12HourTime(time),
          value: time,
          active:
            selectedTimeIn24HourFormat && selectedTimeIn24HourFormat === time,
          onClick,
        }
      })
  }

  isValidDateString = date => {
    if (!date) {
      return false
    }

    return moment(date, DATE_FORMAT, true).isValid()
  }

  dateObjToTimeString = date => {
    if (!date) {
      return null
    }

    return strftime(TIME_FORMAT, date).trim()
  }

  dateObjToDateString = date => {
    if (!date) {
      return null
    }
    return date instanceof moment
      ? date.format(DATE_FORMAT)
      : moment(date).format(DATE_FORMAT)
  }

  getDisabledDays = (fromDate, toDate) => {
    return [
      {
        before: fromDate,
        after: toDate,
      },
    ]
  }

  render() {
    const { focusedPicker, selectedDate, selectedTime } = this.state
    const {
      disabled,
      justDate,
      justTime,
      dateInputError,
      timeInputError,
      fromDate,
      toDate,
      warningFromDate,
      canEditTimeInput,
      timeInputId,
      dateInputId,
    } = this.props

    return (
      <Fragment>
        <Flex className={cn(styles.dateTimeInputSinglePane)}>
          {!disabled &&
            !justTime &&
            focusedPicker === DATE_PICKER && (
              <DatePicker
                className={styles.datePicker}
                selectedDate={selectedDate.value}
                initialMonth={selectedDate.value || new Date()}
                key={selectedDate.value && selectedDate.value.toISOString()}
                onDayClick={this.onDatePickerChange}
                fromMonth={fromDate}
                toMonth={toDate}
                warningFromDate={warningFromDate}
                disabledDays={propFunc(
                  this.getDisabledDays,
                  fromDate,
                  toDate
                )()}
              />
            )}
          {!disabled &&
            !justDate &&
            focusedPicker === TIME_PICKER && (
              <Menu
                className={styles.timePicker}
                vertical
                fluid
                borderless
                items={propFunc(
                  this.timeInputOptions,
                  this.onTimePickerClick
                )()}
                ref={this.timePickerRef}
              />
            )}
        </Flex>
        <Divider className={styles.InputDivider} />
        <Flex className="grui justify-around flex-col">
          {!justTime && (
            <InputBorderless
              className={cn({
                focus: focusedPicker === DATE_PICKER,
              })}
              disabled={disabled}
              icon="calendar alternate outline"
              onBlur={this.onDateInputBlur}
              onChange={this.onDateInputChange}
              onFocus={this.onDateInputFocus}
              placeholder={DATE_FORMAT}
              value={selectedDate.content || ''}
              error={dateInputError}
              id={dateInputId}
            />
          )}
          {!justTime &&
            !justDate && <Divider className={styles.InputDivider} />}
          {!justDate && (
            <InputBorderless
              className={cn(
                {
                  focus: focusedPicker === TIME_PICKER,
                },
                {
                  inputNotEditable: !canEditTimeInput,
                }
              )}
              disabled={disabled}
              icon="clock alternate outline"
              onBlur={this.onTimeInputBlur}
              onChange={canEditTimeInput ? this.onTimeInputChange : undefined}
              onFocus={this.onTimeInputFocus}
              placeholder="Select time"
              value={selectedTime.content || ''}
              error={timeInputError}
              id={timeInputId}
            />
          )}
        </Flex>
      </Fragment>
    )
  }
}
