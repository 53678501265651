// The <Flex /> component is an extension of the <div /> component with
// `display: flex` and props for using flexbox.
import styled from '@emotion/styled'

/**
 * @deprecated Rather use `<div className="grui flex"></div>` instead. This file will be nuked soon
 */
const Flex = styled.div`
  display: flex;
`

Flex.displayName = 'Flex'

export default Flex
