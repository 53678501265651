import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {
  themeColorPropOr,
  themeValByPropKey,
} from '@groovehq/internal-design-system/lib/util/theme'

const Icon = ({
  as: ElementType,
  className,
  disabled,
  hoverColor, // dont passthru
  name,
  withoutPointer: _,
  ...rest
}) => {
  return (
    <ElementType
      className={cn(className, 'Icon', `Icon-${name}`, { disabled })}
      {...rest}
    />
  )
}

Icon.propTypes = {
  /* Type of component to render. E.g. 'span', or Link. */
  as: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.symbol]),

  /* Whether interaction is disabled */
  disabled: PropTypes.bool,

  /* optional color name from the theme, or color code */
  color: PropTypes.string,

  /* name of the corresponding .svg file in assets/icons */
  name: PropTypes.string.isRequired,

  size: PropTypes.oneOfType([
    PropTypes.oneOf(['trump', 'xxsmall', 'xsmall', 'small', 'medium', 'large']),
    PropTypes.number,
  ]),

  withoutPointer: PropTypes.bool,
}

Icon.defaultProps = {
  as: 'div',
  color: undefined,
  hoverColor: undefined,
  disabled: false,
  size: 'medium',
  withoutPointer: false,
}

const sizes = ({ size, theme: { fontSize } }) => {
  if (Number.isInteger(size)) {
    return {
      fontSize: `${size}px`,
      height: `${size}px`,
      width: `${size}px`,
    }
  }

  switch (size) {
    case 'trump':
      return {
        fontSize: fontSize.small,
        height: fontSize.small,
        width: fontSize.small,
      }
    case 'xxsmall':
      return {
        fontSize: fontSize.base,
        height: fontSize.base,
        width: fontSize.base,
      }
    case 'xsmall':
      return {
        fontSize: fontSize.x2large,
        height: fontSize.x2large,
        width: fontSize.x2large,
      }
    case 'small':
      return {
        fontSize: fontSize.x3large,
        height: fontSize.x3large,
        width: fontSize.x3large,
      }
    case 'medium':
      return {
        fontSize: fontSize.x5large,
        height: fontSize.x5large,
        width: fontSize.x5large,
      }
    case 'large':
      return {
        fontSize: fontSize.x6large,
        height: fontSize.x6large,
        width: fontSize.x6large,
      }
    default:
      return {
        fontSize: fontSize.x5large,
        height: fontSize.x5large,
        width: fontSize.x5large,
      }
  }
}

export default styled(Icon)`
  color: ${themeColorPropOr('color.monochrome.mediumDark')};
  cursor: ${({ withoutPointer }) => (withoutPointer ? '' : 'pointer')};
  font-weight: ${props => props.theme.fontWeight.medium};

  &:hover {
    color: ${props =>
      props.hoverColor
        ? themeValByPropKey('hoverColor')(props)
        : themeColorPropOr('color.primary.brand')};
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.disabled:hover {
    color: ${themeColorPropOr('color.monochrome.mediumDark')};
  }

  ${sizes};
`
