/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { createSelector } from 'reselect'
import { selectCustomersById } from 'ducks/customers/selectors'

const selectByMessageId = state => state.ratings.byMessageId

export const selectRatingsByMessageId = createSelector(
  selectByMessageId,
  selectCustomersById,
  (byMessageId, customersById) => {
    const ratingsByMessageId = {}

    for (const messageId in byMessageId) {
      ratingsByMessageId[messageId] = Object.assign(
        {},
        byMessageId[messageId],
        {
          actor: customersById[byMessageId[messageId].actor.id],
        }
      )
    }

    return ratingsByMessageId
  }
)
