// Wrapper around SUI Dropdown that allows differentiation etween the 'active'
// option passed as a prop i.e. Default Active, versus active items that are set
// internally by the DD using `selectOnNavigation` as the user scrolls the list.
//
// See more discussion on this inside index.js
import React from 'react'
import { getDisplayName } from 'util/hoc'

function withDefaultActiveOption(WrappedDropdown) {
  class WithDefaultActiveOption extends React.Component {
    getOptions = () => {
      return this.withDefaultActive(this.props.options, this.getActiveOption())
    }

    // Assumes there's only one active option in options
    getActiveOption = () => {
      return this.props.options.find(opt => !!opt && opt.active)
    }

    withDefaultActive = (options = [], defaultActiveOption) => {
      return options.filter(e => e).map(opt => {
        return {
          ...opt,
          className: opt === defaultActiveOption ? 'defaultActive' : '',
        }
      })
    }

    render() {
      const { className, ...rest } = this.props

      return (
        <WrappedDropdown
          className={className}
          {...rest}
          options={this.getOptions()} // order important. override props.options
        />
      )
    }
  }

  WithDefaultActiveOption.displayName = `WithDefaultActiveOption(${getDisplayName(
    WrappedDropdown
  )})`

  return WithDefaultActiveOption
}

export default withDefaultActiveOption
