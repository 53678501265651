import { createSelector } from 'reselect'
import { isEmpty } from 'util/arrays'
import { selectPrefersAllMailboxesSectionVisible } from 'ducks/currentUser/selectors/preferences/selectPrefersAllMailboxesSectionVisible'
import { selectPrefersUnifiedInbox } from 'ducks/currentUser/selectors/preferences/selectPrefersUnifiedInbox'
import { selectCurrentTicketSearchMailboxes } from 'selectors/search/base'

export const selectIsMailboxIndicatorNeeded = createSelector(
  selectCurrentTicketSearchMailboxes,
  selectPrefersUnifiedInbox,
  selectPrefersAllMailboxesSectionVisible,
  (currentSearchMailbox, perefersUnified, prefersAllMailbox) => {
    if (perefersUnified) return true
    if (prefersAllMailbox) return isEmpty(currentSearchMailbox)
    if (prefersAllMailbox) return true
    return false
  }
)
