import { createSelector } from 'reselect'
import { selectIsInInbox } from 'selectors/location'
import { selectCurrentTicketIdFromPage } from 'selectors/tickets/current/selectCurrentTicketIdFromPage'
import { selectIsSettingUpTheCurrentMailBox } from 'selectors/mailboxes'
import { selectCurrentMailboxHasTickets } from 'selectors/mailboxes/selectCurrentMailboxHasTickets'
import { selectCurrentMailbox } from 'selectors/mailboxes/selectCurrentMailbox'
import { selectIsInboxBootstrapped } from 'selectors/app/base'
import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'
import { selectSearchesRequestState } from './search'

export const selectShowContinueForwardingSetupPane = createSelector(
  selectIsInInbox,
  selectCurrentMailbox,
  selectIsInboxBootstrapped,
  selectCurrentMailboxHasTickets,
  (
    isInInbox,
    currentMailbox,
    isInboxBootstrapped,
    currentMailboxHasTickets
  ) => {
    const { state, channel_type: channelType } = currentMailbox || {}

    const isUnconfirmedForwarding =
      state === 'unconfirmed' && channelType === 'FORWARDING'

    return (
      isInboxBootstrapped &&
      isInInbox &&
      isUnconfirmedForwarding &&
      currentMailboxHasTickets === false
    )
  }
)

export const selectShowContinueForwardingSetupBar = createSelector(
  selectIsInInbox,
  selectCurrentMailbox,
  selectIsInboxBootstrapped,
  selectSearchesRequestState,
  selectIsSettingUpTheCurrentMailBox,
  selectShowContinueForwardingSetupPane,
  selectPrefersClassicView,
  selectCurrentTicketIdFromPage,
  (
    isInInbox,
    currentMailbox,
    isInboxBootstrapped,
    searchesState,
    isSettingUpTheCurrentMailBox,
    showContinueForwardingSetupPane,
    isClassicView,
    currentTicketIdFromPage
  ) => {
    const { state, channel_type: channelType } = currentMailbox || {}

    const isUnconfirmedForwarding =
      state === 'unconfirmed' && channelType === 'FORWARDING'

    const isSearchesLoaded = searchesState.loaded && !searchesState.error

    return (
      isInboxBootstrapped &&
      isInInbox &&
      isUnconfirmedForwarding &&
      isSearchesLoaded &&
      isClassicView &&
      !currentTicketIdFromPage &&
      !isSettingUpTheCurrentMailBox &&
      !showContinueForwardingSetupPane
    )
  }
)
