import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './styles.less'

const TEXT_ALIGN = ['left', 'center', 'right']

const FONT_WEIGHT = ['light', 'regular', 'semiBold', 'bold']

const FONT_TYPES = ['display', 'page']

const COLORS = [
  'juicy',
  'space',
  'golden',
  'grass',
  'lava',
  'sky',
  'apple',
  'bio',
  'sea',
  'neon',
  'berry',
  'groovy',
  'lightJuicy',
  'lightSpace',
  'lightGolden',
  'lightGrass',
  'lightLava',
  'lightSky',
  'lightApple',
  'lightBio',
  'lightSea',
  'lightNeon',
  'lightBerry',
  'lightGroovy',
  'darkJuicy',
  'darkSpace',
  'darkGolden',
  'darkGrass',
  'darkLava',
  'darkSky',
  'darkApple',
  'darkBio',
  'darkSea',
  'darkNeon',
  'darkBerry',
  'darkGroovy',
  'brightGroovy',
  'brightJuicy',
  'brightSuperDark',
  'dust',
  'ultraDust',
  'dark',
  'light',
  'ultraLight',
  'white',
  'newGray',
  'inherit',
]

export const createTypeElement = (
  type,
  {
    size,
    children,
    as = 'div',
    className,
    color,
    textAlign,
    weight,
    fontType,
    ...rest
  }
) => {
  const sizedClassName = size ? `${type}-${size}` : type
  const fullClassName = cn(
    type.toLowerCase(),
    styles[sizedClassName],
    styles[color],
    styles[textAlign],
    styles[`fw${weight}`],
    styles[fontType],
    className
  )
  return React.createElement(
    as,
    { ...rest, className: fullClassName },
    children
  )
}

export const createPropTypes = (definedPropTypes = {}) => {
  return {
    as: PropTypes.node.isRequired,
    color: PropTypes.oneOf(COLORS),
    textAlign: PropTypes.oneOf(TEXT_ALIGN),
    weight: PropTypes.oneOf(FONT_WEIGHT), // HACK (jscheel): withTypography swallows fontWeight
    fontType: PropTypes.oneOf(FONT_TYPES),
    ...definedPropTypes,
  }
}
