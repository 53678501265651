// Wrapper around SUI Divider that adds spacing props, and SUI wrapper
import styled from '@emotion/styled'

import { Divider as SUIDivider } from 'util/ui'

const Divider = styled(SUIDivider)`
  &&& {
    /* General pattern that elements are responsible for their own top spacing. */
    margin-bottom: 0px !important;
  }
`

Divider.displayName = 'Divider'

export default Divider
