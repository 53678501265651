import { selectCurrentPath, selectCurrentId } from 'selectors/location'

import { createSelector } from 'reselect'

export const selectCurrentTicketIdFromPage = createSelector(
  selectCurrentPath,
  selectCurrentId,
  (path, id) => {
    if (!path.match('/tickets/')) return undefined
    if (path.match('/tickets/new')) return 'new'
    return id
  }
)
