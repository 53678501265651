import { createSelector } from 'reselect'

import {
  fetchingStatusesSelector,
  isAuthenticatedSelector,
  isBoostrappedSelector,
  isRealtimeSubscribedSelector,
  hasRealtimeConnectedOnceSelector,
  selectIsInboxBootstrapped,
} from 'selectors/app/base'
import {
  selectCurrentPath,
  selectIsInInbox,
  selectIsInChat,
} from 'selectors/location'
import { selectStoredPinnedSearches } from 'selectors/pinned_searches/base'
import { selectIsOnOnboardingPage } from 'subapps/onboarding/selectors'
import { constructSearchQueryObject } from 'util/search'

export const selectIsInvalidPath = createSelector(
  selectCurrentPath,
  path => path === '/tickets' || path === '/tickets/'
)

export const shouldSubscribeToRealtimeSelector = createSelector(
  isAuthenticatedSelector,
  isBoostrappedSelector,
  isRealtimeSubscribedSelector,
  fetchingStatusesSelector,
  selectIsInChat,
  selectIsOnOnboardingPage,
  hasRealtimeConnectedOnceSelector,
  (
    authenticated,
    bootstrapped,
    subscribed,
    statuses,
    inChat,
    inOnboarding,
    hasConnectedOnce
  ) => {
    return (
      authenticated &&
      (bootstrapped || inChat) &&
      !inOnboarding &&
      !subscribed &&
      !statuses.subscribeRealtime &&
      !hasConnectedOnce
    )
  }
)

export const shouldFetchInboxBootstrapData = createSelector(
  isAuthenticatedSelector,
  fetchingStatusesSelector,
  selectIsInInbox,
  selectIsInboxBootstrapped,
  (authenticated, statuses, inInbox, isInboxBoostrapped) => {
    return (
      inInbox && authenticated && !isInboxBoostrapped && !statuses.fetchAppData
    )
  }
)

export const selectStoredPinnedSearchQueries = createSelector(
  selectStoredPinnedSearches,
  pinned => {
    return pinned && pinned.map(pin => constructSearchQueryObject(pin.query))
  }
)
