import React from 'react'

import { isEmpty, omit } from 'util/objects'
import debug from 'util/debug'
import { getDisplayName } from 'util/hoc'
import { promisify } from 'util/promises'

// HACK (jscheel): Right now, withFormFields doesn't handle validation very
// well, as it only validates during the onChange phase. This causes problems
// for a number of reasons. Primarily, it assumes that the initial value for a
// field is a "valid" value, because it only actually validates if the field
// is changed. However, if you want to validate onSave, you have to do it
// separately, as there is no consideration for validation at that point.

function initialState() {
  return {
    dirtyFields: {},
    formFields: {},
    formFieldsErrors: {},
    formFieldsValidStates: {},
    isFormValid: false,
    pendingValidationFields: {},
    saving: false,
  }
}

export default function withFormFields(WrappedComponent, options = {}) {
  class WithFormFields extends React.Component {
    static defaultProps = {
      autosave: false,
      autosaveInterval: 1000,
      initialValues: {},
      validators: {},
      // Optionally map the API error fields to given formFields
      //
      //     apiFieldMapping: { username: 'usernameInput' },
      //
      apiFieldMapping: {},
    }

    constructor(props) {
      super(props)
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.formFields = props.initialValues
      this.validateFormFields(false)
    }

    state = initialState()

    componentDidMount() {
      const { onFormFieldsMount } = this.props
      if (onFormFieldsMount) onFormFieldsMount()
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.initialValues) {
        const mergedFormFields = this.mergeNewFormFieldsIntoState(
          nextProps.initialValues
        )
        if (mergedFormFields) {
          // TODO (jscheel): Should these mergedFields also reset their
          // formFieldsErrors status?
          this.setState({ formFields: mergedFormFields }, () => {
            this.validateFormFields(false)
          })
        }
      }
    }

    componentWillUnmount() {
      this.unmounting = true
      if (this.autosaveTimeoutId) {
        clearTimeout(this.autosaveTimeoutId)
        this.submit()
      }
    }

    autosaveTimeoutId = null
    unmounting = false

    validateFormFields(updateErrorMessages = true) {
      const { formFields } = this.state
      const validators = this.validators()
      const errorMessages = {}
      const validationPromises = Object.keys(validators).map(key => {
        const validator = validators[key]
        if (!validator) {
          errorMessages[key] = false
          return false // NOTE (jscheel): Return no error message
        }
        return promisify(validator, formFields[key], formFields).then(
          errorMessage => {
            errorMessages[key] = errorMessage
            return errorMessage
          }
        )
      })

      return new Promise(resolve => {
        Promise.all(validationPromises).then(() => {
          if (this.unmounting) return

          const formFieldsValidStates = {}
          let hasErrors = false

          Object.keys(errorMessages).forEach(key => {
            const validState = !errorMessages[key]
            formFieldsValidStates[key] = validState
            if (!validState) hasErrors = true
          })

          this.setState(
            previousState => {
              return {
                formFieldsValidStates,
                isFormValid: !hasErrors && !this.hasPendingValidationFields(),
                formFieldsErrors: updateErrorMessages
                  ? errorMessages
                  : previousState.formFieldsErrors,
              }
            },
            () => {
              resolve()
            }
          )
        })
      })
    }

    updateFormValidState() {
      this.setState(({ formFieldsValidStates }) => {
        const valid = !Object.values(formFieldsValidStates).some(v => !v)
        return { formValid: valid }
      })
    }

    // NOTE (jscheel): Marked for deprecation. We should not allow semi-controlled
    // state for this HOC. If you want to change the initialValues, the change should
    // be forced through a key change, not through semi-controlled initialValues props.
    mergeNewFormFieldsIntoState(newFormFields) {
      const { formFields, dirtyFields } = this.state
      const mergedFormFields = { ...formFields }
      let changed = false
      Object.keys(newFormFields).forEach(key => {
        if (dirtyFields[key]) return // user's changes take precedence

        if (newFormFields[key] !== formFields[key]) {
          mergedFormFields[key] = newFormFields[key]
          changed = true
        }
      })

      if (!changed) {
        return null
      }
      return mergedFormFields
    }

    // TODO (jscheel): Thinking we need to instead reset to a "saved once and
    // only once" state. Need to think about how to say: "this is it".
    resetForm = ({ fields: fieldsToReset = false } = {}) => {
      this.setState((prevState, prevProps) => {
        const currentValuesToKeep = fieldsToReset
          ? omit(fieldsToReset, prevState.formFields)
          : {}
        return {
          ...initialState(),
          formFields: {
            ...prevProps.initialValues,
            ...currentValuesToKeep,
          },
        }
      })
      if (this.autosaveTimeoutId) {
        clearTimeout(this.autosaveTimeoutId)
      }
      this.autosaveTimeoutId = null
    }

    resetFields = (...rest) => {
      this.resetForm({ fields: Array.from(rest) })
    }

    _unsafeSetFormFields = (fields, hasValidator = false) => {
      this.setState({
        formFields: {
          ...this.state.formFields,
          ...fields,
        },
        dirtyFields: {
          ...this.state.dirtyFields,
          ...Object.keys(fields).reduce((obj, key) => {
            // eslint-disable-next-line no-param-reassign
            obj[key] = true
            return obj
          }, {}),
        },
        formFieldsValidStates: {
          ...this.state.formFieldsValidStates,
          ...Object.keys(fields).reduce((obj, key) => {
            // eslint-disable-next-line no-param-reassign
            obj[key] = hasValidator ? undefined : true
            return obj
          }, {}),
        },
        formFieldsErrors: {
          ...this.state.formFieldsErrors,
          ...Object.keys(fields).reduce((obj, key) => {
            // eslint-disable-next-line no-param-reassign
            obj[key] = undefined
            return obj
          }, {}),
        },
      })
    }

    unsafeSetFormFields = fields => {
      this._unsafeSetFormFields(fields, false)
    }

    /**
     * NOTE (jscheel): `handleChange` is passed into the component as `onChange`
     * @param {string} key - Name of field
     * @param {*} value Value of field
     * @param {Function|Promise} [validate] Validation function or promise
     * @param {boolean} [options.force] Force onChange to run, even if content is the same
     * @param {boolean} [options.immediate] Immediately submit if autosave is true, instead of waiting for timeout
     */

    handleChange = (
      key,
      value,
      overrideValidator, // NOTE (jscheel): Legacy, marked for possible deprecation
      { force = false, immediate = false, validate = false } = {}
    ) => {
      const { autosave } = this.props
      return new Promise(resolve => {
        if (this.state.formFields[key] === value && !force) {
          resolve()
          return
        }
        const hasValidation = !!(overrideValidator || this.validators()[key])
        this._unsafeSetFormFields({ [key]: value }, hasValidation)

        if (overrideValidator || validate) {
          this.handleValidate(key, value, overrideValidator).then(
            validationMessage => {
              if (!validationMessage && autosave) {
                this.scheduleSubmit(immediate)
              }
              resolve(validationMessage)
            }
          )
          return
        }

        if (autosave) this.scheduleSubmit(immediate)

        resolve()
      })
    }

    handleValidate = (key, value, overrideValidator) => {
      const { formFields } = this.state
      const validationFn = overrideValidator || this.validators()[key]

      if (!validationFn) return Promise.resolve()
      this.setState(prevState => {
        return {
          pendingValidationFields: {
            ...prevState.pendingValidationFields,
            [key]: true,
          },
        }
      })
      return new Promise(resolve => {
        promisify(validationFn, value, formFields).then(validationMessage => {
          this.setState(
            prevState => {
              const {
                formFieldsValidStates,
                pendingValidationFields,
                formFieldsErrors,
                dirtyFields,
              } = prevState
              const newValidStates = {
                ...formFieldsValidStates,
                [key]: !validationMessage,
              }
              const newPendingValidationFields = {
                ...pendingValidationFields,
                [key]: false,
              }
              const newFormFieldErrors = {
                ...formFieldsErrors,
                [key]: dirtyFields[key] ? validationMessage : null,
              }
              const newIsFormValid =
                !Object.values(newValidStates).includes(false) &&
                !Object.values(newPendingValidationFields).includes(true)
              return {
                formFieldsErrors: newFormFieldErrors,
                formFieldsValidStates: newValidStates,
                pendingValidationFields: newPendingValidationFields,
                isFormValid: newIsFormValid,
              }
            },
            () => {
              resolve(validationMessage)
            }
          )
        })
      })
    }

    hasDirtyFields() {
      return Object.values(this.state.dirtyFields).includes(true)
    }

    hasPendingValidationFields() {
      return Object.values(this.state.pendingValidationFields).includes(true)
    }

    mergeApiErrors(e) {
      // eslint-disable-next-line no-underscore-dangle
      if (!e || (!e._mapped && isEmpty(this.props.apiFieldMapping))) {
        return this.state.formFieldsErrors
      }

      const newErrors = { ...this.state.formFieldsErrors }

      Object.keys(e).forEach(fieldName => {
        const msg = e[fieldName]
        const msgStr = Array.isArray(msg) ? msg.join(', ') : msg
        const mappedField = this.props.apiFieldMapping[fieldName]

        // By default we try to merge API errors with formFieldsErors. This creates
        // convention whereby the UI should name the form field the same as the API
        // does. e.g. usernameInput + username. However if you dont like this,
        // then you need to provide the mapping in `apiFieldMap`
        if (
          Object.prototype.hasOwnProperty.call(this.state.formFields, fieldName)
        ) {
          newErrors[fieldName] = msgStr
        } else if (mappedField) {
          newErrors[mappedField] = msgStr
        } else {
          debug(`WARN: discarding unmapped API field error: ${fieldName}`)
        }
      })

      return newErrors
    }

    submit = () => {
      this.setState({ saving: true })

      return this.validateFormFields(true).then(() => {
        return promisify(this.props.onSave, this.state.formFields)
          .then(() => {
            if (!this.unmounting) {
              this.setState({
                saving: false,
                dirtyFields: {},
              })
            }

            if (this.props.onSaveOK) this.props.onSaveOK()
          })
          .catch(e => {
            debug('Form submit error', { e })
            const mergedErrors = this.mergeApiErrors(e)

            if (!this.unmounting) {
              this.setState({
                saving: false,
                formFieldsErrors: mergedErrors,
                apiErrors: e,
              })
            }

            if (this.props.onSaveFail) this.props.onSaveFail(mergedErrors)

            // NOTE - even if your onSave fn is a promise, we dont throw this
            // error. You need to pass an onSaveFail callback
          })
      })
    }

    scheduleSubmit = (immediate = false) => {
      if (this.autosaveTimeoutId) {
        clearTimeout(this.autosaveTimeoutId)
      }
      if (immediate || this.props.autosaveInterval === 0) {
        this.submit()
      } else {
        this.autosaveTimeoutId = setTimeout(
          this.submit,
          this.props.autosaveInterval
        )
      }
    }

    validators = () => {
      const { validators: propsValidators } = this.props
      const { validators: optionsValidators } = options
      return {
        ...optionsValidators,
        ...propsValidators,
      }
    }

    render() {
      // Prevent showing form fields with incomplete data
      if (isEmpty(this.state.formFields)) return <div />

      const isFormValid = this.state.isFormValid
      const isFormValidating = this.hasPendingValidationFields()
      const canSave = isFormValid && !this.state.saving && !isFormValidating

      return (
        <WrappedComponent
          {...this.props}
          canSave={canSave}
          dirtyFields={this.state.dirtyFields}
          formFields={this.state.formFields}
          formFieldsErrors={this.state.formFieldsErrors}
          hasDirtyFields={this.hasDirtyFields()}
          isFormValid={isFormValid}
          onChange={this.handleChange}
          unsafeSetFormFields={this.unsafeSetFormFields}
          onValidate={this.handleValidate}
          pendingValidationFields={this.state.pendingValidationFields}
          resetFields={this.resetFields}
          resetForm={this.resetForm}
          saving={this.state.saving}
          submitForm={this.submit}
          validating={isFormValidating}
          apiErrors={this.state.apiErrors}
        />
      )
    }
  }
  WithFormFields.displayName = `WithFormFields(${getDisplayName(
    WrappedComponent
  )})`
  return WithFormFields
}
