import { constructSearchQueryId, constructSearchQueryString } from 'util/search'
import { buildSearchObject } from './buildSearchObject'
import { pathFor } from './pathFor'

export function decorate(folder, mailbox, valueMap, searchesById) {
  const searchObject = buildSearchObject(folder, mailbox)
  const searchQueryId = constructSearchQueryId(searchObject, valueMap)
  const search = searchesById[searchQueryId]
  const count = search ? search.totalCount : 0
  const string = constructSearchQueryString(searchObject)
  return {
    ...folder,
    path: pathFor(searchObject),
    string,
    count,
  }
}
