/* eslint-disable no-multi-assign */ // ok in reducers
import * as pages from 'constants/pages'
import * as types from 'constants/action_types'
import storage from 'util/storage'

import {
  constructSearchQueryObject,
  constructSearchQueryId,
  constructSearchQueryTitle,
  getStoredSubmittedQueryIdWithQueryString,
  getSearchMailboxesFromQueryString,
} from 'util/search'
import { any, emptyArr, getLength, diff, uniq } from 'util/arrays'
import { mapAggregationsToSearches } from 'util/search/counts'
import metrics from 'util/metrics'
import debug from 'util/debug'
import { sortOrderContextKeyForQueryId } from 'util/search/sorting'
import { createActionTypeReducer } from 'util/reducers'
import {
  MERGE_TICKET_SUCCESS,
  FETCH_MERGEABLE_TICKETS_SUCCESS,
} from 'ducks/merging/types'
import { CREATE_DRAFT, DELETE_DRAFT } from 'ducks/drafts2/constants'

const reducers = {}
const initialLastSearchQueryId = storage.get('lastSearchQueryId')
// remove the lastSearchQueryId
storage.remove('lastSearchQueryId')

const defaultState = {
  byQueryId: {},
  initialLastSearchQueryId,
  // committedSearchQueryString and submittedSearchQueryString won't include mailbox filter
  committedSearchQueryString: null,
  submittedSearchQueryString: null,
  // Mailbox filters, e.g. ['inbox1', 'inbox2']
  searchMailboxIds: [],
  // Store the current search query part that has the selection (clicked or typed)
  currentPart: { operator: undefined, value: undefined, part: undefined },
  // The editor for Search input
  editor: null,
  editorState: undefined,
  isTypedSearch: false,
}

reducers[types.MARK_FETCHING_STATUS] = (state, action) => {
  const data = action.data
  if (data.action === 'fetchTicketsByKeyword') {
    let isFetching = state.isFetching || false
    isFetching = data.status
    return Object.assign({}, state, {
      isFetching,
    })
  }
  return state
}

reducers[types.SEARCH_SUCCESS] = reducers[FETCH_MERGEABLE_TICKETS_SUCCESS] = (
  state,
  action
) => {
  const {
    tickets,
    queryId,
    queryObject,
    currentPage,
    totalCount,
    totalPages,
    sortOrder,
    foldersById,
    ticketSearchOperatorValueMap,
  } =
    action.data || action.payload

  const search = Object.assign(
    {
      pages: {},
      highlights: {},
    },
    state.byQueryId[queryId],
    {
      queryId,
      queryObject,
      totalCount,
      currentPage,
      totalPages,
      sortOrder,
      loaded: true,
      errored: false,
      resultsStale: false,
      resultsIncomplete: false,
      removedTicketIds: {},
      addedTicketIds: {},
      isNamed: !!constructSearchQueryTitle(queryObject) !== null,
      sortOrderContextKey: sortOrderContextKeyForQueryId(
        queryId,
        foldersById,
        ticketSearchOperatorValueMap
      ),
    }
  )
  const ticketIds = tickets.map(t => t.id)
  search.pages = Object.assign({}, search.pages, {
    [currentPage]: ticketIds,
  })

  const areResultsStale =
    state.byQueryId[queryId] && state.byQueryId[queryId].resultsStale

  // (pnagy) If we already have the ticket list loaded bust it's flagged as stale
  // we need to simply exchange it to the newly received list so we have a new list.
  // We can't empty the list in the reducer when we do the stale flagging because that would
  // lead to the rendering of an empty list and that messes up the scroll position and ugly as
  // it's visible by the user
  if (search.sortedIds && areResultsStale) {
    search.sortedIds = ticketIds
  } else {
    // if we're on the current search, it will not re-sort
    // so we consider the sort order to be correct and add to the list
    search.sortedIds = uniq([].concat(search.sortedIds || [], ticketIds))
  }
  const highlights = tickets.reduce((result, t) => {
    if (t.search_summary) result[t.id] = t.search_summary // eslint-disable-line no-param-reassign
    return result
  }, {})
  search.highlights = Object.assign({}, search.highlights, highlights)
  search.resultsIncomplete = false

  const newByQuery = Object.assign({}, state.byQueryId, {
    [queryId]: search,
  })

  return Object.assign({}, state, {
    byQueryId: newByQuery,
  })
}

reducers[types.MARK_SEARCH_AS_COMPLETE] = (state, action) => {
  const { queryId } = action.data
  const search = {
    ...state.byQueryId[queryId],
    resultsIncomplete: false,
  }
  const newByQuery = { ...state.byQueryId, [queryId]: search }
  return { ...state, byQueryId: newByQuery }
}

reducers[types.SEARCH_BY_TICKET_NUMBER_SUCCESS] = (state, action) => {
  const { ticketId } = action.data
  const search = {
    ...state.byQueryId[ticketId],
    loaded: true,
  }

  if (search.pages) {
    search.pages = {
      ...search.pages,
      1: [ticketId].concat(search.pages[1]),
    }
  } else {
    search.pages = { 1: [ticketId] }
  }

  if (search.sortedIds) {
    search.sortedIds = uniq([ticketId].concat(search.sortedIds))
  } else {
    search.sortedIds = [ticketId]
  }

  const newByQuery = {
    ...state.byQueryId,
    [ticketId]: search,
  }

  return {
    ...state,
    byQueryId: newByQuery,
  }
}

reducers[types.SEARCH_ERROR] = (state, action) => {
  const { queryId } = action.data
  const search = Object.assign({}, state.byQueryId[queryId], {
    errored: true,
  })

  const newByQuery = Object.assign({}, state.byQueryId, {
    [queryId]: search,
  })
  return Object.assign({}, state, {
    byQueryId: newByQuery,
  })
}

reducers[types.SEARCH_CLEAR_TICKET_RESULTS] = (state, action) => {
  const {
    valueIdMap,
    omitNamedSearches,
    queryIdToKeep,
    clearCounts,
    refetchListOnNextUpdate,
  } = action.data
  // clear the results cache
  const { byQueryId } = state
  return {
    ...state,
    byQueryId: Object.keys(byQueryId).reduce((newByQueryId, queryId) => {
      const queryTitle = constructSearchQueryTitle(queryId, valueIdMap)
      const shouldKeep = queryId === queryIdToKeep
      const isNamedSoWeLeaveIt = queryTitle && omitNamedSearches
      const clearQueryResult = !shouldKeep && !isNamedSoWeLeaveIt
      const leaveForNowButFlagAsStale =
        shouldKeep && !isNamedSoWeLeaveIt && refetchListOnNextUpdate
      return {
        ...newByQueryId,
        [queryId]: {
          ...byQueryId[queryId],
          ...(clearQueryResult && {
            pages: null,
            resultsStale: true,
            sortedIds: undefined,
          }),
          ...(leaveForNowButFlagAsStale && { resultsStale: true }),
          ...(clearCounts && { totalCount: null }),
        },
      }
    }, {}),
    openMergableByCustomerId: {},
    closedMergableByCustomerId: {},
  }
}

reducers[types.SEARCH_CLEAR_ALL] = state => {
  const { byQueryId } = state
  return {
    ...state,
    byQueryId: Object.keys(byQueryId).reduce((newByQueryId, queryId) => {
      const search = byQueryId[queryId]
      // no need to do anything when search is not loaded
      if (search.loaded) return newByQueryId
      const clearedSearch = {
        ...byQueryId[queryId],
        pages: null,
        sortedIds: undefined,
        loaded: false,
      }
      // this flag removal is important to show the spinner
      delete search.errored
      return {
        ...newByQueryId,
        [queryId]: clearedSearch,
      }
    }, {}),
  }
}

// A simplified version of SEARCH_CLEAR_TICKET_RESULTS,
// happening only on realtime updates
const clearNonNamedTicketSearches = (state, action) => {
  const { options } = action.data
  const { currentQueryId } = options

  const { byQueryId } = state
  let hasChanges

  Object.keys(byQueryId).forEach(queryId => {
    const search = byQueryId[queryId]
    // no need to do anything to unloaded searches
    if (!search.loaded) return
    // skip named searches
    if (search.isNamed) return
    // also don't touch current search
    if (queryId === currentQueryId) return

    hasChanges = true
    byQueryId[queryId] = {
      ...byQueryId[queryId],
      pages: null,
      loaded: false,
      resultsStale: true,
      sortedIds: undefined,
    }
  })

  if (!hasChanges) return state

  return { ...state, byQueryId }
}
reducers[types.UPDATE_TICKETS] = clearNonNamedTicketSearches
reducers[types.CREATE_CHANGESET_SUCCESS] = clearNonNamedTicketSearches

function immerUpdateDraftSearches(
  draftState,
  direction, // INCREMENT or DECREMENT
  ticketId,
  currentUserId,
  draftFolderIds
) {
  if (ticketId === 'new') return

  const changedSearchIds = Object.keys(draftState.byQueryId).reduce(
    (acc, search) => {
      // we remove from current user's draft search
      const matchesDraftSearch =
        currentUserId && search.match(`draft:${currentUserId}`)
      const matchesDraftFolder = any(
        id => search.match(`folder:${id}`),
        draftFolderIds
      )
      if (matchesDraftSearch || matchesDraftFolder) acc.push(search)
      return acc
    },
    []
  )

  if (getLength(changedSearchIds) === 0) return

  changedSearchIds.forEach(search => {
    const searchObject = draftState.byQueryId[search]

    if (!searchObject.removedTicketIds) {
      searchObject.removedTicketIds = {}
    }
    if (!searchObject.addedTicketIds) {
      searchObject.addedTicketIds = {}
    }
    if (!searchObject.pages) {
      searchObject.pages = []
    }

    // the page is not necessarily present, but count is
    // if page is present, get a new copy and store it for usage
    // later in the function
    // if (searchObject && searchObject.pages && searchObject.pages[1]) {
    //   searchObject.pages = { ...searchObject.pages }
    //   searchObject.pages[1] = [].concat(searchObject.pages[1]) // copy the array
    //   page = searchObject.pages[1]
    // }

    if (direction === 'INCREMENT') {
      if (searchObject.pages[1] && !searchObject.pages[1].includes(ticketId)) {
        searchObject.pages[1].push(ticketId)
      }

      // as in the root reducer, we track added(/removed) ticket ids so
      // that we dont apply diffs (e.g. from realtime updates) twice
      if (!searchObject.addedTicketIds[ticketId]) {
        searchObject.totalCount += 1
        searchObject.addedTicketIds[ticketId] = true
        delete searchObject.removedTicketIds[ticketId]
      }

      if (
        searchObject.sortedIds &&
        searchObject.sortedIds.indexOf(ticketId) < 0
      ) {
        if (searchObject.sortOrder === 'oldest') {
          searchObject.sortedIds.push(ticketId)
        } else {
          searchObject.sortedIds.unshift(ticketId)
        }
      }
    } else if (direction === 'DECREMENT') {
      if (searchObject.pages[1]) {
        const idx = searchObject.pages[1].indexOf(ticketId)
        if (idx > -1) {
          searchObject.pages[1].splice(idx, 1)
        }
      }

      if (!searchObject.removedTicketIds[ticketId]) {
        searchObject.totalCount -= 1
        searchObject.removedTicketIds[ticketId] = true
        delete searchObject.addedTicketIds[ticketId]
      }

      if (
        searchObject.sortedIds &&
        searchObject.sortedIds.indexOf(ticketId) > -1
      ) {
        searchObject.sortedIds = searchObject.sortedIds.filter(
          existingId => existingId !== ticketId
        )
      }
    } else {
      throw new Error('Invalid action')
    }

    // negative counts make us look like asses
    if (searchObject.totalCount < 0) searchObject.totalCount = 0
  })
}

reducers[CREATE_DRAFT] = (draftState, action) => {
  const { ticketId } = action.payload
  const { currentUserId, draftFolderIds = emptyArr } = action.meta

  immerUpdateDraftSearches(
    draftState,
    'INCREMENT',
    ticketId,
    currentUserId,
    draftFolderIds
  )
}

reducers[DELETE_DRAFT] = (draftState, action) => {
  const { ticketId } = action.payload
  const { currentUserId, draftFolderIds = emptyArr } = action.meta

  immerUpdateDraftSearches(
    draftState,
    'DECREMENT',
    ticketId,
    currentUserId,
    draftFolderIds
  )
}

// THIS IS MANAGING DRAFT COUNTS BASED ON IF THE BODY IS NOW EMPTY BECAUSE WE
// DON'T DELETE EMPTY DRAFTS AUTOMATICALLY

// if a draft changes, add or remove the ticket from appropriate search
// reducers[draftActionTypes.UPDATE_LOCAL_REPLY_DRAFT] = (state, action) => {
//   const {
//     ticketId,
//     currentUserId,
//     diff, // eslint-disable-line no-shadow
//     currentDraftBody,
//     draftFolderIds = emptyArr,
//   } = action.data
//   const body = diff ? diff.body : null
//   const byQueryId = state.byQueryId

//   const changedSearchIds = Object.keys(byQueryId).reduce((acc, search) => {
//     // we remove from current user's draft search
//     const matchesDraftSearch =
//       currentUserId && search.match(`draft:${currentUserId}`)
//     const matchesDraftFolder = any(
//       id => search.match(`folder:${id}`),
//       draftFolderIds
//     )
//     if (matchesDraftSearch || matchesDraftFolder) acc.push(search)
//     return acc
//   }, [])

//   if (getLength(changedSearchIds) === 0) return state

//   const updatedSearches = updateDraftSearches(
//     state.byQueryId,
//     changedSearchIds,
//     ticketId,
//     body,
//     currentDraftBody
//   )

//   return {
//     ...state,
//     byQueryId: {
//       ...byQueryId,
//       ...updatedSearches,
//     },
//   }
// }

reducers[types.SEARCHES_SUCCESS] = (state, action) => {
  const {
    trackMetrics,
    metricType,
    isAutomatic,
    isManual,
    foldersById,
    ticketSearchOperatorValueMap,
  } = action.data
  const newState = Object.assign({}, state)
  const searches = action.data && action.data.searches
  newState.byQueryId = Object.assign({}, newState.byQueryId)

  let metricOutput
  if (trackMetrics) {
    metricOutput = {
      correct: 0,
      incorrect: 0,
      correct_folder: 0,
      incorrect_folder: 0,
      correct_assignee: 0,
      incorrect_assignee: 0,
      correct_tag: 0,
      incorrect_tag: 0,
    }
  }

  Object.keys(searches).forEach(queryString => {
    const queryId = constructSearchQueryId(queryString)
    const query = newState.byQueryId[queryId]
    const newSearchData = searches[queryString]
    const newCount = newSearchData.count
    const currentCount = query && query.totalCount
    if (trackMetrics) {
      if (currentCount !== undefined && currentCount !== null) {
        const correct = currentCount === newCount
        if (correct) {
          metricOutput.correct += 1
          try {
            if (queryId.match('folder:')) metricOutput.correct_folder += 1
            if (queryId.match('assignee:')) metricOutput.correct_assignee += 1
            if (queryId.match('tag:')) metricOutput.correct_tag += 1
          } catch (e) {
            // pass
          }
        }
        if (!correct) {
          metricOutput.incorrect += 1
          try {
            if (queryId.match('folder:')) metricOutput.incorrect_folder += 1
            if (queryId.match('assignee:')) metricOutput.incorrect_assignee += 1
            if (queryId.match('tag:')) metricOutput.incorrect_tag += 1
          } catch (e) {
            // pass
          }
        }
      }
    }

    const newQuery = {
      ...query,
      timestamp: newSearchData.timestamp,
      queryId,
      queryObject: constructSearchQueryObject(queryString),
      totalCount: newCount,
      sortOrderContextKey: sortOrderContextKeyForQueryId(
        queryId,
        foldersById,
        ticketSearchOperatorValueMap
      ),
    }
    // count has changed, which means that something has changed in this search,
    // we need to update it on next load, so we panic and clear the search
    if (query && query.sortedIds && currentCount !== newCount) {
      if (debug.enabled) {
        // eslint-disable-next-line no-console
        console.debug({
          queryId,
          currentCount,
          newCount,
          sortedIdsCount: query.sortedIds.length,
        })
      }
      newQuery.pages = null
      newQuery.resultsIncomplete = true
    }
    newState.byQueryId[queryId] = newQuery
  })

  if (trackMetrics) {
    const payload = {
      logname: 'counts',
      metric: metricType || 'searchCounts',
      ...metricOutput,
      isAutomatic,
      isManual,
    }
    metrics.log(payload)
  }

  return newState
}

reducers[types.FETCH_TICKET_AGGREGATION_COUNTS_SUCCESS] = (
  state,
  {
    data: {
      filteredSearch,
      ticketAggregations,
      trackMetrics,
      isManual,
      isAutomatic,
      foldersById,
      ticketSearchOperatorValueMap,
    },
  }
) => {
  return reducers[types.SEARCHES_SUCCESS](state, {
    data: {
      searches: mapAggregationsToSearches(filteredSearch, ticketAggregations),
      trackMetrics,
      metricType: 'aggregationCounts',
      isManual,
      isAutomatic,
      foldersById,
      ticketSearchOperatorValueMap,
    },
  })
}

reducers[pages.SEARCH_PAGE] = (state, action) => {
  const lastSearchQueryId = action.payload.term
  const { isTypedSearch } = action.payload
  // Save the lastSearchQueryId to make sure won't be redirect to the default inbox and folder
  // in this useEffect(src/components/App/DesktopView/Layout/LayoutSwitcher/view.jsx)
  // if the queryId exists
  storage.set('lastSearchQueryId', lastSearchQueryId)

  let isReloadingSubmittedSearchQuery = false
  let storedSubmittedQueryString = null
  if (
    lastSearchQueryId &&
    !state.submittedSearchQueryString &&
    !isTypedSearch &&
    !state.editor
  ) {
    // Reloading the current search
    const storedSubmittedQueryIdWithQueryString = getStoredSubmittedQueryIdWithQueryString()
    storedSubmittedQueryString = storedSubmittedQueryIdWithQueryString?.[1]
    isReloadingSubmittedSearchQuery =
      storedSubmittedQueryIdWithQueryString?.[0] === lastSearchQueryId
  }
  const submittedSearchQueryString = isReloadingSubmittedSearchQuery
    ? storedSubmittedQueryString
    : state.submittedSearchQueryString

  return {
    ...state,
    lastSearchQueryId,
    searchMailboxIds: isReloadingSubmittedSearchQuery
      ? getSearchMailboxesFromQueryString(submittedSearchQueryString)
      : state.searchMailboxIds,
    submittedSearchQueryString,
    isTypedSearch: isReloadingSubmittedSearchQuery
      ? true
      : action.payload.isTypedSearch,
    isReloadingSubmittedSearchQuery,
  }
}

reducers[types.STORE_LATEST_SEARCH] = (state, action) => {
  return Object.assign({}, state, {
    lastSearchQueryId: action.data.queryId,
  })
}

reducers[types.UPDATE_APP_DATA] = (state, action) => {
  return Object.assign({}, state, {
    lastSearchQueryId: action.data.latestQueryId,
  })
}

reducers[types.DELETE_TICKETS_SUCCESS] = reducers[
  types.DELETE_TICKETS_STARTED
] = (state, action) => {
  const data = action.data
  const tickets = data.tickets
  const ticketIds = tickets.map(t => t.id)

  return removeTickets(state, ticketIds)
}

function removeTickets(state, ticketIds, predicateFn = f => f) {
  const byQueryId = state.byQueryId || {}
  const newByQueryId = { ...byQueryId }
  const changedIds = Object.keys(newByQueryId).filter(predicateFn)

  if (getLength(changedIds) === 0) return state

  changedIds.forEach(id => {
    const search = (newByQueryId[id] = Object.assign({}, newByQueryId[id]))
    if (search.pages) {
      const searchPages = (search.pages = Object.assign({}, search.pages))
      Object.keys(searchPages).forEach(pageNumber => {
        const page = searchPages[pageNumber]
        searchPages[pageNumber] = diff(page, ticketIds)
      })
    }
    if (search.sortedIds) {
      const differenceTicketIds = diff(search.sortedIds || [], ticketIds)
      const shouldUpdateTotalCount = ticketIds.every(
        i => search.sortedIds.includes(i) && !differenceTicketIds.includes(i)
      )

      search.sortedIds = differenceTicketIds

      if (
        search.totalCount > 0 &&
        search.totalCount - ticketIds.length >= 0 &&
        shouldUpdateTotalCount
      ) {
        search.totalCount -= ticketIds.length
      }
    }
  })

  return {
    ...state,
    byQueryId: newByQueryId,
  }
}

reducers[MERGE_TICKET_SUCCESS] = (state, action) => {
  if (!action.payload) return state
  const {
    payload: { mergedTickets },
  } = action
  return reducers[types.DELETE_TICKETS_STARTED](state, {
    data: mergedTickets,
  })
}

reducers[pages.TICKET_PAGE] = (state, { payload: { id }, meta }) => {
  const newState = {
    ...state,
  }

  let prevId

  if (
    meta &&
    meta.location &&
    meta.location.prev &&
    meta.location.prev.payload
  ) {
    prevId = meta.location.prev.payload.id
  }

  const ticketPathChanged = id !== prevId

  if (!ticketPathChanged) return state

  return newState
}

reducers[types.UPDATE_CURRENT_TICKET_SEARCH_QUERY] = (state, action) => {
  const {
    data: {
      commit,
      queryString,
      currentPart = defaultState.currentPart,
      submit,
      reset,
      isTypedSearch,
    },
  } = action
  const { committedSearchQueryString, editorState } = state
  if (reset || submit) {
    return {
      ...state,
      currentPart: defaultState.currentPart,
      committedSearchQueryString: null,
      submittedSearchQueryString: submit ? queryString : null,
      isTypedSearch,
      editorState: reset ? undefined : editorState,
      searchMailboxIds: getSearchMailboxesFromQueryString(queryString),
    }
  }

  if (!queryString && queryString !== '') {
    return { ...state, currentPart, isTypedSearch }
  }

  const newCommittedSearchQueryString = commit
    ? queryString
    : committedSearchQueryString

  return {
    ...state,
    currentPart,
    committedSearchQueryString: newCommittedSearchQueryString,
    isTypedSearch,
  }
}

reducers[types.TOGGLE_LIST_SEARCH_BOX_STATUS] = (state, action) => {
  if (!action.data) return state
  const {
    data: { isFocused },
  } = action

  return {
    ...state,
    listSearchBoxFocused: isFocused,
  }
}

reducers[types.SEARCH_MAILBOXES_UPDATE] = (state, action) => {
  return {
    ...state,
    searchMailboxIds: action.payload.mailboxes,
  }
}

reducers[types.SEARCH_UPDATE_BY_KEY] = (state, action) => {
  if (!action.payload.key) return state
  return {
    ...state,
    [action.payload.key]: action.payload.value,
  }
}

export default createActionTypeReducer(reducers, defaultState)
