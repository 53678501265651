import { createSelector } from 'reselect'
import { isBoostrappedSelector } from 'selectors/app'
import { isEmpty } from 'util/arrays'
import { selectMailboxes } from './selectMailboxes'

export const selectAgentHasMailboxAccess = createSelector(
  isBoostrappedSelector,
  selectMailboxes,
  (isAppBootstrapped, mailboxes) => {
    // Until the app isn't bootstrapped we assume that the agent has
    // access to some mailboxes
    if (!isAppBootstrapped) return true
    return !isEmpty(mailboxes)
  }
)
