import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import cn from 'classnames'
import themeVars from 'ui_theme/site/globals/site.variables'
import Link from 'redux-first-router-link'
import Text from 'shared/components/ui/Typography/Text'
import Icon from 'shared/ui/Icon'
import transitions from 'util/transitions'

const isExternalUrl = url => /^https?/.test(url)

// Shows internal and external links
// Doesnt show internal link if matches current path
const UniversalLink = ({ className, link, currentPath, linkText }) => {
  if (isExternalUrl(link)) {
    return (
      <Text
        as="a"
        href={link}
        target="blank"
        size="small"
        className={className}
      >
        {linkText}
      </Text>
    )
  }
  if (currentPath.match(link)) return null
  return (
    <Text as={Link} to={link} size="small" className={className}>
      {linkText}
    </Text>
  )
}

const ActionTextStyle = () => theme => css`
  color: ${theme.color.primary.warningText} !important;
  :hover {
    /* CC indicates 0.8 alpha */
    color: ${theme.color.primary.warningText}cc !important;
  }
`

const CloseXColoring = () => theme => css`
  color: ${theme.color.monochrome.white} !important;
  :hover {
    /* CC indicates 0.8 alpha */
    color: ${theme.color.monochrome.white}cc !important;
  }
`

const ActionStyle = css`
  cursor: pointer;
`

const Body = ({
  className,
  currentPath,
  link,
  linkText,
  action,
  onAction,
  message,
  onDismiss,
}) => {
  return (
    <div className={cn(className, 'fullstack-admiralSnackbar')}>
      {message && (
        <Text as="span" size="small" color="white">
          {message}
        </Text>
      )}
      {link &&
        linkText &&
        currentPath && (
          <UniversalLink
            css={ActionTextStyle}
            className="fullstack-admiralSnackbar-action"
            link={link}
            currentPath={currentPath}
            linkText={linkText}
          />
        )}
      {action &&
        onAction && (
          <Text
            key="custom-action"
            as="span"
            size="small"
            onClick={onAction}
            css={[ActionStyle, ActionTextStyle]}
            className="fullstack-admiralSnackbar-action"
          >
            {action}
          </Text>
        )}
      {onDismiss && (
        <Icon
          css={[CloseXColoring, ActionStyle]}
          key="dismiss-action"
          name="close"
          size="small"
          onClick={onDismiss}
        />
      )}
    </div>
  )
}

const MobilePositionStyles = props => css`
  bottom: ${props.theme.spacing['10']};
  left: 50%;
  transform: translate(-50%, ${props.open ? 0 : 72}px);
  transition: ${transitions.easeOut('400ms', 'transform')},
    ${transitions.easeOut('400ms', 'visibility')};
`

const DesktopPositionStyles = props => css`
  bottom: ${props.theme.spacing['10']};
  left: 0px;
  transform: translateX(${props.open ? 16 : -480}px);
  transition: ${transitions.easeOut('400ms', 'transform')},
    ${transitions.easeOut('400ms', 'visibility')};
`

export default styled(Body)`
  display: flex;
  align-items: center;
  position: fixed;
  visibility: ${props => (props.open ? 'visible' : 'hidden')};
  min-width: 240px;
  max-width: 480px;
  z-index: 3900;
  background-color: ${props => props.theme.color.monochrome.black};
  border-radius: ${themeVars.defaultBorderRadius};

  ${props => (props.isMobile ? MobilePositionStyles : DesktopPositionStyles)};

  & > :first-of-type {
    margin-left: ${props => props.theme.spacing['10']};
    /* The first element should take all the spare space */
    width: 100%;
    flex-grow: 1;
  }
  & > * {
    white-space: nowrap;
    flex-grow: 0;
    margin-top: ${props => props.theme.spacing['7']};
    margin-bottom: ${props => props.theme.spacing['7']};
    margin-right: ${props => props.theme.spacing['10']};
  }
  & > .Icon-close {
    text-align: right;
  }
`
