import { createSelector } from 'reselect'
import * as modalTypes from 'constants/modal_types'

import { selectLabels } from 'selectors/labels/base'

import { sortByKeyInsensitive } from 'util/arrays'

import {
  selectIsSearchingLabels,
  selectCurrentLabelSearchResultsForFilter,
} from './search'

export const selectLabelsForManaging = createSelector(
  selectIsSearchingLabels,
  selectLabels,
  selectCurrentLabelSearchResultsForFilter,
  (isSearching, defaultList, searchList) => {
    if (isSearching) return searchList
    return sortByKeyInsensitive(defaultList, 'name')
  }
)

export const selectIsManageLabelsModalOpen = state =>
  state.modal.modalType === modalTypes.LABEL_MANAGE
