export const hasTicketsForMailbox = (
  mailbox,
  ticketSearchTotalCountsByQueryId
) => {
  if (!mailbox || !mailbox?.id) return false

  const hasTicketForFolders = (mailbox?.folders || []).some(folderId => {
    const queryId = `inbox:${mailbox.id} folder:${folderId}`
    const count = ticketSearchTotalCountsByQueryId[queryId] || 0

    return count > 0
  })

  if (hasTicketForFolders) return hasTicketForFolders

  return ['is:deleted', 'is:open'].some(term => {
    const queryId = `inbox:${mailbox.id} ${term}`
    const count = ticketSearchTotalCountsByQueryId[queryId] || 0

    return count > 0
  })
}
