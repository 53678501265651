import { createSelector } from 'reselect'
import { selectCurrentMailboxId } from 'selectors/mailboxes/selectCurrentMailboxId'
import { selectTicketSearchResultsByQueryId } from 'selectors/search'
import { filterSearches } from 'util/search'

const makeSelectGroupTicketSearchesForCurrentMailbox = state => {
  return createSelector(
    selectTicketSearchResultsByQueryId,
    selectCurrentMailboxId,
    (searches, mailboxId) => {
      return filterSearches(searches, {
        all: mailboxId ? { state, mailbox: mailboxId } : { state },
        present: { assigned_group: true, '*': false },
      })
    }
  )
}
export const selectGroupTicketSearchesForOpenAndCurrentMailbox = makeSelectGroupTicketSearchesForCurrentMailbox(
  'opened'
)
export const selectGroupTicketSearchesForClosedAndCurrentMailbox = makeSelectGroupTicketSearchesForCurrentMailbox(
  'closed'
)
export const selectGroupTicketSearchesForSnoozedAndCurrentMailbox = makeSelectGroupTicketSearchesForCurrentMailbox(
  'snoozed'
)
