import * as types from 'constants/action_types'

const reducers = {}
const defaultState = {}

reducers[types.TOGGLE_SEARCH_MODIFIER] = function(state, action) {
  const newState = Object.assign({}, state, {
    isOpen: action.data.open,
  })
  return newState
}

export default function reducer(state = defaultState, action) {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
