import { createSelector } from 'reselect'

import { selectLabels } from 'selectors/labels/base'
import { selectCurrentMailboxId } from 'selectors/mailboxes/selectCurrentMailboxId'
import { selectPinnedSearches } from 'selectors/pinned_searches'

import { filterSearches } from 'util/search'

import {
  selectCurrentTicketSearchQueryObject,
  selectTicketSearchResultsByQueryId,
} from './base'

const makeSelectLabelTicketSearchesForCurrentMailbox = state => {
  return createSelector(
    selectTicketSearchResultsByQueryId,
    selectCurrentMailboxId,
    (searches, mailboxId) => {
      return filterSearches(searches, {
        all: mailboxId ? { state, mailbox: mailboxId } : { state },
        present: { label: true, '*': false },
      }).sort((a, b) => {
        if (a.totalCount < b.totalCount) return 1
        return a.totalCount > b.totalCount ? -1 : 0
      })
    }
  )
}

export const selectLabelTicketSearchesForOpenAndCurrentMailbox = makeSelectLabelTicketSearchesForCurrentMailbox(
  'opened'
)

export const selectLabelTicketSearchesForClosedAndCurrentMailbox = makeSelectLabelTicketSearchesForCurrentMailbox(
  'closed'
)

export const selectLabelTicketSearchesForSnoozedAndCurrentMailbox = makeSelectLabelTicketSearchesForCurrentMailbox()

export const selectMissingPinnedSearchLabelIds = createSelector(
  selectPinnedSearches,
  selectLabels,
  (pinnedSearches, storeLabels) => {
    const searchLabels = [].concat(
      ...pinnedSearches.map(search => search.label)
    )
    const missingLabels = searchLabels.filter(searchLabel => {
      if (!searchLabel) return false
      return !storeLabels.find(
        storeLabel =>
          storeLabel.id === searchLabel || storeLabel.name === searchLabel
      )
    })
    if (missingLabels.length === 0) return null
    return missingLabels
  }
)

export const selectMissingCurrentSearchLabelNames = createSelector(
  selectCurrentTicketSearchQueryObject,
  selectLabels,
  (searchObject, storeLabels) => {
    const searchLabels = searchObject.label || []
    const missingLabels = searchLabels.filter(searchLabel => {
      if (!searchLabel) return false
      return !storeLabels.find(
        storeLabel =>
          storeLabel.id === searchLabel || storeLabel.name === searchLabel
      )
    })
    if (missingLabels.length === 0) return null
    return missingLabels
  }
)
