import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'
import { selectMailbox } from 'selectors/mailboxes/selectMailbox'
import { selectMailboxesById } from 'selectors/mailboxes/selectMailboxesById'
import { selectCurrentTicketUndecorated } from './current/selectCurrentTicketUndecorated'
import { selectRawById } from './byId/selectRawById'

export const selectCurrentTicketMailboxId = createSelector(
  selectCurrentTicketUndecorated,
  ticket => (ticket ? ticket.mailboxId : null)
)

export const selectCurrentTicketMailbox = createSelector(
  selectCurrentTicketUndecorated,
  selectMailboxesById,
  (ticket, mailboxes) => {
    if (!ticket || !mailboxes) return null
    return mailboxes[ticket.mailboxId]
  }
)

export const selectCurrentTicketMailboxName = createSelector(
  selectCurrentTicketMailbox,
  currentMailbox => {
    return (currentMailbox && currentMailbox.name) || null
  }
)

export const selectCurrentTicketMailboxColor = createSelector(
  selectCurrentTicketMailbox,
  currentMailbox => {
    return (currentMailbox && currentMailbox.color) || null
  }
)

export const selectHasMailboxReceivedForwardingConfirmationCode = createCachedSelector(
  selectRawById,
  selectMailbox,
  (_state, mailboxId) => mailboxId,
  (ticketsById, mailbox, mailboxId) => {
    if (!mailbox || mailbox.channel_type !== 'FORWARDING') return false
    return Object.values(ticketsById).some(
      ticket =>
        ticket.mailboxId === mailboxId &&
        (ticket.body?.match(/confirmation\Wcode/i) ||
          ticket.title?.match(/forwarding\Wconfirmation/i))
    )
  }
)((_state, mailboxId) => mailboxId || 'unknown')
