import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { themeColorProp } from '@groovehq/internal-design-system/lib/util/theme'
import { omit } from 'util/objects'
import Tooltip from 'shared/ui/Tooltip'
import * as icons from 'assets/icons/groove'
import * as iconsV2 from 'assets/icons/groove/v2'

const View = React.forwardRef(
  ({ name, className, onClick, ...rest }, forwardedRef) => {
    const v2Icon = iconsV2[name]

    // slowly replacing old icons with new ones
    const Icon = v2Icon || icons[name]
    if (!Icon) {
      return null
    }

    const htmlProps = omit(['size', 'color', 'withTooltip', 'active'], rest)
    return (
      <Icon
        className={cn(className, { v2Icon: !!v2Icon })}
        onClick={onClick}
        {...htmlProps}
        ref={forwardedRef}
      />
    )
  }
)

const SVGIcon = styled(View)`
  display: inline-block;
  vertical-align: middle;
  width: ${({ size, width }) => width || size}px;
  height: ${({ size, height }) => height || size}px;
  color: inherit;
  line-height: 1;

  .fill,
  [class$='fill'] {
    color: inherit;
    fill: ${props =>
      !props.color || props.color === 'inherit'
        ? 'currentColor'
        : themeColorProp()(props)};
  }

  .stroke,
  [class$='stroke'] {
    color: inherit;
    stroke: ${props =>
      !props.color || props.color === 'inherit'
        ? 'currentColor'
        : themeColorProp()(props)};
  }
`

SVGIcon.propTypes = {
  /* name of the corresponding .svg file in assets/icons/groove */
  name: PropTypes.string.isRequired,

  size: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,

  /* optional color name from the theme */
  color: PropTypes.string,
  withTooltip: PropTypes.shape({
    tooltip: PropTypes.string,
    position: PropTypes.string,
  }),
}

SVGIcon.defaultProps = {
  size: 24,
  withTooltip: null,
}

export default React.forwardRef(({ withTooltip, ...rest }, forwardedRef) => {
  return withTooltip ? (
    <Tooltip {...withTooltip}>
      <SVGIcon {...rest} ref={forwardedRef} />
    </Tooltip>
  ) : (
    <SVGIcon {...rest} ref={forwardedRef} />
  )
})
