import { createSelector } from 'reselect'
import { emptyArr } from 'util/arrays'
import { emptyObj } from 'util/objects'

export const selectPreviousUserSearchResults = state =>
  state.users.previousSearchResults || emptyObj

export const selectLastUserSearchTerm = state => state.users.lastSearchTerm

export const selectLastUserSearchResults = createSelector(
  selectPreviousUserSearchResults,
  selectLastUserSearchTerm,
  (users, term) => users[term]
)

export const selectSuggestedUsers = state =>
  state.users.suggestedUsers || emptyArr
