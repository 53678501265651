// Text area of notification box that repositions text to allow for left icon
//
import styled from '@emotion/styled'
import Flex from 'shared/ui/Flex'
import themeVars from 'ui_theme/site/globals/site.variables'

export default styled(Flex)`
  color: ${props => props.theme.color.monochrome.black};
  font-size: ${themeVars.relative14px};
  line-height: 1.55;
  margin-left: 1em;

  > .textHeader {
    font-weight: ${themeVars.pageFontWeightSemiBold};
  }
`
