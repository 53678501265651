import { createSelector } from 'reselect'
import { selectLabelsById } from 'selectors/labels/base'
import { selectCurrentOrSelectedTicketsUndecorated } from 'selectors/ticket_list/current'
import { emptyArr, isEmpty, uniq, flatMap } from 'util/arrays'
import { selectCurrentTicketUndecorated } from './current/selectCurrentTicketUndecorated'

export const selectCurrentTicketLabelIds = createSelector(
  selectCurrentTicketUndecorated,
  ticket => {
    if (!ticket || !ticket.labelIds) return emptyArr
    if (isEmpty(ticket.labelIds)) return emptyArr // perf: same empty array
    return ticket.labelIds
  }
)

export const selectCurrentTicketLabels = createSelector(
  selectCurrentTicketLabelIds,
  selectLabelsById,
  (labelIds, byId) => labelIds.map(id => byId[id]).filter(e => e)
)

const selectSelectedTicketsOrCurrentTicketLabelIds = createSelector(
  selectCurrentOrSelectedTicketsUndecorated,
  tickets => {
    if (!tickets || tickets.length === 0) return emptyArr // perf: same empty array
    const labelIds = uniq(flatMap(tickets, t => (t && t.labelIds) || []))
    if (isEmpty(labelIds)) return emptyArr // perf: same empty array
    return labelIds
  }
)

export const selectSelectedTicketsOrCurrentTicketLabels = createSelector(
  selectSelectedTicketsOrCurrentTicketLabelIds,
  selectLabelsById,
  (labelIds, byId) => labelIds.map(id => byId[id]).filter(e => e)
)
