import { createSelector } from 'reselect'
import { selectCurrentTicketUndecorated } from '../current/selectCurrentTicketUndecorated'

// DEPRECATED - causes too many rerenders. Prefer selectCurrentTicketCustomer
export const selectCurrentCustomer = createSelector(
  selectCurrentTicketUndecorated,
  ticket => {
    return ticket ? ticket.customer : null
  }
)
