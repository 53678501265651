import styled from '@emotion/styled'

export default styled('div')`
  animation: fadeIn 0.1s ease-in both;
  background-color: ${props => props.theme.color.tooltip.bg};
  max-width: 275px;
  text-align: center;
  overflow-wrap: break-word;
  border-radius: 3px;
  color: ${props => props.theme.color.tooltip.text};
  font-size: ${props => props.theme.fontSize.base};
  font-weight: ${props => props.theme.fontWeight.normal};
  left: 0;
  letter-spacing: normal;
  line-height: ${props => props.theme.lineHeight.leadingNormal};
  padding: 4px 8px 5px;
  pointer-events: none;
  position: fixed;
  top: 0;
  z-index: 2147483647;
  text-transform: none !important;
  white-space: initial;

  a {
    color: ${props => props.theme.color.tooltip.text};
    text-decoration: none;
  }

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: -20px;
    background-color: rgba(255, 255, 255, 0.01);
    content: ' ';
    pointer-events: none;
  }

  &:after {
    position: absolute;
    content: ' ';
    width: 0;
    height: 0;
    border-style: solid;
    border-color: ${props => props.theme.color.tooltip.bg};
  }

  @keyframes :local(fade-in) {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  &.pink {
    background: #f487ae;

    &:after {
      border-color: #f487ae;
    }
  }

  &.red {
    background: #b93519;

    &:after {
      border-color: #b93519;
    }
  }

  &.right {
    transform: translate(9px, -50%);

    &:after {
      top: 50%;
      left: -7px;
      transform: translateY(-50%);
      border-width: 5.5px 7.61px 5.5px 0;
      border-left-color: transparent;
      border-bottom-color: transparent;
      border-top-color: transparent;
    }
  }

  &.top {
    transform: translate(-50%, calc(-100% - 12px));

    &:after {
      top: 99%;
      left: 50%;
      transform: translateX(-50%);
      border-width: 7.61px 5.5px 0 5.5px;
      border-left-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
    }
  }

  &.bottom {
    transform: translate(-50%, 14px);

    &:after {
      top: -7px;
      left: 50%;
      transform: translateX(-50%);
      border-width: 0 5.5px 7.61px 5.5px;
      border-left-color: transparent;
      border-right-color: transparent;
      border-top-color: transparent;
    }
  }

  &.left {
    transform: translate(calc(-100% - 9px), -50%);

    &:after {
      top: 50%;
      left: 99%;
      transform: translateY(-50%);
      border-width: 5.5px 0 5.5px 7.61px;
      border-bottom-color: transparent;
      border-right-color: transparent;
      border-top-color: transparent;
    }
  }
`
