/* eslint-disable no-multi-assign */ /* this is ok in reducers */
import * as types from 'constants/action_types'
import {
  FETCH_FOLDERS_SUCCESS,
  FETCH_FOLDERS_V2_SUCCESS,
} from 'ducks/folders/actionTypes/folders'

import { diff as arrayDiff } from 'util/arrays'
import { getRawId } from 'util/globalId'
import { deepCopy } from 'util/objects'
import { MERGE_TICKET_SUCCESS } from 'ducks/merging/types'

const defaultState = {}
const reducers = {}
const defaultFolderState = () => ({ counts: {} }) // eslint-disable-line arrow-body-style

const updateFolder = (state, folderId, folder, mailboxId) => {
  const byId = state.byId || {}
  const currentFolder = byId[folderId]
  const newFolder = Object.assign(
    {},
    defaultFolderState(),
    currentFolder,
    folder
  )
  if (newFolder.ticketCount && mailboxId) {
    newFolder.counts[mailboxId] = newFolder.ticketCount
  } else if (newFolder.ticketCount) {
    newFolder.counts.total = newFolder.ticketCount
  }
  if (newFolder.tickets) {
    newFolder.mailboxTickets = Object.assign({}, newFolder.mailboxTickets || {})
    const ticketsReferences = newFolder.tickets.records.map(ticket => ticket.id)
    newFolder.mailboxTickets[mailboxId] = ticketsReferences
  }
  const newById = Object.assign({}, byId)
  newById[newFolder.id] = newFolder
  return Object.assign({}, state, {
    currentFolderId: folderId,
    byId: newById,
  })
}

reducers[types.FETCH_FOLDER_WITH_TICKETS_SUCCESS] = (state, action) => {
  const data = action.data
  const updatedFolder = data.folder
  const folderId = updatedFolder.id
  const mailboxId = data.mailboxId

  return updateFolder(state, folderId, updatedFolder, mailboxId)
}

reducers[types.CURRENT_FOLDER_WITH_TICKETS_NEXT_PAGE_SUCCESS] = reducers[
  types.ADD_PAGE_TO_CURRENT_FOLDER
] = (state, action) => {
  const data = action.data
  const byId = state.byId || {}
  const folder = byId[data.folder.id]
  const newFolder = Object.assign({}, defaultFolderState(), folder, data.folder)
  const mailboxId = data.mailboxId
  if (newFolder.tickets) {
    newFolder.mailboxTickets = Object.assign({}, newFolder.mailboxTickets || {})
    const ticketsReferences = newFolder.tickets.records.map(ticket => ticket.id)
    const currentReferences = newFolder.mailboxTickets[mailboxId] || []
    newFolder.mailboxTickets[mailboxId] = currentReferences.concat(
      ticketsReferences
    )
  }
  const newById = deepCopy(byId)
  newById[newFolder.id] = newFolder
  return Object.assign({}, state, {
    byId: newById,
  })
}

reducers[types.UPDATE_APP_DATA] = reducers[FETCH_FOLDERS_SUCCESS] = (
  state,
  action
) => {
  const data = action.data
  const byId = state.byId || {}
  const newById = deepCopy(byId)
  const folders = data.folders || []
  const ordered = []
  folders
    .filter(
      ({ visible, type }) =>
        [undefined, true].includes(visible) && type === 'Ticket::Filter'
    )
    .forEach(folder => {
      const newFolder = Object.assign({}, defaultFolderState(), folder)
      newById[newFolder.id] = newFolder
      ordered.push(newFolder.id)
    })
  return Object.assign({}, state, {
    ordered,
    byId: newById,
  })
}

reducers[FETCH_FOLDERS_V2_SUCCESS] = (state, action) => {
  const folders = Object.values(action.entities.folder)
  const newById = {}
  const ordered = []

  folders.forEach(folder => {
    const folderId = getRawId(folder.id)
    const conditions = folder.conditions.map(condition => {
      const param = condition.param?.toLowerCase()
      return {
        ...condition,
        id: getRawId(condition.id),
        param: param === 'tags' ? 'labels' : param,
        operator: condition.operator?.toLowerCase(),
      }
    })
    const newFolder = Object.assign({}, defaultFolderState(), {
      id: folderId,
      type: ['TICKET', 'ALL'].includes(folder.type)
        ? 'Ticket::Filter'
        : 'Chat::Filter',
      visible: folder.state === 'ACTIVE',
      matchType: folder.matchType.toLowerCase(),
      conditions,
      default: folder.default,
      displayCountWhenInactive: folder.displayCountWhenInactive,
      hideIfZeroConversations: folder.hideIfZeroConversations,
      name: folder.name,
    })

    if (
      [undefined, true].includes(newFolder.visible) &&
      newFolder.type === 'Ticket::Filter'
    ) {
      ordered.push(folderId)
      newById[folderId] = newFolder
    }
  })
  return { ...state, ordered, byId: newById }
}

reducers[types.DELETE_TICKETS_SUCCESS] = reducers[
  types.DELETE_TICKETS_STARTED
] = (state, action) => {
  const byId = state.byId || {}
  const newById = Object.assign({}, byId)
  const data = action.data
  const tickets = data.tickets
  const ticketIds = tickets.map(t => t.id)
  Object.keys(newById).forEach(id => {
    const folder = newById[id]
    const mailboxTickets = folder.mailboxTickets
    if (mailboxTickets) {
      Object.keys(mailboxTickets).forEach(mailboxId => {
        const current = mailboxTickets[mailboxId] || []
        const newArray = arrayDiff(current, ticketIds)
        mailboxTickets[mailboxId] = newArray
        const removedCount = current.length - newArray.length
        folder.counts[mailboxId] -= removedCount
      })
    }
  })
  return Object.assign({}, state, {
    byId: newById,
  })
}

reducers[MERGE_TICKET_SUCCESS] = (state, action) => {
  if (!action.payload) return state
  const {
    payload: { mergedTickets },
  } = action
  return reducers[types.DELETE_TICKETS_STARTED](state, {
    data: mergedTickets,
  })
}

export default function reducer(state = defaultState, action) {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
