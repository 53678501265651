import {
  getAgentUsernameOrYou,
  getAgentPossessiveUsernameOrMine,
  getAgentUsername,
} from 'util/agents'
import { objectHashSerializer, memoize } from 'util/memoization'

const decorate = memoize(
  (currentUser, groups) => agent => {
    const decoratedAgent = Object.assign({}, agent)
    decoratedAgent.label = getAgentUsernameOrYou(decoratedAgent, currentUser)
    decoratedAgent.possessiveLabel = getAgentPossessiveUsernameOrMine(
      decoratedAgent,
      currentUser
    )
    decoratedAgent.ticketSearchQuery = `assignee:${getAgentUsername(agent)}`
    decoratedAgent.groups = groups.filter(group => {
      return (
        group.agents.findIndex(({ id: agentId }) => agentId === agent.id) > -1
      )
    })
    return decoratedAgent
  },
  {
    serializer: objectHashSerializer,
  }
)

export default decorate
