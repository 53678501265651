import React from 'react'
import styled from '@emotion/styled'

const SwitchOuter = styled('div')`
  border: solid 1px ${props => props.color};
  border-radius: 8.5px;
  width: 28px;
  height: 16px;
  padding: 2px;
  cursor: pointer;
`
const SwitchInner = styled('div', {
  shouldForwardProp: prop => prop !== 'on',
})`
  border: solid 1px ${props => props.color};
  background-color: ${props => props.color};
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-left: ${props => (props.on ? '12px' : 0)};
`

export default function Switch({ on, color, onClick }) {
  return (
    <SwitchOuter onClick={onClick} color={color}>
      <SwitchInner color={color} on={on} />
    </SwitchOuter>
  )
}
