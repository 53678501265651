import { createSelector } from 'reselect'
import { selectCurrentMailboxId } from 'selectors/mailboxes/selectCurrentMailboxId'
import { folderMailboxTicketIds } from './folderMailboxTicketIds'
import { selectCurrentFolder } from './selectCurrentFolder'

export const selectCurrentFolderMailboxTickets = createSelector(
  selectCurrentFolder,
  selectCurrentMailboxId,
  (folder, mailboxId) => folderMailboxTicketIds(folder, mailboxId)
)
