import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import {
  isClosed,
  isDeleted,
  isSpam,
  isStarred,
  isUnread,
  getStateLabel,
  getInvertStateActionLabel,
  isOpen,
  isCloseable,
  isSnoozed,
} from 'util/ticketState'
import { selectCurrentTicketUndecorated as selectUndecorated } from './current/selectCurrentTicketUndecorated'
import { selectTicketById } from './byId/selectTicketById'

import { selectRawTicketById } from './byId/selectRawTicketById'

export const selectCurrentTicketIsUnread = createSelector(
  selectUndecorated,
  isUnread
)

export const selectCurrentTicketIsClosed = createSelector(
  selectUndecorated,
  isClosed
)

export const selectCurrentTicketIsSpam = createSelector(
  selectUndecorated,
  isSpam
)

export const selectCurrentTicketIsDeleted = createSelector(
  selectUndecorated,
  isDeleted
)

export const selectCurrentTicketIsFollowing = createSelector(
  selectUndecorated,
  selectCurrentUser,
  ({ follower_ids: followerIds }, { id }) => {
    return followerIds && followerIds.includes(id)
  }
)

export const selectCurrentTicketSnoozedUntil = createSelector(
  selectUndecorated,
  ticket => ticket.snoozedUntil
)

export const selectCurrentTicketIsSnoozed = createSelector(
  selectUndecorated,
  ticket => isSnoozed(ticket)
)

export const selectCurrentTicketIsStarred = createSelector(
  selectUndecorated,
  isStarred
)

export const selectInversedStarredPriority = createSelector(
  selectCurrentTicketIsStarred,
  starred => (starred ? 'low' : 'urgent')
)

export const selectInversedStarredLabel = createSelector(
  selectCurrentTicketIsStarred,
  starred => (starred ? 'unstarred' : 'starred')
)

export const selectCurrentTicketStateLabel = createSelector(
  selectUndecorated,
  ticket => getStateLabel(ticket.state, ticket.snoozedUntil, ticket.deleted_at)
)

export const selectTicketFlagsByTicketId = createCachedSelector(
  selectRawTicketById,
  ticket => {
    return {
      isOpen: isOpen(ticket),
      isClosed: isClosed(ticket),
      isSpam: isSpam(ticket),
      isUnread: isUnread(ticket),
      isStarred: isStarred(ticket),
      isDeleted: isDeleted(ticket),
      isCloseable: isCloseable(ticket),
      isSnoozed: isSnoozed(ticket),
    }
  }
)((_state, ticketId) => ticketId || 'unknown')

export const selectTicketSnoozedUntilByTicketId = createCachedSelector(
  selectTicketById,
  ticket => ticket.snoozedUntil
)((_state, ticketId) => ticketId || 'unknown')

export const selectTicketStateLabelByTicketId = createCachedSelector(
  selectTicketById,
  ticket => getStateLabel(ticket.state, ticket.snoozedUntil, ticket.deleted_at)
)((_state, ticketId) => ticketId || 'unknown')

export const selectTicketInvertStateActionLabelByTicketId = createCachedSelector(
  selectTicketById,
  ticket =>
    getInvertStateActionLabel(
      ticket.state,
      ticket.snoozedUntil,
      ticket.deleted_at
    )
)((_state, ticketId) => ticketId || 'unknown')

export const selectTicketIsUnreadByTicketId = createCachedSelector(
  selectTicketById,
  ticket => isUnread(ticket)
)((_state, ticketId) => ticketId || 'unknown')
