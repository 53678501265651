import createCachedSelector from 're-reselect'
import selectGroupForId from 'ducks/teams/selectors/selectGroupForId'
import selectAgentsById from 'selectors/agents/selectById'
import { emptyArr } from 'util/arrays'

const selectAgentsForGroupId = createCachedSelector(
  selectGroupForId,
  selectAgentsById,
  (_state, _groupId, limit) => limit,
  (group, agentsById, limit) => {
    if (!group) return emptyArr

    // slice() called last in case there are:
    // a. undefined/null agents
    // b. group.agents with archived agents values which is filtered by selectAgentsById
    // this way we try to get as close to the agents specified by limit as possible
    return group.agents
      .map(({ id }) => agentsById[id])
      .filter(a => !!a)
      .slice(0, limit || Number.MAX_SAFE_INTEGER)
  }
)(
  (_state, groupId, limit) =>
    `${groupId || 'UNKNOWN'}-${limit || Number.MAX_SAFE_INTEGER}`
)

export default selectAgentsForGroupId
