import React from 'react'
import cn from 'classnames'
import { IconButton } from 'shared/ui'
import { emptyFunc } from 'util/functions'

import styles from './styles.less'

export default function CalendarNav({ right, onClick, disabled }) {
  return (
    <div
      className={styles.CalendarNav}
      onClick={disabled ? emptyFunc : onClick}
    >
      <IconButton
        name={right ? 'angleRightB' : 'angleLeftB'}
        size="small"
        iconSize={20}
        svg
        className={cn({ disabled })}
      />
    </div>
  )
}
