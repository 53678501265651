import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'

import { selectBase } from './selectBase'

/**
 * @deprecated rather use selectIsBootstrapTypedInStates
 */
export function makeSelectIsBootstrapTypesLoaded(types) {
  return createSelector(selectBase, bootstrap =>
    types.map(type => bootstrap[type] === 'LOADED').every(loaded => loaded)
  )
}

export const selectIsBootstrapTypedInStates = createCachedSelector(
  selectBase,
  (_state, types) => types,
  (_state, _types, states) => states,
  (bootstrap, types, states) => {
    if (!types || !states) return false
    return types
      .map(type => states.includes(bootstrap[type]))
      .every(loaded => loaded)
  }
)(
  (_state, types, states) =>
    `${!types ? 'unknown' : types.join('-')}-${
      !states ? 'unknown' : states.join('-')
    }`
)
