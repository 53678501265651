import {
  UPDATE_APP_DATA,
  REMOVE_MAILBOX_LOCALLY,
  UPDATE_CURRENT_MAILBOX,
  ADD_CREATED_TICKET,
  UPDATE_INACCESSIBLE_MAILBOXES,
} from 'constants/action_types'
import {
  DESTROY_MAILBOX_SUCCESS,
  FETCH_MAILBOXES_SUCCESS,
  UPDATE_MAILBOX_SUCCESS,
} from 'ducks/mailboxes/actionTypes'
import { without, withPush } from 'util/arrays'
import { FETCH_FOLDERS_V2_SUCCESS } from 'ducks/folders/actionTypes/folders'
import { filterByActiveAndMailboxId } from 'ducks/folders/utils'
import { createActionTypeReducer } from 'util/reducers'
import { getRawId } from 'util/globalId'

const defaultState = {
  byId: {},
  sorted: [],
  inaccessibleMailboxes: [],
}

function buildFolders(mailbox) {
  if (mailbox.folders) {
    return mailbox.folders.map(f => f && (f.id || f)).filter(f => !!f)
  }
  return []
}

function reduceById(draftState, mailboxes) {
  const sorted = draftState.sorted
  const byId = draftState.byId

  const sortedMailboxes = mailboxes // just use the API sorting

  if (sortedMailboxes) {
    sortedMailboxes.forEach(mailbox => {
      if (!byId[mailbox.id]) byId[mailbox.id] = {}

      Object.assign(byId[mailbox.id], mailbox, {
        folders: buildFolders(mailbox),
      })

      withPush(sorted, mailbox.id)
    })
  }
}

function reduceRemoveMailbox(draftState, id) {
  without(draftState.sorted, id)
  delete draftState.byId[id]

  // check inaccessible mailboxes too
  const inaccessibleMatch = draftState.inaccessibleMailboxes.find(
    m => m.id === id
  )

  if (inaccessibleMatch) {
    without(draftState.inaccessibleMailboxes, inaccessibleMatch)
  }
}

export default createActionTypeReducer(
  {
    [UPDATE_APP_DATA]: (draftState, { data: { mailboxes } }) => {
      reduceById(draftState, mailboxes)
      return draftState
    },
    [FETCH_MAILBOXES_SUCCESS]: (draftState, { data: { mailboxes } }) => {
      reduceById(draftState, mailboxes)
      return draftState
    },
    [UPDATE_MAILBOX_SUCCESS]: (
      draftState,
      { payload: { mailbox: payloadMailbox, isInAccessibleMailbox } }
    ) => {
      const stateMailbox =
        draftState.byId[payloadMailbox.id] ||
        draftState.inaccessibleMailboxes[payloadMailbox.id] ||
        {}
      const mailboxToUpdate = {
        ...stateMailbox,
        ...payloadMailbox,
      }
      mailboxToUpdate.channel_type = (
        mailboxToUpdate.channel_type || ''
      ).toUpperCase()
      mailboxToUpdate.folders = buildFolders(mailboxToUpdate)

      if (isInAccessibleMailbox) {
        draftState.sorted = draftState.sorted.filter(
          id => id !== payloadMailbox.id
        )
        delete draftState.byId[payloadMailbox.id]
        const indexInInaccessibleMailboxes = draftState.inaccessibleMailboxes.findIndex(
          m => m.id === payloadMailbox.id
        )
        if (indexInInaccessibleMailboxes > -1) {
          draftState.inaccessibleMailboxes[
            indexInInaccessibleMailboxes
          ] = mailboxToUpdate
        } else {
          draftState.inaccessibleMailboxes.push(mailboxToUpdate)
        }
      } else {
        draftState.inaccessibleMailboxes = draftState.inaccessibleMailboxes.filter(
          m => m.id !== payloadMailbox.id
        )
        draftState.byId[payloadMailbox.id] = mailboxToUpdate
        draftState.sorted = draftState.sorted.includes(payloadMailbox.id)
          ? draftState.sorted
          : [...draftState.sorted, payloadMailbox.id]
      }
      return draftState
    },
    [REMOVE_MAILBOX_LOCALLY]: (draftState, { data: { id } }) => {
      reduceRemoveMailbox(draftState, id)
      return draftState
    },
    [DESTROY_MAILBOX_SUCCESS]: (draftState, { payload: { id } }) => {
      reduceRemoveMailbox(draftState, id)
      return draftState
    },
    [UPDATE_CURRENT_MAILBOX]: (draftState, { data }) => {
      const mailbox = Object.assign({}, data.currentMailbox)
      if (mailbox.tickets && mailbox.tickets.edges) {
        // flatten the tickets collection
        const ticketsReferences = mailbox.tickets.edges.map(
          edge => edge.node.id
        )
        mailbox.tickets = ticketsReferences
      }
      draftState.byId[mailbox.id] = mailbox
      return draftState
    },
    [ADD_CREATED_TICKET]: (draftState, { data: { ticket, mailboxId } }) => {
      if (draftState.byId[mailboxId]) {
        draftState.byId[mailboxId].tickets.push(ticket.id)
      }
      return draftState
    },
    [UPDATE_INACCESSIBLE_MAILBOXES]: (draftState, { data: { mailboxes } }) => {
      draftState.inaccessibleMailboxes = mailboxes
      return draftState
    },
    [FETCH_FOLDERS_V2_SUCCESS]: (
      draftState,
      {
        entities: {
          current: {
            update: { folder: folderById },
          },
        },
      }
    ) => {
      const folders = Object.values(folderById)
      Object.values(draftState.byId).forEach(mailbox => {
        // eslint-disable-next-line no-param-reassign
        mailbox.folders = folders
          .filter(filterByActiveAndMailboxId(mailbox.id))
          .map(f => getRawId(f.id))
      })
    },
  },
  defaultState
)
