import { createSelector } from 'reselect'

import * as modals from 'constants/modal_types'
import { selectModalProps } from 'selectors/modals'

export const selectIsOpen = state => state.modal.modalType === modals.LIGHTBOX

export const selectImages = createSelector(
  selectModalProps,
  props => props.images
)

export const selectImageIndex = createSelector(
  selectModalProps,
  props => props.imageIndex
)
