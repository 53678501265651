import { createSelector } from 'reselect'
import { selectPreferences } from 'ducks/currentUser/selectors/preferences/selectPreferences'
import { selectMailboxesById } from 'selectors/mailboxes/selectMailboxesById'
import {
  selectTicketSearchOperatorValueMap,
  selectTicketSearchResultsByQueryId,
} from 'selectors/search/base'
import { mapObject } from 'util/objects'
import { foldersByIdSelector } from './foldersByIdSelector'
import { selectOrderedFolderIds } from './selectOrderedFolderIds'
import { decorate } from './decorate'

export const selectMailboxFoldersById = createSelector(
  foldersByIdSelector,
  selectMailboxesById,
  selectOrderedFolderIds,
  selectPreferences,
  selectTicketSearchOperatorValueMap,
  selectTicketSearchResultsByQueryId,
  (byId, mailboxes, folderIds, preferences, valueMap, searchesById) => {
    const r = {
      ...mapObject(mailbox => {
        return mailbox.folders.map(folderId => {
          return decorate(byId[folderId], mailbox, valueMap, searchesById)
        })
      })(mailboxes),
      [null]: folderIds.map(folderId => {
        return decorate(byId[folderId], null, valueMap, searchesById)
      }),
    }
    return r
  }
)
