/* eslint-disable no-multi-assign */ // ok in reducers
import * as types from 'constants/action_types'
import * as pages from 'constants/pages'
import { keys } from 'util/objects'

const defaultState = {
  moreTicketOptionsOpen: false,
  changeInboxOpen: false,
}
const reducers = {}

const exclusive = newState => () => {
  return {
    ...defaultState,
    ...newState,
  }
}

reducers[types.OPEN_CHANGE_INBOX_MENU] = exclusive({
  changeInboxOpen: true,
})

reducers[types.CLOSE_CHANGE_INBOX_MENU] = state => ({
  ...state,
  changeInboxOpen: false,
})

reducers[types.OPEN_MORE_TICKET_OPTIONS_MENU] = exclusive({
  moreTicketOptionsOpen: true,
})

reducers[types.CLOSE_MORE_TICKET_OPTIONS_MENU] = state => ({
  ...state,
  moreTicketOptionsOpen: false,
})

// close all menus if legacy modal menus are opened
reducers[types.SHOW_MODAL] = () => defaultState

// close menus if you nav to another page (eg with shortcut keys)
keys(pages).map(key => {
  reducers[pages[key]] = () => defaultState
  return true
})

reducers[types.SHOW_MODAL] = () => defaultState

export default function reducer(state = defaultState, action) {
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
