import createCachedSelector from 're-reselect'
import { selectCurrentSortOrder } from 'selectors/app/base'
import {
  selectCurrentTicketSearchQueryId,
  selectTicketSearchOperatorValueMap,
} from 'selectors/search/base'
import { selectCurrentUserSortOrderBySortContext } from 'ducks/currentUser/selectors'
import { selectFoldersById } from 'ducks/folders/selectors/folders'
import {
  DEFAULT_SORT_ORDER,
  NEWEST_BY_CLOSED,
  NEWEST_BY_DELETED,
  NEWEST_BY_SPAM,
} from 'constants/defaults'
import { constructSearchQueryObject } from 'util/search'
import { CLOSED, SPAM } from 'constants/ticket_state_types'
import { buildId, isGid } from 'util/globalId'
import {
  sortOrderContextKeyForQueryId,
  sortOrderContextKeyToSortOptionKeys,
} from 'util/search/sorting'
import {
  hasDeletedQueryFilter,
  hasFolderQueryFilter,
  hasStateQueryFilter,
} from 'util/search/queryObject'

const selectDefaultSortOrderKeyForQueryId = createCachedSelector(
  selectFoldersById,
  selectTicketSearchOperatorValueMap,
  (_, { queryId }) => queryId,
  (foldersById, ticketSearchOperatorValueMap, queryId) => {
    const queryObject = constructSearchQueryObject(
      queryId,
      ticketSearchOperatorValueMap
    )

    const hasDeletedQuery = hasDeletedQueryFilter(queryObject)
    const hasClosedQuery = hasStateQueryFilter(queryObject, CLOSED)
    const hasSpamQuery = hasStateQueryFilter(queryObject, SPAM)

    if (hasDeletedQuery) {
      return NEWEST_BY_DELETED
    }

    if (hasClosedQuery) {
      return NEWEST_BY_CLOSED
    }

    if (hasSpamQuery) {
      return NEWEST_BY_SPAM
    }

    if (hasFolderQueryFilter(queryObject)) {
      const { folder: folders } = queryObject
      for (let i = 0; i < folders.length; i += 1) {
        const fq = folders[i]
        const folderId = isGid(fq) ? fq : buildId('Folder', fq)
        const { defaultSortOrderKey } = foldersById[folderId] || {}

        if (defaultSortOrderKey) return defaultSortOrderKey
      }
    }

    return null
  }
)((_, selectorArgs) => JSON.stringify(selectorArgs || {}))

export const selectSortOrderForSearchQuery = createCachedSelector(
  selectCurrentSortOrder,
  selectCurrentTicketSearchQueryId,
  selectTicketSearchOperatorValueMap,
  selectFoldersById,
  selectCurrentUserSortOrderBySortContext,
  selectDefaultSortOrderKeyForQueryId,
  (_, { queryId }) => queryId,
  (_, { sort: querySort }) => querySort,
  (
    currentSortOrder,
    currentTicketSearchQueryId,
    ticketSearchOperatorValueMap,
    foldersById,
    currentUserSortOrderBySortContext,
    defaultSelectedSortOrder,
    queryId,
    querySort
  ) => {
    const queryIdSortOrderContextKey = sortOrderContextKeyForQueryId(
      queryId,
      foldersById,
      ticketSearchOperatorValueMap
    )

    const queryIdSortOptionKeys = sortOrderContextKeyToSortOptionKeys(
      queryIdSortOrderContextKey
    )

    const sort = querySort || currentSortOrder || DEFAULT_SORT_ORDER

    const isValidSortForQuery =
      !querySort || queryIdSortOptionKeys.includes(sort)

    // user previously selected a sort option with options similar similar to this new query id
    const userSortOrderForContext =
      currentUserSortOrderBySortContext[queryIdSortOrderContextKey]

    if (currentTicketSearchQueryId !== queryId || !isValidSortForQuery) {
      if (defaultSelectedSortOrder) return defaultSelectedSortOrder

      if (userSortOrderForContext) return userSortOrderForContext
    }

    // no default selected order
    // no user selected option yet
    // usually happens on refresh with the sortorder in url
    if (!isValidSortForQuery) {
      return DEFAULT_SORT_ORDER
    }

    return sort
  }
)((_, selectorArgs) => JSON.stringify(selectorArgs || {}))
