// Wrapper around SUI Dropdown that tracks the selected item fired from core SUI
// DD `onChange` events (where you have `selectOnNavigation`).
//
// Note: SUI defines "selected" as the one currently selected by KB shortcut.
// "Active" means the current "chosen" one. So Selected <> Chosen!!
// Confusing AF, I know. Also note, that by default, selectOnNavigation` is set
// which updates `active` to be the currently `selected` item whenever you use
// the keyboard to navigate.
//
import React from 'react'

import { isEmpty } from 'util/arrays'
import debug from 'util/debug'
import { getDisplayName } from 'util/hoc'
import { runOnNextTick, throttle } from 'util/functions'

function withOnChangeTracking(WrappedDropdown) {
  class WithOnChangeTracking extends React.Component {
    state = { selectedValue: null }

    componentDidMount() {
      this.mountTimeout = runOnNextTick(() => {
        // Upon initialization, we default to first item as selected
        const { options } = this.props
        if (!isEmpty(options)) this.saveValue(options[0])
      })
    }

    componentWillUnmount() {
      clearTimeout(this.mountTimeout)
    }
    // Returns the decorated options in local state, if present.
    // Otherwise returns given `options` from props (if present)
    getOptions = () => {
      const { selectedValue } = this.state
      if (!selectedValue) return this.props.options
      return this.withIndicators(this.props.options, selectedValue)
    }

    mountTimeout = null

    saveValue = opt => {
      this.setState({ selectedValue: opt.value })
    }

    withIndicators = (options = [], selectedValue) => {
      return options.map(opt => {
        return {
          ...opt,
          selected: opt.value === selectedValue || opt.selected,
        }
      })
    }

    // Tracks the selected item in the list based on the DD.Item's onChange
    // callback. Decorates local state with a copy of `props.options` that
    // includes an indicator for the currently active option
    handleChange = throttle((event, data) => {
      if (event && event.persist) event.persist()
      const { onChange } = this.props
      debug('DD: withOnChangeTracking: handleChange', {
        data,
        onChangeCallback: onChange,
      })

      this.saveValue(data)

      // Optional callback. Honours SUI DD onChange contract
      if (onChange) onChange(event, data)
    }, 64)

    render() {
      return (
        <WrappedDropdown
          {...this.props}
          onChange={this.handleChange}
          options={this.getOptions()} // order important. override props.options
        />
      )
    }
  }

  WithOnChangeTracking.displayName = `WithOnChangeTracking(${getDisplayName(
    WrappedDropdown
  )})`

  return WithOnChangeTracking
}

export default withOnChangeTracking
