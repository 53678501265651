import { createSelector } from 'reselect'

import { selectDefaultPinnedSearch } from 'selectors/pinned_searches/withoutMailbox'
import { selectInitialLastSearchQueryId } from 'selectors/search/base'
import { selectPrefersAllMailboxesSectionVisible } from 'ducks/currentUser/selectors/preferences/selectPrefersAllMailboxesSectionVisible'
import { selectPrefersUnifiedInbox } from 'ducks/currentUser/selectors/preferences/selectPrefersUnifiedInbox'
import { selectOrderedFolderIds } from 'selectors/folders/selectOrderedFolderIds'
import { selectKnownMailboxes } from 'selectors/mailboxes/selectKnownMailboxes'

import { constructSearchQueryId, constructSearchQueryObject } from 'util/search'
import { showAllInboxesMailboxInLeftNav } from 'util/mailboxes'

const allOpenSearch = { state: 'open' }

export const selectDefaultTicketSearch = createSelector(
  selectDefaultPinnedSearch,
  selectOrderedFolderIds,
  selectKnownMailboxes,
  selectPrefersUnifiedInbox,
  selectPrefersAllMailboxesSectionVisible,
  selectInitialLastSearchQueryId,
  (
    defaultPinnedSearch,
    folderIds,
    mailboxes,
    prefersUnifiedInbox,
    prefersAllMailboxesSectionVisible,
    initialLastSearchQueryId
  ) => {
    let firstFolderSearch = false
    if (initialLastSearchQueryId) {
      return constructSearchQueryObject(initialLastSearchQueryId)
    } else if (
      prefersUnifiedInbox ||
      showAllInboxesMailboxInLeftNav(
        prefersAllMailboxesSectionVisible,
        mailboxes
      )
    ) {
      firstFolderSearch = folderIds &&
        folderIds.length > 0 && { folder: folderIds[0] }
    } else if (mailboxes && mailboxes[0]) {
      firstFolderSearch = {
        mailbox: mailboxes[0].id,
        folder: mailboxes[0].folders[0],
      }
    }

    return defaultPinnedSearch || firstFolderSearch || allOpenSearch
  }
)

export const selectDefaultTicketSearchId = createSelector(
  selectDefaultTicketSearch,
  defaultSearch => {
    return constructSearchQueryId(defaultSearch)
  }
)
