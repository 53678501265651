// Props Proxy HOC for scrolling using arrow keys.
// Because SUI Search-in-Menu DDs dont have proper keyboard handling...
//
// We track the selected item index, and mark that item as `selected`.
//
// Note: This is different from the core/internal SUI DD ArrowUp/Down handling!
// Here we are just emulating the same selected behaviour that SUI has here.
//
// From their docs selected is defined as:
//
//  "The item currently selected by keyboard shortcut.  This is not the active item."
//
// Meaning `selected` is something that is completely internal to the SUI and
// the options.selected prop is inferred to be entirely controlled by the
// dropdown (as you use arrow keys)
//
// Since we are doing (duplicating) the arrow key handling ourselves, we control
//  this field here (and thus its' default).
//
import React from 'react'
import KeyboardNavigator from 'components/KeyboardNavigator'
import KeyboardHandler from 'components/Lists/KeyboardHandler'
import { getDisplayName } from 'util/hoc'
import { getLength, isEmpty } from 'util/arrays'

function withArrowKeys(WrappedMenu) {
  class WithArrowKeys extends React.Component {
    state = {
      selectedIndex: 0,
      decoratedOptions: [],
    }

    componentDidMount() {
      this.handleSelect(0)
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.options !== this.props.options) {
        this.saveSelected(nextProps.options, 0)
      }
    }

    // Returns the decorated options in local state, if present.
    // Otherwise returns given `options` from props
    getOptions = () => {
      const { decoratedOptions } = this.state
      if (!isEmpty(decoratedOptions)) return decoratedOptions
      return this.props.options
    }

    /* when user focuses an option (e.g. via keyboard). */
    handleSelect = selectedIndex => {
      this.saveSelected(this.props.options, selectedIndex)
    }

    saveSelected = (options, selectedIndex = 0) => {
      this.setState({
        selectedIndex,
        decoratedOptions: this.withSelected(options, selectedIndex),
      })
    }

    // decorates the currently selected option
    withSelected = (options = [], selectedIndex) => {
      return options.map((opt, index) => {
        return {
          ...opt,
          selected: index === selectedIndex,
        }
      })
    }

    render() {
      const props = this.props
      const { selectedIndex } = this.state

      return (
        <React.Fragment>
          {props.open && (
            // We only want keyboard handling if the dropdown is open
            <React.Fragment>
              <KeyboardNavigator.Focus />
              <KeyboardHandler
                count={getLength(this.props.options)}
                focusedIndex={selectedIndex}
                onDown={this.handleSelect}
                onUp={this.handleSelect}
              />
            </React.Fragment>
          )}
          <WrappedMenu
            {...props}
            options={this.getOptions()}
            selectedIndex={selectedIndex}
          />
        </React.Fragment>
      )
    }
  }

  WithArrowKeys.displayName = `WithArrowKeys(${getDisplayName(WrappedMenu)})`

  return WithArrowKeys
}

export default withArrowKeys
