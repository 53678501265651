/* global tinymce */

import { convertWhitespacesToHTMLEntities, humanFileSize } from 'util/strings'
import { EDITOR_MAX_INLINE_IMAGE_SIZE, convertDataUrlToFile } from 'util/file'

tinymce.PluginManager.add('groove-paste-cleanup', editor => {
  let lastPastePlainText = null
  let lastWasPastePlainText = null
  let lastPasteWasFiles = null
  let uploadAttachmentsFromEditorPaste = () => {}

  editor.on('init', () => {
    uploadAttachmentsFromEditorPaste = editor.getParam(
      'uploadAttachmentsFromEditorPaste'
    )
  })

  // this is the native "paste" event
  editor.on('Paste', e => {
    lastWasPastePlainText = true
    lastPastePlainText = null
    lastPasteWasFiles = false

    const types = e && e.clipboardData && e.clipboardData.types
    if (types.length === 1 && types[0] === 'Files') {
      lastPasteWasFiles = true
      return
    }
    // detect if paste was html. If not, we will use plain text
    if (
      e &&
      e.clipboardData &&
      e.clipboardData.types &&
      e.clipboardData.getData
    ) {
      if (
        (types instanceof DOMStringList && types.contains('text/html')) ||
        (types.indexOf && types.indexOf('text/html') !== -1)
      ) {
        lastWasPastePlainText = false
      }
    }
    const text = (e.clipboardData || window.clipboardData).getData('text')
    lastPastePlainText = text
  })

  // this is the paste plugin event
  editor.on('PastePreProcess', e => {
    if (lastPasteWasFiles) return

    if (!lastWasPastePlainText && e.content.startsWith('<img')) {
      // if base64 dataUrl image and exceeds max allowed byte size,
      // prevent inline embedding and attach image instead. Just like drag and drop
      const template = document.createElement('img')
      template.innerHTML = e.content.trim()
      const {
        firstChild: { src = '' },
      } = template
      if (src.startsWith('data:image')) {
        const indexOfSplit = src.indexOf(',')
        if (indexOfSplit !== -1) {
          const base64ImgStr = src.substr(indexOfSplit + 1)
          const filesize = window.atob(base64ImgStr).length
          if (filesize > EDITOR_MAX_INLINE_IMAGE_SIZE) {
            // prevent image from being embedded in editor
            e.content = ''

            if (uploadAttachmentsFromEditorPaste) {
              app.doShowSnackbar(
                'Added image as attachment because the file was too large'
              )

              const mimeType = src
                .substr(0, indexOfSplit)
                .split(';', 1)[0]
                .replace('data:', '')

              convertDataUrlToFile(src, mimeType).then(file => {
                uploadAttachmentsFromEditorPaste([file])
              })
            } else {
              app.doShowSnackbar(
                `Pasted file exceeds size limit (${humanFileSize(
                  EDITOR_MAX_INLINE_IMAGE_SIZE
                )}). Please add as an attachment`,
                { duration: 5000 }
              )
            }

            return
          }
        }
      }
    }

    // if last paste was plaintext, make it the content and bail out
    // we force plain text, because the paste plugin loses line breaks
    // when gets a plain text object
    if (lastWasPastePlainText) {
      const rootBlockElementName = editor.settings.forced_root_block || 'div'
      const emptyLine = `<${rootBlockElementName}>&nbsp;</${rootBlockElementName}>`
      let firstLine = true
      const lines = tinymce.html.Entities.encodeNamed(lastPastePlainText)
        .split('\n')
        // (pnagy) Here at first we convert the trailing
        // spaces and tabs to HTML entitites to preserve formatting
        .map(line => line.replace(/^\s+/, convertWhitespacesToHTMLEntities))
        .map(line => {
          let transformedLine
          if (line === '') {
            // NOTE (jscheel): Because of our split call, empty lines now look
            // like empty strings in the array. We need to transform them to
            // look like what tiny would have made with hard breaks (not BRs).
            transformedLine = emptyLine
          } else if (firstLine) {
            // NOTE (jscheel): For the first line, we want to let tiny figure out
            // what to do about merging it with the existing text.
            transformedLine = line
          } else {
            // NOTE (jscheel): All successive lines should be wrapped in the block
            // root level element, instead of using soft BR breaks.
            transformedLine = `<${rootBlockElementName}>${line}</${rootBlockElementName}>`
          }
          firstLine = false
          return transformedLine
        })
      if (lines.length > 1 && lines[lines.length - 1] === emptyLine) {
        // NOTE (jscheel): Clean up last line break to account for tiny inserting
        // a line break at the end of the paste. Also fixes pasted url unfurling
        // for some unknown reason.
        lines.pop()
      }
      e.content = lines.join('')
      lastWasPastePlainText = false
      return
    }
  })

  return {
    getMetadata() {
      return {
        name: 'Groove pre-paste cleanup',
      }
    },
  }
})

export default {}
