import { createSelector } from 'reselect'
import { selectCurrentUserIsAdminOrOwner } from 'ducks/currentUser/selectors/base'
import { selectIsTestAccount } from 'selectors/account'
import {
  selectIsInboxBootstrapped,
  isBoostrappedSelector,
} from 'selectors/app/base'
import {
  selectCurrentOpenDrawerResourceId,
  selectIsChannelOnboardingDrawerOpen,
} from 'ducks/drawers/selectors'
import { selectMailboxIds } from './mailboxes/selectMailboxIds'
import { selectKnownMailboxes } from './mailboxes/selectKnownMailboxes'
import { selectMailboxByStates } from './mailboxes/selectMailboxByStates'
import { selectIsCurrentDemoMailbox } from './mailboxes/selectIsCurrentDemoMailbox'
import { selectCurrentMailboxId } from './mailboxes/selectCurrentMailboxId'
import { selectInaccessibleMailboxes } from './mailboxes/selectInaccessibleMailboxes'

const selectMailboxCountExclDemo = createSelector(
  selectKnownMailboxes,
  selectIsTestAccount,
  (mailboxes, isTestAccount) => {
    return (mailboxes || []).filter(
      mailbox => isTestAccount || mailbox.state !== 'demo'
    ).length
  }
)

const selectInaccessibleMailboxCountExclDemo = createSelector(
  selectInaccessibleMailboxes,
  selectIsTestAccount,
  (mailboxes, isTestAccount) => {
    return (mailboxes || []).filter(
      mailbox => isTestAccount || mailbox.state !== 'demo'
    ).length
  }
)

const selectMailboxCountExclDemoAndUnconfirmed = createSelector(
  selectKnownMailboxes,
  selectIsTestAccount,
  (mailboxes, isTestAccount) => {
    return (mailboxes || []).filter(
      mailbox =>
        isTestAccount ||
        (mailbox.state !== 'demo' && mailbox.state !== 'unconfirmed')
    ).length
  }
)

export const selectHasRealMailboxes = createSelector(
  isBoostrappedSelector,
  selectMailboxCountExclDemo,
  selectInaccessibleMailboxCountExclDemo,
  (bootstrapped, count, inaccessibleCount) => {
    const totalCount = count + inaccessibleCount
    return bootstrapped && totalCount > 0
  }
)

export const selectHasRealConfirmedMailboxes = createSelector(
  isBoostrappedSelector,
  selectMailboxCountExclDemoAndUnconfirmed,
  (bootstrapped, count) => bootstrapped && count > 0
)

export const selectShowInboxesEmptyPage = createSelector(
  selectIsInboxBootstrapped,
  selectMailboxIds,
  selectCurrentMailboxId,
  selectCurrentUserIsAdminOrOwner,
  selectIsChannelOnboardingDrawerOpen,
  selectCurrentOpenDrawerResourceId,
  state => state,
  (
    bootstrapped,
    mailboxIds,
    currentMailboxId,
    isManager,
    isChannelOnboardingDrawerOpen,
    currentOpenDrawerResourceId,
    state
  ) => {
    if (!bootstrapped || !isManager) return false
    const mailboxesCount = mailboxIds.length
    const hasActiveMailbox = selectMailboxByStates(state, [
      'active',
      'confirmed',
    ]).length

    const isCreatingFirstMailbox =
      mailboxesCount === 1 &&
      !currentMailboxId &&
      isChannelOnboardingDrawerOpen &&
      (currentOpenDrawerResourceId === mailboxIds[0] ||
        currentOpenDrawerResourceId === 'new')

    return mailboxesCount === 0 || (isCreatingFirstMailbox && !hasActiveMailbox)
  }
)

export const selectShowDemoInboxCard = createSelector(
  selectIsTestAccount,
  selectCurrentUserIsAdminOrOwner,
  selectIsCurrentDemoMailbox,
  selectKnownMailboxes,
  (isTestAccount, isManager, isDemoMailbox, mailboxes) => {
    // HACK (jscheel): We have a 5 year old bug with the way we store the mailbox
    // id in localStorage. Sometimes the current mailbox is null when it really isn't.
    // This doesn't happen often, but accounting for the issue here.
    const onlyDemoMailbox =
      mailboxes.length === 1 && mailboxes[0].state === 'demo'

    // HACK (jscheel): Hiding for fullstack tests because nightwatch is terrible.
    return !isTestAccount && isManager && isDemoMailbox && onlyDemoMailbox
  }
)
