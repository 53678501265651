// Appends an animated ellipsis after the content
//
import styled from '@emotion/styled'

const AppendEllipsis = styled('div')`
  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 500ms infinite;
    content: '\\2026';
    width: 0px;
  }

  @keyframes ellipsis {
    to {
      width: 14px;
    }
  }

  @-webkit-keyframes ellipsis {
    to {
      width: 14px;
    }
  }
`

export default AppendEllipsis
