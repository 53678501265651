import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'
import Assignment from 'inbox/assignment'
import {
  selectAgentsById,
  selectAgentsByIdIncludingArchived,
} from 'selectors/agents/base'
import { selectGroupsById } from 'selectors/app'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { emptyObj } from 'util/objects'
import { createBasicSelector } from 'util/redux'
import { selectCurrentTicket } from './current/selectCurrentTicket'
import { selectCurrentTicketUndecorated } from './current/selectCurrentTicketUndecorated'
import { selectTicketById } from './byId/selectTicketById'

const selectCurrentTicketAssignee = createSelector(
  selectCurrentTicketUndecorated,
  ticket => (ticket && ticket.assignee) || emptyObj
)

const selectCurrentTicketAssigneeId = createSelector(
  selectCurrentTicketAssignee,
  assignee => assignee.id
)

export const selectCurrentTicketAssignedGroupId = createSelector(
  selectCurrentTicketUndecorated,
  ticket => ticket && ticket.assigned_group_id
)

const selectCurrentTicketAssignedAgent = createSelector(
  selectCurrentTicketAssigneeId,
  selectAgentsById,
  (assigneeId, agentsById) => agentsById[assigneeId]
)

const selectCurrentTicketAssignedGroup = createSelector(
  selectCurrentTicketAssignedGroupId,
  selectGroupsById,
  (groupId, groupsById) => (groupId ? groupsById[groupId] : undefined)
)

// This style of selector avoids a huge decorated ticket object, and encourages
// components to use more specific selectors. This method improves our ability
// to find performance/rerendering issues; individual selectors misbehaving are
// easier to debug than a huge ticket decorator.
export const selectCurrentTicketAssignment = createSelector(
  selectCurrentTicketAssignedAgent,
  selectCurrentTicketAssignedGroup,
  selectAgentsById,
  selectCurrentUser,
  (agent, group, agentsById, currentUser) => {
    return Assignment.build(agent, group, currentUser, agentsById)
  }
)

export const selectAssignedAgent = createSelector(
  selectCurrentTicket,
  selectAgentsByIdIncludingArchived,
  (ticket, agents) => {
    if (!ticket) return null
    if (!ticket.assignee) return null
    return agents[ticket.assignee.id]
  }
)

export const selectAssignedGroup = createSelector(
  selectCurrentTicket,
  selectGroupsById,
  (ticket, groups) => {
    if (ticket === null) return null
    return groups[ticket.assigned_group_id] || null
  }
)

export const selectTicketAssigneeByTicketId = createCachedSelector(
  selectTicketById,
  ticket => ticket.assignee || emptyObj
)((_state, ticketId) => ticketId || 'unknown')

export const selectTicketAssigneeIdByTicketId = createCachedSelector(
  selectTicketAssigneeByTicketId,
  assignee => assignee.id
)((_state, ticketId) => ticketId || 'unknown')

export const selectCurrentTicketAssignedAgentByTicketId = createCachedSelector(
  selectTicketAssigneeIdByTicketId,
  selectAgentsById,
  (assigneeId, agentsById) => agentsById[assigneeId]
)((_state, ticketId) => ticketId || 'unknown')

export const selectTicketAssignedGroupIdByTicketId = createCachedSelector(
  selectTicketById,
  ticket => ticket.assigned_group_id
)((_state, ticketId) => ticketId || 'unknown')

export const selectTicketAssignmentByTicketId = createCachedSelector(
  selectCurrentTicketAssignedAgentByTicketId,
  selectTicketAssignedGroupIdByTicketId,
  selectAgentsById,
  selectCurrentUser,
  (agent, group, agentsById, currentUser) => {
    return Assignment.build(agent, group, currentUser, agentsById)
  }
)((_state, ticketId) => ticketId || 'unknown')

export const selectReassignTicketOnReply = state =>
  state.tickets.reassignTicketOnReply

export const selectReassignTicketOnNote = state =>
  state.tickets.reassignTicketOnNote

export const selectReassignTicketByType = createBasicSelector(state => {
  return {
    note: state.tickets.reassignTicketOnNote,
    reply: state.tickets.reassignTicketOnReply,
  }
})
