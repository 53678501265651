import PropTypes from 'prop-types'
import { createTypeElement, createPropTypes } from './util'

const Text = props => createTypeElement('Text', props)

Text.propTypes = createPropTypes({
  size: PropTypes.oneOf(['large', 'small', 'tiny']),
})

Text.defaultProps = {
  as: 'span',
  size: 'large',
}

export default Text
