import React from 'react'

import styles from './styles.less'

export default function MonthTitle(date) {
  return (
    <div className={styles.DatePickerCalendarMonthTitle}>
      <span className={styles.DatePickerCalendarMonthTitle_month}>
        {date.toLocaleString('en-us', { month: 'long' })}
      </span>
      <span className={styles.DatePickerCalendarMonthTitle_year}>
        {date.getFullYear()}
      </span>
    </div>
  )
}
