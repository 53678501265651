/* global tinymce */
import Bugsnag from '@bugsnag/js'

import Cleaner from './cleaner'

tinymce.PluginManager.add('groove-before-set-content-cleanup', editor => {
  const configValidStyles = editor.settings?.valid_styles
  let parsedValidStyles

  if (configValidStyles) {
    parsedValidStyles = {}
    Object.keys(configValidStyles).forEach(tagName => {
      parsedValidStyles[tagName] = configValidStyles[tagName]
        .split(',')
        .map(styleName => {
          return styleName.trim()
        })
    })
  }

  /**
   * Fired before the content is parsed and rendered in the editor.
   * https://www.tiny.cloud/docs/advanced/events/#editorcoreevents
   */
  editor.on('BeforeSetContent', e => {
    const { content, format } = e

    // don't wanna open the floodgates just yet, bugsnag notify below will inform us if we need to add more
    const isValidContentType = ['html', 'raw'].includes(format)

    if (!isValidContentType) {
      Bugsnag.notify(
        new Error('tinymce-BeforeSetContent: unknown content type'),
        event => {
          event.severity = 'info'
          event.addMetadata('metaData', {
            meta: {
              format,
              content,
            },
          })
        }
      )
    }

    if (!content || !isValidContentType) return

    try {
      const cleaner = new Cleaner({
        remove: ['meta', 'input', 'button', 'style'],
        unwrap: ['span'],
        replace: {
          p: 'div',
        },
        removeClasses: true,
        removeStyles: true,
        validStyles: parsedValidStyles,
      })

      const contentAfterCleanup = cleaner.cleanup(content)

      if (
        contentAfterCleanup &&
        contentAfterCleanup.trim() === '' &&
        content.trim() !== ''
      ) {
        Bugsnag.notify(
          new Error(
            'tinymce-BeforeSetContent-cleaner: content cleared after clean'
          ),
          event => {
            event.severity = 'info'
            event.addMetadata('metaData', {
              meta: {
                format,
                content,
                contentAfterCleanup,
              },
            })
          }
        )
      }

      e.content = contentAfterCleanup
    } catch (err) {
      Bugsnag.notify(err, event => {
        event.severity = 'error'
        event.addMetadata('metaData', {
          meta: {
            format,
            content,
            contentAfterCleanup,
          },
        })
      })
    }
  })

  return {
    getMetadata() {
      return {
        name:
          'Groove cleanup HTML content before it is parsed and rendered in the editor.',
      }
    },
  }
})

export default {}
