import { createSelector } from 'reselect'
import { mapObject } from 'util/objects'
import { _selectById } from './_selectById'
import { _selectCommonWords } from './_selectCommonWords'
import { decorate } from './decorate'

// Our main selector. This returns a denormalized collection of mailboxes
// (keyed by ID), with each mailbox object having all the
// transformation/decorations applied.

export const selectMailboxesById = createSelector(
  _selectById,
  _selectCommonWords,

  (mailboxes, commonWords) => mapObject(decorate(commonWords))(mailboxes)
)
