import { createSelector } from 'reselect'
import { emptyObj } from 'util/objects'
import { selectCurrentUserIsAdminOrOwner } from 'ducks/currentUser/selectors/base'
import {
  toInboxesPage,
  toSettingsProfilePage,
} from 'subapps/settings/actions/redirect'
import { selectIsUnread } from './realtime'

export const selectIsChangeInboxMenuOpen = state =>
  !!state.menus.changeInboxOpen

export const selectIsMoreTicketOptionsMenuOpen = state =>
  !!state.menus.moreTicketOptionsOpen

export const selectMenuNoticeCounts = () => emptyObj

export const selectMenuAlerts = selectIsUnread

export const selectSettingsLandingPageAction = createSelector(
  selectCurrentUserIsAdminOrOwner,
  isAdminOrOwner => (isAdminOrOwner ? toInboxesPage : toSettingsProfilePage)
)
