import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import themeVars from 'ui_theme/site/globals/site.variables'

const headingH5 = () => theme => css`
  font-family: ${themeVars.walsheim};
  font-size: 25px;
  font-weight: ${themeVars.walsheimWeightBold};
  line-height: 1.28;
  letter-spacing: normal;
  color: ${theme.color.monochrome.black};
`

export default function Heading({ children, level, className }) {
  const styles = {
    5: headingH5,
  }

  return (
    <div css={styles[level]} className={className}>
      {children}
    </div>
  )
}

Heading.propTypes = {
  level: PropTypes.number,
}
Heading.defaultProps = {
  level: 1,
}
