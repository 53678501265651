import { createSelector } from 'reselect'
import Link from 'redux-first-router-link'
import {
  selectGroups,
  selectCurrentSearchQueryString,
  selectCurrentSortOrder,
} from 'selectors/app/base'
import { selectAgents, selectCurrentAgent } from 'selectors/agents/base'
import { selectAccountPreferenceSortByLastUnansweredUserMessageAtEnabled } from 'selectors/app/selectAccountPreferences'
import { foldersByIdSelector as selectFoldersById } from 'selectors/folders/foldersByIdSelector'
import { selectLabelsById } from 'selectors/labels/base'
import { selectMailboxes } from 'selectors/mailboxes/selectMailboxes'
import {
  NEWEST,
  OLDEST,
  LONGEST_UNANSWERED,
  NEWEST_BY_DELETED,
  NEWEST_BY_CLOSED,
  NEWEST_BY_SPAM,
} from 'constants/defaults'
import { emptyArr, uniq } from 'util/arrays'
import { getAgentUsername } from 'util/agents'
import { hasProp, values } from 'util/objects'
import {
  constructSearchQueryObject,
  constructSearchQueryId,
  constructSearchQueryString,
  constructMailboxLessSearchQueryId,
  constructEncodedSearchQueryString,
  buildSearchPathFromId,
} from 'util/search'
import {
  byNewest,
  byOldest,
  byLongestUnanswered,
  byNewestDeleted,
  byNewestClosed,
  byNewestSpam,
  sortOrderContextKeyToSortOptionKeys,
  SORT_CONTEXT_KEY_DELIMITER,
} from 'util/search/sorting'
import getFinalSearchQueryPart from 'util/search/getFinalSearchQueryPart'
import isSearchPinnable from 'util/search/isSearchPinnable'
import createCachedSelector from 're-reselect'

function makeSelectTicketSearchOperatorValueMap(property = null) {
  return createSelector(
    selectCurrentAgent,
    selectAgents,
    selectGroups,
    selectMailboxes,
    selectLabelsById,
    selectFoldersById,
    (currentAgent, agents, groups, mailboxes, labelsById, foldersById) => {
      const agentMap =
        agents &&
        Object.assign(
          agents.reduce((newAgents, agent) => {
            const name = getAgentUsername(agent)
            // eslint-disable-next-line no-param-reassign
            newAgents[name] = property ? agent[property] : agent
            return newAgents
          }, {}),
          currentAgent && {
            me: property ? currentAgent[property] : currentAgent,
          }
        )
      return {
        draft: agentMap,
        assignee: agentMap,
        mentions: agentMap,
        assigned_group:
          groups &&
          groups.reduce((newGroups, group) => {
            // eslint-disable-next-line no-param-reassign
            newGroups[group.name] = property ? group[property] : group
            return newGroups
          }, {}),
        mailbox:
          mailboxes &&
          mailboxes.reduce((newMailboxes, mailbox) => {
            // eslint-disable-next-line no-param-reassign
            newMailboxes[mailbox.name] = property ? mailbox[property] : mailbox
            return newMailboxes
          }, {}),
        label:
          labelsById &&
          // perf: we intentionally do the values mapping here and not in the 'base' folders selector.
          values(labelsById).reduce((newLabels, label) => {
            // eslint-disable-next-line no-param-reassign
            newLabels[label.name] = property ? label[property] : label
            return newLabels
          }, {}),
        folder:
          foldersById &&
          // perf: we intentionally do the values mapping here and not in the 'base' folders selector.
          values(foldersById).reduce((newFolders, folder) => {
            // eslint-disable-next-line no-param-reassign
            newFolders[folder.name] = property ? folder[property] : folder
            return newFolders
          }, {}),
      }
    }
  )
}
export const selectTicketSearchOperatorValueMap = makeSelectTicketSearchOperatorValueMap(
  'id'
)
export const selectTicketSearchOperatorFullValueMap = makeSelectTicketSearchOperatorValueMap(
  null
)

export const selectCurrentTicketSearchQueryString = createSelector(
  selectCurrentSearchQueryString,
  queryString => queryString
)

export const selectEncodedSearchQueryStringBuilder = createSelector(
  selectTicketSearchOperatorValueMap,
  queryValueMap => params => {
    const string = constructEncodedSearchQueryString(params, queryValueMap)
    return `/search/${string}`
  }
)

export const selectCurrentTicketSearchQueryObject = createSelector(
  selectCurrentTicketSearchQueryString,
  selectTicketSearchOperatorValueMap,
  (queryString, operatorValueMap) => {
    return constructSearchQueryObject(queryString, operatorValueMap)
  }
)

export const selectCurrentTicketSearchMailboxId = createSelector(
  selectCurrentTicketSearchQueryObject,
  queryObject => (queryObject.mailbox && queryObject.mailbox[0]) || null
)

export const selectCurrentTicketSearchQueryObjectString = createSelector(
  selectCurrentTicketSearchQueryString,
  selectTicketSearchOperatorValueMap,
  (queryString, operatorValueMap) => {
    return constructSearchQueryString(queryString, operatorValueMap)
  }
)

export const selectCurrentTicketSearchQueryId = createSelector(
  selectCurrentTicketSearchQueryObject,
  selectTicketSearchOperatorValueMap,
  (queryObject, valueMap) => {
    return constructSearchQueryId(queryObject, valueMap)
  }
)

export const selectMailboxLessCurrentTicketSearchQueryID = createSelector(
  selectCurrentTicketSearchQueryObject,
  selectTicketSearchOperatorValueMap,
  (queryObject, valueMap) => {
    return constructMailboxLessSearchQueryId(queryObject, valueMap)
  }
)

export const selectTicketSearchResultsByQueryId = state =>
  state.search.byQueryId || {}

export const selectInitialLastSearchQueryId = state =>
  state.search.initialLastSearchQueryId

export const selectCurrentTicketSearch = createSelector(
  selectCurrentTicketSearchQueryId,
  selectTicketSearchResultsByQueryId,
  (queryID, byQueryId) => {
    return byQueryId[queryID]
  }
)

export const selectCurrentTicketSearchSortOrderContextKey = createSelector(
  selectCurrentTicketSearch,
  search =>
    search?.sortOrderContextKey ||
    [NEWEST, OLDEST].join(SORT_CONTEXT_KEY_DELIMITER)
)

export function searchTicketIds(search) {
  if (!search) return null
  const { pages } = search
  if (!pages) return null
  const pageValues = Object.values(pages)
  if (pageValues.length === 0) return []
  return pageValues.reduce((a, b) => a.concat(b))
}

export const selectHasCurrentTicketSearchLoaded = createSelector(
  selectCurrentTicketSearch,
  search => {
    if (!search) return false
    return hasProp(search, 'loaded')
  }
)

export const selectCurrentTicketSearchResultTicketIds = createSelector(
  selectCurrentTicketSearch,
  search => {
    if (!search) return emptyArr
    const sortedIds = search.sortedIds
    // This uniq run is a last-resort save before we start showing duplicates
    // to customers. We're not proud of it, but it's the hero we deserve.
    return sortedIds ? uniq(sortedIds) : emptyArr
  }
)

export const selectCurrentTicketSearchResultTicketCount = createSelector(
  selectCurrentTicketSearch,
  search => {
    if (!search) return 0
    return search.totalCount || 0
  }
)

export const selectCurrentTicketSearchResultFirstTicketId = createSelector(
  selectCurrentTicketSearchResultTicketIds,
  ids => ids && ids[0]
)

// Committed search and uncommitted search is basically the distinction between
// the search that the user entered and the search that we've amended with a
// suggestion. As the user keys or mouses through suggestions we ammend the
// search input, this is the uncommitted search query.
// The committed search query represents what the user has committed to ie if
// you select an autosuggest item or type something manually it goes into the
// committed query.
export function selectCurrentCommittedTicketSearchQueryString(state) {
  const { committedSearchQueryString } = state.search
  if (committedSearchQueryString || committedSearchQueryString === '') {
    return committedSearchQueryString
  }
  return null
}

export const selectDisplayedSearchQueryString = createSelector(
  selectCurrentCommittedTicketSearchQueryString,
  selectCurrentSearchQueryString,
  (committed, submitted) => {
    if (committed || committed === '') return committed
    if (submitted || submitted === '') return submitted
    return ''
  }
)

export const selectCurrentCommittedTicketSearchQueryFinalPart = createSelector(
  selectCurrentCommittedTicketSearchQueryString,
  queryString => {
    if (!queryString && queryString !== '') return null
    return getFinalSearchQueryPart(queryString)
  }
)

export function selectCurrentTicketSearchSelectionStart(state) {
  const { selectionStart } = state.search
  if (selectionStart || selectionStart === 0) {
    return selectionStart
  }
  return null
}

export const selectCurrentCommittedTicketSearchQueryCurrentPart = state =>
  state.search.currentPart

export const selectIsListSearchBoxFocused = state =>
  state.search.listSearchBoxFocused

export const selectIsCurrentTicketSearchPinnable = createSelector(
  selectCurrentTicketSearchQueryObject,
  isSearchPinnable
)

export const selectCurrentTicketSearchMailboxes = createSelector(
  selectCurrentTicketSearchQueryObject,
  queryObject => queryObject.mailbox || emptyArr
)

export const selectSortOptions = createSelector(
  selectCurrentSortOrder,
  selectCurrentSearchQueryString,
  selectCurrentTicketSearchSortOrderContextKey,
  selectAccountPreferenceSortByLastUnansweredUserMessageAtEnabled,
  (
    sortOrder,
    query,
    currentSearchSortOrderContextKey,
    sortByLastUnansweredUserMessageAtEnabled
  ) => {
    const options = []
    const contextOptions = sortOrderContextKeyToSortOptionKeys(
      currentSearchSortOrderContextKey
    )

    contextOptions.forEach(orderKey => {
      if (NEWEST_BY_CLOSED === orderKey) {
        options.push({
          active: byNewestClosed(sortOrder),
          as: Link,
          key: orderKey,
          text: 'Recently Closed',
          selectedOptionText: 'Recently Closed',
          to: buildSearchPathFromId({ sortOrder: orderKey })(query),
          value: orderKey,
        })
      }

      if (NEWEST_BY_SPAM === orderKey) {
        options.push({
          active: byNewestSpam(sortOrder),
          as: Link,
          key: orderKey,
          text: 'Recently Spammed',
          selectedOptionText: 'Recently Spammed',
          to: buildSearchPathFromId({ sortOrder: orderKey })(query),
          value: orderKey,
        })
      }

      if (NEWEST_BY_DELETED === orderKey) {
        options.push({
          active: byNewestDeleted(sortOrder),
          as: Link,
          key: orderKey,
          text: 'Recently Trashed',
          selectedOptionText: 'Recently Trashed',
          to: buildSearchPathFromId({ sortOrder: orderKey })(query),
          value: orderKey,
        })
      }

      if (
        LONGEST_UNANSWERED === orderKey &&
        sortByLastUnansweredUserMessageAtEnabled
      ) {
        options.push({
          active: byLongestUnanswered(sortOrder),
          as: Link,
          key: orderKey,
          text: 'Waiting Longest',
          selectedOptionText: 'Waiting Longest',
          to: buildSearchPathFromId({ sortOrder: orderKey })(query),
          value: orderKey,
        })
      }

      if (NEWEST === orderKey) {
        options.push({
          active: byNewest(sortOrder),
          as: Link,
          key: orderKey,
          text: 'Newest',
          selectedOptionText: 'Newest first',
          to: buildSearchPathFromId({ sortOrder: orderKey })(query),
          value: orderKey,
        })
      }

      if (OLDEST === orderKey) {
        options.push({
          active: byOldest(sortOrder),
          as: Link,
          key: orderKey,
          text: 'Oldest',
          selectedOptionText: 'Oldest first',
          to: buildSearchPathFromId({ sortOrder: orderKey })(query),
          value: orderKey,
        })
      }
    })

    return options
  }
)

export const selectTicketSearchQueryIdByQueryString = createCachedSelector(
  (_state, queryString) => queryString,
  selectTicketSearchOperatorValueMap,
  (queryString, valueMap) => {
    return constructSearchQueryId(
      constructSearchQueryObject(queryString, valueMap),
      valueMap
    )
  }
)((_state, queryString) => queryString)
