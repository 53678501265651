import { selectGroupsById } from 'selectors/app/base'
import { selectRawAgentsById } from 'selectors/agents/base'
import { emptyObj } from 'util/objects'

export function selectTicketAssignmentLabel(state, ticket) {
  const agent = selectTicketAssignee(state, ticket)
  if (agent) return agent.label
  const group = selectTicketAssignedGroup(state, ticket)
  if (group) return group.name
  return null
}

export function selectTicketAssignee(state, ticket) {
  const assigneeId = ticket.assignee && ticket.assignee.id
  if (!assigneeId) return null
  const agentsById = selectRawAgentsById(state)
  return agentsById[assigneeId] || emptyObj
}

export function selectTicketAssignedGroup(state, ticket) {
  const groupId = ticket.assigned_group_id
  if (!groupId) return null
  const groupsById = selectGroupsById(state)
  return groupsById[groupId] || emptyObj
}
