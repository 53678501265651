/* eslint-disable no-multi-assign */
import * as types from 'constants/action_types'
import { camelize, downcase } from 'util/strings'
import {
  INSERT_CANNED_REPLY_REQUEST,
  INSERT_CANNED_REPLY,
} from 'ducks/cannedReplies/types'
import {
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_FAIL,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAIL,
} from 'ducks/customers/actionTypes'
import {
  FETCH_MERGEABLE_TICKETS_STARTED,
  FETCH_MERGEABLE_TICKETS_SUCCESS,
} from 'ducks/merging/types'

const reducers = {}

const defaultState = {
  fetchingStatuses: {},
}

const updateFetchingStatus = (state, key, newStatus) => {
  return {
    ...state,
    fetchingStatuses: { ...state.fetchingStatuses, [key]: newStatus },
  }
}

// Creates a standard status-tracking reducer. The X_REQUEST action sets the
// fetchX status to true, and the _SUCCESS/_FAIL actions set it to false.
// Reduces boilerplate.
//
// e.g. Suppling an `actionPrefix` of FETCH_TICKET_ACTIONS maps to
// `app.statuses.fetchTicketActions`
//
// NOTE: has side effect on `reducers` object in current scope.
function trackFetchingStatus(actionPrefix) {
  const fetchLabel = camelize(downcase(actionPrefix))

  reducers[types[`${actionPrefix}_REQUEST`]] = state =>
    updateFetchingStatus(state, fetchLabel, true)

  reducers[types[`${actionPrefix}_SUCCESS`]] = reducers[
    types[`${actionPrefix}_FAIL`]
  ] = state => updateFetchingStatus(state, fetchLabel, false)

  return true
}

reducers[types.MARK_FETCHING_STATUS] = (state, { data: { action, status } }) =>
  updateFetchingStatus(state, action, status)

reducers[types.CURRENT_FOLDER_WITH_TICKETS_NEXT_PAGE_REQUEST] = state =>
  updateFetchingStatus(state, 'fetchCurrentFolderWithTicketsPage', true)

reducers[types.CURRENT_FOLDER_WITH_TICKETS_NEXT_PAGE_COMPLETE] = state =>
  updateFetchingStatus(state, 'fetchCurrentFolderWithTicketsPage', false)

reducers[types.FETCH_TICKET_AGGREGATION_COUNTS_REQUEST] = (
  state,
  { data: { searchQueryString } }
) =>
  updateFetchingStatus(
    state,
    `ticketAggregationCounts-${searchQueryString}`,
    true
  )

reducers[types.FETCH_TICKET_AGGREGATION_COUNTS_SUCCESS] = reducers[
  types.FETCH_TICKET_AGGREGATION_COUNTS_FAIL
] = (state, { data: { searchQueryString } }) =>
  updateFetchingStatus(
    state,
    `ticketAggregationCounts-${searchQueryString}`,
    false
  )

reducers[types.FETCH_FOLDER_COUNTS_REQUEST] = state =>
  updateFetchingStatus(state, 'fetchFolderCounts', true)

reducers[types.FETCH_FOLDER_COUNTS_SUCCESS] = state =>
  updateFetchingStatus(state, 'fetchFolderCounts', false)

reducers[types.SEARCH_USER_REQUEST] = state =>
  updateFetchingStatus(state, 'searchUsers', true)

reducers[types.FETCH_FOLDER_WITH_TICKETS_REQUEST] = state =>
  updateFetchingStatus(state, 'fetchCurrentFolderWithTickets', true)

reducers[types.FETCH_TOP_LABELS_REQUEST] = state =>
  updateFetchingStatus(state, 'fetchTopLabelsForSelection', true)

reducers[types.FETCH_TOP_LABELS_SUCCESS] = state =>
  updateFetchingStatus(state, 'fetchTopLabelsForSelection', false)

reducers[types.SEARCH_REQUEST] = state =>
  updateFetchingStatus(state, 'fetchTicketSearch', true)

reducers[types.SEARCH_COMPLETE] = state =>
  updateFetchingStatus(state, 'fetchTicketSearch', false)

reducers[types.SEARCH_BY_TICKET_NUMBER_REQUEST] = state =>
  updateFetchingStatus(state, 'fetchTicketSearch', true)

reducers[types.SEARCH_BY_TICKET_NUMBER_SUCCESS] = state =>
  updateFetchingStatus(state, 'fetchTicketSearch', false)

reducers[types.MERGE_SEARCH_REQUEST] = reducers[
  types.MERGE_SEARCH_STARTED
] = state => updateFetchingStatus(state, 'fetchMergeSearch', true)

reducers[types.MERGE_SEARCH_ERROR] = reducers[
  types.MERGE_SEARCH_SUCCESS
] = reducers[types.MERGE_SEARCH_FAILED] = state =>
  updateFetchingStatus(state, 'fetchMergeSearch', false)

reducers[types.FETCH_TICKET_REQUEST] = state =>
  updateFetchingStatus(state, 'fetchTicket', true)

reducers[types.FETCH_TICKET_SUCCESS] = state =>
  updateFetchingStatus(state, 'fetchTicket', false)

reducers[types.FETCH_TICKET_FAIL] = state =>
  updateFetchingStatus(state, 'fetchTicket', false)

reducers[FETCH_MERGEABLE_TICKETS_STARTED] = state =>
  updateFetchingStatus(state, 'fetchMergeableTickets', true)

reducers[FETCH_MERGEABLE_TICKETS_SUCCESS] = state =>
  updateFetchingStatus(state, 'fetchMergeableTickets', false)

reducers[types.FETCH_TICKET_SNIPPETS_REQUEST] = state =>
  updateFetchingStatus(state, 'fetchSnippets', true)

reducers[types.FETCH_TICKET_SNIPPETS_SUCCESS] = reducers[
  types.FETCH_TICKET_SNIPPETS_FAIL
] = state => updateFetchingStatus(state, 'fetchSnippets', false)

reducers[types.FETCH_OPTIMISTIC_MERGE_TICKETS_REQUEST] = state =>
  updateFetchingStatus(state, 'fetchOptimisticMergeTickets', true)

reducers[types.FETCH_OPTIMISTIC_MERGE_TICKETS_SUCCESS] = state =>
  updateFetchingStatus(state, 'fetchOptimisticMergeTickets', false)

reducers[types.PIN_SEARCH_REQUEST] = state =>
  updateFetchingStatus(state, 'pinSearch', true)

reducers[INSERT_CANNED_REPLY_REQUEST] = state =>
  updateFetchingStatus(state, 'fetchCannedReplyForInsert', true)

reducers[INSERT_CANNED_REPLY] = state =>
  updateFetchingStatus(state, 'fetchCannedReplyForInsert', false)

reducers[types.SEARCHES_REQUEST] = state =>
  updateFetchingStatus(state, 'starredSearchCounts', true)

reducers[types.SEARCHES_SUCCESS] = reducers[types.SEARCHES_FAIL] = state =>
  updateFetchingStatus(state, 'starredSearchCounts', false)

reducers[types.TICKET_MATCH_START] = state =>
  updateFetchingStatus(state, 'deriveFolderFromTicket', true)

reducers[types.TICKET_MATCHES_SEARCH] = reducers[
  types.TICKET_MATCHES_FOLDER
] = state => updateFetchingStatus(state, 'deriveFolderFromTicket', false)

reducers[UPDATE_CUSTOMER_REQUEST] = state =>
  updateFetchingStatus(state, 'savingCustomer', true)

reducers[UPDATE_CUSTOMER_SUCCESS] = reducers[UPDATE_CUSTOMER_FAIL] = state =>
  updateFetchingStatus(state, 'savingCustomer', false)

reducers[types.FETCH_CUSTOMERS_REQUEST] = state =>
  updateFetchingStatus(state, 'fetchCustomers', true)

reducers[FETCH_CUSTOMERS_SUCCESS] = reducers[FETCH_CUSTOMERS_FAIL] = state =>
  updateFetchingStatus(state, 'fetchCustomers', false)

reducers[types.FETCH_LABELS_BY_NAME_REQUEST] = state =>
  updateFetchingStatus(state, 'fetchLabelsByName', true)

reducers[types.FETCH_LABELS_BY_NAME_SUCCESS] = reducers[
  types.FETCH_LABELS_BY_NAME_FAIL
] = state => updateFetchingStatus(state, 'fetchLabelsByName', false)

trackFetchingStatus('FETCH_TICKET_ACTIONS')

reducers[types.CLEAR_FETCHED_STATUSES] = (state, action) => {
  const regex = action.data.regex
  const newState = Object.assign({}, state)
  newState.fetchingStatuses = Object.assign({}, newState.fetchingStatuses)

  Object.keys(newState.fetchingStatuses).forEach(key => {
    if (
      (!regex || key.match(regex)) &&
      newState.fetchingStatuses[key] === false
    ) {
      delete newState.fetchingStatuses[key]
    }
  })
  return newState
}

reducers[types.FETCH_FOLDER_WITH_TICKETS_SUCCESS] = state => {
  const newState = updateFetchingStatus(
    state,
    'fetchCurrentFolderWithTickets',
    false
  )
  return Object.assign({}, newState)
}

export default function reducer(state = defaultState, action) {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
