import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import cn from 'classnames'
import { avatarURL, initials as getInitials } from 'util/actors'
import presenceTypes from 'components/Presence/presenceTypes'

export const Avatar = ({
  actor,
  children,
  className,
  avatarImageClassName,
  style,
  user, // Deprecated
  size, // dont render on DOM
  initialsColor, // dont render on DOM
  initialsBackground, // dont render on DOM
  initialsFontSize, // dont render on DOM
  radius, // dont render on DOM
  url: propUrl,
  presence,
  ...rest
}) => {
  const url = propUrl || avatarURL(actor || user)
  const initials = getInitials(actor || user)
  const { className: presenceTypeClassName } = presenceTypes[presence] || {}

  return (
    <div
      className={cn(className, presenceTypeClassName, {
        presence: !!presenceTypeClassName,
      })}
    >
      <div
        className={cn('avatarImage', avatarImageClassName)}
        style={{
          ...(url && {
            backgroundImage: `url(${url})`,
          }),
          ...style,
        }}
        {...rest}
      >
        {!url && initials}
        {children}
      </div>
    </div>
  )
}

Avatar.propTypes = {
  // User object.
  // eslint-disable-next-line react/forbid-prop-types
  actor: PropTypes.object.isRequired,

  // Overrides color of initials
  initialsColor: PropTypes.string,

  // Overrides background color of initials
  initialsBackground: PropTypes.string,

  // Overrides font size of initials
  initialsFontSize: PropTypes.number,

  // Overrides radius of Avatar (image and initials)
  radius: PropTypes.string,

  // Size of the avatar (in px). Otherwise defaults to 100% HxW.
  // TODO As we standardize, we should define named sizes e.g. 'small', 'medium'
  size: PropTypes.number,

  // live chat online presence
  presence: PropTypes.string,
}

Avatar.defaultProps = {
  initialsColor: undefined,
  initialsBackground: undefined,
  initialsFontSize: undefined,
  radius: undefined,
  size: undefined,
  presence: undefined,
}

Avatar.displayName = 'Avatar'

const avatarStyle = ({
  size,
  radius = '50%',
  initialsColor,
  initialsBackground,
  initialsFontSize,
  url,
  theme,
  initialsBorderRight,
}) => css`
  position: relative;

  &.presence:after {
    content: ' ';
    width: 10px;
    height: 10px;
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    border: solid 1px white;
    display: block;
  }

  &.presence.auto:after {
    background-color: ${theme.color.primary.warning};
  }

  &.presence.online:after {
    background-color: ${theme.color.primary.positive};
  }

  &.presence.away:after {
    background-color: ${theme.color.primary.warning};
  }

  &.presence.offline:after {
    background-color: ${theme.color.primary.negative};
  }

  .avatarImage {
    align-items: center;
    background-color: ${initialsBackground ||
      theme.color.monochrome.mediumDark};
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: ${radius};
    color: ${!url
      ? initialsColor || theme.color.monochrome.white
      : theme.color.monochrome.transparent};
    display: flex;
    font-size: ${initialsFontSize
      ? `${initialsFontSize}px`
      : size < 35 && `${Math.floor(size / 2)}px`};
    font-weight: ${theme.fontWeight.medium};
    height: ${size ? `${size}px` : '100%'};
    justify-content: center;
    text-transform: uppercase;
    width: ${size ? `${size}px` : '100%'};
    border-right: 1px solid
      ${!url && initialsBorderRight
        ? `rgba(${theme.color.monochrome.white_rgba}, 0.3)`
        : theme.color.monochrome.transparent};
  }
`

export default styled(Avatar)`
  ${avatarStyle};
`
