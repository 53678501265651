import cn from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { Flex } from 'shared/ui'
import { Button, Icon } from 'util/ui'

import DateInput from './DateInput'
import styles from './styles.less'
import TimeInput from './TimeInput'

export default class DateTimeInput extends React.PureComponent {
  static propTypes = {
    defaultDate: PropTypes.instanceOf(Date),
    defaultTime: PropTypes.string,
    disabled: PropTypes.bool,
    justDate: PropTypes.bool,
    justTime: PropTypes.bool,
    onChange: PropTypes.func,
    setDefaultValue: PropTypes.func,
    removable: PropTypes.bool,
    removeText: PropTypes.string,
    value: PropTypes.instanceOf(Date),
  }

  static defaultProps = {
    defaultDate: null,
    defaultTime: null,
    disabled: false,
    justDate: false,
    justTime: false,
    onChange: null,
    setDefaultValue: undefined,
    removable: false,
    removeText: 'Clear',
    value: null,
  }

  constructor(props) {
    super(props)
    this.doClear = this.doClear.bind(this)
    this.doFocusTimeInput = this.doFocusTimeInput.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleTimeChange = this.handleTimeChange.bind(this)
    this.timeInputRef = React.createRef()
    this.state = {
      value: props.value,
    }
  }

  componentDidMount() {
    if (this.props.setDefaultValue) {
      this.props.setDefaultValue(
        this.mergeDateAndTime(this.currentDate(), this.currentTime())
      )
    }
  }

  isClearable() {
    return this.currentDate() || this.currentTime() || this.props.value
  }

  currentDate() {
    const {
      props: { value },
      state: { date },
    } = this
    if (value) return value
    return date
  }

  currentTime() {
    const {
      props: { value },
      state: { time },
    } = this
    if (!value) return time
    return `${`0${value.getHours()}`.slice(
      -2
    )}:${`0${value.getMinutes()}`.slice(-2)}`
  }

  currentValue() {
    const {
      props: { value: propsValue },
    } = this
    if (propsValue) return propsValue
    return this.mergeDateAndTime(this.currentDate(), this.currentTime())
  }

  doClear() {
    this.setState({
      date: null,
      dateNullValue: true,
      time: null,
      timeNullValue: true,
    })
    this.triggerOnChange(null, null)
  }

  doFocusTimeInput() {
    if (this.timeInputRef.current) this.timeInputRef.current.doFocus()
  }

  handleDateChange(date) {
    this.handleChange(date, this.currentTime())
  }

  handleTimeChange(time) {
    this.handleChange(this.currentDate(), time)
  }

  handleChange(date, time) {
    const {
      props: { defaultDate, defaultTime },
    } = this
    const defaultDate2 = time && defaultDate
    const defaultTime2 = date && defaultTime
    this.setState({
      date: date || defaultDate2,
      dateNullValue: !(date || defaultDate2),
      time: time || defaultTime2,
      timeNullValue: !(time || defaultTime2),
    })
    this.triggerOnChange(date || defaultDate2, time || defaultTime2)
  }

  mergeDateAndTime(date, time) {
    const {
      props: { justDate, justTime },
    } = this
    if ((!date && !justTime) || (!time && !justDate)) return null
    const value = date ? new Date(date) : new Date()
    const matches = time && time.split(':')
    const hours = matches ? parseInt(matches[0], 10) : 0
    const minutes = matches ? parseInt(matches[1], 10) : 0
    value.setHours(hours)
    value.setMinutes(minutes)
    value.setSeconds(0)
    value.setMilliseconds(0)
    return value
  }

  triggerOnChange(date, time) {
    const {
      props: { onChange },
    } = this
    if (onChange) {
      const newValue = this.mergeDateAndTime(date, time)
      onChange(newValue)
    }
  }
  render() {
    const {
      props: { disabled, justDate, justTime, removable, removeText },
      state: { dateNullValue, timeNullValue },
    } = this
    return (
      <Flex>
        {!justTime && (
          <DateInput
            disabled={disabled}
            onChange={this.handleDateChange}
            onDayClick={this.doFocusTimeInput}
            nullValue={dateNullValue}
            value={this.currentDate()}
          />
        )}
        {!justTime &&
          !justDate && (
            <div className={cn(styles.at, { [styles.disabled]: disabled })}>
              {' '}
              at{' '}
            </div>
          )}
        {!justDate && (
          <TimeInput
            disabled={disabled}
            nullValue={timeNullValue}
            onChange={this.handleTimeChange}
            ref={this.timeInputRef}
            value={this.currentTime()}
          />
        )}
        {removable &&
          (this.currentTime() || this.currentDate()) && (
            <Button
              basic
              className={styles.removeButton}
              disabled={!this.isClearable()}
              onClick={this.doClear}
              primary
            >
              <Icon name="trash alternate outline" />
              {removeText || 'Clear'}
            </Button>
          )}
      </Flex>
    )
  }
}
