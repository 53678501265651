import { Editor } from '@tinymce/tinymce-react'

// ok, black magic time
// tinymce-react runs setContent after init, which we don't need
// because we set the content in the textarea
// so we need the first execution of setContent to do nothing
// or it will override the content from the textarea
Editor.prototype.originalInitEditor = Editor.prototype.initEditor
Editor.prototype.initEditor = function(initEvent, editor) {
  if (this.inline) {
    return Editor.prototype.originalInitEditor.apply(this, [initEvent, editor])
  }
  editor.originalSetContent = editor.setContent
  editor.setContent = function() {
    // First run of this function `editor.setContent` is a noop, the only thing
    // it does is reset setContent back to the original definition so that
    // subsequent runs work normally.
    editor.setContent = editor.originalSetContent
  }
  return Editor.prototype.originalInitEditor.apply(this, [initEvent, editor])
}

// This is a monkey patch to put the initialValue (if present)
// in the textarea, so it renders instantly
Editor.prototype.renderIframe = function() {
  var _this = this
  var value =
    typeof this.props.initialValue === 'string' ? this.props.initialValue : ''
  return React.createElement('textarea', {
    ref: function(elm) {
      return (_this.element = elm)
    },
    style: { visibility: 'hidden' },
    id: this.id,
    defaultValue: value,
  })
}

module.exports = {
  Editor,
}
