const fire = maybeRun('fire')

const getContent = maybeRun('getContent')

const focus = maybeRun('focus')

const insertContent = (content, options) => {
  const editor = getEditor()
  if (!editor) return false
  const result = editor.insertContent(content)
  if (options && options.focus) editor.focus()
  return result
}

const setContent = (content, options) => {
  const editor = getEditor()
  if (!editor) return false
  const result = editor.setContent(content)
  if (options && options.focus) editor.focus()
  return result
}

function getEditor(name = null) {
  const tiny = getTiny()
  if (!tiny) return undefined
  if (name) return tiny.get(name)
  return tiny.activeEditor
}

function getTiny() {
  if (typeof window === 'undefined') return undefined
  return window.tinyMCE
}

function maybeRun(fnName) {
  return (...args) => {
    const editor = getEditor()
    if (!editor) return false
    return editor[fnName](...args)
  }
}

function isTextSelected(editor) {
  return !!(editor.selection && !editor.selection.isCollapsed())
}

export default {
  fire,
  getContent,
  insertContent,
  setContent,
  focus,
  getEditor,
  isTextSelected,
  COMPOSER_ID: 'composer-editor',
}
