import PropTypes from 'prop-types'
import React from 'react'
import { advanceMonth, startOf, add, subtract } from 'util/date'

import DatePickerView from './view'

const DOWN = 'down'
const UP = 'up'
export default class DatePicker extends React.PureComponent {
  static propTypes = {
    displayNumberOfMonths: PropTypes.number,
    className: PropTypes.string,
    initialMonth: PropTypes.instanceOf(Date),
    onDayClick: PropTypes.func,
    onMouseDown: PropTypes.func,
    monthWidth: PropTypes.number,
    selectedDate: PropTypes.instanceOf(Date),
    selectedRangeEnd: PropTypes.instanceOf(Date),
    selectedRangeStart: PropTypes.instanceOf(Date),
    fromMonth: PropTypes.instanceOf(Date),
    toMonth: PropTypes.instanceOf(Date),
    warningFromDate: PropTypes.instanceOf(Date),
  }

  static defaultProps = {
    monthWidth: undefined,
    displayNumberOfMonths: 1,
    className: null,
    initialMonth: new Date(),
    onDayClick: null,
    onMouseDown: null,
    selectedDate: null,
    selectedRangeEnd: null,
    selectedRangeStart: null,
    disabledDays: null,
    fromMonth: null,
    toMonth: null,
    warningFromDate: null,
  }

  constructor(props) {
    super(props)
    const { initialMonth } = this.props
    this.state = {
      month: initialMonth,
      prevNavDisabled: this.shouldDisablePrevNav(initialMonth),
      nextNavDisabled: this.shouldDisableNextNav(initialMonth),
    }
  }

  currentMonth = () => {
    const {
      props: { month: propsMonth },
      state: { month: stateMonth },
    } = this
    const month = propsMonth || stateMonth
    if (this.state.animating === UP) return advanceMonth(month, -1)
    if (this.state.animating === DOWN) return advanceMonth(month, 1)
    return month
  }

  doNextMonth = () => {
    this.doAdvanceMonth(1, true)
  }

  doPrevMonth = () => {
    this.doAdvanceMonth(-1, true)
  }

  doAdvanceMonth = (by, animate) => {
    const {
      props: { month: propsMonth, onMonthChange },
      state: { month: stateMonth },
    } = this
    const month = propsMonth || stateMonth
    const animating = animate && (by === 1 ? UP : DOWN)
    const newMonth = advanceMonth(month, by)
    this.setState({
      prevNavDisabled: this.shouldDisablePrevNav(newMonth),
      nextNavDisabled: this.shouldDisableNextNav(newMonth),
      month: newMonth,
      animating,
    })
    if (onMonthChange) onMonthChange(newMonth)
    clearTimeout(this.animatingTimeout)
    this.animatingTimeout = setTimeout(this.removeAnimating, 250)
  }

  shouldDisablePrevNav = month => {
    const { fromMonth } = this.props
    if (!month || !fromMonth || !(fromMonth instanceof Date)) {
      return false
    }

    const prevMonth = subtract(startOf(month, 'month'), 1, 'month')

    const fromMonthTrunc = startOf(fromMonth, 'month')

    return prevMonth.getTime() < fromMonthTrunc.getTime()
  }

  shouldDisableNextNav = month => {
    const { toMonth } = this.props
    if (!month || !toMonth || !(toMonth instanceof Date)) {
      return false
    }

    const nextMonth = add(startOf(month, 'month'), 1, 'month')

    const toMonthTrunc = startOf(toMonth, 'month')

    return nextMonth.getTime() > toMonthTrunc.getTime()
  }

  removeAnimating = () => {
    this.setState({
      animating: null,
    })
    clearTimeout(this.animatingTimeout)
  }

  render() {
    const {
      className,
      displayNumberOfMonths,
      monthWidth,
      onMouseDown,
      onDayClick,
      selectedDate,
      selectedRangeEnd,
      selectedRangeStart,
      disabledDays,
      warningFromDate,
    } = this.props
    return (
      <DatePickerView
        animating={this.state.animating}
        className={className}
        displayNumberOfMonths={displayNumberOfMonths}
        doNextMonth={this.doNextMonth}
        doPrevMonth={this.doPrevMonth}
        month={this.currentMonth()}
        monthWidth={monthWidth}
        onDayClick={onDayClick}
        onMouseDown={onMouseDown}
        selectedDate={selectedDate}
        selectedRangeEnd={selectedRangeEnd}
        selectedRangeStart={selectedRangeStart}
        disabledDays={disabledDays}
        prevNavDisabled={this.state.prevNavDisabled}
        nextNavDisabled={this.state.nextNavDisabled}
        warningFromDate={warningFromDate}
      />
    )
  }
}
