// HOC that auto-closes (using a given `onDismiss` callback fn) after `dismissAfter`
// milliseconds.
//
// Also calls `onClose` callback when onDismiss is invoked
import React from 'react'
import PropTypes from 'prop-types'

import { getDisplayName } from 'util/hoc'

function withAutoDismiss(WrappedComponent) {
  class WithAutoDismiss extends React.Component {
    componentDidMount() {
      if (this.props.open && this.isAutoDismissable()) this.setupAutoDismiss()
    }

    componentWillReceiveProps(nextProps) {
      if (!this.props.open && nextProps.open && this.isAutoDismissable()) {
        this.setupAutoDismiss()
      }
    }

    componentWillUnmount() {
      clearTimeout(this.timeoutId)
    }

    setupAutoDismiss = () => {
      this.timeoutId = setTimeout(this.handleDismiss, this.props.dismissAfter)
    }

    timeoutId = null

    handleDismiss = () => {
      if (this.isAutoDismissable()) clearTimeout(this.timeoutId)
      const { onClose, onDismiss, id } = this.props
      if (onClose) onClose()
      if (onDismiss) onDismiss(id)
    }

    isAutoDismissable = () => {
      const { onDismiss, dismissAfter } = this.props
      return onDismiss && dismissAfter > 0
    }

    render() {
      const { onDismiss, dismissAfter, ...rest } = this.props
      const handler =
        onDismiss || this.isAutoDismissable() ? this.handleDismiss : undefined
      return <WrappedComponent {...rest} onDismiss={handler} />
    }
  }

  WithAutoDismiss.displayName = `WithAutoDismiss(${getDisplayName(
    WrappedComponent
  )})`

  WithAutoDismiss.propTypes = {
    // Auto close after N milliseconds
    dismissAfter: PropTypes.number,

    // Unique identifier - passed to onClose callback
    id: PropTypes.string,

    // Called when notification closes or is dismissed
    onClose: PropTypes.func,

    // Called when notification closes or is dismissed. If not defined,
    onDismiss: PropTypes.func,
  }

  WithAutoDismiss.defaultProps = {
    dismissAfter: -1, // Never auto dismiss by default
    id: '0',
    onClose: undefined,
    onDismiss: undefined,
  }

  return WithAutoDismiss
}

export default withAutoDismiss
