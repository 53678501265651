import { createSelector } from 'reselect'
import { selectCurrentPath, selectQueryParams } from 'selectors/location'
import { selectCurrentTicketIdFromPage } from './selectCurrentTicketIdFromPage'

export const selectCurrentTicketId = createSelector(
  selectCurrentPath,
  selectQueryParams,
  selectCurrentTicketIdFromPage,
  (path, params, idFromPage) => {
    if (!path) return null
    if (idFromPage) return idFromPage
    if (path.match('/tickets/')) {
      if (path.match(/\/tickets\/new/)) return 'new'
      if (path.match(/\/tickets\/log-conversation/)) return 'new'
      return path.match(/\/tickets\/(\w*)/)[1]
    }
    if (params && params.conversationId) return params.conversationId
    return null
  }
)
