import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import debug from 'util/debug'
import styles from './styles.less'

// TODO (jscheel): Figure out gap for multi-line containers.
// TODO (jscheel): Figure out what to do about align-content and wrap.

const Container = React.forwardRef(
  (
    {
      gap,
      direction,
      align, // TODO (jscheel): DEPRECATED
      justify, // TODO (jscheel): DEPRECATED
      className,
      css,
      horizontalAlign,
      verticalAlign,
      children,
      ...rest
    },
    forwardedRef
  ) => {
    if (align || justify) {
      debug(
        `SpacedItems.Container: \`align\` and \`justify\` props have been
      deprecated! Use \`horizontalAlign\` and \`verticalAlign\` instead.`
      )
    }
    return (
      <div
        className={cn(
          styles.Container,
          styles[gap],
          styles[direction],
          {
            [styles[`align-horizontal-${horizontalAlign}`]]: !!horizontalAlign,
            [styles[`align-vertical-${verticalAlign}`]]: !!verticalAlign,
            [styles[`align-${align}`]]: !!align,
            [styles[`justify-${justify}`]]: !!justify,
          },
          className
        )}
        {...rest}
        ref={forwardedRef}
      >
        {children}
      </div>
    )
  }
)

Container.defaultProps = {
  gap: 'medium',
  direction: 'horizontal',
  horizontalAlign: undefined,
  verticalAlign: undefined,
  align: undefined, // TODO (jscheel): DEPRECATED
  justify: undefined, // TODO (jscheel): DEPRECATED
}

Container.propTypes = {
  /** Size of space between items. Uses standard sizing names from SUI. */
  gap: PropTypes.oneOf([
    'none',
    'nano',
    'mini',
    'tiny',
    'small',
    'medium',
    'large',
    'larger',
    'big',
    'huge',
    'massive',
    'giant',
    'ginormous',
    'auto',
  ]),
  /** Direction of items in container. */
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  /** Horizontal alignment of items */
  horizontalAlign: PropTypes.oneOf(['start', 'end', 'center', 'space-between']),
  /** Vertical alignment of items */
  verticalAlign: PropTypes.oneOf(['start', 'end', 'center', 'space-between']),
  /** DEPRECATED, do not use! */
  align: PropTypes.oneOf(['start', 'end', 'center']),
  /** DEPRECATED, do not use! */
  justify: PropTypes.oneOf(['start', 'end', 'center']),
}

Container.displayName = 'SpacedItems.Container'

export default Container
