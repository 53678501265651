import { createSelector } from 'reselect'

import {
  isAuthenticatedSelector,
  isBoostrappedSelector,
  fetchingStatusesSelector,
  selectCurrentTicketListType,
} from 'selectors/app'
import { selectCurrentPath as currentPathSelector } from 'selectors/location'
import { selectCurrentTicketUndecorated } from 'selectors/tickets/current/selectCurrentTicketUndecorated'

export const shouldSetCurrentFolder = createSelector(
  isAuthenticatedSelector,
  isBoostrappedSelector,
  fetchingStatusesSelector,
  currentPathSelector,
  selectCurrentTicketListType,
  (auth, isBootstrapped, statuses, path, currentListType) => {
    if (!auth) return false
    if (!isBootstrapped) return false // wait til app loaded. Need Folders.
    if (statuses.fetchCurrentFolderWithTickets) return false
    if (!path) return false
    if (!path.match('/folders/')) return false

    // So we are on the folders page
    // We only want to trigger if we havent got a currentList
    if (!currentListType) return true

    return false
  }
)

export const shouldSetCurrentFolderFromTicket = createSelector(
  isAuthenticatedSelector,
  isBoostrappedSelector,
  selectCurrentTicketUndecorated,
  fetchingStatusesSelector,
  currentPathSelector,
  selectCurrentTicketListType,
  (auth, isBootstrapped, ticket, statuses, path, currentListType) => {
    if (!auth) return false
    if (!isBootstrapped) return false // wait til app loaded. Need Folders.
    if (statuses.fetchTicket) return false
    if (statuses.deriveFolderFromTicket) return false
    if (ticket && ticket.loadFailed) return false
    if (!ticket) return false
    if (!ticket.full) return false
    if (!path) return false
    if (!path.match('/tickets/')) return false
    // So we are on the tickets page, we have the ticket and folders loaded up
    // We only want to trigger if we havent got a currentList
    if (!currentListType) return true

    return false
  }
)
