import { createSelector } from 'reselect'

import {
  ASSIGNING,
  DRAFT_ASSIGNING,
  SNOOZING,
  DELETING,
  LABEL_FORM,
  CONFIRM_UNSNOOZE,
  CANNED_REPLIES,
  MAILBOX_CHANGE,
  RECIPIENTS,
  DROPDOWN,
  CUSTOMER,
  TICKET_PREVIEW,
  CHAT_AVAILABILITY,
  CHAT_PRESENCE_OVERVIEW,
  LABELING_EDIT,
} from 'constants/modal_types'
import { emptyObj } from 'util/objects'
import { selectTicketIdFromProps } from 'selectors/tickets/props'

function selectBase(state) {
  return state.modal || emptyObj
}

export const selectModalProps = createSelector(
  selectBase,
  base => base.modalProps || emptyObj
)

export const selectIsModalOpen = createSelector(
  selectBase,
  base => !!base.modalType
)

function makeIsModalTypeSelector(modalType) {
  return createSelector(selectBase, base => base.modalType === modalType)
}

export const selectTicketAssigning = makeIsModalTypeSelector(ASSIGNING)

export const selectDraftAssigning = makeIsModalTypeSelector(DRAFT_ASSIGNING)

export const selectTicketSnoozing = makeIsModalTypeSelector(SNOOZING)

export const selectTicketDeleting = makeIsModalTypeSelector(DELETING)

// MOBILE Edit Label (labelling a ticket) page (er modal). Arguably this should
// be reused by desktop. See also selectIsLabelingModalOpen for Desktop.
export const selectIsEditingLabels = makeIsModalTypeSelector(LABEL_FORM)

// Desktop edit labelings or manage labels modal + create new label pane state.
export const selectIsCreatingALabel = createSelector(
  selectModalProps,
  modalProps => modalProps.isCreatingALabel
)

// Desktop manage modal + edit label pane state.
export const selectIsEditingALabel = createSelector(
  selectModalProps,
  modalProps => modalProps.isEditingALabel
)

// Desktop manage modal + delete label pane state.
export const selectIsDeletingALabel = createSelector(
  selectModalProps,
  modalProps => modalProps.isDeletingALabel
)

// Desktop edit labelings or manage labels modal
export const selectInitialLabelProps = createSelector(
  selectModalProps,
  modalProps => modalProps.label
)

export const selectTicketConfirmingUnsnooze = makeIsModalTypeSelector(
  CONFIRM_UNSNOOZE
)

export const selectIsBrowsingCannedReplies = makeIsModalTypeSelector(
  CANNED_REPLIES
)

export const selectIsChangingMailboxes = makeIsModalTypeSelector(MAILBOX_CHANGE)

export const selectChangingRecipients = makeIsModalTypeSelector(RECIPIENTS)

export const selectIsDropdown = makeIsModalTypeSelector(DROPDOWN)

export const selectDropdownName = createSelector(
  selectModalProps,
  selectIsDropdown,
  (modalProps, isDropdown) => (isDropdown ? modalProps.componentName : '')
)

export const selectModalOffset = createSelector(
  selectModalProps,
  modalProps => modalProps.offset || emptyObj
)

export const selectModalTop = createSelector(
  selectModalOffset,
  offset => offset.top
)

export const selectModalLeft = createSelector(
  selectModalOffset,
  offset => offset.left
)

export const selectIsEditingCustomer = makeIsModalTypeSelector(CUSTOMER)

export const selectIsTicketPreviewModalOpen = makeIsModalTypeSelector(
  TICKET_PREVIEW
)

export function makeIsTicketPreviewOpen() {
  return createSelector(
    selectIsTicketPreviewModalOpen,
    selectModalProps,
    selectTicketIdFromProps,
    (isTicketPreviewModalOpen, modalProps, ticketId) => {
      return (
        isTicketPreviewModalOpen &&
        modalProps.ticketPreviewTicketId === ticketId
      )
    }
  )
}

export const selectIsChatAvailabilityOpen = makeIsModalTypeSelector(
  CHAT_AVAILABILITY
)

export const selectIsPresenceOverviewOpen = makeIsModalTypeSelector(
  CHAT_PRESENCE_OVERVIEW
)

export const selectIsTagsAssignOpen = makeIsModalTypeSelector(LABELING_EDIT)
