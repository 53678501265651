import React from 'react'
import styled from '@emotion/styled'
import cn from 'classnames'
import { Button } from 'util/ui'
import SpacedItems from 'shared/components/ui/SpacedItems'
import themeVars from 'ui_theme/site/globals/site.variables'

const ErrorContainer = styled(SpacedItems.Container)`
  color: ${props => props.theme.color.primary.negative};
`

const IntegrationWarningSign = styled('div')`
  font-size: ${themeVars.massive};
  color: ${props => props.theme.color.primary.negative};
`

const Message = styled(SpacedItems.Item)`
  text-align: center;
`

const ErrorPage = ({
  onRetry,
  message = 'Oh no! An error occurred :(',
  className,
}) => {
  return (
    <ErrorContainer
      direction="vertical"
      gap="big"
      horizontalAlign="center"
      verticalAlign="center"
      className={className}
    >
      <SpacedItems.Item>
        <IntegrationWarningSign className={cn('Icon', 'Icon-warning')} />
      </SpacedItems.Item>
      <Message>{message}</Message>
      {onRetry && (
        <SpacedItems.Item>
          <Button
            size="tiny"
            color={themeVars.berryColorName}
            onClick={onRetry}
            style={{ marginRight: '0px' }}
          >
            Try again
          </Button>
        </SpacedItems.Item>
      )}
    </ErrorContainer>
  )
}

export default ErrorPage
