import styled from '@emotion/styled'
import themeVars from 'ui_theme/site/globals/site.variables'

const TextDivider = styled('div')`
  display: flex;
  flex-basis: 100%;
  align-items: center;
  margin: ${props => props.theme.spacing['10']} 0;
  font-size: ${themeVars.fontSize};
  &::before,
  &::after {
    content: '';
    flex-grow: 1;
    background: ${props => props.theme.color.monochrome.medium};
    height: 1px;
    font-size: 0;
    line-height: 0;
  }
  &::before {
    margin: 0 ${props => props.theme.spacing['12']} 0 0;
  }
  &::after {
    margin: 0 0 0 ${props => props.theme.spacing['12']};
  }
`

export default TextDivider
