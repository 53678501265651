import createCachedSelector from 're-reselect'
import { selectMailboxByStates } from './selectMailboxByStates'
import { mailboxesByStatesCacheKey } from './mailboxesByStatesCacheKey'

export const selectHasAnyMailboxByStates = createCachedSelector(
  selectMailboxByStates,
  mailboxByStates => {
    return mailboxByStates.length > 0
  }
)(mailboxesByStatesCacheKey)
