import { createSelector } from 'reselect'
import { isEmpty, emptyArr } from 'util/arrays'
import { emptyObj } from 'util/objects'
import {
  constructSearchQueryObject,
  constructSearchQueryId,
  constructEncodedSearchQueryString,
} from 'util/search'
import { selectCurrentMailbox } from 'selectors/mailboxes/selectCurrentMailbox'
import {
  selectTicketSearchOperatorValueMap,
  selectMailboxLessCurrentTicketSearchQueryID,
} from 'selectors/search/base'
import { selectStoredPinnedSearches } from 'selectors/pinned_searches/base'

function buildPinned(pinned, mailbox) {
  return pinned.map(pin => {
    const query = constructSearchQueryObject(pin.query)
    query.mailbox = mailbox && mailbox.id
    return { search: query, pinnedSearchId: pin.id }
  })
}

export const selectPinnedSearchesWithPinId = createSelector(
  selectCurrentMailbox,
  selectStoredPinnedSearches,
  (currentMailbox, pinned) => {
    if (!pinned) return emptyArr
    return buildPinned(pinned, currentMailbox)
  }
)

export const selectPinnedSearches = createSelector(
  selectPinnedSearchesWithPinId,
  pinned => {
    if (!pinned) return emptyArr
    return pinned.map(pin => pin.search)
  }
)

export const selectDefaultPinnedSearch = createSelector(
  selectPinnedSearches,
  searches => {
    if (!isEmpty(searches)) return searches[0]
    return null
  }
)

export const selectPinnedSearchQueryIds = createSelector(
  selectPinnedSearches,
  selectTicketSearchOperatorValueMap,
  (searches, valueMap) => {
    if (!searches) return null
    return searches.map(search => constructSearchQueryId(search, valueMap))
  }
)

export const selectDefaultPinnedSearchQueryId = createSelector(
  selectPinnedSearchQueryIds,
  pinnedSearchQueryIds => {
    if (!isEmpty(pinnedSearchQueryIds)) return pinnedSearchQueryIds[0]
    return null
  }
)

export const selectPinnedSearchesByQuery = createSelector(
  selectStoredPinnedSearches,
  searches => {
    if (!searches) return emptyObj
    const lookup = {}
    searches.forEach(
      search => (lookup[constructSearchQueryId(search.query)] = search)
    )
    return lookup
  }
)

export const selectPinnedSearchesWithIdsByPath = createSelector(
  selectPinnedSearchesWithPinId,
  selectTicketSearchOperatorValueMap,
  (pins, valueIdMap) => {
    if (!pins) return emptyObj

    return pins.reduce((byPath, pin) => {
      const searchString = constructEncodedSearchQueryString(
        pin.search,
        valueIdMap
      )

      const searchWithPinId = {
        ...pin.search,
        id: pin.pinnedSearchId,
      }

      // eslint-disable-next-line no-param-reassign
      byPath[`/search/${searchString}`] = searchWithPinId

      return byPath
    }, {})
  }
)

export const selectCurrentSearchPin = createSelector(
  selectPinnedSearchesByQuery,
  selectMailboxLessCurrentTicketSearchQueryID,
  (lookup, term) => lookup[term]
)
