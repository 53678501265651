/* eslint-disable no-multi-assign */ // ok in reducers
import * as types from 'constants/action_types'
import {
  MERGE_TICKET_STARTED,
  MERGE_TICKET_SUCCESS,
  MERGE_TICKET_FAILED,
} from 'ducks/merging/types'

const reducers = {}
const defaultState = { byId: {} }

// Currently the only action that needs to track request IDs
reducers[types.CREATE_CHANGESET_REQUEST] = reducers[
  types.CREATE_CHANGESET_SUCCESS
] = reducers[types.DELETE_TICKETS_STARTED] = reducers[
  types.DELETE_TICKETS_SUCCESS
] = reducers[MERGE_TICKET_STARTED] = reducers[MERGE_TICKET_SUCCESS] = reducers[
  MERGE_TICKET_FAILED
] = (state, action) => {
  const { requestId } = action && action.meta
  if (!requestId) return state || defaultState
  const { byId } = state

  const newById = { ...byId }
  newById[requestId] = { id: requestId }

  return {
    ...state,
    byId: newById,
  }
}

export default function reducer(state = defaultState, action) {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
