// Spinning loading icon.
import React from 'react'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import PropTypes from 'prop-types'

const Animated = styled(({ className, color }) => {
  let fillColor = color

  if (!color) {
    const {
      color: {
        monochrome: { mediumDark },
      },
    } = useTheme()

    fillColor = mediumDark
  }

  return (
    <svg
      className={className}
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Spinner" fill={fillColor}>
          <path
            d="M12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 L12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 C17.5228475,22 22,17.5228475 22,12 L24,12 C24,18.627417 18.627417,24 12,24 Z"
            id="spin"
          />
        </g>
      </g>
    </svg>
  )
})`
  animation: rotate 0.4s linear infinite;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const Loader = ({ className, color }) => {
  return (
    <div className={className}>
      <Animated color={color} />
    </div>
  )
}

Loader.propTypes = {
  color: PropTypes.string,
}

Loader.defaultProps = {
  color: undefined,
}

Loader.displayName = 'Loader'

export default styled(Loader)`
  margin: 0 auto;
  width: 25px;
`
