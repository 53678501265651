import * as types from 'constants/action_types'
import { omit } from 'util/objects'
import { isWebPushSupported } from 'util/browser'
import { getPermission } from 'util/webPush/permissions'
import { NOTIFICATIONS_STATE_UPDATE } from 'ducks/chat/actionTypes/notifications'

const reducers = {}
const defaultState = {
  isSupported: isWebPushSupported(),
  permissionStatus: getPermission(),
  cachedPushManagerEndpoint: null, // caches the current SW push subscriptions' endpoint
  byEndpoint: null,
  saveAttempted: false,
  resubscribeAttempted: false,
  saving: false,
  fetching: false,
  subscribing: false,
  unsubscribing: false,
}

const merge = (state, obj) => Object.assign({}, state, obj)

const addSubscriptions = (state, subscriptions) => {
  const newSubs = subscriptions.reduce((hash, subscription) => {
    // eslint-disable-next-line no-param-reassign
    hash[subscription.endpoint] = subscription
    return hash
  }, {})

  return merge(state, {
    byEndpoint: Object.assign({}, state.byEndpoint, newSubs),
  })
}

const removeSubscriptions = (state, endpoints) =>
  merge(state, {
    byEndpoint: omit(endpoints, state.byEndpoint),
  })

reducers[types.FETCH_WEB_PUSH_SUBSCRIPTIONS_REQUEST] = state =>
  merge(state, { fetching: true })

reducers[types.FETCH_WEB_PUSH_SUBSCRIPTIONS_SUCCESS] = (state, action) => {
  const subscriptions = action.data.webPushSubscriptions.records
  return merge(addSubscriptions(state, subscriptions), { fetching: false })
}

reducers[types.FETCH_WEB_PUSH_SUBSCRIPTIONS_FAIL] = state =>
  merge(merge(state, { fetching: false }), { byEndpoint: [] })

// Subscription objects need to be serialized before shoving into redux
const serializeSubscription = subObject => JSON.parse(JSON.stringify(subObject))

// Update this with care! This prop (should!) cache only the current
// subscription and is faster than calling pushManager.getSubscription(). It
// must be kept in sync with the current ServiceWorker subscription.
const updateCachedPushManagerEndpoint = (state, endpoint) =>
  merge(state, { cachedPushManagerEndpoint: endpoint })

reducers[types.WEB_PUSH_SUBSCRIPTION_CREATED] = (state, action) => {
  const sub = serializeSubscription(action.data.subscription)
  if (!sub) return state
  return updateCachedPushManagerEndpoint(state, sub.endpoint)
}

reducers[types.WEB_PUSH_SUBSCRIPTION_LOADED] = (state, action) => {
  const sub = serializeSubscription(action.data.subscription)
  if (!sub) return state
  return updateCachedPushManagerEndpoint(state, sub.endpoint)
}

reducers[types.WEB_PUSH_SUBSCRIBE] = (state, action) =>
  merge(addSubscriptions(state, [action.data]), {
    subscribing: true,
    permissionStatus: getPermission(),
  })

reducers[types.WEB_PUSH_SUBSCRIBE_COMPLETE] = state =>
  merge(
    merge(state, {
      saveAttempted: true,
      permissionStatus: getPermission(),
    }),
    { subscribing: false }
  )

reducers[types.SAVE_WEB_PUSH_SUBSCRIPTION_REQUEST] = (state, action) =>
  merge(addSubscriptions(state, [action.data]), { saving: true })

reducers[types.SAVE_WEB_PUSH_SUBSCRIPTION_SUCCESS] = (state, action) =>
  merge(addSubscriptions(state, [action.data]), { saving: false })

reducers[types.SAVE_WEB_PUSH_SUBSCRIPTION_FAIL] = state =>
  merge(state, { saving: false })

reducers[types.WEB_PUSH_RESUBSCRIBE] = state =>
  merge(state, { resubscribeAttempted: true })

reducers[types.WEB_PUSH_UNSUBSCRIBE] = (state, action) =>
  merge(
    removeSubscriptions(state, [action.data.endpoint]), // optimistic
    { unsubscribing: true }
  )

reducers[types.DELETE_WEB_PUSH_SUBSCRIPTION_SUCCESS] = (state, action) =>
  removeSubscriptions(state, [action.data.endpoint])

reducers[types.WEB_PUSH_UNSUBSCRIBE_COMPLETE] = state =>
  merge(updateCachedPushManagerEndpoint(state, null), { unsubscribing: false })

reducers[NOTIFICATIONS_STATE_UPDATE] = state => {
  return {
    ...state,
    permissionStatus: getPermission(),
  }
}

export default function reducer(state = defaultState, action) {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
