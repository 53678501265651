export default class Cleaner {
  ZERO_WIDTH_SPACE_CHARS = new RegExp(
    // eslint-disable-next-line no-control-regex
    '[\v\f\u200B\u200C\u200D\u200E\u200F\u000b\u2028\u2029\uFEFF\u202D]+',
    'g'
  )

  constructor(inputConfig) {
    const config = { ...inputConfig }
    config.removeLookup = {}
    config.remove.forEach(tagName => (config.removeLookup[tagName] = true))
    config.unwrapLookup = {}
    config.unwrap.forEach(tagName => (config.unwrapLookup[tagName] = true))
    this.config = config
    this.validStylesSets = null
    if (config.validStyles) {
      this.validStylesSets = {}
      Object.keys(config.validStyles).forEach(tagName => {
        const properties = config.validStyles[tagName]
        this.validStylesSets[tagName.toLowerCase()] = new Set(properties)
      })
    }
  }

  replaceNode(parent, original, replacement) {
    while (original.hasChildNodes())
      replacement.appendChild(original.firstChild)
    parent.replaceChild(replacement, original)
    return replacement
  }

  unwrapNode(node) {
    // Enable to insert copied html into tags like strong, the editor will
    // mark the cursor position with <span id="mce_marker"></span>, then will divide the strong tag's
    // content into two strong tags and insert the html between them.
    // This is the default behaviour:
    // Prevent removing bookmark span tag
    if (node.id === 'mce_marker' || node.className === 'mention') return
    const parent = node.parentNode
    while (node.lastChild) parent.insertBefore(node.lastChild, node.nextSibling) // insertAfter
    parent.removeChild(node)
  }

  cleanupNode(node) {
    const config = this.config
    const staticNodeList = Array.from(node.childNodes)
    // first pass, remove nodes
    // We need to do it on a static array as node.childNodes is a living colletion
    staticNodeList.forEach(n => {
      const tagName = n.tagName && n.tagName.toLowerCase()
      if (config.removeLookup[tagName]) node.removeChild(n)
    })
    // eslint-disable-next-line consistent-return
    node.childNodes.forEach(n => {
      const tagName = n.tagName && n.tagName.toLowerCase()
      // unwrap the node and return
      // unwrapping will put children after the unrapped node, so after we move them
      // we will clean them up
      if (config.unwrapLookup[tagName]) return this.unwrapNode(n) // return as

      // if we have replacements
      if (config.replace[tagName]) {
        const replacementName = config.replace[tagName]
        // eslint-disable-next-line no-param-reassign
        n = this.replaceNode(node, n, document.createElement(replacementName))
      }

      // remove any classes
      if (
        config.removeClasses &&
        n &&
        n.hasAttribute &&
        n.hasAttribute('class')
      ) {
        n.removeAttribute('class')
      }

      // remove any styles
      if (
        config.removeStyles &&
        n &&
        n.hasAttribute &&
        n.hasAttribute('style')
      ) {
        if (this.validStylesSets) {
          const existingStyleText = n.style.cssText
          // NOTE (jscheel): Do not assign styles directly to the style attributes
          // in a loop, it causes multiple layout calls. Instead we build up a
          // new style cssText string that will be assigned all at once.
          let newStyleText = ''
          existingStyleText.split(';').forEach(s => {
            if (!s) return
            const [property] = s.split(':', 1)
            const tagSet = this.validStylesSets[tagName]
            const globalSet = this.validStylesSets['*']
            if (
              (tagSet && tagSet.has(property)) ||
              (globalSet && globalSet.has(property))
            ) {
              newStyleText += `${s};`
            }
          })
          if (typeof n.style.cssText !== 'undefined') {
            // eslint-disable-next-line no-param-reassign
            n.style.cssText = newStyleText
          } else {
            n.setAttribute('style', newStyleText)
          }
        } else {
          n.removeAttribute('style')
        }
      }
    })
    // recursively clean down
    node.childNodes.forEach(n => this.cleanupNode(n))
    return node
  }

  cleanup(string) {
    const div = document.createElement('div')

    // remove zero-width characters if any
    const stringMinusZeroWidthSpace = string
      ? string.replace(this.ZERO_WIDTH_SPACE_CHARS, '')
      : string

    div.innerHTML = stringMinusZeroWidthSpace
    this.cleanupNode(div)
    return div.innerHTML
  }
}
