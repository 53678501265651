/* eslint-disable no-multi-assign */
import * as types from 'constants/action_types'

const defaultState = {
  fetching: false,
  byMailboxId: {},
}
const reducers = {}

reducers[types.FETCH_TOP_ASSIGNMENTS_REQUEST] = (
  state,
  { data: { mailboxId, groupId } }
) => {
  const mailbox = state.byMailboxId[mailboxId] || { byGroupId: {} }

  return {
    ...state,
    fetching: true,
    byMailboxId: {
      ...state.byMailboxId,
      [mailboxId]: {
        byGroupId: {
          ...mailbox.byGroupId,
          [groupId]: {},
        },
      },
    },
  }
}

const mapAssignments = assignments => {
  return assignments.reduce((map, assignment) => {
    map[assignment.assignee_id] = assignment.count // eslint-disable-line no-param-reassign
    return map
  }, {})
}

reducers[types.FETCH_TOP_ASSIGNMENTS_SUCCESS] = (
  state,
  { data: { assignments, mailboxId, groupId } }
) => {
  const mailbox = state.byMailboxId[mailboxId] || { byGroupId: {} }

  return {
    ...state,
    fetching: false,
    byMailboxId: {
      ...state.byMailboxId,
      [mailboxId]: {
        byGroupId: {
          ...mailbox.byGroupId,
          [groupId]: mapAssignments(assignments),
        },
      },
    },
  }
}

reducers[types.FETCH_TOP_ASSIGNMENTS_FAIL] = state => {
  return {
    ...state,
    fetching: false,
  }
}

reducers[types.ASSIGN_TICKET_TO_AGENT] = (
  state,
  { data: { agentId, mailboxId, groupId, currentUserId } }
) => {
  if (agentId === currentUserId) return state

  const mailbox = state.byMailboxId[mailboxId] || { byGroupId: {} }
  const group = mailbox.byGroupId[groupId] || {}

  return {
    ...state,
    fetching: false,
    byMailboxId: {
      ...state.byMailboxId,
      [mailboxId]: {
        byGroupId: {
          ...mailbox.byGroupId,
          [groupId]: {
            ...group,
            [agentId]: (group[agentId] || 0) + 1,
          },
        },
      },
    },
  }
}

// Loops over all tickets and applies the single ticket reducer.
reducers[types.ASSIGN_TICKETS_TO_AGENT] = (
  state,
  { data: { ticketIds, agentId, mailboxId, groupId } }
) => {
  const single = reducers[types.ASSIGN_TICKET_TO_AGENT]
  let newState = { ...state }
  ticketIds.forEach(ticketId => {
    newState = single(newState, {
      data: { ticketId, agentId, mailboxId, groupId },
    })
  })
  return newState
}

export default function reducer(state = defaultState, action) {
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
