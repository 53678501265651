import cn from 'classnames'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import { Icon } from 'shared/ui'
import styles from './styles.less'

export default function SideBarHeaderView({ onClose, children, className }) {
  return (
    <div className={cn(styles.header, className)}>
      <span className={styles['header-body']}>{children}</span>

      <button
        type="button"
        onClick={onClose}
        css={button.styles.preflight}
        className={cn('grui', styles['header-close'])}
      >
        <Icon name="close" color="color.monochrome.white" />
      </button>
    </div>
  )
}
