import { createSelector } from 'reselect'

import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { selectCustomersById } from 'ducks/customers/selectors'
import { selectContactIdsByCustomerId } from 'ducks/crm/contacts/selectors/base'
import { addActorLabelIfPresent } from '../shared/util'
import { selectCurrentTicketUndecorated } from '../current/selectCurrentTicketUndecorated'
import { selectIsTwitterTicket } from '../type'

// This style of selector avoids a huge decorated ticket object, and encourages
// components to use more specific selectors. This method improves our ability
// to find performance/rerendering issues; individual selectors misbehaving are
// easier to debug than a huge ticket decorator.
export const selectCurrentTicketCustomer = createSelector(
  selectCurrentTicketUndecorated,
  selectCustomersById,
  selectCurrentUser,
  selectIsTwitterTicket,
  (ticket, customersById, currentUser, isTwitterTicket) => {
    return addActorLabelIfPresent(
      customersById[ticket?.customer && ticket.customer.id],
      currentUser,
      isTwitterTicket
    )
  }
)

export const selectTicketCustomer = createSelector(
  selectCurrentTicketUndecorated,
  ticket => ticket && ticket.customer
)

export const selectCurrentConversationContactId = createSelector(
  selectCurrentTicketUndecorated,
  selectContactIdsByCustomerId,
  (ticket, contactIds) => {
    return (
      ticket &&
      (ticket.contactId || (ticket.customer && contactIds[ticket.customer.id]))
    )
  }
)

export const selectCurrentConversationContactIdOrTemp = createSelector(
  selectCurrentTicketUndecorated,
  selectCurrentConversationContactId,
  (ticket, contactId) => {
    return contactId || (ticket && `forConversation:${ticket.id}`)
  }
)

// Checks if customer email missing on non-twitter tickets
export const selectIsCustomerEmailBlank = createSelector(
  selectCurrentTicketUndecorated,
  selectCurrentTicketCustomer,
  (ticket, customer) => {
    return ticket && !ticket.isTwitterTicket && customer && !customer.email
  }
)
