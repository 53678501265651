import { createSelector } from 'reselect'

import { getId } from 'util/labels'

export const selectAdded = state => state.labels.selection.added || []

export const selectRemoved = state => state.labels.selection.removed || []

export const selectLabelsChanged = createSelector(
  selectAdded,
  selectRemoved,
  (added, removed) => added.length || removed.length
)

export const selectAddedIds = createSelector(selectAdded, added =>
  added.map(getId)
)

export const selectRemovedIds = createSelector(selectRemoved, removed =>
  removed.map(getId)
)

export const selectCreated = state => state.labels.selection.created || []
