/* eslint-disable no-param-reassign */
/* eslint-disable no-multi-assign, func-names */
import { UPDATE_APP_DATA, REMOVE_MAILBOX_LOCALLY } from 'constants/action_types'
import { DESTROY_MAILBOX_SUCCESS } from 'ducks/mailboxes/actionTypes'
import { isAgentActive, isRoleOneOf } from 'ducks/agents/utils'
import { BUILD_INBOX_MENU_FROM_MAILBOXES } from 'ducks/folders/actionTypes/collections'
import { createActionTypeReducer } from 'util/reducers'
import { getRawId, getType } from 'util/globalId'
import { showAllInboxesMailboxInLeftNav } from 'util/mailboxes'
import { getFolderKind } from 'ducks/folders/utils'
import { PAID_AGENT_ROLES } from 'ducks/agents/constants'
import { getShortAgentName } from '../util/agents'

const COLORS = [
  'hsl(45, 76%, 57%)',
  'hsl(187, 69%, 53%)',
  'rgba(220, 86, 56, 1)',
  'rgba(145, 30, 180, 0.7)',
  'rgba(245, 130, 48, 0.7)',
  'rgba(230, 25, 75, 0.7)',
  'rgba(52, 130, 234, 0.7)',
  'rgba(210, 245, 60, 0.9)',
  'rgba(240, 50, 230, 0.7)',
  'rgba(70, 240, 240, 0.7)',
  'rgba(250, 190, 190, 0.7)',
  'rgba(0, 128, 128, 0.7)',
  'rgba(230, 190, 255, 0.7)',
  'rgba(170, 110, 40, 0.7)',
  'rgba(255, 250, 200, 0.7)',
  'rgba(128, 0, 0, 0.7)',
  'rgba(170, 255, 195, 0.7)',
  'rgba(128, 128, 0, 0.7)',
  'rgba(255, 215, 180, 0.7)',
  'rgba(0, 0, 128, 0.7)',
  'rgba(128, 128, 128, 0.7)',
  'rgba(0, 0, 0, 0.7)',
  'rgba(60, 180, 75, 0.7)',
  'rgba(255, 225, 25, 0.7)',
]

function assignColorToMailbox(mailbox, index) {
  if (mailbox.color) return mailbox
  return {
    ...mailbox,
    color: COLORS[index % COLORS.length],
  }
}

const defaultState = {}

const buildLeftNav = (
  draftState,
  inputCurrentUser,
  inputFolders,
  inputChannels,
  inputAgents,
  { disableMutate }
) => {
  const currentUser = inputCurrentUser

  const folders = (inputFolders || [])
    .filter(
      ({ visible, type, hasAccess }) =>
        [undefined, true].includes(visible) &&
        type === 'Ticket::Filter' &&
        [undefined, true].includes(hasAccess)
    )
    // Makes the code compatibile with recieving a folder that has a globalId format
    .map(f => ({
      ...f,
      id: getRawId(f.id),
      matchType: f.matchType.toLowerCase(),
      kind: getFolderKind(f, { currentUserId: currentUser.id }),
    }))

  let wantsUnifiedInbox = false
  let wantsAllMailboxes = false

  try {
    wantsUnifiedInbox = currentUser.preferences.unified_inbox
    wantsAllMailboxes =
      currentUser.preferences.prefers_v2_show_all_mailboxes_section
  } catch (e) {
    // pass
  }

  const mailboxes = (inputChannels || [])
    .filter(mb => [null, 'Channel'].includes(getType(mb.id)))
    .map(mailbox => {
      return {
        ...mailbox,
        folders: mailbox.folders
          .map(f => f.id || f)
          .filter(fId => !!fId && folders.some(f => f.id === fId))
          .sort((a, b) => {
            // sort by already position-ordered folders
            return (
              folders.findIndex(e => e.id === a) >=
              folders.findIndex(e => e.id === b)
            )
          }),
      }
    })

  let newMailboxes

  if (wantsUnifiedInbox) {
    newMailboxes = [
      {
        id: null,
        name: 'Conversations',
        folders: folders.map(f => f.id),
      },
    ]
  } else {
    newMailboxes = mailboxes.map((mailbox, index) => {
      return {
        ...assignColorToMailbox(mailbox, index),
      }
    })
    if (showAllInboxesMailboxInLeftNav(wantsAllMailboxes, newMailboxes)) {
      newMailboxes.unshift({
        id: null,
        name: `All ${app.t('Mailboxes')}`,
        folders: folders.map(f => f.id),
      })
    }
  }

  const agents = (inputAgents || [])
    .filter(isAgentActive)
    .filter(a => isRoleOneOf(a, PAID_AGENT_ROLES))
    .map(agent => {
      const shortName = getShortAgentName(inputAgents, agent, {
        disableMutate,
      })
      return {
        ...agent,
        shortName,
      }
    })

  draftState.mailboxes = newMailboxes
  draftState.folders = folders
  draftState.agents = agents
}

const reduceRemoveMailbox = (draftState, action) => {
  const {
    meta: { leftNavPayload: metaLeftNavPayload } = {},
    data: { leftNavPayload: dataLeftNavPayload } = {},
    // For DESTROY_MAILBOX_SUCCESS
    payload: { leftNavPayload: payloadLeftNavPayload } = {},
  } =
    action || {}

  const leftNavPayload =
    dataLeftNavPayload || metaLeftNavPayload || payloadLeftNavPayload

  if (!leftNavPayload) return
  const { folders, mailboxes, agents, currentUser } = leftNavPayload

  buildLeftNav(draftState, currentUser, folders, mailboxes, agents, {
    disableMutate: false,
  })
}

export default createActionTypeReducer(
  {
    [UPDATE_APP_DATA]: (draftState, action) => {
      const { folders, mailboxes, agents, currentUser } = action.data

      buildLeftNav(draftState, currentUser, folders, mailboxes, agents, {
        disableMutate: false,
      })
    },
    [REMOVE_MAILBOX_LOCALLY]: reduceRemoveMailbox,
    [DESTROY_MAILBOX_SUCCESS]: reduceRemoveMailbox,
    [BUILD_INBOX_MENU_FROM_MAILBOXES]: (draftState, action) => {
      const { folders, mailboxes, agents, currentUser } = action.payload
      buildLeftNav(draftState, currentUser, folders, mailboxes, agents, {
        disableMutate: true,
      })
    },
  },
  defaultState
)
