import { createSelector } from 'reselect'
import {
  doOpenNotePage,
  doOpenTicketPage,
  doOpenReplyDirectPage,
  doOpenForwardPage,
  doOpenReplyPage,
} from 'actions/pages'

import { selectVisibleFields } from 'selectors/page'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'

import { isEmpty } from 'util/arrays'

export const selectOpenReplyPageAction = createSelector(
  selectCurrentTicketId,
  ticketId => doOpenReplyPage(ticketId)
)

export const selectOpenExpandedAction = createSelector(
  selectCurrentTicketId,
  selectVisibleFields,
  (ticketId, show) => {
    const options = {
      expand: true,
      show: !isEmpty(show) ? show : ['subject'],
    }

    return doOpenTicketPage(ticketId, options)
  }
)

export const selectOpenTicketPageExpandedAction = createSelector(
  selectCurrentTicketId,
  ticketId => doOpenTicketPage(ticketId, { expand: true, show: ['subject'] })
)

export const selectOpenReplyDirectPageAction = createSelector(
  selectCurrentTicketId,
  ticketId => doOpenReplyDirectPage(ticketId)
)

export const selectOpenForwardPageAction = createSelector(
  selectCurrentTicketId,
  ticketId => doOpenForwardPage(ticketId)
)

export const selectOpenForwardPageExpandedAction = createSelector(
  selectCurrentTicketId,
  ticketId => doOpenForwardPage(ticketId, { expand: true, show: ['subject'] })
)

export const selectOpenNotePageAction = createSelector(
  selectCurrentTicketId,
  ticketId => doOpenNotePage(ticketId)
)

export const selectOpenTicketOrForwardPageAction = createSelector(
  selectCurrentTicketId,
  ticketId => {
    return doOpenTicketPage(ticketId)
  }
)
