import { createSelector } from 'reselect'
import { sortByName } from 'util/arrays'
import { selectGroups } from './selectGroups'

export const selectGroupsById = createSelector(selectGroups, groups => {
  return groups.reduce((groupsById, group) => {
    // eslint-disable-next-line no-param-reassign
    groupsById[group.id] = group
    return groupsById
  }, {})
})

export const selectGroupsSortedByName = createSelector(
  selectGroupsById,
  groupsById => {
    return sortByName(Object.values(groupsById))
  }
)
