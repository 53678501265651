import { createSelector } from 'reselect'
import { selectMailboxes } from './selectMailboxes'
import { selectInaccessibleMailboxes } from './selectInaccessibleMailboxes'

export const selectAllMailboxes = createSelector(
  selectMailboxes,
  selectInaccessibleMailboxes,
  (mailboxes, inaccessibleMailboxes) => {
    return [...mailboxes, ...inaccessibleMailboxes]
  }
)
