import PropTypes from 'prop-types'
import { createTypeElement, createPropTypes } from './util'

const Header = props => createTypeElement('Header', props)
Header.propTypes = createPropTypes({
  size: PropTypes.oneOf(['large', 'medium', 'small']),
})

Header.defaultProps = {
  size: 'large',
  as: 'h1',
  fontType: 'page',
}

export default Header
