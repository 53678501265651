/* eslint-disable no-multi-assign */ /* this is ok in reducers */
import * as types from 'constants/action_types'

export const defaultState = {
  isActive: false,
  changesetId: null,
  ticketId: null,
  status: null,
  draft: null,
  draftId: null,
}

const reducers = {}

reducers[types.UNDO_SEND_TIMER_START] = (state, { data }) => {
  return {
    ...defaultState,
    changesetId: data.changesetId,
    draft: data.draft,
    draftId: data.draft.id,
    ticketId: data.ticketId,
    isActive: true,
    status: 'sending',
  }
}

reducers[types.UNDO_SEND_TIMER_END] = () => {
  return defaultState
}

reducers[types.UNDO_SEND_REQUEST] = state => {
  return { ...state, status: 'undoing' }
}

reducers[types.UNDO_SEND_SUCCESS] = state => {
  return { ...state, status: 'succeeded' }
}
reducers[types.UNDO_SEND_FAIL] = state => {
  return { ...state, status: 'failed' }
}

export default function reducer(state = defaultState, action) {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
