// Props Proxy HOC for adding keyboard handling to a SUI Dropdown
//   1. focuses keyboard events on the dropdown.
//   2. Adds a better onEnter handler that handles links
//   3. If you provide `options` with onClick handlers, then we automatically
//      trigger those when you use the keyboard to select an item (see onChange)
//
import React from 'react'

import KeyboardNavigator from 'components/KeyboardNavigator'
import ListenToKeyboard from 'components/ListenToKeyboard'
import KeyboardHandler from 'components/Lists/KeyboardHandler'

import { findFirst, getLength, isEmpty } from 'util/arrays'
import debug from 'util/debug'
import { getDisplayName } from 'util/hoc'
import { runOnNextTick } from 'util/functions'

function withKeyboardHandler(WrappedDropdown) {
  class WithKeyboardHandler extends React.Component {
    state = { value: null }

    componentDidMount() {
      // Upon initialization, we need to sync our internal value with the
      // selectedIndex prop. If no selectedIndex is set, default to first item
      this.initializeSelected(this.props)
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.options !== this.props.options) {
        this.initializeSelected(nextProps)
      }
    }

    componentWillUnmount() {
      clearTimeout(this.initializeSelectedTimeout)
    }

    initializeSelectedTimeout = null

    initializeSelected = props => {
      const { options, selectedIndex } = props
      const value = !isEmpty(options) && options[selectedIndex || 0]
      if (value) {
        this.initializeSelectedTimeout = runOnNextTick(() => {
          this.saveValue(value)
        })
      }
    }

    // trigger onClick handler (if present) on the currently selected option. We
    // track the 'selected' options' value in `state.value. This works well
    // since we assume the options' `value` prop never changes, unlike its
    // text/description (which can be moved into `content`)
    handleEnter = () => {
      const { options } = this.props
      const { value } = this.state
      const opt = findFirst(options, o => o.value === value)
      debug('DD handleEnter', {
        options,
        value,
        found: opt,
        onClickForFound: opt && opt.onClick,
      })
      if (!opt) return false

      if (opt.onClick) {
        opt.onClick()
        // Close the dropdown explicitly
        return this.handleClose()
      }

      // If the option is a Link, we can expect a `to` prop on it. In which
      // case, perform it's click event
      if (opt.to) {
        const linkSelector = `.dropdown a[href$='${opt.to}']`
        const link = document.body.querySelector(linkSelector)
        if (link) {
          link.click()
          return this.handleClose()
        }
      }

      return false
    }

    handleClose = () => {
      const { onClose } = this.props
      if (onClose) onClose()
    }

    saveValue = opt => {
      this.setState({ value: opt.value })
    }

    // tracks the active item in the list. This callback receives the full
    // option
    handleChange = (event, option) => {
      const { onChange } = this.props
      debug('DD: KB Handler: handleChange', {
        option,
        onChangeCallback: onChange,
      })
      this.saveValue(option)
      // Optional callback. Honours SUI DD onChange contract
      if (onChange) onChange(event, option)
    }

    render() {
      const {
        className,
        hotkey,
        onOpen,
        open,
        selectedIndex,
        ...rest
      } = this.props

      return (
        <React.Fragment>
          {hotkey && (
            <ListenToKeyboard
              disableForInput
              onKeyUp
              {...{ [hotkey]: onOpen }}
            />
          )}
          {open && (
            <ListenToKeyboard hijack={ListenToKeyboard.DROPDOWN}>
              <KeyboardNavigator.Focus />
              <KeyboardHandler
                count={getLength(this.props.options)}
                focusedIndex={selectedIndex}
                onEnter={this.handleEnter}
              />
            </ListenToKeyboard>
          )}
          <WrappedDropdown
            className={className}
            open={open}
            onOpen={onOpen}
            {...rest}
            onChange={this.handleChange}
          />
        </React.Fragment>
      )
    }
  }

  WithKeyboardHandler.displayName = `WithKeyboardHandler(${getDisplayName(
    WrappedDropdown
  )})`

  return WithKeyboardHandler
}

export default withKeyboardHandler
