import createCachedSelector from 're-reselect'
import { diff } from 'util/date'
import { selectIsNewAccountSinceDate } from 'ducks/accounts/selectors/selectIsNewAccountSinceDate'

export const selectFeatureCalloutExpired = createCachedSelector(
  selectIsNewAccountSinceDate,
  (_state, dateString) => dateString,
  (_state, _dateString, durationDays = 14) => durationDays,
  (isNewAccount, dateString, durationDays) => {
    const daysSinceLaunched = diff('days', dateString, new Date())
    return daysSinceLaunched > durationDays || isNewAccount
  }
)((_state, dateString, durationDays) => dateString + durationDays)
