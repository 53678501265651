import cn from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

import { Icon } from 'shared/ui'
import { propFunc } from 'util/functions'
import { Pagination } from 'util/ui'

Pager.propTypes = {
  ...Pagination.propTypes,
  className: PropTypes.string,
  lite: PropTypes.bool,
  perPage: PropTypes.number,
  totalPages: PropTypes.number,
  totalCount: PropTypes.number,
}

Pager.defaultProps = {
  ...Pagination.defaultProps,
  className: '',
  lite: false,
  perPage: null,
  totalCount: null,
  totalPages: null,
}

function mappedOnPageChange(onPageChange, event, data) {
  const { activePage } = data
  onPageChange(activePage, event, data)
}

function Pager({
  className,
  lite,
  onPageChange,
  perPage,
  totalCount,
  ...paginationProps
}) {
  const { activePage, totalPages } = paginationProps
  const pages =
    typeof totalPages === 'number'
      ? totalPages
      : Math.ceil(totalCount / perPage)
  if (pages <= 1) return null
  const atEnd = pages === activePage
  const atStart = activePage === 1
  if (lite) {
    const from = (activePage - 1) * perPage + 1
    const to = atEnd ? totalCount : activePage * perPage

    return (
      <div className={cn(className, 'PagerLite')}>
        <span>
          {from}-{to}
          {totalCount && ` out of ${totalCount}`}
        </span>
        <Icon
          disabled={atStart}
          onClick={atStart ? null : propFunc(onPageChange, activePage - 1)}
          name="chevron_left"
        />
        <Icon
          disabled={atEnd}
          onClick={atEnd ? null : propFunc(onPageChange, activePage + 1)}
          name="chevron_right"
        />
      </div>
    )
  }
  return (
    <div className={cn(className, 'Pager')}>
      <Pagination
        {...paginationProps}
        firstItem={null}
        lastItem={null}
        prevItem={{ content: <Icon disabled={atStart} name="chevron_left" /> }}
        nextItem={{ content: <Icon disabled={atEnd} name="chevron_right" /> }}
        totalPages={pages}
        onPageChange={propFunc(mappedOnPageChange, onPageChange)}
      />
    </div>
  )
}

export default styled(Pager)`
  &.Pager {
    .Icon {
      margin: -9px;
    }
  }
  &.PagerLite {
    .label {
      vertical-align: middle;
      padding-right: 5px;
    }

    .Icon {
      display: inline-block;
      vertical-align: middle;
    }
  }
`
