import { createSelector } from 'reselect'
import { selectCurrentPath as currentPathSelector } from 'selectors/location'
import { isAuthenticatedSelector, fetchingStatusesSelector } from './app'

const selectRawEmailsById = state => state.rawEmails.byId || {}

export const selectCurrentRawEmailId = createSelector(
  currentPathSelector,
  path => {
    if (path && path.match('/raw_email/')) {
      return path.split('/').pop()
    }
    return null
  }
)

export const selectCurrentRawEmail = createSelector(
  selectCurrentRawEmailId,
  selectRawEmailsById,
  (currentEmailId, emailsById) => {
    const found = emailsById[currentEmailId]
    return found
  }
)

export const shouldFetchRawEmailSelector = createSelector(
  isAuthenticatedSelector,
  fetchingStatusesSelector,
  selectCurrentRawEmailId,
  selectRawEmailsById,
  (auth, statuses, currentEmailId, emailsById) => {
    if (!auth) return false
    // not if we're currently fetching
    if (statuses.fetchRawEmail) return false
    if (!currentEmailId) return false
    const found = emailsById[currentEmailId]
    if (!found) return true
    return false
  }
)
