import { createSelector } from 'reselect'
import {
  selectAgentsByIdIncludingArchived,
  selectAgentsByUsername,
} from 'selectors/agents/base'
import { selectGroupsById } from 'selectors/app'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { selectCustomersById } from 'ducks/customers/selectors'
import { selectLabelsById } from 'selectors/labels/base'
import { mapObject, emptyObj } from 'util/objects'
import { decorate } from '../shared/util'
import { selectById } from './selectById'

// Our main ticket selector

export const selectTicketsById = createSelector(
  selectById,
  selectAgentsByIdIncludingArchived,
  selectGroupsById,
  selectCurrentUser,
  selectCustomersById,
  selectLabelsById,
  selectGroupsById,
  selectAgentsByUsername,
  (
    byId,
    agentsById,
    groupsById,
    currentUser,
    customersById,
    labelsById,
    groupsByName,
    agentsByUsername
  ) => {
    return mapObject(
      decorate(
        agentsById,
        groupsById,
        currentUser,
        customersById,
        labelsById,
        groupsByName,
        agentsByUsername
      )
    )(byId || emptyObj)
  }
)
