// Main decoration function.
export const decorate = commonWords => mailbox => {
  const label = mailbox.name
    .split(' ')
    .filter(
      word => commonWords.indexOf(word) <= -1 // keep only uncommon words
    )
    .join(' ')
    .replace(/(^at | at$|^from | from$)/g, '')
    .trim()

  // add new decorator code here
  // build one new object
  return Object.assign({}, mailbox, {
    label,
    // add new decorated props here
  })
}
