import { createSelector } from 'reselect'
import selectByIdIncArchived from 'selectors/agents/selectByIdIncArchived'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import selectGroups from 'ducks/teams/selectors/selectGroups'
import { filter, mapObject } from 'util/objects'
import decorate from './decorate'

const selectById = createSelector(selectByIdIncArchived, agents => {
  return filter(agent => !agent.archived, agents)
})

const selectAgentsById = createSelector(
  selectById,
  selectCurrentUser,
  selectGroups,
  (byId, currentUser, groups) =>
    mapObject(decorate(currentUser, groups))(byId || {})
)

export default selectAgentsById
