import React, { useEffect, useRef, useState } from 'react'
import themeVars from 'ui_theme/site/globals/site.variables'

import { getDisplayName } from 'util/hoc'

export default function withDelayedMountUnmount(WrappedComponent) {
  function WithDelayedUmount(props) {
    const {
      undelayedShouldMountProp = 'undelayedMount',
      shouldMountProp = 'mount',
    } = props
    const undelayedShouldMount = !!props[shouldMountProp]
    const [shouldMount, setShouldMount] = useState(false)
    const [shouldDrawMounted, setShouldDrawMounted] = useState(false)
    const unmountTimeout = useRef(null)
    useEffect(
      () => {
        if (undelayedShouldMount && !shouldMount) {
          setShouldMount(true)
          setShouldDrawMounted(false)
        }
        if (undelayedShouldMount && shouldMount && !shouldDrawMounted) {
          window.requestAnimationFrame(() => {
            setShouldDrawMounted(true)
          })
        }
        if (!undelayedShouldMount && shouldMount) {
          clearTimeout(unmountTimeout.current)
          setShouldDrawMounted(false)
          unmountTimeout.current = setTimeout(() => {
            window.requestAnimationFrame(() => {
              setShouldMount(false)
            })
          }, themeVars.defaultDurationInt)
        }
      },
      [undelayedShouldMount, shouldMount, shouldDrawMounted]
    )
    useEffect(() => {
      clearTimeout(unmountTimeout.current)
    }, [])

    if (shouldMount) {
      const newProps = {
        ...props,
        [shouldMountProp]: shouldDrawMounted,
      }
      newProps[undelayedShouldMountProp] = undelayedShouldMount
      return <WrappedComponent {...newProps} />
    }
    return null
  }

  WithDelayedUmount.displayName = `WithDelayedUmount(${getDisplayName(
    WrappedComponent
  )})`

  return WithDelayedUmount
}
