import React from 'react'
import cn from 'classnames'
import styled from '@emotion/styled'
import { css, useTheme } from '@emotion/react'
import Tooltip from 'shared/ui/Tooltip'
import Flex from 'shared/ui/Flex'
import BoxHeadline from 'shared/ui/BoxHeadline'
import Text from 'shared/ui/Text'
import SVGIcon from 'shared/ui/SVGIcon'
import { themeColorPropOr } from '@groovehq/internal-design-system/lib/util/theme'

import { Image } from 'util/ui'

const flexStyle = css`
  flex: 1 1 auto;
`

const flexInnerStyle = css`
  flex: 1 1 90%;
`

const iconWrapperStyle = css`
  margin-top: 0.3em;
`

const ReturnIcon = styled(({ className }) => (
  <span className={cn('Icon Icon-keyboard-return', className)} />
))`
  color: ${themeColorPropOr('color.primary.brand')};
  font-size: ${props => props.theme.fontSize.x2large};
  opacity: ${({ selected }) => (selected ? 1 : 0)};
  margin-top: 4px; /* hack the vertical positioning */
`

// Most of our DD options should look sthg like this. If we provide a uniform
// view for these, then we have less cause to pass them as option.content. This
// in turn lets us pass simple text/description fields, which help with our
// searchInMenu DDs.
const TextOption = styled(
  ({
    className,
    active,
    image,
    svgIcon,
    text,
    description,
    tooltip,
    textColor,
  }) => {
    const theme = useTheme()

    const tooltipIconWrapperStyle = css`
      margin: -3px 0 0 4px;
      color: ${textColor};
    `

    return (
      <Flex className={cn('grui overflow-hidden', className)}>
        {image && <Image size="small" src={image} alt={text} />}
        {svgIcon && (
          <SVGIcon
            className={'image'}
            size={16}
            name={svgIcon}
            css={iconWrapperStyle}
          />
        )}
        <BoxHeadline color={textColor || (active && theme.color.primary.brand)}>
          {text}
        </BoxHeadline>
        {description && (
          <Text size="small" className="grui ml-4" truncate color={textColor}>
            {description}
          </Text>
        )}
        {tooltip && (
          <Tooltip portal position={'right'} tooltip={tooltip}>
            <div css={tooltipIconWrapperStyle}>
              <SVGIcon size={10} name="question" />
            </div>
          </Tooltip>
        )}
      </Flex>
    )
  }
)`
  > .text {
    line-height: 1.85 !important; // aligns with BoxHeadline
  }
`

const Option = ({ content, ...rest }) => {
  if (content) return content
  return <TextOption {...rest} />
}

// Renders option content/text, along with an arrow icon (if selected;
// a hidden placeholder otherwise)
// Note we dont rely on it being inside a .selected class - like we used to.
// Instead we are explicit about passing props.selected. This means we can
// use this in both SUI DDs and our own legacy modal-y dropdowns.
const SelectableItem = React.forwardRef(
  (
    {
      arrowColor,
      className,
      css: cssStyle,
      key,
      onContainerClick,
      onMouseMoveCapture,
      selected,
      showArrow = true,
      ...rest
    },
    ref
  ) => {
    return (
      <span
        className={className}
        css={cssStyle}
        key={key}
        onClick={onContainerClick}
        onMouseMoveCapture={onMouseMoveCapture}
        ref={ref}
      >
        <Flex css={flexStyle} className="grui overflow-hidden">
          <Flex css={flexInnerStyle} className="grui overflow-hidden">
            <Option {...rest} />
          </Flex>
          {showArrow && (
            <Flex className="grui ml-4 flex-1-0-auto">
              <ReturnIcon selected={selected} color={arrowColor} />
            </Flex>
          )}
        </Flex>
      </span>
    )
  }
)

export default styled(SelectableItem)`
  background-color: ${props =>
    props.selected
      ? props.theme.color.secondary.brand
      : props.theme.color.monochrome.white};
  cursor: ${props => props.onContainerClick && 'pointer'};

  &:hover {
    background-color: ${props => props.theme.color.secondary.brand};
  }
`
