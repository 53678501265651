import { emptyObj } from 'util/objects'
import { emptyArr } from 'util/arrays'
import { createSelector } from 'reselect'
import { selectUnreadByChannelType } from 'ducks/searches/selectors'
import { selectIsInLiveChat, selectIsInSocialChat } from 'selectors/location'

export function selectBase(state) {
  return state.realtime || emptyObj
}

export const selectUnreadRoomIds = createSelector(
  selectBase,
  realtime => realtime.unreadRoomIds || emptyArr
)

export const selectIsUnread = createSelector(
  selectBase,
  selectUnreadByChannelType,
  selectIsInLiveChat,
  selectIsInSocialChat,
  (realtime, unreadByChannelType, isInLiveChat, isInSocialChat) => {
    return {
      widget: isInLiveChat
        ? unreadByChannelType.widget
        : realtime.unreadChannelTypes?.widget,
      social: isInSocialChat
        ? unreadByChannelType.social
        : realtime.unreadChannelTypes?.social,
    }
  }
)
