import { createSelector } from 'reselect'

import { selectIsFetchingLabelSearchResults } from 'selectors/app'
import { selectLabelsById, selectCounts } from 'selectors/labels/base'
import { selectCurrentTicketLabelIds } from 'selectors/tickets/labels'
import { selectCurrentMailboxName } from 'selectors/mailboxes/selectCurrentMailboxName'

import { addPath } from 'util/labels'
import { mapObject, isEmpty as isEmptyObject } from 'util/objects'

const emptyObject = {}

export const selectSearch = state => state.labels.search || emptyObject

export const selectLabelSearchResultsByTerm = createSelector(
  selectSearch,
  selectLabelsById,
  (search, labelsById) => {
    const ret = mapObject(results => {
      return results.map(id => labelsById[id])
    })(search.resultsByTerm)
    if (!ret || isEmptyObject(ret)) {
      return emptyObject
    }
    return ret
  }
)

export const selectLabelSearchTerm = createSelector(
  selectSearch,
  search => search.term
)

export const selectIsSearchingLabels = createSelector(
  selectLabelSearchTerm,
  term => term && term.length > 0
)

export const selectPreviousLabelSearchTerm = createSelector(
  selectSearch,
  search => search.previousTerm
)

const selectPreviousLabelSearchResults = createSelector(
  selectLabelSearchResultsByTerm,
  selectPreviousLabelSearchTerm,
  (pastResults, previousTerm) => pastResults[previousTerm]
)

export const selectCurrentLabelSearchResultsForFilter = createSelector(
  selectLabelSearchResultsByTerm,
  selectLabelSearchTerm,
  (byTerm, term) => {
    return byTerm[term] || []
  }
)

export const selectCurrentLabelSearchResults = createSelector(
  selectCurrentLabelSearchResultsForFilter,
  selectCurrentTicketLabelIds,
  (results, currentLabelIds) => {
    return results.filter(label => currentLabelIds.indexOf(label.id) < 0)
  }
)

export const selectLabelSearchResultsWithCounts = createSelector(
  selectCounts,
  selectCurrentLabelSearchResultsForFilter,
  (counts, labels) => {
    return labels.map(label => ({
      ...label,
      ticketCount: counts[label],
    }))
  }
)

export const selectCurrentLabelSearchResultsWithCounts = createSelector(
  selectCounts,
  selectCurrentLabelSearchResults,
  (counts, labels) =>
    labels.map(label => {
      return Object.assign({}, label, { ticketCount: counts[label] })
    })
)

export const selectIsSearchingLabelsWithoutResults = createSelector(
  selectIsSearchingLabels,
  selectIsFetchingLabelSearchResults,
  selectLabelSearchResultsWithCounts,
  selectPreviousLabelSearchResults,
  (isSearching, isFetchingSearchResults, searchResults, prevResults) =>
    isSearching &&
    // Finished fetching for current search term, so check if any results.
    ((!isFetchingSearchResults && searchResults.length === 0) ||
      // If we have already have no results, and are in the middle of fetching
      // search results, with only the above logic we would see the 'no
      // results' page flicker while the search results were being fetched. By
      // assuming if the user is already seeing the 'no results' page, and they
      // continue to type, then they are trying to create a new label. If the
      // previous search didn't yield any results, then we can avoid the 'no
      // results' flickering by assuming we will still get no results when the
      // next search returns.
      (isFetchingSearchResults && prevResults && prevResults.length === 0))
)

export const selectLabelSearchResultsWithCountsAndPath = createSelector(
  selectLabelSearchResultsWithCounts,
  selectCurrentMailboxName,
  (labels, mailboxName) => labels.map(addPath(mailboxName))
)
