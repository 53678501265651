/* eslint-disable no-multi-assign */
import * as types from 'constants/action_types'
import * as onboardingTypes from 'subapps/onboarding/types'
import storage from 'util/storage'
import { capitalize } from 'util/strings'
import { getRawId } from 'util/globalId'
import { APP_BILLING_EXPIRED } from 'ducks/billing/types'
import { RESET_LOGIN_ATTEMPTS_SUCCESS } from 'ducks/agents/types'

import {
  getShortAgentName,
  getAgentUsername,
  fullName,
  getAgentNameCounts,
} from '../util/agents'

const storedCurrentUser = storage.get('currentUser') || {}
const defaultState = {
  byId: {},
  currentUserId: storedCurrentUser.id,
  totalCount: null,
}
const reducers = {}

export function getAvatarUrl(agent) {
  return agent && agent.avatar_url && !agent.avatar_url.match('missing.png')
    ? agent.avatar_url
    : null
}

function build(agents, agent, currentUserId, { precalculatedCounts } = {}) {
  let agentFullName
  let label = null
  const shortName = getShortAgentName(agents, agent, { precalculatedCounts })

  if (agent) {
    agentFullName = fullName(agent)
    if (agent.id === currentUserId) {
      label = 'You'
    } else {
      label = capitalize(shortName)
    }
  }

  const lastName = agent.last_name || ''
  const letter = lastName.charAt(0).toUpperCase()

  const newAgent = {
    ...agent,
    agentId: agent ? agent.id : undefined,
    label,
    avatarUrl: getAvatarUrl(agent),
    labelFull: agentFullName,
    fullName: agentFullName,
    isAssignedToSelf: agent && agent.id === currentUserId,
    type: 'Agent',
    initial: [agent.first_name, letter].join(' '),
    initials: [agent.first_name, agent.last_name]
      .map(part => part && part.charAt(0))
      .join(''),
    name: [agent.first_name, agent.last_name].join(' '),
    username: agent.username ? getAgentUsername(agent) : null,
    shortName,
  }

  // eslint-disable-next-line no-underscore-dangle
  delete newAgent._cached_shortName
  return newAgent
}

// eslint-disable-next-line no-multi-assign
reducers[types.UPDATE_APP_DATA] = reducers[APP_BILLING_EXPIRED] = reducers[
  types.FETCH_AGENTS_SUCCESS
] = (state, action) => {
  const { agents = [], currentUser = {}, fetchedAll } = action.data
  const newById = {}
  const precalculatedCounts = getAgentNameCounts(agents)
  agents.forEach(agent => {
    if (agent.hasAcceptedInvitation === false) return
    newById[agent.id] = build(agents, agent, currentUser.id, {
      precalculatedCounts,
    })
  })
  return {
    ...state,
    fetchedAll,
    byId: newById,
    currentUserId: currentUser.id,
    // Total count including invited agents
    totalCount: agents.length,
  }
}

reducers[types.UPDATE_AGENT] = (state, action) => {
  const currentUserId = state.currentUserId
  const agent = action.data
  const byId = state.byId || {}
  const existingAgent = byId[agent.id]
  const newAgent = build(
    Object.values(byId),
    Object.assign({}, existingAgent || {}, agent),
    currentUserId
  )
  const newById = Object.assign({}, byId, { [agent.id]: newAgent })

  return Object.assign({}, state, {
    byId: newById,
  })
}

reducers[types.REMOVE_AGENT] = (state, action) => {
  const id = action.data
  const byId = state.byId || {}
  const newById = Object.assign({}, byId)
  delete newById[id]
  return Object.assign({}, state, {
    byId: newById,
  })
}

reducers[onboardingTypes.SIGNUP_SUCCESS] = (
  state,
  { payload: { user } = {} }
) => {
  if (!user) return state
  if (!user.id) return state
  return {
    ...state,
    currentUserId: user.id,
  }
}

reducers[RESET_LOGIN_ATTEMPTS_SUCCESS] = (state, { payload, meta }) => {
  const { agentId: agentGid } = meta.requestParameters
  const {
    agent: { status2fa },
  } = payload.agentResetLoginAttempts
  const agentId = getRawId(agentGid)

  const currentUserId = state.currentUserId
  const byId = state.byId || {}

  const existingAgent = byId[agentId]
  const newAgent = build(
    Object.values(byId),
    Object.assign({}, existingAgent || {}, {
      status2fa: status2fa.toLowerCase(),
    }),
    currentUserId
  )
  const newById = Object.assign({}, byId, { [agentId]: newAgent })

  return Object.assign({}, state, {
    byId: newById,
  })
}

export default function reducer(state = defaultState, action) {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
