// Provides a flashing dot to call attention to new features in the app. The dot
// is positioned with popper.js so that it does not interfere with the target
// component's layout in any way.
//
// Usage Example
// ---------------------------------------------------
// const Button = () => {
//   const { dotTargetRef, dotMarkAsSeen, Dot } = useFeatureDot()
//   return (
//     <React.Fragment>
//       <button ref={dotTargetRef} onClick={dotMarkAsSeen}>
//         new shiny thing
//       </button>
//       <Dot />
//     </React.Fragment>
//   )
// }
//
//
// There are two distinct method to show a featured dot via agent flags:
//
// Param limitedScope === true
// ---------------------------------------------------
// If limitedScope is true, you must add the flag to each agent you want to show
// want to see the dot on the backend. Unless this flag is set, the feature dot
// will not show. Notice that this is different from the way flags have sometimes
// been used in the past, as it requires you to set the visibility manually. When
// dotMarkAsSeen is called, the agent flag will be cleared on the backend and they
// will no longer see the dot. This approach is useful if you need to show the
// feature dot to a specific subset of agents.
//
//
// Param limitedScope === false
// ---------------------------------------------------
// If limitedScope is false, the flags work the same as they always have. Once
// flags have loaded, the feature dot will be visible on all agents that do not
// have the flag set. Once dotMarkAsSeen is called, the flag will be set on the
// agent and they will no longer see the dot.

import React, { useRef, useState, useLayoutEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { css, keyframes } from '@emotion/react'
import { createPopper } from '@popperjs/core/lib/popper-lite'
import { selectFlag } from 'ducks/flags/selectors'
import { doClearFlag, doSetFlag } from 'ducks/flags/operations'

const pulsate = keyframes`
  0% { transform: scale(0.1, 0.1); opacity: 0.0; }
  30% { opacity: 0.22; }
  50% { transform: scale(1, 1); opacity: 0.0; }
  100% { transform: scale(1, 1); opacity: 0.0; }
`
const dotStyle = () => theme => css`
  position: relative;
  user-select: none;
  pointer-events: none;
  z-index: 1;

  &:before {
    content: '';
    width: 30px;
    height: 30px;
    background-color: ${theme.color.primary.negative};
    border-radius: 50%;
    position: absolute;
    left: -15px;
    top: -15px;
    animation: ${pulsate} 2s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    z-index: 1;
    will-change: transform;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000;
    user-select: none;
    pointer-events: none;
  }

  &:after {
    content: '';
    width: 10px;
    height: 10px;
    background-color: ${theme.color.primary.negative};
    border-radius: 50%;
    position: absolute;
    top: -5px;
    left: -5px;
    z-index: 2;
    user-select: none;
    pointer-events: none;
  }
`

const useFeatureDot = (
  flagName,
  limitedScope = false,
  { placement = 'top-start' } = {}
) => {
  const getFlag = useSelector(selectFlag)
  const dispatch = useDispatch()
  const popperElement = useRef(null)
  const setPopperElement = useCallback(
    node => {
      popperElement.current = node
    },
    [popperElement]
  )

  // HACK (jscheel): Storing the ref in state because it will force updates better
  // and popper gets confused on certain updates, causing it to mis-place the dot.
  const [targetRef, setTargetRef] = useState(null)
  useLayoutEffect(
    () => {
      if (flagName) {
        const flagValue = getFlag(flagName)
        if (
          (limitedScope && flagValue !== true) ||
          (!limitedScope && (flagValue === undefined || flagValue === true))
        ) {
          return undefined
        }
      }
      if (!targetRef || !popperElement.current) return undefined
      const popper = createPopper(targetRef, popperElement.current, {
        placement,
        modifiers: [],
      })
      return () => {
        popper.destroy()
        popperElement.current = null
        targetRef.current = null
      }
    },
    [targetRef, popperElement, getFlag, flagName, limitedScope, placement]
  )

  const dotMarkAsSeen = useCallback(
    () => {
      if (flagName) {
        const flagValue = getFlag(flagName)
        if (limitedScope && flagValue) {
          return dispatch(doClearFlag(flagName))
        } else if (!limitedScope && !flagValue)
          return dispatch(doSetFlag(flagName))
      }
      return undefined
    },
    [dispatch, flagName, getFlag, limitedScope]
  )

  const Dot = useCallback(
    () => {
      if (flagName) {
        const flagValue = getFlag(flagName)
        if (
          (limitedScope && flagValue !== true) ||
          (!limitedScope && (flagValue === undefined || flagValue === true))
        ) {
          return null
        }
      }
      return <div ref={setPopperElement} css={dotStyle} />
    },
    [setPopperElement, flagName, getFlag, limitedScope]
  )

  return {
    dotTargetRef: setTargetRef,
    dotMarkAsSeen,
    Dot,
  }
}

export default useFeatureDot
