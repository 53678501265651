import PropTypes from 'prop-types'
import { createTypeElement, createPropTypes } from './util'

const Label = props => createTypeElement('Label', props)

Label.propTypes = createPropTypes({
  size: PropTypes.oneOf(['large', 'medium', 'small', 'huge']),
})

Label.defaultProps = {
  as: 'span',
  size: 'large',
}

export default Label
