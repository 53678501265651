import React, { useState, useCallback, useMemo } from 'react'
import { Icon } from 'util/ui'
import SpacedItems from 'shared/components/ui/SpacedItems'
import { BoxHeadline } from 'shared/components/ui/Typography'

import themeVars from 'ui_theme/site/globals/site.variables'

import styles from './styles.less'

// NOTE (jscheel): First-level childen must have props that match your sort keys.
// This is the way the list actually sorts based on data. If you want an empty
// component to dump these props on, instead of passing them to a component that
// may not expect those specific props, you can use the SortableListItem (but it
// is not explicitly necessary).

const ColumnHeaders = React.memo(
  ({ onChange, sorting, headers, headerPrefix, headerSuffix }) => {
    const { key, direction } = sorting

    const onClick = useCallback(
      () => {
        onChange(key)
      },
      [onChange, key]
    )

    // TODO (jscheel): The concept of a headerPrefix and headerSuffix absolutely
    // sucks. If you have any ideas on a better approach, please refactor :)

    return (
      <div className={styles.ColumnHeaders}>
        <SpacedItems.Container verticalAlign="center">
          {headerPrefix && (
            <SpacedItems.Item key="prefix">{headerPrefix}</SpacedItems.Item>
          )}
          {headers.map(
            ({
              key: itemKey,
              sortKey,
              title,
              sortable = true,
              gap,
              flexBasis,
            }) =>
              sortable ? (
                <SpacedItems.Item key={itemKey} gap={gap} basis={flexBasis}>
                  <HeaderCell
                    sortKey={sortKey}
                    title={title}
                    currentSortKey={key}
                    currentSortDirection={direction}
                    onClick={onClick}
                  />
                </SpacedItems.Item>
              ) : (
                <HeaderCellNoSort
                  title={title}
                  key={itemKey}
                  gap={gap}
                  basis={flexBasis}
                />
              )
          )}
          {headerSuffix && (
            <SpacedItems.Item key="suffix" gap="auto">
              {headerSuffix}
            </SpacedItems.Item>
          )}
        </SpacedItems.Container>
      </div>
    )
  }
)

const directionIcons = {
  asc: 'up',
  desc: 'down',
}
const HeaderCell = ({
  onClick,
  sortKey,
  title,
  currentSortDirection,
  currentSortKey,
}) => {
  const active = sortKey === currentSortKey
  let iconName = 'sort'
  if (active) {
    iconName = `caret ${directionIcons[currentSortDirection]}`
  }

  return (
    <BoxHeadline
      size="small"
      className={styles.HeaderCell}
      // eslint-disable-next-line react/jsx-no-bind
      onClick={() => onClick(sortKey)}
    >
      {title}{' '}
      <Icon
        name={iconName}
        color={active ? themeVars.blackColorName : undefined}
      />
    </BoxHeadline>
  )
}

const HeaderCellNoSort = ({ title, ...rest }) => {
  return (
    <SpacedItems.Item {...rest}>
      <BoxHeadline size="small" className={styles.HeaderCellNoSort}>
        {title}{' '}
      </BoxHeadline>
    </SpacedItems.Item>
  )
}

const SortableList = ({
  children,
  headers,
  headerPrefix,
  headerSuffix,
  listPrefix,
  initialSortKey = 'id',
  initialSortDirection = 'asc',
}) => {
  const [sorting, setSorting] = useState({
    key: initialSortKey,
    direction: initialSortDirection,
  })

  const handleColumnChange = useCallback(
    newKey => {
      setSorting(currentSorting => {
        if (newKey === currentSorting.key) {
          return {
            key: newKey,
            direction: currentSorting.direction === 'asc' ? 'desc' : 'asc',
          }
        }
        return {
          key: newKey,
          direction: 'asc',
        }
      })
    },
    [setSorting]
  )

  const sortedChildren = useMemo(
    () => {
      const collator = new Intl.Collator('en', {
        numeric: true,
        sensitivity: 'base',
      })
      const result = React.Children.toArray(children)
      const { direction, key } = sorting
      let missingSortKey = false
      result.some(child => {
        if (!Object.prototype.hasOwnProperty.call(child.props, key)) {
          missingSortKey = true
          return true
        }
        return false
      })
      if (missingSortKey) {
        throw new Error(
          `SortableList first-level child missing prop for sort key "${key}". To fix, add a prop to the child with this name.`
        )
      }
      if (direction === 'asc') {
        result.sort((a, b) => collator.compare(a.props[key], b.props[key]))
      } else {
        result.sort((a, b) => collator.compare(b.props[key], a.props[key]))
      }
      return result
    },
    [children, sorting]
  )

  return (
    <React.Fragment>
      <ColumnHeaders
        onChange={handleColumnChange}
        sorting={sorting}
        headers={headers}
        headerPrefix={headerPrefix}
        headerSuffix={headerSuffix}
      />
      {listPrefix}
      <React.Fragment>{sortedChildren}</React.Fragment>
    </React.Fragment>
  )
}

export const SortableListItem = ({ children }) => {
  return <React.Fragment>{children}</React.Fragment>
}

export default SortableList
