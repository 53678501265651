// Wrapper around SUI Dropdown that remaps prop.options, adding Groove
// 'enter arrow' icon next to the selected one.
//
// Note: It mutates the options, replacing text/image/description with content.
//
// Note: SUI defines "selected" as the one currently selected by KB shortcut.
// "Active" means the current "chosen" one. So Selected <> Chosen!!
// Confusing AF, I know. Also note, that by default, selectOnNavigation` is set
// which updates `active` to be the currently `selected` item whenever you use
// the keyboard to navigate.
//
import React from 'react'

import { withTheme } from '@emotion/react'
import cn from 'classnames'
import { isEmpty } from 'util/arrays'
import { getDisplayName } from 'util/hoc'

import SelectableItem from './SelectableItem'
import styles from './styles.less'

function withSelectedItemIndicator(WrappedDropdown) {
  class WithSelectedItemIndicator extends React.Component {
    state = { decoratedOptions: [] }

    // track selected item changes passed in from props.options
    componentWillReceiveProps(nextProps) {
      if (nextProps.options !== this.props.options) {
        this.setState({
          decoratedOptions: this.withIndicators(nextProps),
        })
      }
    }

    // Returns the decorated options in local state, if present.
    // Otherwise returns given `options` from props (if present)
    getOptions = () => {
      const { decoratedOptions } = this.state
      if (!isEmpty(decoratedOptions)) return decoratedOptions
      return this.withIndicators(this.props)
    }

    // remaps options.text (and .image + .description) into options.content items
    // that have placeholder spans for the return arrow. Mutates options and
    // removes those remapped fields.<Paste>
    withIndicators = props => {
      const { options = [], arrowColor } = props
      return options.map(opt => {
        return {
          ...opt,
          text: undefined,
          image: undefined,
          description: undefined,
          // if a selected BG color is given, then we always use that for
          // all hover colors. We also use it as bg for currently selected option.
          className: cn({ [styles.divider]: opt.divider }),
          content: <SelectableItem arrowColor={arrowColor} {...opt} />,
        }
      })
    }

    render() {
      const {
        // dont passthru theme, colors etc that are handled by this HOC
        arrowColor,
        showSelectedIndicator,
        theme,
        ...rest
      } = this.props

      if (!showSelectedIndicator) return <WrappedDropdown {...rest} />

      return (
        <WrappedDropdown
          {...rest}
          options={this.getOptions()} // order important. override props.options
        />
      )
    }
  }

  WithSelectedItemIndicator.displayName = `WithSelectedItemIndicator(${getDisplayName(
    WrappedDropdown
  )})`

  return withTheme(WithSelectedItemIndicator)
}

export default withSelectedItemIndicator
