import * as types from 'constants/action_types'

function increment(object, key) {
  object[key] ? object[key]++ : (object[key] = 1)
}

const firstName = obj => (obj.name ? obj.name.split(' ')[0] : '')

const defaultState = {}
const reducers = {}

reducers[types.FETCH_TICKET_SUCCESS] = function(state, action) {
  const data = action.data
  const ticket = data.ticket
  const customer = ticket.customer
  const assignee = ticket.assignee
  const actions = ticket.actions.records
  let i
  const len = actions.length

  const cache = Object.assign({}, state.ticketNameCache || {})

  if (customer) {
    const first_name = firstName(customer)
    cache[customer.email] = {
      name: customer.name,
      first_name,
    }
  }
  if (assignee) {
    const first_name = firstName(assignee)
    cache[assignee.email] = {
      name: assignee.name,
      first_name,
    }
  }

  for (i = 0; i < len; i++) {
    const ticketAction = actions[i]
    const actor = ticketAction.actor
    const email = actor.email
    if (!cache[email]) {
      const first_name = firstName(actor)
      cache[email] = {
        name: actor.name,
        first_name,
      }
    }
  }

  const counts = {}
  for (const key in cache) {
    const user = cache[key]
    increment(counts, user.first_name)
    increment(counts, user.name)
  }

  for (const key in cache) {
    const user = cache[key]
    const found = [user.first_name, user.name].find(
      candidate => counts[candidate] < 2
    )
    user.shortName = found || user.name
  }

  return Object.assign({}, state, {
    ticketNameCache: cache,
  })
}

export default function reducer(state = defaultState, action) {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
