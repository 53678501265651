// Wrapper around our Typography elemens that adds spacing and color props
//
// You can pass an optional `color` prop which will override the base SUI color
//
import styled from '@emotion/styled'
import { optionalColor, fontWeight, fontSize } from './theme'
import { truncate } from './styles'

function withTypography(Elem) {
  // remove the props that we dont want to passthru to underlying component (or
  // to the DOM element)
  return styled(
    // eslint-disable-next-line no-shadow
    ({ fontWeight, fontSize, color, truncate, wrap, ...rest }) => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Elem {...rest} />
    )
  )`
    &&& {
      ${optionalColor}
      ${fontWeight}
      ${fontSize}
      ${truncate}
    }
  `
}

export default withTypography
