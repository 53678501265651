export const selectSelectedSnoozeState = state =>
  state.tickets.selectedSnoozeState

export const selectTicketsSorting = state => state.tickets.sorting

export const selectCommentMap = state => state.tickets.commentMap

export const selectLastSeenFolderId = state => state.tickets.lastSeenFolderId

export const selectNoteFormHeight = state => state.tickets.noteFormHeight || 0

export const selectOptimisticMergeTicketById = state =>
  state.tickets.optimisticMergeTicketsById

export const selectMergeSnippetsVisibility = state =>
  !!state.tickets.showMergeSnippets

export const selectSnippetsById = state => state.tickets.snippetsById || {}
