/* eslint-disable react/jsx-filename-extension */
// Wrapper around SUI Dropdown that
//   1. Renders either a 'basic' or search-in-menu dropdown
//   2. Adds spacing shortcut props
//
import PropTypes from 'prop-types'
import { notImplemented } from 'util/functions'
import SearchInMenu from './SearchInMenu'
import Basic from './Basic'

const Dropdown = ({ searchInMenu, ...rest }) => {
  if (!searchInMenu) return <Basic {...rest} />

  return <SearchInMenu {...rest} />
}

Dropdown.propTypes = {
  /* Option arrow color */
  arrowColor: PropTypes.string,

  // Callback when DD is blurred
  onBlur: PropTypes.func,

  // Callback when DD is closed.
  onClose: PropTypes.func,

  // Shows a search-in-menu with Groove's custom SearchBox/Divider
  searchInMenu: PropTypes.bool,

  // Shows the return icon next to the active option
  showSelectedIndicator: PropTypes.bool,

  // The rest are per SUI Dropdown...
}

Dropdown.defaultProps = {
  arrowColor: undefined,
  onBlur: notImplemented('onBlur'),
  onClose: notImplemented('onClose'),
  searchInMenu: false,
  showSelectedIndicator: true,
}

Dropdown.displayName = 'Dropdown'

export default Dropdown
