// Less janky vertical Divider
// NOTE: you should use SUI for horizontal one!
import React from 'react'
import styled from '@emotion/styled'
import cn from 'classnames'

const sizes = ({ size, theme: { fontSize } }) => {
  switch (size) {
    case 'trump':
      return {
        height: fontSize.small,
      }
    case 'xxsmall':
      return {
        height: fontSize.base,
      }
    case 'xsmall':
      return {
        height: fontSize.x2large,
      }
    case 'small':
      return {
        height: fontSize.x3large,
      }
    case 'medium':
      return {
        height: fontSize.x5large,
      }
    case 'large':
      return {
        height: fontSize.x6large,
      }
    default:
      return {
        height: fontSize.x5large,
      }
  }
}

const Divider = ({ className }) => (
  <div className={cn(className, { vertical: true })} />
)

Divider.defaultProps = {
  size: 'xsmall',
}

Divider.displayName = 'Divider'

export default styled(Divider)`
  -webkit-transform: translateX(-50%);
  line-height: 0;
  text-align: center;
  transform: translateX(-50%);
  width: auto;
  z-index: 2;

  &:before {
    border-left: 2px solid ${props => props.theme.color.monochrome.medium};
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    content: '';
    height: calc(100%);
    left: 50%;
    position: absolute;
    width: 0%;
    z-index: 3;
  }

  ${sizes};
`
