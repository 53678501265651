/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
// "Simple" HOC that passes down the left & top ( & bottom if 'upward') coords
// wrt the Trigger. Assumes the Trigger component takes those props and passes
// them to the (child) menu component.
//
// NOTE: old method where Trigger wraps the Menu (modal) is jank. Consider
// inverting those a la SUI DDs.
import React from 'react'
import PropTypes from 'prop-types'
import deepEqual from 'fast-deep-equal'
import requestIdleCallback from 'ric-shim'
import { getDisplayName } from 'util/hoc'
import { throttle } from 'util/functions'
import { clamp } from 'util/numbers'
import { getClosestVisibleParent } from 'util/dom'

function withMenuPositioning(Trigger, initProps) {
  class WithMenuPositioning extends React.PureComponent {
    state = {
      left: undefined,
      top: undefined,
      bottom: undefined,
      hidden: true,
    }

    componentWillMount() {
      window.addEventListener('resize', this.onResizeThrottled)
      window.addEventListener('panelGroupUpdated', this.onResizeThrottled)
      window.addEventListener('betaBarHidden', this.onResizeThrottled)
      window.addEventListener('replyHeaderToggled', this.onResizeThrottled)
      window.addEventListener('editorResized', this.onResizeThrottled)
    }

    componentDidMount() {
      this.onResize()
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.onResizeThrottled)
      window.removeEventListener('panelGroupUpdated', this.onResizeThrottled)
      window.removeEventListener('betaBarHidden', this.onResizeThrottled)
      window.removeEventListener('replyHeaderToggled', this.onResizeThrottled)
      window.removeEventListener('editorResized', this.onResizeThrottled)
    }

    onResize = () => {
      requestIdleCallback(() => {
        if (!this.button) return false
        return this.recalc()
      })
    }

    onResizeThrottled = throttle(this.onResize, 20)

    getProps = () => {
      const props = {
        defaultOffset: [0, 8],
        direction: 'right',
        includeWidth: false,
        includeHeight: true,
        keepOnScreen: true,
        shouldDoPositioning: true,
        upward: false,
        ...initProps,
        ...this.props,
      }
      if (
        (props.keepOnScreen || props.keepOnScreen === 0) &&
        props.keepOnScreenBy === undefined
      ) {
        props.keepOnScreenBy =
          props.keepOnScreen === true ? 0 : props.keepOnScreen
      }
      return props
    }

    getButtonBoundingClientRect = () => {
      if (!this.button) return this.elemRect || {}
      // We have to extract to object otherwise deepEqual doesn't compare the DOMRects correctly
      const {
        top,
        left,
        right,
        bottom,
        width,
        height,
        x,
        y,
      } = this.button.getBoundingClientRect()

      let elemRect = {
        top,
        left,
        right,
        bottom,
        width,
        height,
      }

      if (width === 0 && height === 0 && x === 0 && y === 0) {
        // button trigger is hidden, so dropdown cannot be bound to it
        // try find closest parent that is not hidden
        const parentNode = getClosestVisibleParent(this.button)
        if (parentNode) {
          const clientRectParent = parentNode.getBoundingClientRect()

          elemRect = {
            top: clientRectParent.top,
            left: clientRectParent.left,
            right: clientRectParent.right,
            bottom: clientRectParent.bottom,
            width: clientRectParent.width,
            height: clientRectParent.height,
          }
        }
      }

      if (!deepEqual(this.elemRect, elemRect)) {
        this.elemRect = elemRect
      }
      return this.elemRect
    }

    getMenuBoundingClientRect = () => {
      if (!this.menu || !this.menu.getBoundingClientRect)
        return this.menuElemRect || {}
      // We have to extract to object otherwise deepEqual doesn't compare the DOMRects correctly
      const { top, left, right, bottom, width, height } =
        this.menu.getBoundingClientRect() || {}
      const menuElemRect = {
        top,
        left,
        right,
        bottom,
        width,
        height,
      }
      if (!deepEqual(this.menuElemRect, menuElemRect)) {
        this.menuElemRect = menuElemRect
      }
      return this.menuElemRect
    }

    calcLeft = elemRect => {
      const { defaultOffset, direction, includeWidth } = this.getProps()
      if (direction !== 'right') return undefined
      return (
        elemRect.left + (includeWidth ? elemRect.width : 0) + defaultOffset[0]
      )
    }

    calcRight = elemRect => {
      const { defaultOffset, direction, includeWidth } = this.getProps()
      if (direction !== 'left') return undefined
      if (typeof document === 'undefined') return undefined
      if (!document.body) return undefined
      // To calc the absolute right, need width of body
      const bodyRect = document.body.getBoundingClientRect()
      return (
        bodyRect.width -
        elemRect.right +
        +(includeWidth ? elemRect.width : 0) +
        defaultOffset[0]
      )
    }

    calcTop = elemRect => {
      const {
        defaultOffset,
        includeHeight,
        keepOnScreen,
        keepOnScreenBy,
        upward,
      } = this.getProps()
      if (upward) return undefined
      const calculatedTop =
        elemRect.top + (includeHeight ? elemRect.height : 0) + defaultOffset[1]
      const keepOnScreenByTop =
        keepOnScreenBy.top || keepOnScreenBy.top === 0
          ? keepOnScreenBy.top
          : keepOnScreenBy
      const keepOnScreenByBottom =
        keepOnScreenBy.bottom || keepOnScreenBy.bottom === 0
          ? keepOnScreenBy.bottom
          : keepOnScreenBy
      return keepOnScreen
        ? clamp(
            keepOnScreenByTop + defaultOffset[1],
            document.body.clientHeight -
              defaultOffset[1] -
              keepOnScreenByBottom -
              (this.getMenuBoundingClientRect().height || 0),
            calculatedTop
          )
        : calculatedTop
    }

    calcBottom = elemRect => {
      const { defaultOffset, includeHeight, upward } = this.getProps()
      if (!upward) return undefined
      if (typeof document === 'undefined') return undefined
      if (!document.body) return undefined
      // To calc the absolute bottom, need height of body
      const bodyRect = document.body.getBoundingClientRect()
      const bottom = bodyRect.bottom - elemRect.bottom
      return bottom + (includeHeight ? elemRect.height : 0) + defaultOffset[1]
    }

    saveButtonRef = node => (this.button = node)
    saveMenuRef = node => {
      const wasUnset = !this.menu
      this.menu = node
      if (wasUnset) setTimeout(() => this.recalc(), 1) // Defer so that node has a chance to render otherwise height is 0
    }

    recalc = (force = false) => {
      if (!this.getProps().shouldDoPositioning && !force) return
      if (!this.button) return
      const elemRect = this.getButtonBoundingClientRect()
      this.setState({
        elemRect, // useful for debugging
        left: this.calcLeft(elemRect),
        right: this.calcRight(elemRect),
        top: this.calcTop(elemRect),
        bottom: this.calcBottom(elemRect),
        hidden: false,
      })
    }

    handleOpen = (...args) => {
      this.recalc(true)
      const { onOpen } = this.getProps()
      if (onOpen) onOpen(...args)
    }

    render() {
      const { className } = this.getProps()
      const { left, right, top, bottom, hidden } = this.state
      return (
        <div className={className} ref={this.saveButtonRef}>
          <Trigger
            {...this.getProps()}
            doRecalc={this.recalc}
            left={left}
            menuRef={this.saveMenuRef}
            right={right}
            top={top}
            bottom={bottom}
            onOpen={this.handleOpen}
            hidden={hidden}
          />
        </div>
      )
    }
  }

  WithMenuPositioning.displayName = `WithMenuPositioning(${getDisplayName(
    Trigger
  )})`

  WithMenuPositioning.propTypes = {
    /* default spacing between trigger and modal menu */
    defaultOffset: PropTypes.number,

    /* the direction the menu opens */
    direction: PropTypes.oneOf(['right', 'left']),

    includeWidth: PropTypes.bool,

    includeHeight: PropTypes.bool,

    // Attempt to keep the menu on screen
    // Only works for `top` as is designed to deal with vertical scroll.
    // Same logic could be applied to other properties if/when needed.
    keepOnScreen: PropTypes.bool,

    // Set to true to prevent recalculation when not required
    // (normally when the menu is hidden)
    shouldDoPositioning: PropTypes.bool,

    /* positions the menu above the trigger */
    upward: PropTypes.bool,
  }

  // default prop types defined in getProps to prevent them overriding initProps

  return WithMenuPositioning
}

export default withMenuPositioning
