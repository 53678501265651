import cn from 'classnames'
import moment from 'moment'
import React from 'react'

import DatePicker from 'shared/components/ui/DatePicker'
import { Input } from 'util/ui'
import { runOnNextTick } from 'util/functions'
import styles from './styles.less'

export default class DateInput extends React.PureComponent {
  constructor(props) {
    super(props)
    this.doRefocus = this.doRefocus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleDayClick = this.handleDayClick.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.shouldShowDatePickerAbove = this.shouldShowDatePickerAbove.bind(this)
    this.inputRef = React.createRef()
    this.state = {}
  }

  doRefocus() {
    this.refocus = true
  }

  handleBlur() {
    if (this.refocus) {
      // I'm not entirely sure why it is needed, but Firefox needs this in the next tick
      runOnNextTick(() => this.inputRef.current.focus())
      this.refocus = false
    } else {
      const {
        props: { onChange, value },
        state: { date: currentDate, tempDate, tempDateString },
      } = this
      const { dateFromPicker } = this
      this.dateFromPicker = null
      const date =
        tempDateString === ''
          ? null
          : dateFromPicker || tempDate || value || currentDate || null
      if (onChange) onChange(date)
      this.setState({
        focused: false,
        tempDate: null,
        tempDateString: null,
        date,
      })
    }
  }

  handleDayClick(date) {
    const {
      props: { onDayClick },
    } = this
    this.dateFromPicker = date
    this.inputRef.current.inputRef.current.blur()
    if (onDayClick) onDayClick(date)
  }

  handleChange(event, { value }) {
    const tempDate = moment.utc(value)
    this.setState({
      tempDate: tempDate && tempDate.isValid() ? tempDate.toDate() : null,
      tempDateString: value,
    })
  }

  handleFocus() {
    this.setState({ focused: true })
  }

  shouldShowDatePickerAbove() {
    const rect = this.inputRef.current.inputRef.current.getBoundingClientRect()
    const top = rect.top
    const bottom = window.innerHeight - rect.bottom
    return bottom < 320 && top > bottom
  }

  render() {
    const { disabled, nullValue, value: propsDate } = this.props
    const { date: stateDate, focused, tempDate, tempDateString } = this.state
    const date = nullValue ? null : propsDate || stateDate
    const hasTempDateString = tempDateString || tempDateString === ''
    const tempDateOrDate = tempDate || date || new Date()
    return (
      <div className={styles.DateInput}>
        <Input
          className={styles.Input}
          disabled={disabled}
          icon="calendar alternate outline"
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          placeholder={'YYYY-MM-DD'}
          ref={this.inputRef}
          value={
            hasTempDateString
              ? tempDateString
              : (date && date.toISOString().substr(0, 10)) || ''
          }
        />
        {focused &&
          !disabled && (
            <DatePicker
              className={cn(styles.DatePicker, {
                [styles.DatePicker_above]: this.shouldShowDatePickerAbove(),
              })}
              key={tempDateOrDate && tempDateOrDate.toISOString()}
              initialMonth={tempDateOrDate}
              onMouseDown={this.doRefocus}
              onDayClick={this.handleDayClick}
              selectedDate={tempDateOrDate}
            />
          )}
      </div>
    )
  }
}
