// Props Proxy HOC that adds Esc key handling to unfocus the editor
import React from 'react'
import { getDisplayName } from 'util/hoc'
import { ESCAPE } from 'constants/keys'

function withEscKeyUnfocus(WrappedEditor) {
  class WithEscKeyUnfocus extends React.Component {
    handleKeyDown = e => {
      const { onKeyDown } = this.props
      if (e.keyCode === ESCAPE) this.blur()
      if (onKeyDown) onKeyDown(e)
    }

    blur = () => {
      window.focus()
    }

    render() {
      return <WrappedEditor {...this.props} onKeyDown={this.handleKeyDown} />
    }
  }

  WithEscKeyUnfocus.displayName = `WithEscKeyUnfocus(${getDisplayName(
    WrappedEditor
  )})`

  return WithEscKeyUnfocus
}

export default withEscKeyUnfocus
