// Inbox themed outlined Button
import styled from '@emotion/styled'
import Button from './Simple'

const getHoverColor = props => {
  if (props.disabled) return null
  if (props.hoverColor) return props.theme.color[props.hoverColor]
  return props.theme.color.primary.brand(props)
}

const ButtonOutline = styled(Button)`
  border: 1px solid ${props => props.theme.color.monochrome.medium};
  background-color: transparent;
  color: ${props => props.theme.color.monochrome.mediumDark};
  text-transform: none; /* SUI override */

  &:hover {
    color: ${getHoverColor};
    background-color: transparent;
    border: 1px solid ${getHoverColor};
    transition: background-color 175ms ease-in-out, border 175ms ease-in-out,
      color 175ms ease-in-out;

    .Icon {
      line-height: 15px; /* hack: icon height matches medium button text height */
      color: ${getHoverColor};
      transition: color 175ms ease-in-out;
    }
  }

  .Icon {
    line-height: 15px; /* hack: icon height matches medium button text height */
    color: ${props => props.theme.color.monochrome.mediumDark};
  }
`

ButtonOutline.displayName = 'ButtonOutline'

export default ButtonOutline
