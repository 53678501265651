import React from 'react'
import cn from 'classnames'

import Flex from 'shared/ui/Flex'
import BoxHeadline from 'shared/ui/BoxHeadline'

const NoResults = ({ className, text = 'No results found' }) => (
  <Flex className={cn('grui m-20 items-center justify-center', className)}>
    <BoxHeadline>{text}</BoxHeadline>
  </Flex>
)

export default NoResults
