import { createSelector } from 'reselect'
import intersectionBy from 'lodash.intersectionby'
import { selectSelectedTicketIds } from 'selectors/ticket_list/base'
import { selectTopLabels, selectLabelsById } from 'selectors/labels'
import { selectSelectedTicketsOrCurrentTicketLabels } from 'selectors/tickets'
import { selectRawById } from 'selectors/tickets/byId/selectRawById'
import { selectCurrentTicketUndecorated } from 'selectors/tickets/current/selectCurrentTicketUndecorated'
import { selectTicketsById } from 'selectors/tickets/byId/selectTicketsById'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'

import { diff, getLength, sortByKey, uniqByProp } from 'util/arrays'
import { getId } from 'util/labels'
import {
  commonLabelsFromSelection,
  uncommonLabelsFromSelection,
  commonTopLabelsFromSelection,
  unusedTopLabelsFromSelection,
  labelSelection,
} from 'util/label_selection'
import { mapObject } from 'util/objects'
import { compareCaseInsensitive } from 'util/strings'

import {
  selectAdded,
  selectRemoved,
  selectRemovedIds,
  selectCreated,
} from './base'
import { selectIsLabelingModalOpen } from './edit'
import {
  selectLabelSearchResultsWithCounts,
  selectIsSearchingLabels,
  selectCurrentLabelSearchResultsForFilter,
  selectLabelSearchTerm,
} from './search'

export const selectActiveLabelIndex = state => state.activeLabelIndex

// Selects the label sets of currently selected tickets
const selectLabelSetsForSelectedTickets = createSelector(
  selectRawById,
  selectSelectedTicketIds,
  // dodgy hack. Make this work for single tickets if not doing a bulk selection
  (tickets, selected) => {
    return selected.map(id => {
      if (!tickets || !tickets[id]) return []
      return tickets[id].labels
    })
  }
)

// Selects the label sets of the current ticket
const selectLabelSetForCurrentTicket = createSelector(
  selectCurrentTicketUndecorated,
  ticket => {
    if (!ticket) return []
    return [ticket.labels] // expects sets of labels.
  }
)

// bit of a dodgy hack. falls back to assuming you are doing a single ticket label editing
const selectLabelSets = createSelector(
  selectSelectedTicketIds,
  selectLabelSetsForSelectedTickets,
  selectLabelSetForCurrentTicket,
  // dodgy hack. Make this work for single tickets if not doing a bulk selection
  (selected, selectedLabels, currentTicketLabels) => {
    if (!selected || getLength(selected) === 0) return currentTicketLabels
    return selectedLabels
  }
)

// Selects the unique labels of all currently selected tickets
export const selectLabelsForSelectedTickets = createSelector(
  selectLabelSets,
  labelSets => {
    return uniqByProp(
      labelSets.reduce((acc, labels) => acc.concat(labels), []),
      'id'
    )
  }
)

// Selects the labels that are common across all currently selected tickets
export const selectCommonLabelsForSelectedTickets = createSelector(
  selectLabelSets,
  labelSets => {
    return intersectionBy(...labelSets, 'id')
  }
)

// Selects the labels that are not common across all currently selected tickets
export const selectUncommonLabelsForSelectedTickets = createSelector(
  selectLabelsForSelectedTickets,
  selectCommonLabelsForSelectedTickets,
  (all, common) => {
    return diff(all, common)
  }
)
// Builds the current label selection state.
export const selectLabelSelection = createSelector(
  selectAdded,
  selectRemoved,
  selectCommonLabelsForSelectedTickets,
  selectUncommonLabelsForSelectedTickets,
  selectTopLabels,
  selectCreated,
  (added, removed, common, uncommon, top, created) =>
    labelSelection({ added, removed, common, uncommon, top, created })
)

// Selects the current common labels (i.e. minus the removed labels)
export const selectSelectedCommonLabels = createSelector(
  selectLabelSelection,
  selection => commonLabelsFromSelection(selection)
)

// Selects the current partial/uncommon labels (minus the removed labels)
export const selectSelectedUncommonLabels = createSelector(
  selectLabelSelection,
  selection => uncommonLabelsFromSelection(selection)
)

// Selects the current common labels (i.e. minus the removed labels)
export const selectSelectedCommonTopLabels = createSelector(
  selectLabelSelection,
  selection => commonTopLabelsFromSelection(selection)
)

// Selects the current partial/uncommon labels (minus the removed labels)
export const selectSelectedUnusedTopLabels = createSelector(
  selectLabelSelection,
  selection => unusedTopLabelsFromSelection(selection).filter(e => e)
)

export const selectSelectedUnusedTopLabelsSorted = createSelector(
  selectSelectedUnusedTopLabels,
  labels => labels.sort((a, b) => compareCaseInsensitive(a.name, b.name))
)

export const selectCurrentSelectableLabels = createSelector(
  selectIsSearchingLabels,
  selectLabelSearchResultsWithCounts,
  selectLabelSelection,
  selectSelectedCommonLabels,
  selectSelectedUncommonLabels,
  selectSelectedCommonTopLabels,
  selectSelectedUnusedTopLabelsSorted,
  (
    isSearching,
    searchResults,
    selection,
    commonLabels,
    uncommonLabels,
    commonTopLabels,
    unusedTopLabels
  ) => {
    if (isSearching) return searchResults
    return []
      .concat(
        commonLabels,
        uncommonLabels,
        commonTopLabels,
        selection.removed,
        unusedTopLabels
      )
      .filter(x => !!x)
  }
)

export const selectActiveLabel = createSelector(
  selectActiveLabelIndex,
  selectCurrentSelectableLabels,
  (index, labels) => {
    const labelFromIndex = labels[index]
    if (labelFromIndex) return labelFromIndex
    return labels[labels.length - 1]
  }
)

function addCheckedState(labels = [], checkedLabelIds = []) {
  return labels.map(label => ({
    ...label,
    status: checkedLabelIds.includes(label.id) ? 'checked' : null,
  }))
}

function addIndeterminateState(
  labels = [],
  checkedLabelIds = [],
  promoteLabelIds = []
) {
  const labelsWithStatusAndRank = labels.map(label => {
    const status = checkedLabelIds.includes(label.id) ? 'checked' : null
    const indeterminate =
      !checkedLabelIds.includes(label.id) && label.status === 'checked'

    // this moves the label ids to the top of the array, used for newly added labels
    const positionRank = promoteLabelIds.includes(label.id) ? 0 : 1

    return {
      ...label,
      indeterminate,
      status,
      positionRank,
    }
  })

  return sortByKey(labelsWithStatusAndRank, 'positionRank')
}

function mergeLabelLists(labels = [], added) {
  return uniqByProp(labels.concat(added), 'id').filter(e => e)
}

// The base undecorated list of tags we see: all current labelings,merged with a
// list of the top labels.
// We also include any just-created labels (in this modal "session"). That way
// if you create a label from the Edit Modal, and you uncheck that label, it
// doesnt disappear from the list.
const selectDefaultViewLabels = createSelector(
  selectSelectedTicketsOrCurrentTicketLabels,
  selectTopLabels,
  selectAdded, // we may have added some from search view
  selectCreated, // we may have created some from Create form view
  (current, top, added, created) =>
    [current, top, added, created].reduce(
      (acc, list) => mergeLabelLists(acc, list),
      []
    )
)

// All the labels on the current ticket, plus the added ones, minus the removed
// ones (where added/removed refers to the yet-to-be-applied labelings)
export const selectCheckedLabels = createSelector(
  selectSelectedTicketsOrCurrentTicketLabels,
  selectAdded,
  selectRemovedIds,
  (labels, added, removedIds) =>
    mergeLabelLists(labels, added).filter(l => !removedIds.includes(l.id))
)

export const selectCheckedLabelIds = createSelector(
  selectCheckedLabels,
  labels => labels.map(getId)
)

// The current list decorated with their current 'checked' status.
// We decorate the checked status of each top/current/recently-created label
// depending on whether it appears in current or added or removed.
const selectLabelsForEditingInDefaultView = createSelector(
  selectDefaultViewLabels, // the full list
  selectCheckedLabelIds, // the selected ones
  (list, checkedIds) => {
    return addCheckedState(list, checkedIds)
  }
)

// If we are searching, we want only the search results with those that are
// already tagged on the ticket with the checked status
const selectLabelsForEditingInSearchView = createSelector(
  selectCheckedLabelIds,
  selectCurrentLabelSearchResultsForFilter,
  (currentLabelIds, searchResults) => {
    return addCheckedState(searchResults, currentLabelIds)
  }
)

/* Edit Modal label lists */
export const selectLabelsForEditing = createSelector(
  selectIsSearchingLabels,
  selectLabelsForEditingInDefaultView,
  selectLabelsForEditingInSearchView,
  (isSearching, defaultList, searchList) => {
    return !isSearching ? defaultList : searchList
  }
)

// Takes into account whether or not the edit labels modal is open - which means
// the user has not yet applied the checked labels (current+added-removed). This
// list is what we show as the 'current' list of tags on the ticket (and list).
// This means, it returns the same checked labels once they are 'applied' (or at
// least once the modal is closed and the bulk update is being debounced. So it
// is ideal for optimistically showing the applied labels.
export const selectLabelsWithAppliedUpdates = createSelector(
  selectIsLabelingModalOpen,
  selectSelectedTicketsOrCurrentTicketLabels,
  selectCheckedLabels,
  (isEditing, currentLabels, checkedLabels) => {
    return isEditing ? currentLabels : checkedLabels
  }
)

// A map of all tickets keyed by ticketId. The 'current' ticket labels are shown
// with any applied (optimistic) labelings (but not unapplied labelings)
export const selectTicketLabelMap = createSelector(
  selectLabelsById,
  selectTicketsById,
  selectCurrentTicketId,
  selectLabelsWithAppliedUpdates,
  (labels, tickets, currentTicketId, currentLabelings) => {
    const res = mapObject(t => {
      if (t.id === currentTicketId) return currentLabelings
      return t.labelIds.map(id => labels[id])
    })(tickets)
    return res
  }
)

export const selectLabelsForBulkEditing = createSelector(
  selectLabelsForEditing,
  selectCommonLabelsForSelectedTickets,
  selectAdded,
  selectRemoved,
  (allLabels, commonLabels, addedLabels, removedLabels) => {
    const commonIds = commonLabels.map(l => l.id)
    const addedIds = addedLabels.map(l => l.id)
    const removedIds = removedLabels.map(l => l.id)
    const all = []
      .concat(commonIds, addedIds)
      .filter(id => !removedIds.includes(id))

    return addIndeterminateState(allLabels, all, addedIds)
  }
)

export const selectLabelsForBulkEditingWithCreateOption = createSelector(
  selectLabelsForBulkEditing,
  selectLabelSearchTerm,
  (allLabels, searchTerm) => {
    if (!searchTerm) {
      return allLabels
    }
    return [
      ...allLabels,
      {
        color: '',
        id: -1,
        labelingsCount: 0,
        name: searchTerm,
        status: null,
      },
    ]
  }
)
