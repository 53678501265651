import cn from 'classnames'
import React from 'react'
import DayPicker, { LocaleUtils } from 'react-day-picker'

import { advanceMonth, isFirstOfMonth, isLastOfMonth } from 'util/date'
import themeVars from 'ui_theme/site/globals/site.variables'

import CalendarNav from './CalendarNav'
import MonthTitle from './MonthTitle'
import styles from './styles.less'
import WeekDayShort from './WeekDayShort'

const myLocalUtils = {
  ...LocaleUtils,
  formatMonthTitle: MonthTitle,
  formatWeekDayShort: WeekDayShort,
}

export default function DatePickerView({
  animating,
  className,
  disabledDays,
  doNextMonth,
  doPrevMonth,
  month = new Date(),
  monthWidth = themeVars.calendarMonthWidth,
  displayNumberOfMonths = 1,
  onMouseDown,
  onDayClick,
  selectedDate,
  selectedRangeEnd,
  selectedRangeStart,
  prevNavDisabled,
  nextNavDisabled,
  warningFromDate,
}) {
  return (
    <div
      className={cn('DatePicker', styles.DatePicker, className)}
      onMouseDown={onMouseDown}
      style={{
        width: parseInt(monthWidth, 10) * displayNumberOfMonths,
      }}
    >
      <div className={cn(styles.DatePickerCalenderNavPane)}>
        <CalendarNav onClick={doPrevMonth} disabled={prevNavDisabled} />
        <CalendarNav onClick={doNextMonth} disabled={nextNavDisabled} right />
      </div>
      <DayPicker
        canChangeMonth={false}
        className={cn(styles.DayPicker, styles[animating], {
          [styles.DayPicker_singleMonth]: displayNumberOfMonths === 1,
        })}
        disabledDays={disabledDays}
        firstDayOfWeek={1}
        localeUtils={myLocalUtils}
        modifiers={{
          firstOfMonth: isFirstOfMonth,
          lastOfMonth: isLastOfMonth,
          firstOfWeek: { daysOfWeek: [1] },
          lastOfWeek: { daysOfWeek: [0] },
          selectedDate,
          selectedRangeEnd,
          selectedRangeStart,
          weekend: { daysOfWeek: [0, 6] },
          ...(warningFromDate && {
            warning: {
              after: warningFromDate,
            },
          }),
        }}
        month={advanceMonth(month, -1)}
        numberOfMonths={displayNumberOfMonths + 2}
        onDayClick={onDayClick}
        tabIndex={-1}
        selectedDays={[
          selectedRangeStart || selectedDate,
          {
            from: selectedRangeStart || selectedDate,
            to: selectedRangeEnd || selectedDate,
          },
        ]}
        showOutsideDays
      />
    </div>
  )
}
