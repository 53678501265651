import { createSelector } from 'reselect'
import { downcase } from 'util/strings'
import { selectKnownMailboxes } from './selectKnownMailboxes'

export const selectMailboxesByName = createSelector(
  selectKnownMailboxes,
  mailboxes =>
    mailboxes.reduce((hash, mailbox) => {
      hash[mailbox.name] = mailbox // eslint-disable-line no-param-reassign
      hash[downcase(mailbox.name)] = mailbox // eslint-disable-line no-param-reassign
      return hash
    }, {})
)
