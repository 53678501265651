import { createSelector } from 'reselect'
import { _selectCommonWords } from './_selectCommonWords'
import { decorate } from './decorate'
import { selectInaccessibleMailboxesRaw } from './selectInaccessibleMailboxesRaw'

export const selectInaccessibleMailboxes = createSelector(
  selectInaccessibleMailboxesRaw,
  _selectCommonWords,
  (inaccessibleMailboxes, commonWords) => {
    if (inaccessibleMailboxes) {
      return inaccessibleMailboxes.map(mailbox =>
        decorate(commonWords)(mailbox)
      )
    }
    return null
  }
)
