import { createSelector } from 'reselect'
import { selectRawById } from '../byId/selectRawById'
import { selectCurrentTicketId } from './selectCurrentTicketId'

export const selectCurrentTicketUndecorated = createSelector(
  selectRawById,
  selectCurrentTicketId,
  (byId, id) => {
    return byId[id] || null
  }
)
