const getColor = color => props => {
  if (!props || !color) return undefined
  return color
}

/**
 * Looks up the theme for color specified by props[prop]
 *
 * Base case for the colorProp fn
 *
 * @param {string} prop The name of the color property e.g. 'hoverColor'
 * @param {object} props - The props passed to the component. Should contain
 * `theme`, and prop (e.g. `hoverColor`)
 *
 * @returns {string} A color code from the theme e.g. '#f1f1f1'.
 */
export const colorPropFrom = (prop = 'color') => props =>
  getColor(props[prop])(props)

/**
 * Looks up the theme for color specified by props.color.
 *
 * Will perfom a mapping on the color name
 *
 * If none found, returns an empty string.
 *
 * @param {object} props - The props passed to the component. Should contain
 * `theme`, and `color`
 * @returns {string} A color code from the theme e.g. '#f1f1f1'.
 */
// GR: NOTE Given
//
//    const prop = p => x => x[p]
//
// Then the expanded form of colorProp would look like;
//
//   export const colorProp = props => {
//     return getColor(prop('color')(props))(props)
//   }
//
// Which takes the form of f(g(x))(x), which could be simplified using Ramda's
// `chain` (aka flatMap)
//
// Another reason for adding Ramda...
//
export const colorProp = colorPropFrom('color')

const colorPropOrNamed = (useColorProp, namedColor) => {
  if (useColorProp) return colorProp
  return getColor(namedColor)
}

// Inverse of colorPropIf
export const colorPropUnless = (testProp, namedColor) => props => {
  return colorPropOrNamed(!props[testProp], namedColor)(props)
}

// Maps the value of the given propName to the same one in the theme, and builds
// an override css from it. If no themeGroupName provided, assumes the
// pluralized propName is the theme grouping name. E.g. 'fontWeight` will look
// under `theme.fontWeights`
//  Smacks !important on it to override SUI.
const lookup = (
  propName, // 'color'
  themeGroupName = undefined, // e.g. 'colors'
  cssPropName = undefined, // e.g. 'color',
  units = ''
) => props => {
  const prop = props[propName]
  if (!prop) return {}

  const themeGroup = themeGroupName || `${propName}s`
  const cssProp = cssPropName || propName

  const groupVal = props.theme[themeGroup] || {}
  const themeValue = groupVal[prop] || prop

  return {
    [cssProp]: `${themeValue}${units} !important`,
  }
}

export const optionalColor = lookup('color')
export const fontWeight = lookup('fontWeight')
export const fontSize = lookup('fontSize', 'fontSize', 'fontSize')
