import createCachedSelector from 're-reselect'
import { selectIsInboxBootstrapped } from 'selectors/app/base'
import { selectIsInInbox } from 'selectors/location'
import { selectPrefersUnifiedInbox } from 'ducks/currentUser/selectors/preferences/selectPrefersUnifiedInbox'
import { toDate } from 'util/date'
import { selectCurrentMailbox } from './selectCurrentMailbox'
import { mailboxesByStatesCacheKey } from './mailboxesByStatesCacheKey'
import { selectHasAnyMailboxByStates } from './selectHasAnyMailboxByStates'

export const selectDoesCurrentMailboxHaveState = createCachedSelector(
  selectCurrentMailbox,
  selectIsInboxBootstrapped,
  selectHasAnyMailboxByStates,
  selectIsInInbox,
  selectPrefersUnifiedInbox,
  (_state, mailboxStates) => mailboxStates,
  (_state, _mailboxStates, createdAfter) => createdAfter,
  (_state, _mailboxStates, _createdAfter, channelTypes) => channelTypes,
  (
    currentMailbox,
    isInboxBootstrapped,
    hasAnyMailboxByStates,
    isInInbox,
    prefersUnifiedInbox,
    mailboxStates,
    createdAfter,
    channelTypes
  ) => {
    if (!isInInbox || !isInboxBootstrapped || !mailboxStates) {
      return false
    }

    if (!currentMailbox) {
      if (prefersUnifiedInbox) {
        // most likely on Unified inbox, search all mailboxes for state
        return hasAnyMailboxByStates
      }
      // On all inboxes, don't seach all mailboxes for state
      return false
    }

    if (createdAfter && toDate(currentMailbox.created_at) < createdAfter) {
      return false
    }

    return (
      mailboxStates.includes(currentMailbox.state) &&
      (!channelTypes || channelTypes.includes(currentMailbox.channel_type))
    )
  }
)(mailboxesByStatesCacheKey)
