import { createSelector } from 'reselect'
import { emptyArr } from 'util/arrays'
import {
  INTEGRATION_METHOD_ADD_TO_EXISITING,
  INTEGRATION_METHOD_CREATE_NEW,
  INTEGRATION_FORM_INITIAL_LOADING,
  INTEGRATION_FORM_NEXT_STEP_HAS_TO_LOAD,
  INTEGRATION_FORM_NEXT_STEP_READY,
  INTEGRATION_FORM_NEXT_STEP_LOADING,
} from 'constants/integrations'
import { selectCurrentUserGlobalId } from 'ducks/currentUser/selectors'

const selectForm = state => state.userIntegrations.form

export const selectIntegrationMethodLabels = () => ({
  [INTEGRATION_METHOD_CREATE_NEW]: 'Create new',
  [INTEGRATION_METHOD_ADD_TO_EXISITING]: 'Add to existing',
})

export const selectIsFormInitialLoading = state =>
  state.userIntegrations.form.initialLoadingState ===
  INTEGRATION_FORM_INITIAL_LOADING

export const selectIsFormNextStepLoading = state =>
  state.userIntegrations.form.nextStepState ===
  INTEGRATION_FORM_NEXT_STEP_LOADING

export const selectIsFormNextStepDisabled = state =>
  state.userIntegrations.form.nextStepState !== INTEGRATION_FORM_NEXT_STEP_READY

export const selectHasToLoadNextStep = state =>
  state.userIntegrations.form.nextStepState ===
  INTEGRATION_FORM_NEXT_STEP_HAS_TO_LOAD

export const selectIsRequestFailed = state => state.userIntegrations.isError

export const selectIntegration = state =>
  state.userIntegrations.selectedIntegration

export const selectCurrentMethod = state =>
  state.userIntegrations.selectedMethod

const selectCurrentUserIntegrations = state =>
  state.currentUser.integrations || emptyArr

export const selectUserIntegrations = createSelector(
  selectCurrentUserIntegrations,
  integrations =>
    integrations.filter(({ __typename }) => __typename === 'UserIntegration')
)

const isAccountIntegration = ({ provider }) => {
  return ['STRIPE', 'SHOPIFY'].includes(provider)
}

export const selectAccountIntegrations = createSelector(
  selectCurrentUserIntegrations,
  integrations => integrations.filter(isAccountIntegration)
)

export const selectIntegrationFormCurrentValues = state =>
  state.userIntegrations.form.values

export const selectIntegrationGithubData = state =>
  state.userIntegrations.github

const getOrgList = repos => {
  if (!repos) return null
  return repos.reduce(
    (orgs, repo) =>
      orgs.some(org => org.login === repo.owner.login)
        ? orgs
        : [...orgs, repo.owner],
    []
  )
}
const getRepoList = (repos, org) => {
  if (!repos || !org) return null
  return repos.filter(repo => repo.owner.login === org)
}

const isOrgValue = values => values && values.org
export const selectGithubFormCurrentOptions = createSelector(
  selectIntegrationFormCurrentValues,
  selectIntegrationGithubData,
  (values, github) => {
    if (!github) return {}
    return {
      org: getOrgList(github.repos),
      repo: isOrgValue(values) && getRepoList(github.repos, values.org),
      label: github.labels,
      assignee: github.assignees,
      issue: github.issues,
    }
  }
)

export const selectIntegrationFormValues = state =>
  state.userIntegrations.form.values

export const selectFormElementValue = createSelector(
  selectIntegrationFormValues,
  values => id => values[id]
)

export const selectIsFormElementDisabled = (state, id, dependencies) => {
  const enabled =
    !dependencies ||
    dependencies
      .map(dependency => selectFormElementValue(state)(dependency))
      .reduce(
        (enabledPart, dependencyValue) => enabledPart && !!dependencyValue,
        true
      )
  return !enabled
}

export const selectSelectedGithubIssueData = createSelector(
  selectFormElementValue,
  selectIntegrationGithubData,
  (getValue, githubData) => {
    const issueId = getValue('issue')
    return githubData.issues.find(({ id }) => id === issueId)
  }
)

export const selectIsFormValid = createSelector(selectForm, form => {
  if (!form || !form.validation) return false
  return form.validation.reduce(
    (isValid, element) => element.isValid && isValid,
    true
  )
})

export const selectFormElementError = createSelector(selectForm, form => id => {
  if (!form || !form.validation || !id) return null
  const validationInfo = form.validation.find(field => field.key === id)
  return (
    validationInfo &&
    validationInfo.show &&
    !validationInfo.isValid &&
    validationInfo.message
  )
})

export const selectCurrentUserIntegrationsByProvider = createSelector(
  selectCurrentUserGlobalId,
  selectUserIntegrations,
  (userGid, integrations) => {
    const result = {}
    Object.values(integrations).forEach(({ id, agent, provider }) => {
      if (agent?.id === userGid) {
        result[provider] = id
      }
    })

    return result
  }
)
