/**
 * NOTE: You probably want to use selectors from `withMailbox.js` instead,
 * unless it incurs a circular dependency via `location` selectors.
 */

import { createSelector } from 'reselect'
import { isEmpty, emptyArr } from 'util/arrays'
import { constructSearchQueryObject, constructSearchQueryId } from 'util/search'
import { selectStoredPinnedSearches } from 'selectors/pinned_searches/base'
import { selectTicketSearchOperatorValueMap } from 'selectors/search/base'

function buildPinned(pinned, valueMap) {
  return pinned.map(pin => {
    const query = constructSearchQueryObject(pin.query, valueMap)
    return { search: query, pinnedSearchId: pin.id }
  })
}

export const selectPinnedSearchesWithPinId = createSelector(
  selectStoredPinnedSearches,
  selectTicketSearchOperatorValueMap,
  (pinned, valueMap) => {
    if (!pinned) return emptyArr
    return buildPinned(pinned, valueMap)
  }
)

export const selectPinnedSearches = createSelector(
  selectPinnedSearchesWithPinId,
  pinned => {
    if (!pinned) return emptyArr
    return pinned.map(pin => pin.search)
  }
)

export const selectDefaultPinnedSearch = createSelector(
  selectPinnedSearches,
  searches => {
    if (!isEmpty(searches)) return searches[0]
    return null
  }
)

export const selectPinnedSearchQueryIds = createSelector(
  selectPinnedSearches,
  selectTicketSearchOperatorValueMap,
  (searches, valueMap) => {
    if (!searches) return null
    return searches.map(search => constructSearchQueryId(search, valueMap))
  }
)

export const selectDefaultPinnedSearchQueryId = createSelector(
  selectPinnedSearchQueryIds,
  pinnedSearchQueryIds => {
    if (!isEmpty(pinnedSearchQueryIds)) return pinnedSearchQueryIds[0]
    return null
  }
)
