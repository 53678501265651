import cn from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import TooltipView from './view'

export default class ToolTip extends React.Component {
  static displayName = 'ToolTip'
  static propTypes = {
    as: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
      PropTypes.symbol,
    ]),
    /**
     * A millisecond delay amount to show and hide the tooltip once triggered
     */
    delay: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({
        show: PropTypes.number,
        hide: PropTypes.number,
      }),
    ]),
    portal: PropTypes.bool,
  }

  static defaultProps = {
    as: 'div',
    className: undefined,
    delay: { show: 500, hide: 100 },
    tooltip: undefined,
    portal: false,
  }

  state = {
    left: 0,
    top: 0,
    onHover: false,
    onHoverTooltip: false,
  }

  componentDidMount = () => {
    if (this.props.forceShowTooltip) {
      setTimeout(() => {
        this.setBoundingBox(this.element)
        this.handleShow()
      }, 1)
    }
  }

  componentWillUnmount = () => {
    this.unmounting = true
    document.removeEventListener('wheel', this.onScroll)
    if (this.rafId) {
      window.cancelAnimationFrame(this.rafId)
    }
  }

  onScroll = ev => {
    if (!this.element) return
    if (!this.state.active) return // Must be already visible
    if (this.updatingPosition) return // We're already going to update position
    if (this.unmounting) return
    this.updatingPosition = true
    this.rafId = window.requestAnimationFrame(() => {
      if (!this.element) return false
      const boundingBox = this.element.getBoundingClientRect()
      const { clientX, clientY } = ev
      const isOverX = clientX > boundingBox.left && clientX < boundingBox.right
      const isOverY = clientY > boundingBox.top && clientY < boundingBox.bottom
      const active = isOverX && isOverY
      this.setState({ boundingBox, active })
      this.updatingPosition = false
      return true
    })
  }

  onMouseEnter = ev => {
    if (!this.props.forceShowTooltip) {
      this.setBoundingBox(ev.currentTarget)
      this.handleShow()
    }
    if (this.props.onMouseEnter) this.props.onMouseEnter(ev)
  }

  onMouseLeave = ev => {
    if (!this.props.forceShowTooltip) this.handleHide()
    if (this.props.onMouseLeave) this.props.onMouseLeave(ev)
  }

  setBoundingBox(target) {
    if (!target || this.unmounting) return
    const boundingBox = target.getBoundingClientRect()
    this.setState({ boundingBox })
  }

  handleShow = () => {
    clearTimeout(this.timeout)
    this.hoverState = 'show'

    const { delay } = this.props

    if (!delay.show) {
      this.show()
      return
    }

    this.timeout = setTimeout(() => {
      if (this.hoverState === 'show') this.show()
    }, delay.show)
  }

  handleHide = () => {
    clearTimeout(this.timeout)
    this.hoverState = 'hide'

    const { delay } = this.props

    if (!delay.hide) {
      this.hide()
      return
    }

    this.timeout = setTimeout(() => {
      if (this.hoverState === 'hide') this.hide()
    }, delay.hide)
  }

  hide() {
    document.removeEventListener('wheel', this.onScroll)
    if (!this.unmounting) {
      this.setState({ active: false })
    }
  }

  show() {
    // We attach to the `wheel` event because the `scroll`` event doesn't
    // contain mouse position data.
    try {
      document.addEventListener('wheel', this.onScroll, { passive: true })
    } catch (e) {
      document.addEventListener('wheel', this.onScroll, true)
    }
    if (!this.unmounting) {
      this.setState({ active: true })
    }
  }

  takeRef = node => {
    this.element = node
  }

  get showTooltip() {
    return !!(!this.props.disabled && this.state.active && this.props.tooltip)
  }

  render() {
    const {
      as,
      children,
      className,
      color,
      delay,
      enabled,
      offsetX,
      offsetY,
      portal,
      position,
      tooltip,
      tooltipClassName,
      tooltipCss,
      ...rest
    } = this.props
    const { boundingBox } = this.state

    if (enabled === false)
      return <div className={cn(className, 'DisabledTooltip')}>{children}</div>

    return (
      <TooltipView
        as={as}
        boundingBox={boundingBox}
        className={className}
        color={color}
        delay={delay}
        offsetX={offsetX}
        offsetY={offsetY}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        passedProps={rest}
        position={position || 'bottom'}
        portal={portal}
        showTooltip={this.showTooltip}
        takeRef={this.takeRef}
        tooltip={tooltip}
        tooltipClassName={tooltipClassName}
        tooltipCss={tooltipCss}
      >
        {children}
      </TooltipView>
    )
  }
}
