import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import styles from './styles.less'

const Item = React.forwardRef(
  (
    { gap, align, grow, shrink, basis, className, style, children, ...rest },
    forwardedRef
  ) => {
    return (
      <div
        className={cn(
          styles.Item,
          styles[gap],
          styles[`align-${align}`],
          className
        )}
        style={{
          flexGrow: grow,
          flexShrink: shrink,
          flexBasis: basis,
          ...style,
        }}
        {...rest}
        ref={forwardedRef}
      >
        {children}
      </div>
    )
  }
)

Item.defaultProps = {
  gap: undefined,
  align: undefined,
  grow: 0,
  shrink: 1,
  basis: undefined,
}

Item.propTypes = {
  /** Size of space between items. Uses standard sizing names from SUI. Use `auto` to push item (and subsequent items) to side of container. This value overrides the value from SpacedItems.Container. */
  gap: PropTypes.oneOf([
    'none',
    'nano',
    'mini',
    'tiny',
    'small',
    'medium',
    'large',
    'larger',
    'big',
    'huge',
    'massive',
    'giant',
    'ginormous',
    'auto',
  ]),
  /** Aligment of individual item along the cross-axis */
  align: PropTypes.oneOf(['start', 'end', 'center']),
  /** Flex grow */
  grow: PropTypes.number,
  /** Flex shrink */
  shrink: PropTypes.number,
  /** Flex basis */
  basis: PropTypes.string,
}

Item.displayName = 'SpacedItems.Item'

export default Item
