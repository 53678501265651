import { useCallback } from 'react'

import { Tooltip } from 'shared/ui'
import { Button, Icon, Input, Radio } from 'util/ui'

export default function ArrayOfStringsWithPrimaryRecord({
  doSetRecord,
  doSetPrimary,
  doRemove,
  index,
  isPrimary,
  noun,
  name,
  value,
}) {
  const onCheckbox = useCallback(() => doSetPrimary(index), [
    index,
    doSetPrimary,
  ])
  const onChange = useCallback(
    ({ target: { value: newValue = '' } = {} }) => {
      doSetRecord(index, newValue)
    },
    [index, doSetRecord]
  )
  const onRemove = useCallback(
    e => {
      e.preventDefault()
      if (doRemove) doRemove(index)
    },
    [index, doRemove]
  )

  return (
    <div className="emailRow">
      <Input
        fluid
        label={{
          basic: true,
          content: (
            <Tooltip position="left" tooltip={`Select as primary ${noun}`}>
              <Radio checked={isPrimary} onClick={onCheckbox} />
            </Tooltip>
          ),
        }}
        name={name}
        onChange={onChange}
        value={value}
      />
      <Button
        as="div"
        className="RemoveButton"
        disabled={!doRemove}
        basic
        icon
        onClick={onRemove}
      >
        <Icon name="trash alternate outline" />
      </Button>
    </div>
  )
}
