import { styles as newStyles } from './styles'
import styles from './styles.less'

export default function SidebarFooterView({ children }) {
  return (
    <div className={styles.footer} css={newStyles.footer}>
      {children}
    </div>
  )
}
