// Wrapper around SUI Dropdown that
//   1. focuses keyboard events on the dropdown (see withKeyboardHandler)
//     - this lets the SUI DD handle arrow events (using their built in
//     handling)
//     - adds an onEnter handler that works with links
//   2. Optionally adds active icon to menu items
//
// Note it relies on the `onChange` callback to track the currently
// "selected" item. The problem here is that implies selectOnNavigation=true
// (the default) which updates the "active" item in the list as you select with
// the keyboard (thus triggering the onChange callback).
//
// In other words, the arrow indicators we add in this wrapper break if you
// set selectOnNavigation=false. And because SUI provides no `onSelect` callback
// either, you are up the creek.
//
// One of the main use-cases for `selectOnNavigation=false` is to distinguish
// the 'current' active item (if there is a default already chosen for the list)
// from a "selected" item (selected with the keyboard). We can sidestep this with
// a bit of css e.g.
//
//   .ui.dropdown .menu>.active.item:not(.selected)
//
// The limitation here however, is that we cant know if (and differentiate
// visually) the 'default' active item, and the item that is active because its
// selected. That is where withDefaultActiveOption comes in. It adds a
// `defaultActive` class to the (one) Item that is set to 'active' in the props.
//
// Then we can always color it differently ("selected" or otherwise) with
//
//   .ui.dropdown .menu>.defaultActive.item
//
// Theme overrides go in ui_theme/site/modules/dropdown.overrides, not here.
//
import { compose } from 'redux'

import { Dropdown as SUIDropdown } from 'util/ui'
import withOpenState from 'shared/ui/withOpenState'

import withKeyboardHandler from './withKeyboardHandler'
import withSelectedItemIndicator from './withSelectedItemIndicator'
import withDefaultActiveOption from './withDefaultActiveOption'
import withOnChangeTracking from './withOnChangeTracking'
import withScrollIntoView from './withScrollIntoView'
import withCleanDomProps from './withCleanDomProps'

// Uses redux's handly compose() function to, er, compose the additional
// dropdown behaviours.  https://reactjs.org/docs/higher-order-components.html
export default compose(
  withDefaultActiveOption,
  withOpenState,
  withKeyboardHandler,
  withOnChangeTracking,
  withSelectedItemIndicator,
  withScrollIntoView('.menu.visible'),
  withCleanDomProps
)(SUIDropdown)
