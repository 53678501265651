import { createSelector } from 'reselect'
import { selectCurrentMailbox } from './selectCurrentMailbox'

export const selectIsCurrentDemoMailbox = createSelector(
  selectCurrentMailbox,
  mailbox => {
    if (!mailbox) return null
    return mailbox.state === 'demo'
  }
)
