import { createSelector } from 'reselect'
import { isIOS } from 'util/browser'

export const selectIsWebPushSupported = createSelector(
  state => state.webPush.isSupported,
  isSupported => isSupported && !isIOS()
)

const selectPermissionStatus = state => state.webPush.permissionStatus

export const selectNeedsPushPermission = createSelector(
  selectIsWebPushSupported,
  selectPermissionStatus,
  (isSupported, permissionStatus) => {
    if (!isSupported) return false
    if (typeof window === 'undefined') return false
    return permissionStatus === 'default'
  }
)
