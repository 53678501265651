// For truncating text with an ellipsis. Make sure your component has a width!
// Defaults to nowrap. Set props.wrap to have the text wrap.
export const truncate = props => {
  if (!props.truncate) return undefined

  return {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: !props.wrap ? 'nowrap' : undefined,
  }
}
