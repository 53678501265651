import { createSelector } from 'reselect'
import { selectEditingTicketTitleDraft } from 'selectors/app'
import { selectCurrentTicketUndecorated } from './current/selectCurrentTicketUndecorated'

export const selectCurrentTicketTitle = createSelector(
  selectCurrentTicketUndecorated,
  ticket => ticket && ticket.title
)

export const selectHasTicketTitleChanged = createSelector(
  selectCurrentTicketTitle,
  selectEditingTicketTitleDraft,
  (title, draftTitle) => title !== draftTitle
)
