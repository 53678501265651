import React from 'react'
import { v4 as uuidV4 } from 'uuid'
import { isChar, walkChars } from 'util/strings'

const keys = {
  UP: 38,
  TAB: 9,
  DOWN: 40,
  ENTER: 13,
  SPACE: 32,
  ESCAPE: 27,
  BACKSPACE: 8,
  DELETE: 46,
  LEFT: 37,
  RIGHT: 39,
  SHIFT: 16,
}

function extractMentionUnderCursor(editor) {
  const cursorIndex = editor.selection.getRng(true).startOffset - 1
  const node = editor.selection.getRng(true).startContainer
  const text = node.data
  if (!text) return null
  const chr = text[cursorIndex]
  let chars = []
  // we only track mentions if the cursor is after a word
  if (isChar.test(chr)) {
    chars.push(chr)
    const leading = walkChars(text, cursorIndex, -1)
    const trailing = walkChars(text, cursorIndex, 1)
    chars = leading.chars.concat(chars)
    chars = chars.concat(trailing.chars)
    chars = chars.filter(c => !!c)

    // word does not start with trigger, abort
    if (chars[0] != '@') return null
    const startIndex = leading.index
    // Get the @ node's DomRect object for positioning MentionedAgentList
    const range = document.createRange()
    range.setStart(node, startIndex)
    range.setEnd(node, startIndex + 1)
    const rect = range.getBoundingClientRect()

    const output = {
      node: node,
      word: chars.join(''),
      length: chars.length,
      startIndex,
      endIndex: trailing.index,
      charsAfter: text.length - trailing.index,
      rect,
    }
    return output
  } else {
    return null
  }
}

const stopEvent = function(e) {
  e.preventDefault()
  e.stopPropagation()
}

const moveCursorToNextNode = function(editor, node) {
  const parent = node.parentNode
  const next = parent.nextSibling
  if (next && next.length > 0) {
    // if a previous node is found, move the beginning
    editor.selection.setCursorLocation(next, 1)
  } else {
    // if no previous node, we'll need to create one to move the cursor there
    const empty = document.createTextNode(' ')
    editor.dom.insertAfter(empty, parent)
    editor.selection.setCursorLocation(empty, 1)
  }
}

const preventMoveCursor = (e, editor) => {
  // If we are in a mention and the key is arrow-up or arrow-down
  // prevent moving the cursor when navigating the MentionSelector
  if (e.keyCode === keys.UP || e.keyCode === keys.DOWN) {
    const mention = extractMentionUnderCursor(editor)
    if (mention) {
      e.preventDefault()
    }
  }
}

tinymce.PluginManager.add('groove-mentions', function(editor, url) {
  // This function is massive. But it also hosts the magic that makes
  // the new mentions super usable.
  //
  // Essentially, the whole idea is to turn the mention into an uneditable block
  // which is moved around by the content around it. When you want to enter it,
  // the cursor is automatically moved to the end. If you press space before it,
  // the mention moves. You cannot edit it once it's set.
  const downHandler = function(e) {
    /**
     * pnagy: we need to be defensive: it can happen that we remove editor or
     * its content before this handler can be called
     */
    if (!editor || !editor.selection) return

    // Get information about the node that the cursor is in
    const position = editor.selection.getRng(true).startOffset
    const cursorIndex = position - 1
    const node = editor.selection.getRng(true).startContainer

    // Check if we are in a mention
    const inMention = !!(
      node &&
      node.parentNode &&
      node.parentNode.tagName.toLowerCase() == 'span' &&
      node.parentNode.className.match('mention')
    )

    // Check if previous node is a mention - it's used when backspace is pressed
    // in the next node to the mention
    const prevNodeIsMention = !!(
      node &&
      node.previousSibling &&
      node.previousSibling.tagName &&
      node.previousSibling.tagName.toLowerCase() == 'span' &&
      node.previousSibling.className.match('mention')
    )

    if (
      e.keyCode == keys.BACKSPACE &&
      ((inMention && position > 0) || (prevNodeIsMention && position === 0))
    ) {
      // if we're backspacing in a mention, remove the mention and go back to editing mode
      // same applies if we're in a next node at position 0 and backspacing would take us into
      // the mention
      stopEvent(e)
      const $node = tinymce.dom.DomQuery(node.parentNode)
      return $node.replaceWith('')
    } else if (e.keyCode == keys.LEFT && inMention) {
      // When LEFT is pressed and we are in the mention, jump to the beginning of the mention
      // of the mention
      editor.selection.setCursorLocation(node, 0)
      stopEvent(e)
    } else if (inMention && position === 0 && e.keyCode == keys.RIGHT) {
      // When RIGHT is pressed and we are in the mention, jump to the end of the mention
      if (e.keyCode) stopEvent(e)
      const parent = node.parentNode
      const next = parent.nextSibling
      if (next) {
        editor.selection.setCursorLocation(next, 0)
      } else {
        const empty = document.createTextNode(' ')
        editor.dom.insertAfter(empty, parent)
        editor.selection.setCursorLocation(empty, 0)
      }
    } else if (inMention && position === 0 && e.keyCode !== undefined) {
      // When we're at the beginning of the mention and a key is pressed,
      // there are several things we want to do
      if (
        e.keyCode == keys.UP || // do nothing on UP, let the cursor pass to the line above
        e.keyCode == keys.DOWN || // do nothing on DOWN, let the cursor pass to the line below
        e.keyCode == keys.BACKSPACE // do nothing on BACKSPACE, let the event delete preceding content
      ) {
        return // pass
      }

      // in other cases, we need to move the cursor before allowing the key from being inserted
      const parent = node.parentNode
      const prev = parent.previousSibling
      if (prev) {
        // if a previous node is found, move the cursor to the end of that node
        editor.selection.setCursorLocation(next, next.length - 1)
      } else {
        // if no previous node, we'll need to create one to move the cursor there
        // it will be an empty text node with a space char
        let content = ' '
        let targetLocation = 0
        // when enter is pressed, we make the next node empty to not add unnecessary whitespace
        if (e.keyCode === keys.ENTER) content = ''
        // with space, we need to stop the event and pass the space in the text node, otherwise
        // we'll get two spaces. We also need to change the location to simulate string
        // behavior
        if (e.keyCode === keys.SPACE) {
          targetLocation = 1
          stopEvent(e)
        }
        // create the text node, add it before the mention and move the cursor in there
        const empty = document.createTextNode(content)
        const uberParent = parent.parentNode
        uberParent.insertBefore(empty, parent)
        editor.selection.setCursorLocation(empty, targetLocation)
      }
    } else if (
      inMention &&
      position === node.length &&
      e.keyCode !== undefined
    ) {
      // When a key is pressed at the end of the mention, we need to move the cursor
      // after the mention before allowing the key from being inserted
      moveCursorToNextNode(editor, node)
    } else if (inMention && position > 0 && position !== node.length) {
      // if any other occurence of cursor within the mention, move it to
      // after the mention before allowing the key from being inserted
      if (e.keyCode) stopEvent(e)
      moveCursorToNextNode(editor, node)
    }

    preventMoveCursor(e, editor)
  }

  editor.on('NodeChange', downHandler)
  editor.on('keydown', downHandler)

  const moveHandler = function(e) {
    if (e.keyCode == keys.SHIFT) return
    const cursorIndex = editor.selection.getRng(true).startOffset - 1
    const node = editor.selection.getRng(true).startContainer

    // check if we are in a mention
    const inMention =
      node.parentNode &&
      node.parentNode.tagName &&
      node.parentNode.tagName.toLowerCase() == 'span' &&
      node.parentNode.className.match('mention')

    // if we're in a complete mention, do nothing
    if (inMention) {
      return editor.fire('mention-change', null)
    }

    const mention = extractMentionUnderCursor(editor)
    if (mention) {
      mention.keyCode = e.keyCode
      editor.fire('mention-change', { mention: mention })
    } else {
      editor.fire('mention-change', null)
    }
  }
  editor.on('keyup', moveHandler)
  editor.on('mouseup', moveHandler)
  editor.on('mention-insert', function(e) {
    const replacement = e.mention
    const text = `<span class="mention">${replacement}</span> `
    editor.insertContent(text)
  })
  editor.on('mention-select', function(e) {
    editor.focus()

    const mention = extractMentionUnderCursor(editor)
    if (!mention) return
    const replacement = e.mention
    const node = editor.selection.getRng(true).startContainer
    const text = node.data
    const mentionId = uuidV4()
    const leading = text.substr(0, mention.startIndex)
    let trailing = text.substr(mention.endIndex + 1, text.length)
    const hasTrailing = trailing.length > 0
    if (!trailing.startsWith(' ')) {
      trailing = ' ' + trailing
    }
    const parts = [
      leading,
      hasTrailing
        ? `<span class="mention" data-mention-id=${mentionId}>${replacement}</span>`
        : `<span class="mention">${replacement}</span>`,
      trailing,
    ]
    const $node = tinymce.dom.DomQuery(node)
    $node.replaceWith(parts)
    if (hasTrailing) {
      // Move the cursor to the whitespace after the mention
      const newMention = editor.dom.select(
        `.mention[data-mention-id="${mentionId}"]`
      )[0]
      newMention.removeAttribute('data-mention-id')
      const range = document.createRange()
      range.setStart(newMention.nextSibling, 1)
      range.collapse(true)
      editor.selection.setRng(range)
    }
  })
  return {
    getMetadata: function() {
      return {
        name: 'Groove mentions',
      }
    },
  }
})
