// Stateful version of KB's ValidatedInput that allows autofocusing
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Input, Label } from 'util/ui'
import { moveCursorToEnd } from 'util/forms'

class ValidatedInput extends React.Component {
  componentDidUpdate(prevProps) {
    const { defaultValue, renderDefaultValueChanges } = this.props

    if (!renderDefaultValueChanges) return

    if (defaultValue !== prevProps.defaultValue) {
      this.inputRef.value = defaultValue || ''
    }
  }

  takeRef = node => {
    const { autoFocus, forwardRef } = this.props

    if (node && forwardRef) {
      forwardRef(node)
    }
    if (node && node.inputRef) {
      // WARNING (Serg): this is using SUI internals and might break on SUI update
      this.inputRef = node.inputRef.current
    }

    return autoFocus && node && node.focus()
  }

  render() {
    const {
      as: As = Input,
      className,
      errorMessage,
      onChange,
      size,
      value,
      renderDefaultValueChanges,
      forwardRef,
      ...rest
    } = this.props

    return (
      <div className={className}>
        <As
          onChange={onChange}
          onFocus={moveCursorToEnd}
          ref={this.takeRef}
          size={size}
          value={value}
          {...rest}
        />
        {!!errorMessage && (
          <Label basic color="red" pointing>
            {errorMessage}
          </Label>
        )}
      </div>
    )
  }
}

ValidatedInput.propTypes = {
  /** Whether to autofocus the input */
  autoFocus: PropTypes.bool,

  size: PropTypes.oneOf([
    'mini',
    'tiny',
    'small',
    'large',
    'big',
    'huge',
    'massive',
  ]),
}

ValidatedInput.defaultProps = {
  autoFocus: false,
  size: undefined,
}

const Styled = styled(ValidatedInput)`
  label {
    border-radius: 0.2em;
    background-color: white;
  }
`

export default Styled
