/* eslint-disable no-multi-assign */
/* eslint-disable eqeqeq */ // for the one exception below
/* eslint-disable no-underscore-dangle */
import * as types from 'constants/action_types'
import * as pages from 'constants/pages'
import {
  selectLatestTicketSearchQueryId,
  selectCurrentTicketSearchQueryId,
  selectCurrentTicketSearchSortOrder,
  selectTicketSearchOperatorValueMap,
} from 'selectors/search'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'
import { selectAccountPreferenceSortByCollaboratorCommentEnabled } from 'selectors/app/selectAccountPreferences'
import { normalizeSearchQueryId } from 'util/search/normalization'
import { updateTicketDirtyState } from 'reducers/tickets'
import { hasProp, length } from 'util/objects'
import {
  sortSearchIds,
  byOldest,
  sortOrderContextKeyForQueryId,
  byLongestUnanswered,
} from 'util/search/sorting'
import debug from 'util/debug'
import GA from 'util/googleAnalytics'
import { gcCheckAndRunForSearches } from 'util/gc/searches'
import deepEqual from 'fast-deep-equal'
import { selectFoldersById } from 'ducks/folders/selectors/folders'
import { MERGE_TICKET_SUCCESS } from 'ducks/merging/types'

const reducers = {}
const defaultState = {}

function trackCountErrorEvent(properties) {
  return GA.track('inbox', 'counts-mismatch-on-next-page', properties.reason)
}

function shouldAppendTicketToEnd(sortOrder) {
  return byOldest(sortOrder) || byLongestUnanswered(sortOrder)
}

// Applies diffs in search result counts.
// Is in the root reducer as it needs both searches and the current mailbox ID
// in scope.
reducers[types.UPDATE_TICKETS] = reducers[
  types.CREATE_CHANGESET_SUCCESS
] = reducers[types.CREATE_CHANGESET_REQUEST] = reducers[
  types.DELETE_TICKETS_STARTED
] = reducers[types.DELETE_TICKETS_SUCCESS] = (state, action) => {
  const { data, type: actionType } = action
  const tickets = data && (data.tickets || [data])
  if (!tickets) return state
  const newState = { ...state }
  const search = (newState.search = Object.assign({}, state.search))
  const byQueryId = (search.byQueryId = Object.assign({}, search.byQueryId))

  tickets.forEach(ticket => {
    const searches = ticket.diff && ticket.diff.searches
    if (!searches) return
    const ticketId = ticket.id || ticket.ticketId

    const uniqSearches = getUniqSearches(searches)

    uniqSearches.__keys.forEach(queryId => {
      const searchByQuery = byQueryId[queryId] || {}

      // if the ticket timestamp is older than the count, skip the diff
      if (
        ticket.timestamp &&
        searchByQuery.timestamp &&
        ticket.timestamp < searchByQuery.timestamp
      ) {
        return
      }

      let hasChanged = false // flags if we need to update this query or not
      // this flag controls whether the count should change
      // we start with true, but depending on what we see in the
      // diff application phase, we may change our mind
      let shouldChangeCount = true
      const difference = uniqSearches[queryId]
      const { pages: currentPages, sortedIds, sortOrder, totalPages } =
        byQueryId[queryId] || {}
      const newSortedIds = sortedIds && [].concat(...sortedIds)
      let newPages

      // These data structures hold information about tickets we have
      // added/removed from the query. We need it, because it is possible to
      // receive multiple messages about a ticket joining/leaving a search and
      // for us to not know the actual ids of tickets within that search.
      //
      // Thus, we cannot always know whether to increase/decrease count based
      // on current search contents.
      //
      // By tracking ticket ids we can ensure we add/remove a ticket from a
      // search AT MOST ONCE
      //
      // This also means we need to keep this set clean by removing an entry
      // upon a successful add/remove.
      const newRemovedTicketIds = { ...(searchByQuery.removedTicketIds || {}) }
      const newAddedTicketIds = { ...(searchByQuery.addedTicketIds || {}) }

      if (currentPages) {
        // All the ticket ids we have cached on all pages
        const ticketIds = [].concat(...Object.values(currentPages))

        if (difference > 0) {
          const currentIndex = ticketIds.indexOf(ticketId)

          // ticket is already in the list, no need to add
          if (currentIndex >= 0) {
            // and also no need to change the search count
            shouldChangeCount = false
          } else {
            // If sorting by oldest and we have *all* pages cached, then we
            // can simply add the new ticket to the end of the list. If we
            // dont have all pages cached, we cant know where to insert it,
            // so we do nothing.
            // eslint-disable-next-line no-lonely-if
            if (shouldAppendTicketToEnd(sortOrder)) {
              // see if we have the last page cached...
              if (hasProp(currentPages, totalPages)) {
                // if so, add the new ticket to the end of the list.
                ticketIds.push(ticketId)
                newAddedTicketIds[ticketId] = true
                if (newSortedIds) newSortedIds.push(ticketId)
              }
            } else {
              // ASSUMES ORDERED BY NEWEST
              ticketIds.unshift(ticketId)
              newAddedTicketIds[ticketId] = true
              if (newSortedIds) newSortedIds.unshift(ticketId)
            }
          }
          delete newRemovedTicketIds[ticketId]
          hasChanged = true
        } else if (difference == -1) {
          const currentIndex = ticketIds.indexOf(ticketId)
          if (currentIndex >= 0) {
            if (newSortedIds) {
              const index = newSortedIds.indexOf(ticketId)
              newSortedIds.splice(index, 1)
            }
            ticketIds.splice(currentIndex, 1)
            newRemovedTicketIds[ticketId] = true
          } else if (!newRemovedTicketIds[ticketId]) {
            newRemovedTicketIds[ticketId] = true
          } else {
            // ticket is present in removed, do not decrease count
            shouldChangeCount = false
          }
          // if we just removed a ticket, make sure it's not
          // in added anymore
          delete newAddedTicketIds[ticketId]
          hasChanged = true
        }

        // This regroups all the tickets into pages of correct lengths.
        let page = 1
        newPages = {}
        while (ticketIds.length > 0) {
          newPages[page] = ticketIds.splice(0, 20)
          page += 1
        }
      } else {
        // in this branch, currentPages is falsey, which
        // means we only know the count of the search, nothing
        // more. To ensure we don't apply similar operations
        // multiple times, we need to rely on removedTicketIds

        // GR: I think this is clearer with the lonely-if
        // eslint-disable-next-line no-lonely-if
        if (difference > 0) {
          if (!newAddedTicketIds[ticketId]) {
            newAddedTicketIds[ticketId] = true
          } else {
            // we have added this ticket already at least once,
            // no need to bump count
            shouldChangeCount = false
          }
          delete newRemovedTicketIds[ticketId]
          hasChanged = true
        } else if (difference === -1) {
          if (!newRemovedTicketIds[ticketId]) {
            newRemovedTicketIds[ticketId] = true
          } else {
            // we have removed this ticket already at least once
            // no need to decrease count
            shouldChangeCount = false
          }
          delete newAddedTicketIds[ticketId]
          hasChanged = true
        }
      }

      let currentCount = byQueryId[queryId] && byQueryId[queryId].totalCount
      // if a search is not present we don't want to alter the
      // count because we just don't know it, so the result will be misleading
      let canChangeCount = currentCount !== undefined && currentCount !== null
      // Special case - we're allowing tags to fill empty searches as a pilot program
      // to see how well it will work. We also make sure that the difference is positive
      // to prevent negative counts
      if (difference > 0 && queryId.match('tag:') && !currentCount) {
        canChangeCount = true
        currentCount = 0
      }
      if (difference > 0 && queryId.match('assignee:') && !currentCount) {
        canChangeCount = true
        currentCount = 0
      }
      let newCount = canChangeCount ? currentCount : undefined
      // These two flags control whether the count should change
      // canChangeCount - specifies whether the search has a count that can be changed
      // shouldChangeCount - specifies whether depending on the diff application
      //                     we should change the count. If we already have the ticket
      //                     in list, no need to increase count.
      if (canChangeCount && shouldChangeCount) {
        newCount = currentCount + difference
      }

      // last resort - negative counts make us look like asses
      // so never allow the count to fall below 0, but log it
      // for debugging purposes
      if (newCount && newCount < 0) {
        if (debug.enabled) {
          // eslint-disable-next-line no-console
          console.error(
            'got negative search count',
            searchByQuery,
            queryId,
            uniqSearches[queryId]
          )
        }
        newCount = 0
      }

      hasChanged = hasChanged || newCount !== currentCount

      // protect against an embarrasing case in which we show that a folder has 3 tickets, but in reality it has 4
      try {
        if (
          hasChanged &&
          newCount < 20 &&
          newSortedIds.length < 20 &&
          newCount != newSortedIds.length
        ) {
          let reason = 'unknown'
          if (newSortedIds.length < newCount) reason = 'count_smaller_than_set'
          if (newSortedIds.length > newCount) reason = 'count_larger_than_set'
          trackCountErrorEvent({ reason })
          newCount = sortedIds.length
        }
      } catch (e) {
        // ignore the error
      }

      // Detect a situation in which we have a set which has less than a full
      // page, but it is not the last page. This means we need to load the
      // missing tickets and we do this by marking results as stale.
      //
      // In the future, we will mark it differently and have a smarter way
      // of fetching missing tickets.
      let resultsIncomplete = false
      const isRightActionType = actionType === types.CREATE_CHANGESET_SUCCESS

      if (isRightActionType && newSortedIds) {
        const currentSize = newSortedIds.length
        const currentPageCount = length(searchByQuery.pages)
        const expectedSize = currentPageCount * 20
        if (
          currentPageCount < searchByQuery.totalPages &&
          currentSize < expectedSize
        ) {
          resultsIncomplete = true
          hasChanged = true
        }
      }

      if (hasChanged) {
        // perf: dont mutate if you dont need to!
        const previous = byQueryId[queryId]
        byQueryId[queryId] = {
          ...previous,
          totalCount: newCount,
          queryId,
          pages: newPages,
          removedTicketIds: newRemovedTicketIds,
          addedTicketIds: newAddedTicketIds,
          sortedIds: newSortedIds,
          resultsIncomplete,
        }
      }
    })
  })
  return newState
}

function getUniqSearches(searches) {
  // Ensure we have no semantical duplicates for query ids
  // There are no guarantees that the backend won't send
  // a diff with a query id in the wrong order. In such a
  // case we want to make sure all semantically identical
  // queries are put under the same key.
  //
  // In case of conflicts it will use the latest found key
  // meaning backend response will override optimistic
  // (optimistic is put at the beginning of the diff)
  const uniqSearches = {}
  uniqSearches.__keys = []

  Object.keys(searches).forEach(diffSearchQuery => {
    const difference = searches[diffSearchQuery]
    const uniqueKey = normalizeSearchQueryId(diffSearchQuery)
    uniqSearches[uniqueKey] = difference
    uniqSearches.__keys.push(uniqueKey)
  })

  return uniqSearches
}

reducers[MERGE_TICKET_SUCCESS] = (state, action) => {
  const { ticket, mergedTickets } = action.payload
  const tickets = mergedTickets.tickets.concat(ticket)
  return reducers[types.UPDATE_TICKETS](state, { data: { tickets } })
}

function reduceBulkChangesetAction(state, { data: { tickets } }, type) {
  if (!tickets) return state
  let newState = Object.assign({}, state)
  const single = reducers[type]
  tickets.forEach(ticketData => {
    newState = single(newState, {
      type,
      data: ticketData,
      isBulk: true,
    })
  })
  return newState
}

reducers[types.CREATE_BULK_CHANGESET_REQUEST] = (state, action) => {
  return reduceBulkChangesetAction(
    state,
    action,
    types.CREATE_CHANGESET_REQUEST
  )
}

reducers[types.CREATE_BULK_CHANGESET_SUCCESS] = (state, action) => {
  return reduceBulkChangesetAction(
    state,
    action,
    types.CREATE_CHANGESET_SUCCESS
  )
}

reducers[types.LOGOUT] = () => {
  return undefined
}

// When we land on a search page, store the sortOrder against the correct query
reducers[pages.SEARCH_PAGE] = (state, { meta, payload }) => {
  if (!meta) return state

  const { query } = meta
  if (!query || !query.sort) return state

  const sortOrder = query.sort
  const newState = { ...state } // NOTE: shallow copy.
  const queryId = selectCurrentTicketSearchQueryId(state)

  const foldersById = selectFoldersById(state)
  const ticketSearchOperatorValueMap = selectTicketSearchOperatorValueMap(state)
  const sortOrderContextKey = sortOrderContextKeyForQueryId(
    payload?.term,
    foldersById,
    ticketSearchOperatorValueMap
  )

  const newSortOrderBySortContext = {
    ...newState.currentUser.sortOrderBySortContext,
  }
  newSortOrderBySortContext[sortOrderContextKey] = sortOrder

  if (!newState.search.byQueryId[queryId]) {
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        sortOrderBySortContext: newSortOrderBySortContext,
      },
    }
  }

  const oldQuery = newState.search.byQueryId[queryId]
  const oldOrder = oldQuery.sortOrder
  const oldTotalCount = oldQuery.totalCount
  const newByQueryId = { ...newState.search.byQueryId }
  const shouldClearCache = oldOrder !== sortOrder
  const cacheProps = !shouldClearCache
    ? {}
    : {
        pages: null,
        resultsStale: true,
        sortedIds: undefined,
        totalCount: oldTotalCount,
        totalPages: undefined,
        currentPage: undefined,
        addedTicketIds: {},
        removedTicketIds: {},
      }

  newByQueryId[queryId] = {
    ...newByQueryId[queryId],
    ...cacheProps,
  }

  // queryId: current ticket page
  // payload.term: new search to be executed
  // without below, it updates the current queryId which new query (payload.term) sort which is wrong
  if (queryId === payload?.term) {
    newByQueryId[queryId].sortOrder = sortOrder
  }

  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      sortOrderBySortContext: newSortOrderBySortContext,
    },
    search: {
      ...state.search,
      byQueryId: newByQueryId,
    },
  }
}

const sortSearchesIfRequired = state => {
  if (!state.search || !state.app || !state.agents) return state

  let hasChanges = false
  const byQueryId = state.search.byQueryId
  const ticketsById = state.tickets.byId

  const newByQueryId = { ...byQueryId }

  const latestSearchQueryId = selectLatestTicketSearchQueryId(state)
  const currentSearchQueryId = selectCurrentTicketSearchQueryId(state)
  const sortOrder = selectCurrentTicketSearchSortOrder(state)

  // if we just switched search context, enforce re-sorting of the list
  if (latestSearchQueryId != currentSearchQueryId) {
    hasChanges = true
    if (newByQueryId[latestSearchQueryId]) {
      newByQueryId[latestSearchQueryId] = {
        ...newByQueryId[latestSearchQueryId],
        sortedIds: undefined,
      }
      delete newByQueryId[latestSearchQueryId].sortedIds
    }
    if (newByQueryId[currentSearchQueryId]) {
      newByQueryId[currentSearchQueryId] = {
        ...newByQueryId[currentSearchQueryId],
        sortedIds: undefined,
      }
      delete newByQueryId[currentSearchQueryId].sortedIds
    }
  }

  if (byQueryId) {
    const sortByCollaboratorCommentAtEnabled = selectAccountPreferenceSortByCollaboratorCommentEnabled(
      state
    )

    const sortOptions = {
      sortByCollaboratorCommentAtEnabled,
    }

    Object.keys(byQueryId).forEach(queryId => {
      const query = newByQueryId[queryId]
      // Kevin Rademan (2020-04-15)
      // The code below always recalculates the sortedIds field. The reasoning
      // behind this is that we need to add the sortedIds if it doesnt exist
      // but we also need to recompute it to check if the order is still the
      // same after the action that has been received. This is important for
      // events like responses that modify the updated_at of tickets
      if (query && query.pages) {
        const newQuery = { ...query }
        newQuery.sortedIds = sortSearchIds(
          query,
          sortOrder,
          ticketsById,
          sortOptions
        )
        if (!deepEqual(newQuery.sortedIds, query.sortedIds)) {
          newByQueryId[queryId] = newQuery
          hasChanges = true
        }
      }
    })
  }
  // if we changed nothing, return unchanged state
  if (!hasChanges) return state

  return {
    ...state,
    search: {
      ...state.search,
      byQueryId: newByQueryId,
    },
  }
}

reducers[types.SEARCH_COMPLETE] = (state, action) => {
  const { queryId } = action.data
  return gcCheckAndRunForSearches(state, queryId)
}

reducers[types.FETCH_TICKET_SUCCESS] = (state, action) => {
  const { ticket } = action.data
  if (ticket) {
    return gcRegisterFetchedTicket(state, ticket.id)
  }
  return state
}

const MAX_FETCHED_TICKETS = 50

function gcRegisterFetchedTicket(state, ticketId) {
  const { gcCandidates } = state.tickets
  const ticketAlreadyCandidate = gcCandidates.includes(ticketId)

  if (ticketAlreadyCandidate) return state

  if (gcCandidates.length < MAX_FETCHED_TICKETS) {
    return {
      ...state,
      tickets: {
        ...state.tickets,
        gcCandidates: [...gcCandidates, ticketId],
      },
    }
  }

  const currentTicketId = selectCurrentTicketId(state)
  const targetGcTicketId = gcCandidates.find(gcCandidateId => {
    return gcCandidateId !== currentTicketId
  })
  const newGcCandidates = gcCandidates.filter(gcId => gcId !== targetGcTicketId)
  return {
    ...state,
    tickets: {
      ...updateTicketDirtyState(
        {
          ...state.tickets,
          gcCandidates: [...newGcCandidates, ticketId],
          byId: {
            ...state.tickets.byId,
            [targetGcTicketId]: {
              ...state.tickets.byId[targetGcTicketId],
              actions: undefined,
              changesets: undefined,
            },
          },
        },
        targetGcTicketId,
        true
      ),
    },
  }
}

export default function reducer(state = defaultState, action) {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  let newState = state
  if (handler) newState = handler(state, action)
  return sortSearchesIfRequired(newState)
}
