//
// <Divider />  //=> SUI horizontal divider
// <Divider horizontal />  //=> SUI horizontal divider
// <Divider vertical />  //=> our <Divider.Vertical>
//
import Horizontal from './Horizontal'
import Vertical from './Vertical'

const Divider = ({ vertical, ...rest }) => {
  if (vertical) return <Vertical {...rest} />
  return <Horizontal {...rest} />
}

export default Divider
