import { emptyObj } from 'util/objects'
import createCachedSelector from 're-reselect'
import { selectRawById } from './selectRawById'

export const selectRawTicketById = createCachedSelector(
  selectRawById,
  (_state, ticketId) => ticketId,
  (rawTicketsById, ticketId) => {
    return rawTicketsById[ticketId] || emptyObj
  }
)((_state, ticketId) => ticketId || 'unknown')
