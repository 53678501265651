import { createSelector } from 'reselect'
import { emptyArr } from 'util/arrays'
import { selectInaccessibleMailboxesRaw } from './selectInaccessibleMailboxesRaw'

export const selectInaccessibleMailboxesIds = createSelector(
  selectInaccessibleMailboxesRaw,
  inaccessibleMailboxesRaw => {
    return (inaccessibleMailboxesRaw || []).map(m => m.id) || emptyArr
  }
)
