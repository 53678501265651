// Inbox themed Button
// DEPRECATED: Prefer SUI Buttons (i.e. Base)
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import themeVars from 'ui_theme/site/globals/site.variables'
import { capitalize } from 'util/strings'

const size = ({ sizeProp, theme: { fontSize, spacing } }) => {
  switch (sizeProp) {
    case 'small':
      return {
        fontSize: fontSize.small,
        padding: `${spacing['0']} ${spacing['7']}`,
        height: spacing['14'],
      }
    case 'large':
      return {
        fontSize: fontSize.small,
        padding: `${spacing['0']} ${spacing['12']}`,
        height: spacing['18'],
      }
    default:
      return {
        fontSize: fontSize.small,
        padding: `${spacing['0']} ${spacing['12']}`,
        height: spacing['16'],
      }
  }
}

const Button = styled.button`
  -webkit-font-smoothing: antialiased;
  background-color: ${({ color, theme: { colors } }) =>
    colors[color] || colors.blue};
  border-radius: ${props => props.theme.spacing['3']};
  border-style: solid;
  border-width: 0;
  color: ${props => props.theme.color.monochrome.white};
  cursor: pointer;
  display: inline-block;
  font-family: ${themeVars.walsheim};
  line-height: 1.5;
  outline: none;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;

  &:disabled {
    opacity: 0.25;
  }

  &:hover {
    background-color: ${({ disabled, color, theme: { colors } }) => {
      if (disabled) return null
      return colors[`dark${capitalize(color)}`] || colors.blueHover
    }};
  }

  ${size};
`

Button.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  disabled: PropTypes.bool,
}

Button.defaultProps = {
  disabled: false,
}

Button.displayName = 'Button'

export default Button
