import { createSelector } from 'reselect'
import {
  selectSearchesRequestState,
  selectTicketSearchTotalCountsByQueryId,
} from 'selectors/search/search'
import { selectCurrentMailbox } from './selectCurrentMailbox'
import { hasTicketsForMailbox } from './hasTicketsForMailbox'

/**
 * @returns {boolean|undefined} undefined if searches request not loaded yet, boolean if searches request has completed
 */

export const selectCurrentMailboxHasTickets = createSelector(
  selectCurrentMailbox,
  selectTicketSearchTotalCountsByQueryId,
  selectSearchesRequestState,
  (mailbox, ticketSearchTotalCountsByQueryId, searchesRequestState) => {
    const { loaded, error } = searchesRequestState
    if (!loaded || error) return undefined

    return hasTicketsForMailbox(mailbox, ticketSearchTotalCountsByQueryId)
  }
)
