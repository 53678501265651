/* eslint-disable no-multi-assign */

import {
  SIDEBARCARD_FETCH_DATA_REQUEST,
  SIDEBARCARD_FETCH_DATA_SUCCESS,
  SIDEBARCARD_FETCH_DATA_FAILURE,
  SIDEBARCARD_CHANGE_OPEN_STATE,
  EMAIL_MARKETING_GET_INTEGRATION_STATE_REQUEST,
  EMAIL_MARKETING_GET_INTEGRATION_STATE_SUCCESS,
  EMAIL_MARKETING_GET_INTEGRATION_STATE_FAILURE,
  EMAIL_MARKETING_SUBSCRIBE_REQUEST,
  EMAIL_MARKETING_SUBSCRIBE_SUCCESS,
  EMAIL_MARKETING_SUBSCRIBE_FAILURE,
  EMAIL_MARKETING_UNSUBSCRIBE_REQUEST,
  EMAIL_MARKETING_UNSUBSCRIBE_SUCCESS,
  EMAIL_MARKETING_UNSUBSCRIBE_FAILURE,
  UPDATE_TICKETS,
} from 'constants/action_types'
import { isEmpty, intersection } from 'util/arrays'
import { keys } from 'util/objects'
import storage from 'util/storage'

const STORAGE_KEY = 'sidebarcards-open'

const getOpenState = id => {
  const item = storage.get(STORAGE_KEY)
  return (item && item[id]) || false
}

const setOpenState = ({ id, isOpen }) => {
  const item = storage.get(STORAGE_KEY) || {}
  storage.set(STORAGE_KEY, {
    ...item,
    [id]: isOpen,
  })
}

const reducers = {}
const initialState = {}

reducers[SIDEBARCARD_FETCH_DATA_REQUEST] = reducers[
  EMAIL_MARKETING_GET_INTEGRATION_STATE_REQUEST
] = reducers[EMAIL_MARKETING_SUBSCRIBE_REQUEST] = reducers[
  EMAIL_MARKETING_UNSUBSCRIBE_REQUEST
] = (state = initialState, action) => ({
  ...state,
  [action.item.id]: {
    ...state[action.item.id],
    isLoading: true,
    isError: false,
    provider: action.item.provider,
  },
})

reducers[SIDEBARCARD_FETCH_DATA_FAILURE] = reducers[
  EMAIL_MARKETING_GET_INTEGRATION_STATE_FAILURE
] = reducers[EMAIL_MARKETING_SUBSCRIBE_FAILURE] = reducers[
  EMAIL_MARKETING_UNSUBSCRIBE_FAILURE
] = (state = initialState, action) => ({
  ...state,
  [action.item.id]: {
    isError: true,
    isLoading: false,
    isOpen: getOpenState(action.item.id),
    provider: action.item.provider,
  },
})

function computeDerivedStripeData(data) {
  const { customerId, subscriptions } = data
  const derived = { ...data }
  derived.customerPageUrl = `https://dashboard.stripe.com/customers/${customerId}`
  if (subscriptions && subscriptions.data) {
    derived.subscriptionPageUrls = subscriptions.data.reduce(
      (result, sub) => ({
        ...result,
        [sub.id]: `https://dashboard.stripe.com/subscriptions/${sub.id}`,
      }),
      {}
    )
  }
  return derived
}

function computeDerivedShopifyData(data) {
  const { customer, shop } = data
  return {
    ...data,
    customerUrl:
      customer &&
      `https://${shop.myshopify_domain}/admin/customers/${customer.id}`,
    allOrdersUrl:
      customer &&
      `https://${shop.myshopify_domain}/admin/orders?customer_id=${
        customer.id
      }`,
    orderUrls:
      customer &&
      shop &&
      customer.orders.reduce(
        (result, order) => ({
          ...result,
          [order.id]: `https://${shop.myshopify_domain}/admin/orders/${
            order.id
          }`,
        }),
        {}
      ),
  }
}

function computeDerivedData(item) {
  const { provider, data } = item
  if (provider === 'stripe') {
    return computeDerivedStripeData(data)
  }
  if (provider === 'shopify') {
    return computeDerivedShopifyData(data)
  }
  return data
}

reducers[SIDEBARCARD_FETCH_DATA_SUCCESS] = reducers[
  EMAIL_MARKETING_GET_INTEGRATION_STATE_SUCCESS
] = (state = initialState, action) => ({
  ...state,
  [action.item.id]: {
    data: computeDerivedData(action.item),
    isOpen: getOpenState(action.item.id),
    isError: !!action.item.data.error,
    isLoading: false,
    provider: action.item.provider,
  },
})

reducers[EMAIL_MARKETING_SUBSCRIBE_SUCCESS] = reducers[
  EMAIL_MARKETING_UNSUBSCRIBE_SUCCESS
] = (state = initialState, action) => ({
  ...state,
  [action.item.id]: {
    data: null,
    isOpen: getOpenState(action.item.id),
    isError: !!action.item.data.error,
    isLoading: false,
    provider: action.item.provider,
  },
})

reducers[SIDEBARCARD_CHANGE_OPEN_STATE] = (state = initialState, action) => {
  setOpenState(action.item)
  return {
    ...state,
    [action.item.id]: {
      ...state[action.item.id],
      isOpen: action.item.isOpen,
    },
  }
}

const customProfileKeys = state =>
  keys(state).filter(k => k.match(/^custom-profile-/))

reducers[UPDATE_TICKETS] = (state = initialState, action) => {
  const customerIds = action.data.tickets
    .map(t => t.customer && t.customer.id)
    .filter(e => !!e)

  if (isEmpty(customerIds)) return state

  const profileKeys = customProfileKeys(state)

  if (isEmpty(profileKeys)) return state

  const cachedCustomerIds = profileKeys.map(
    k => k.match(/^custom-profile-(.*)$/)[1]
  )
  const keysToExpire = intersection(cachedCustomerIds, customerIds).map(
    k => `custom-profile-${k}`
  )

  if (isEmpty(keysToExpire)) return state

  return {
    ...state,
    ...keysToExpire.reduce((result, k) => {
      /* eslint-disable no-param-reassign */
      result[k] = { ...state[k], isExpired: true }
      return result
    }, {}),
  }
}

export default (state = initialState, action) => {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
