import { createSelector } from 'reselect'
import {
  fetchingStatusesSelector,
  isAuthenticatedSelector,
  isBoostrappedSelector,
  selectCurrentFolderId,
  selectDeviceTypeIsDesktop,
} from 'selectors/app'
import { selectCurrentPath as currentPathSelector } from 'selectors/location'

import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { selectCurrentMailboxId } from 'selectors/mailboxes/selectCurrentMailboxId'
import {
  selectTicketSorting,
  selectPreviousTicketSorting,
} from 'selectors/sorting'

import { any, getLength } from 'util/arrays'
import { isMe } from 'util/folders'

import { foldersByIdSelector } from './foldersByIdSelector'
import { selectFolders } from './selectFolders'
import { selectCurrentFolderMailboxTickets } from './selectCurrentFolderMailboxTickets'
import { selectCurrentFolder } from './selectCurrentFolder'
import { selectMailboxFoldersById } from './selectMailboxFoldersById'

const getFolderName = (byId, folderId) => {
  if (!folderId) return null
  const found = byId[folderId]
  if (!found) return null
  return found.name
}

export const selectCurrentFolderName = createSelector(
  foldersByIdSelector,
  selectCurrentFolderId,
  (byId, folderId) => getFolderName(byId, folderId)
)

export const selectCurrentFolderIfTickets = createSelector(
  selectCurrentFolder,
  selectCurrentFolderMailboxTickets,
  (folder, hasTickets) => (hasTickets ? folder : null)
)

export const selectCurrentFolderFailedToLoad = createSelector(
  selectCurrentFolderIfTickets,
  folder => (folder ? folder.failed : false)
)

export const shouldFetchCurrentFolderSelector = createSelector(
  isAuthenticatedSelector,
  isBoostrappedSelector,
  selectCurrentFolderIfTickets,
  fetchingStatusesSelector,
  currentPathSelector,
  selectTicketSorting,
  selectPreviousTicketSorting,
  (auth, isBootstrapped, current, statuses, path, sortBy, previousSortBy) => {
    if (!auth) return false
    if (!isBootstrapped) return false // wait til app loaded
    // if we're currently fetching, we should never trigger another fetch
    if (statuses.fetchCurrentFolderWithTickets) return false
    if (path === '/') return false // handled by starred search instead
    const onRightPage = path.match('folders/') || path.match('mailbox/')
    const should = !!(onRightPage && !current)
    const sortingChanged = sortBy !== previousSortBy
    return should || sortingChanged
  }
)

export const shouldFetchPreviousFolderForDesktop = createSelector(
  isAuthenticatedSelector,
  isBoostrappedSelector,
  selectDeviceTypeIsDesktop,
  selectCurrentFolderIfTickets,
  fetchingStatusesSelector,
  currentPathSelector,
  selectTicketSorting,
  selectPreviousTicketSorting,
  (
    auth,
    isBootstrapped,
    isDesktop,
    currentFolder,
    statuses,
    path,
    sortBy,
    previousSortBy
  ) => {
    if (!isDesktop) return false
    if (!auth) return false
    if (!isBootstrapped) return false
    // if we're currently fetching, we should never trigger another fetch
    if (statuses.fetchCurrentFolderWithTickets) return false
    const onRightPage = path.match('tickets/')
    const should =
      !!(onRightPage && !currentFolder) || sortBy !== previousSortBy
    return should
  }
)

export const selectCurrentMailboxFolders = createSelector(
  selectMailboxFoldersById,
  selectCurrentMailboxId,
  (mailboxFolders, mailboxId) => {
    return mailboxFolders[mailboxId].filter(e => !!e)
  }
)

export const selectCurrentMailboxHasFolders = createSelector(
  selectCurrentMailboxFolders,
  folders => folders && getLength(folders) > 0
)

export const selectCurrentMailboxFolders2 = () => [
  { name: 'Spam', count: 10 },
  { name: 'Spam', count: 10 },
  { name: 'Spam', count: 10 },
  { name: 'Spam', count: 10 },
  { name: 'Spam', count: 10 },
]

export const selectCurrentFolderIndex = createSelector(
  selectCurrentMailboxFolders,
  selectCurrentFolderId,
  (folders, folderId) => {
    if (!folders || !folderId) return null
    return folders.map(f => f.id).indexOf(folderId)
  }
)

const isDraftConditionFor = id => c => {
  return c.param === 'drafts' && (isMe(c.value) || c.value === id)
}

export const selectFolderIdsMatchingDraftForCurrentUser = createSelector(
  foldersByIdSelector,
  selectCurrentUser,
  (folders, user) => {
    return Object.keys(folders).filter(id => {
      const folder = folders[id]
      if (!folder || !folder.conditions) return false
      return any(isDraftConditionFor(user.id), folder.conditions)
    })
  }
)

export const selectUndefaultFoldersTotalCount = createSelector(
  selectFolders,
  folders => {
    if (!isBoostrappedSelector) {
      return null
    }
    return folders.filter(folder => !folder.default).length
  }
)
