import { emptyObj } from 'util/objects'
import { selectRawById } from './selectRawById'

// Deprecated, use selectRawTicketById

export function selectRawTicket(state, id) {
  if (id === 'new') {
    return state.tickets.byId?.new // bypasses any decoration
  }
  return selectRawById(state)[id] || emptyObj
}
