// Chips are compact elements that represent an input, attribute, or action.
//
// API inspired by https://material-ui.com/demos/chips/#chip
// Implemented using SUI Label https://react.semantic-ui.com/elements/label/
//
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { themeColorPropOr } from '@groovehq/internal-design-system/lib/util/theme'
import { Label } from 'util/ui'
import Flex from 'shared/ui/Flex'
import Text from 'shared/ui/Text'
import Icon from 'shared/ui/Icon'

const DeleteIcon = styled(props => <Icon name="close" {...props} />)`
  margin-left: 8px;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  @media print {
    display: none;
  }
`

const Chip = ({ className, bgColor, color, label, onDelete, ...rest }) => {
  return (
    <Label className={className} {...rest}>
      <Flex>
        <Text color={color} size="small">
          {label}
        </Text>
        {onDelete && (
          <DeleteIcon
            color={color}
            name="close"
            size="xsmall"
            onClick={onDelete}
          />
        )}
      </Flex>
    </Label>
  )
}

// Mostly SemUI overrides!
const Styled = styled(Chip)`
  &&&& {
    background-color: ${props =>
      props.bgColor || props.theme.color.primary.brand};
    border-radius: ${props => props.theme.spacing['3']};
    color: ${themeColorPropOr('color.monochrome.white')} !important;
    line-height: 16px;
    letter-spacing: initial !important; /* SUI ui.label override */
    text-transform: none !important; /* SUI ui.label override */
    padding: 4px 8px; /* SUI override */

    @media print {
      color: ${props => props.theme.color.monochrome.black} !important;
      background-color: white !important;
      border: 1px solid currentColor;

      & * {
        color: inherit !important;
        background-color: inherit !important;
      }
    }
  }
`

Styled.propTypes = {
  // Optional hex color code
  bgColor: PropTypes.string,

  // Text content
  label: PropTypes.string.isRequired,
}

export default Styled
