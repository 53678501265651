/* eslint-disable react/no-unused-prop-types */
// Wrapper around main Snackbar view that handles show/hide timeouts
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { propFunc } from 'util/functions'
import Body from './Body'

export default class Snackbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: props.open,
      message: props.message,
      action: props.action,
    }
  }

  componentDidMount() {
    if (this.state.open) {
      this.setAutoHideTimer()
    }
  }

  componentWillReceiveProps(nextProps) {
    const willStayOpen = this.props.open && nextProps.open
    const isOpening = this.props.open === false && nextProps.open
    const contentChanged =
      nextProps.message !== this.props.message ||
      nextProps.action !== this.props.action ||
      nextProps.id !== this.props.id
    const shouldMakeTransition = !nextProps.noTransition

    if (willStayOpen && contentChanged && shouldMakeTransition) {
      this.makeTransitionContent(nextProps)
    } else if (isOpening || !shouldMakeTransition) {
      this.makeItOpen(nextProps)
    } else {
      // isClosing
      this.makeItClose(nextProps)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.open !== this.state.open) {
      // if it's been changed ...
      if (this.state.open) {
        // ... and now it's open
        this.setAutoHideTimer()
      } else {
        // ... and now it's closed
        clearTimeout(this.timerAutoHideId)
      }
    } else if (this.state.open) {
      // if open state hasn't been changed and it keeps to be open
      if (prevProps.autoHideDuration !== this.props.autoHideDuration) {
        // ... but the duration changed
        this.setAutoHideTimer()
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timerAutoHideId)
    clearTimeout(this.timerOneAtTheTimeId)
  }

  // Timer that controls delay before snackbar auto hides
  setAutoHideTimer() {
    const { autoHideDuration } = this.props
    if (autoHideDuration > 0) {
      clearTimeout(this.timerAutoHideId)
      this.timerAutoHideId = setTimeout(() => {
        if (this.props.open !== null && this.props.onRequestClose) {
          this.props.onRequestClose('timeout')
        } else {
          this.setState({ open: false })
        }
      }, autoHideDuration)
    }
  }

  makeTransitionContent({ message, action }) {
    // it keeps to be open, only the content changed
    this.setState({ open: false })
    clearTimeout(this.timerOneAtTheTimeId)
    this.timerOneAtTheTimeId = setTimeout(() => {
      this.setState({
        message,
        action,
        open: true,
      })
    }, 400)
  }

  makeItOpen({ open, linkText, message, action }) {
    this.setState({
      message,
      action,
      linkText,
      open: open === null ? this.state.open : open,
    })
  }

  makeItClose({ open }) {
    this.setState({
      open: open === null ? this.state.open : open,
    })
  }

  render() {
    const {
      className,
      currentPath,
      link,
      onAction,
      onRequestClose,
      isMobile,
    } = this.props
    const { action, message, open, linkText } = this.state

    return (
      <Body
        action={action}
        onAction={onAction}
        onDismiss={propFunc(onRequestClose, 'dismiss')}
        className={className}
        currentPath={currentPath}
        link={link}
        linkText={linkText}
        message={message}
        open={open}
        isMobile={isMobile}
      />
    )
  }
}

Snackbar.propTypes = {
  // Text of Dismiss button
  action: PropTypes.string,

  // callback (to hide ) snackbar when action pressed
  onAction: PropTypes.func,

  // Optional uniqe id to differentiate to contents
  // when the action/message texts are the same
  id: PropTypes.string,

  // Time in ms before snackbar auto-hides
  autoHideDuration: PropTypes.number,

  // Needed for Optional link to show next to message
  currentPath: PropTypes.string,

  // Optional link to show next to message
  link: PropTypes.string,

  // Optional link text
  linkText: PropTypes.string,

  // Snackbar text
  message: PropTypes.string, // required if `open`!

  // callback (to hide ) snackbar when auto hide timeout reached
  onRequestClose: PropTypes.func,

  // Whether or not it is visible
  open: PropTypes.bool.isRequired,

  // Specify if no transition needed for the update
  noTransition: PropTypes.bool,
}

Snackbar.defaultProps = {
  action: null,
  onAction: null,
  autoHideDuration: 3000,
  currentPath: undefined,
  link: undefined,
  linkText: undefined,
  onRequestClose: undefined,
  message: '',
  id: '',
  noTransition: false,
}

Snackbar.displayName = 'Snackbar'
