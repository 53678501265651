import { createSelector } from 'reselect'

import {
  selectGroupsById,
  fetchingStatusesSelector,
  selectNavigationVisible,
  selectIsTicketsCurtainVisible,
  selectCurrentTicketListType as selectBasicTicketListType,
} from 'selectors/app'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { selectAgentsById } from 'selectors/agents/base'
import { selectCustomersById } from 'ducks/customers/selectors'
import { selectCurrentFolderName } from 'selectors/folders'
import { selectCurrentFolderMailboxTickets } from 'selectors/folders/selectCurrentFolderMailboxTickets'
import {
  selectCurrentTicketSearchQueryString,
  selectCurrentTicketSearchQueryObject,
  selectCurrentTicketSearch,
  selectCurrentTicketSearchResultTicketIds,
  selectCurrentTicketSearchQueryTitle,
} from 'selectors/search'
import { intersection, uniq, getLength } from 'util/arrays'

import { selectRawById } from 'selectors/tickets/byId/selectRawById'
import { selectTicketsById } from 'selectors/tickets/byId/selectTicketsById'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'

import {
  selectSelectedTicketIds,
  selectIsTicketSelectionMode,
} from 'selectors/ticket_list/base'

import { selectTicketSorting } from 'selectors/sorting'

import Assignment from 'inbox/assignment'
import { isEmpty } from 'util/objects'
import { getStateLabel } from 'util/ticketState'
import { memoize, objectHashSerializer } from 'util/memoization'

import * as TicketListTypes from 'constants/conversation_list_types'

const sortingFuncs = {
  'updated_at:desc': (a, b) =>
    Date.parse(b.updated_at) - Date.parse(a.updated_at),
  'updated_at:asc': (a, b) =>
    Date.parse(a.updated_at) - Date.parse(b.updated_at),
}

export const ticketSortByFunc = sorting =>
  sortingFuncs[sorting] || sortingFuncs['updated_at:desc']

const sortFolderTickets = memoize(
  (byId, mailboxTickets, sorting) => {
    if (!mailboxTickets) return null
    // NOTE (jscheel): Disabling instead of removing because this code path
    // really needs to be investigated.
    // eslint-disable-next-line no-undef
    return mailboxTickets.sort(ticketSortByFunc(sorting))
  },
  {
    serializer: objectHashSerializer,
  }
)

export const selectFirstTicketIdInList = createSelector(
  selectCurrentTicketSearchResultTicketIds,
  ids => (ids ? ids[0] : null)
)

export const selectCurrentTicketIndexInList = createSelector(
  selectCurrentTicketSearchResultTicketIds,
  selectCurrentTicketId,
  (ids, currentTicketId) => {
    if (!ids) return null
    if (!currentTicketId) return null
    return ids.indexOf(currentTicketId)
  }
)

export const selectNextTicketIdInList = createSelector(
  selectCurrentTicketSearchResultTicketIds,
  selectCurrentTicketId,
  (ids, currentTicketId) => {
    if (!ids) return null
    if (!currentTicketId) return null
    const currentTicketIndex = ids.indexOf(currentTicketId)
    return ids[currentTicketIndex + 1]
  }
)

export const selectPreviousTicketIdInList = createSelector(
  selectCurrentTicketSearchResultTicketIds,
  selectCurrentTicketId,
  (ids, currentTicketId) => {
    if (!ids) return null
    if (!currentTicketId) return null
    const currentTicketIndex = ids.indexOf(currentTicketId)
    return ids[currentTicketIndex - 1]
  }
)

export const selectTicketListTitle = createSelector(
  selectCurrentFolderName,
  selectCurrentTicketSearchQueryTitle,
  (folderTitle, queryTitle) => folderTitle || queryTitle
)

// Returns all the unique customers from the selected tickets.
export const selectSelectedTicketsAuthors = createSelector(
  selectSelectedTicketIds,
  selectTicketsById,
  selectCustomersById,
  (selectedTickets, ticketsById, customersById) => {
    const nullValue = []
    if (!selectedTickets) return nullValue
    if (getLength(selectedTickets) === 0) return nullValue
    if (!ticketsById) return nullValue

    return uniq(
      selectedTickets
        .map(ticketId => ticketsById[ticketId])
        .filter(t => !!t)
        .map(t => t.customer && t.customer.id)
        .filter(customerId => !!customerId)
    ).map(id => customersById[id])
  }
)

export const selectIsLoadingFolderCounts = createSelector(
  fetchingStatusesSelector,
  statuses => statuses.fetchFolderCounts
)

export const selectIsLoadingMoreTickets = createSelector(
  fetchingStatusesSelector,
  selectCurrentTicketSearchQueryString,
  (statuses, queryString) => {
    if (queryString && statuses.fetchTicketSearch) return true
    return statuses.fetchCurrentFolderWithTicketsPage
  }
)

// eslint-disable-next-line no-underscore-dangle
export const _selectCurrentPage = state => state.tickets.currentPage

export const selectCurrentPage = createSelector(
  _selectCurrentPage,
  selectCurrentTicketSearch,
  (currentPage, search) => {
    if (search) return search.currentPage
    return currentPage || 1
  }
)

// eslint-disable-next-line no-underscore-dangle
export const _selectTotalPages = state => state.tickets.totalPages

export const selectTotalPages = createSelector(
  _selectTotalPages,
  selectCurrentTicketSearch,
  (totalPages, search) => {
    if (search) return search.totalPages
    return totalPages || 1
  }
)

export const currentFolderTicketsSelector = createSelector(
  selectRawById,
  selectCurrentFolderMailboxTickets,
  selectTicketSorting,
  (byId, mailboxTickets, sorting) =>
    sortFolderTickets(byId, mailboxTickets, sorting)
)

export const selectAreAllTicketsSelected = createSelector(
  currentFolderTicketsSelector,
  selectSelectedTicketIds,
  (tickets, selected) => {
    if (!tickets) return false
    if (!selected) return false
    return tickets.length === selected.length
  }
)

export const selectAreAllSelectedStarred = createSelector(
  selectRawById,
  selectSelectedTicketIds,
  (tickets, selected) =>
    selected.reduce(
      (acc, id) => acc && tickets[id] && tickets[id].starred,
      true
    )
)

export const selectAreSomeSelectedAssigned = createSelector(
  selectRawById,
  selectSelectedTicketIds,
  (tickets, selected) =>
    selected.reduce(
      (acc, id) =>
        acc ||
        (tickets[id] &&
          (tickets[id].assignee || tickets[id].assigned_group_id)),
      false
    )
)

function findCommonState(tickets, selected, opts = {}) {
  const states = uniq(
    selected.map(
      id =>
        tickets[id] &&
        getStateLabel(
          tickets[id].state,
          tickets[id].snoozedUntil,
          tickets[id].deleted_at,
          opts
        )
    )
  )
  return states.length === 1 ? states[0] : null
}

export const selectCommonStateForSelected = createSelector(
  selectRawById,
  selectSelectedTicketIds,
  (tickets, selected) => findCommonState(tickets, selected)
)

export const selectAreAllSelectedSpam = createSelector(
  selectCommonStateForSelected,
  commonState => commonState === 'spam'
)

export const selectUnmappedCommonStateForSelected = createSelector(
  selectTicketsById,
  selectSelectedTicketIds,
  (tickets, selected) =>
    findCommonState(tickets, selected, { mapUnreadToOpen: false })
)

// Selects the ids of the assigned agents of the selected tickets, (includes nulls)
const selectAssignedAgentIdsForSelectedTickets = createSelector(
  selectRawById,
  selectSelectedTicketIds,
  (tickets, selected) =>
    selected.map(id => {
      if (tickets[id] && tickets[id].assignee) {
        return tickets[id].assignee.id
      }
      return null // this ticket assigned to any agent
    })
)

const selectCommonAssignedAgentIdsForSelectedTickets = createSelector(
  selectAssignedAgentIdsForSelectedTickets,
  selectedAgentIds => {
    // because of how intersection works, each id needs to be in an array
    const agentIds = selectedAgentIds.map(id => [id])
    return intersection.apply(this, agentIds)
  }
)

// There can be only one
function highlander(list) {
  if (isEmpty(list) || getLength(list) > 1) return null
  return list[0]
}

export const selectCommonAssignedAgentsForSelectedTickets = createSelector(
  selectCommonAssignedAgentIdsForSelectedTickets,
  selectAgentsById,
  (commonAgentIds, agents) => {
    // eslint-disable-next-line no-confusing-arrow
    return commonAgentIds.map(id => (id ? agents[id] : null))
  }
)

// Selects the one common agent across all selected tickets. Null if > 1
// agents are assigned among the selected, or no agents assigned at all.
export const selectCommonAssignedAgentForSelectedTickets = createSelector(
  selectCommonAssignedAgentsForSelectedTickets,
  highlander
)

// Selects the ids of the assigned groups of the selected tickets
const selectAssignedGroupsForSelectedTickets = createSelector(
  selectRawById,
  selectSelectedTicketIds,
  selectGroupsById,
  (tickets, selected, groupsById) =>
    selected.map(id => {
      if (tickets[id] && tickets[id].assigned_group_id) {
        const groupId = tickets[id].assigned_group_id
        return groupsById[groupId]
      }
      return null // no group is also a group!
    })
)

const selectCommonAssignedGroupIdsForSelectedTickets = createSelector(
  selectAssignedGroupsForSelectedTickets,
  selectedGroups => {
    // because of how intersection works, each id needs to be in an array
    const groupIds = selectedGroups.map(group => [group ? group.id : null])
    return intersection.apply(this, groupIds)
  }
)

export const selectCommonAssignedGroupsForSelectedTickets = createSelector(
  selectCommonAssignedGroupIdsForSelectedTickets,
  selectGroupsById,
  (commonGroupIds, groups) => {
    // eslint-disable-next-line no-confusing-arrow
    return commonGroupIds.map(id => (id ? groups[id] : null))
  }
)

// Selects the one common group across all selected tickets. Null if > 1
// groups are assigned among the selected, or no groups assigned at all.
export const selectCommonAssignedGroupForSelectedTickets = createSelector(
  selectCommonAssignedGroupsForSelectedTickets,
  highlander
)

// Creates an the unique 'Assignment' representation for all selected tickets
export const selectAssignmentForSelectedTickets = createSelector(
  selectCommonAssignedAgentForSelectedTickets,
  selectCommonAssignedGroupForSelectedTickets,
  selectCurrentUser,
  selectAgentsById,
  (agent, group, currentUser, agents) =>
    Assignment.build(agent, group, currentUser, agents)
)

// Selects the ids of the assigned groups of the selected tickets
export const selectAssignedPairsForSelectedTickets = createSelector(
  selectTicketsById,
  selectSelectedTicketIds,
  selectGroupsById,
  selectAgentsById,
  (tickets, selected, groupsById, agentsById) =>
    selected.map(id => {
      let groupId
      let group
      let agentId
      let agent
      if (tickets[id] && tickets[id].assigned_group_id) {
        groupId = tickets[id].assigned_group_id
        group = groupsById[groupId] || null
      }
      if (tickets[id] && tickets[id].assignee) {
        agentId = tickets[id].assignee.id
        agent = agentsById[agentId] || null
      }
      return { groupId, group, agentId, agent }
    })
)

export const selectAssignedNameForSelectedTickets = createSelector(
  selectAssignedPairsForSelectedTickets,
  selectCurrentUser,
  (pairs, currentUser) => {
    const length = pairs.length
    let lastPair
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < length; i++) {
      const pair = pairs[i]
      if (
        lastPair &&
        (lastPair.agentId !== pair.agentId || lastPair.groupId !== pair.groupId)
      )
        return null
      if (!lastPair) lastPair = pair
    }
    if (lastPair) {
      const { group, agent } = lastPair
      const parts = []
      if (group) {
        parts.push(group.name)
      }
      if (agent) {
        const agentName =
          agent.email === currentUser.email ? 'You' : agent.shortName
        parts.push(agentName)
      }
      return parts.length > 0 ? parts.join(': ') : null
    }
    return null
  }
)

export const selectSelectedTicketsAreDeleted = createSelector(
  selectTicketsById,
  selectSelectedTicketIds,
  (ticketsById, selectedTicketIds) => {
    return !selectedTicketIds.find(
      ticketId => !(ticketsById[ticketId] && ticketsById[ticketId].deleted_at)
    )
  }
)

export const selectTicketSelectionCount = createSelector(
  selectSelectedTicketIds,
  selectIsTicketSelectionMode,
  (selected, isTicketSelectionMode) => {
    if (!isTicketSelectionMode) return undefined
    const length = getLength(selected)
    return length >= 0 ? length : 0
  }
)

export const selectIsTicketSelectionModeAndTicketsSelected = createSelector(
  selectIsTicketSelectionMode,
  selectTicketSelectionCount,
  (isBulkSelectionMode, ticketSelectionCount) =>
    isBulkSelectionMode && ticketSelectionCount > 0
)

export const selectRandomSeed = state => state.tickets.randomSeed

export const selectIsNewTicketButtonVisible = createSelector(
  selectNavigationVisible,
  selectIsTicketsCurtainVisible,
  selectIsTicketSelectionMode,
  (isNavVisible, isTicketsCurtainVisible, isSelecting) =>
    !(isNavVisible || isTicketsCurtainVisible || isSelecting)
)

export const selectCurrentTicketListTypeIncludingDrafts = createSelector(
  selectBasicTicketListType,
  selectCurrentTicketSearchQueryObject,
  (basicType, queryObject) => {
    if (basicType === TicketListTypes.SEARCH) {
      const hasValidSearch = queryObject && !isEmpty(queryObject)

      if (hasValidSearch && queryObject.draft) return TicketListTypes.DRAFTS

      return TicketListTypes.SEARCH
    }

    // Return FOLDER, or null (its possible to be in a state where no current
    // ticket list type has been initialized yet (e.g. on loading /tickets/:id
    // directly))
    return basicType
  }
)

export const selectFirstFolderTicketId = createSelector(
  currentFolderTicketsSelector,
  currentFolderTickets => {
    return (
      currentFolderTickets &&
      currentFolderTickets[0] &&
      currentFolderTickets[0].id
    )
  }
)

export const selectFirstFolderTicket = createSelector(
  selectFirstFolderTicketId,
  selectTicketsById,
  (id, byId) => byId[id]
)
