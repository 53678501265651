import {
  EMAIL_MARKETING_FETCH_INTEGRATIONS_REQUEST,
  EMAIL_MARKETING_FETCH_INTEGRATIONS_FAILURE,
  EMAIL_MARKETING_FETCH_INTEGRATIONS_SUCCESS,
} from 'constants/action_types'

const initialState = {
  loadingState: 'initial', // ready, loading, error
  data: null,
}

const reducers = {}

reducers[EMAIL_MARKETING_FETCH_INTEGRATIONS_REQUEST] = () => ({
  data: null,
  loadingState: 'loading',
})
reducers[EMAIL_MARKETING_FETCH_INTEGRATIONS_FAILURE] = (
  state = initialState,
  action
) => ({
  data: action.item,
  loadingState: 'error',
})
reducers[EMAIL_MARKETING_FETCH_INTEGRATIONS_SUCCESS] = (
  state = initialState,
  action
) => ({
  data: action.item,
  loadingState: 'ready',
})

export default (state = initialState, action) => {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
