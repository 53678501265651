// Hack wrapper to prevent errors like  Unknown event handler property `onToggleOpen`. It will be ignored.
import React from 'react'
import { getDisplayName } from 'util/hoc'
import { omit } from 'util/objects'

function withCleanDomProps(WrappedDropdown) {
  class WithCleanDomProps extends React.PureComponent {
    render() {
      return <WrappedDropdown {...omit(['onToggleOpen'], this.props)} />
    }
  }

  WithCleanDomProps.displayName = `WithCleanDomProps(${getDisplayName(
    WrappedDropdown
  )})`

  return WithCleanDomProps
}

export default withCleanDomProps
