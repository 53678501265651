import { createSelector } from 'reselect'
import { selectCurrentMailbox as cachedMailboxObject } from 'selectors/app/base'
import { selectMailboxesByName } from './selectMailboxesByName'
import { selectMailboxesById } from './selectMailboxesById'

// The currentMailbox in the store can have either an id (from folder paths) or a
// name (from search paths).

export const selectCurrentMailboxId = createSelector(
  cachedMailboxObject,
  selectMailboxesByName,
  selectMailboxesById,
  (mailbox, byName, byId) => {
    if (!mailbox) return null
    if (mailbox.id) return mailbox.id
    const idOrName = mailbox.name
    if (idOrName && byName[idOrName]) return byName[idOrName].id
    if (idOrName && byId[idOrName]) return byId[idOrName].id
    return null
  }
)
