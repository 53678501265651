// An individual notification
//
// Wrapper around SUI Message. Not dismissable by default.
//
import cn from 'classnames'
import { defaultProps } from 'recompose'
import React from 'react'
import { Message } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { compose } from 'redux'

import { last } from 'util/arrays'
import withOpenState from 'shared/ui/withOpenState'
import { Icon } from 'util/ui'

import withAutoDismiss from './withAutoDismiss'
import withSlideTransition from './withSlideTransition'
import AppendEllipsis from './AppendEllipsis'
import IconBox from './IconBox'
import TextBox from './TextBox'

function getIconName(type = 'positive') {
  if (type === 'positive') return 'success-outline'
  return 'alert-outline'
}

const Notification = styled(
  ({ className, fluid, header, message, messageEllipsis, onDismiss, type }) => {
    let headerText = header
    let messageText = message

    if (!headerText) {
      headerText = message
      messageText = null
    }

    const messageHeader = headerText ? (
      <div className="textHeader">{headerText}</div>
    ) : null

    if (messageText) {
      const pieces = messageText.split('\n')
      const lastPiece = last(pieces)

      messageText = pieces.map(piece => {
        if (!messageEllipsis || piece !== lastPiece) return <div>{piece}</div>
        return <AppendEllipsis>{piece}</AppendEllipsis>
      })
    }

    return (
      <Message
        className={cn(className, { fluid })}
        icon
        {...{ [type]: true }}
        onDismiss={onDismiss}
      >
        <IconBox className="grui flex-col">
          <Icon className={`Icon Icon-${getIconName(type)}`} size="big" />
        </IconBox>
        <TextBox className="grui flex-col">
          {messageHeader}
          <Message.Content>{messageText}</Message.Content>
        </TextBox>
      </Message>
    )
  }
)`
  &&& {
    &.ui.message {
      background-color: white;
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.15);
      max-width: 300px;

      &.positive {
        border-left: 40px solid ${props => props.theme.color.primary.positive};
      }

      &.warning {
        border-left: 40px solid ${props => props.theme.color.primary.warning};
      }

      &.negative {
        border-left: 40px solid ${props => props.theme.color.primary.negative};
      }

      &.fluid {
        max-width: none;
      }
    }
  }
`

Notification.propTypes = {
  // Disable transition on mount
  appear: PropTypes.bool,

  // Disable limit on width
  fluid: PropTypes.bool,

  // Text content
  messageEllipsis: PropTypes.bool,

  // Text content
  header: PropTypes.string,

  // Text content
  message: PropTypes.string.isRequired,

  // Called when notification is manually closed
  onDismiss: PropTypes.func,

  // Type of notification
  type: PropTypes.oneOf(['positive', 'warning', 'negative']),
}

Notification.defaultProps = {
  appear: false,
  fluid: false,
  messageEllipsis: false,
  header: '',
  type: 'positive',
}

export default compose(
  defaultProps({ initiallyOpen: true }),
  withOpenState,
  withAutoDismiss,
  withSlideTransition
)(Notification)
