/* eslint-disable no-multi-assign, func-names */
import * as pages from 'constants/pages'
import * as types from 'constants/action_types'
import { levenshteinDistance } from 'util/strings'
import {
  CREATE_DRAFT,
  DELETE_DRAFT,
  UPDATE_DRAFT,
} from 'ducks/drafts2/constants'

// started_at, updated_at, priority, state

const defaultState = {}
const reducers = {}

reducers[types.SEARCH_USER_SUCCESS] = (state, action) => {
  const { term, users, isForwarding } = action.data

  const sortedUsers = [].concat(users)

  if (term && term.indexOf('@') > -1) {
    sortedUsers.sort((a, b) => {
      return (
        levenshteinDistance(a.email, term) - levenshteinDistance(b.email, term)
      )
    })
  }

  const cacheKey = (isForwarding ? 'forward:' : '') + term
  const previousSearchResults = state.previousSearchResults || {}
  previousSearchResults[cacheKey] = sortedUsers

  return Object.assign({}, state, {
    suggestedUsers: sortedUsers,
    lastSearchTerm: term,
    previousSearchResults,
  })
}

reducers[types.CLEAR_USER_SUGGESTION] = state =>
  Object.assign({}, state, {
    suggestedUsers: [],
    lastSearchTerm: null,
  })

// When we go from (mobile) draft back to the ticket or switch between
// New Convo and Log a Convo pages, clear the search results
reducers[pages.TICKET_PAGE] = reducers[pages.NEW_CONVERSATION_PAGE] = reducers[
  pages.LOG_CONVERSATION_PAGE
] = state =>
  Object.assign({}, state, {
    suggestedUsers: [],
    lastSearchTerm: null,
  })

reducers[CREATE_DRAFT] = reducers[DELETE_DRAFT] = state => {
  return Object.assign({}, state, {
    suggestedUsers: [],
    lastSearchTerm: null,
  })
}

reducers[UPDATE_DRAFT] = (state, action) => {
  const { fields } = action.payload

  if (fields.to || fields.cc || fields.bcc) {
    return Object.assign({}, state, {
      suggestedUsers: [],
      lastSearchTerm: null,
    })
  }

  return state
}

export default function reducer(state = defaultState, action) {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
