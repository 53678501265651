import cn from 'classnames'
import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { compose } from 'redux'
import { withProps } from 'recompose'
import { Portal, Sidebar as SemanticSidebar } from 'semantic-ui-react'
import { styles as drawerStyles } from '@groovehq/internal-design-system/lib/components/Drawer/Drawer.styles'

import ListenToKeyboard from 'components/ListenToKeyboard'
import { SUI } from 'shared/ui'
import withDelayedMountUnmount from 'shared/ui/withDelayedMountUnmount'

import Content from './Content'
import Header from './Header'
import Footer from './Footer'
import styles from './styles.less'

const ComposedSidebar = compose(
  withProps({
    shouldMountProp: 'open',
    undelayedShouldMountProp: 'undelayedOpen',
  }),
  withDelayedMountUnmount
)(Sidebar)

export default ComposedSidebar

ComposedSidebar.propTypes = {
  /** Animation style used when hiding/showing component */
  animation: PropTypes.oneOf([
    'overlay',
    'push',
    'scale down',
    'uncover',
    'slide out',
    'slide along',
  ]),
  /** Direction from where the component shows */
  direction: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  /** Narrow version of the sidebar */
  narrow: PropTypes.bool,
  /** Wide version of the sidebar */
  wide: PropTypes.bool,
  /** Dimmer is transparent */
  noDim: PropTypes.bool,

  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  headerChildren: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  footerChildren: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  bodyCss: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}

ComposedSidebar.defaultProps = {
  animation: 'overlay',
  direction: 'right',
  narrow: false,
  wide: false,
  headerChildren: null,
  footerChildren: null,
  onInit: null,
  noDim: false,
  bodyCss: null,
}

const FrozenChildren = memo(
  // eslint-disable-next-line prefer-arrow-callback
  function FreezeChildren({ children }) {
    return children
  },
  ({ undelayedOpen: oldOpen }, { undelayedOpen: newOpen }) => {
    if (oldOpen === true && newOpen === false) {
      return true
    }
    return false
  }
)

function Sidebar({
  animation,
  children,
  className,
  direction,
  footerChildren,
  headerChildren,
  narrow = false,
  onClose,
  open = false,
  undelayedOpen = false,
  wide = false,
  noDim = false,
  bodyCss = null,
}) {
  return (
    <SUI>
      <Portal open>
        <ListenToKeyboard
          disableForInput
          onEscape={onClose}
          hijack={ListenToKeyboard.MODAL}
        >
          <SemanticSidebar
            animation={animation}
            direction={direction}
            visible={open}
            className={cn(className, styles.sideBar, {
              [styles.narrow]: narrow,
              [styles.wide]: wide,
            })}
          >
            <FrozenChildren undelayedOpen={undelayedOpen}>
              <Header onClose={onClose} css={drawerStyles.header}>
                {headerChildren}
              </Header>
              <div className={styles.body} css={bodyCss}>
                {children}
              </div>
              <Footer>{footerChildren}</Footer>
            </FrozenChildren>
          </SemanticSidebar>
          <div
            className={cn(styles.dimmer, {
              [styles.show]: open,
              [styles.transparent]: noDim,
            })}
            onClick={onClose}
          />
        </ListenToKeyboard>
      </Portal>
    </SUI>
  )
}

ComposedSidebar.Content = Content
