import { createSelector } from 'reselect'
import {
  fetchingStatusesSelector,
  isAuthenticatedSelector,
} from 'selectors/app'
import { selectIsInInbox } from 'selectors/location'

import { dirtyTicketIdsSelector } from './dirty'
import { selectCurrentTicketUndecorated } from './current/selectCurrentTicketUndecorated'

export const shouldFetchTicketSelector = createSelector(
  isAuthenticatedSelector,
  selectCurrentTicketUndecorated,
  fetchingStatusesSelector,
  dirtyTicketIdsSelector,
  selectIsInInbox,
  (auth, ticket, statuses, dirtyTicketIds, isInbox) => {
    if (!isInbox) return false
    if (!auth) return false
    // not if we're currently fetching
    if (statuses.fetchTicket) return false
    if (ticket && ticket.loadFailed) return false
    if (!ticket) return true
    if (!ticket.full) return true
    if (dirtyTicketIds[ticket.id]) return true
    return false
  }
)
