// TODO (jscheel): Add ability to define position

import React from 'react'
import PropTypes from 'prop-types'

import { Input } from 'util/ui'

import FieldError from './FieldError'
import PendingValidation from './PendingValidation'

class ValidatedField extends React.PureComponent {
  constructor() {
    super()
    this.takeRef = this.takeRef.bind(this)
  }

  takeRef(node) {
    const { autoFocus, fieldRefCallback } = this.props
    if (node?.inputRef?.current && autoFocus) {
      setTimeout(() => {
        node.inputRef.current.focus()
      }, 10)
    }
    if (fieldRefCallback) return fieldRefCallback(node)
    return null
  }

  render() {
    const {
      autoFocus,
      errorMessage,
      isErrorMessageHidden,
      validatedFieldComponent: ComponentType,
      fieldRefCallback,
      ...rest
    } = this.props

    const errorMsg = (
      <>
        {!!errorMessage && errorMessage.then && <PendingValidation />}
        {!!errorMessage &&
          !errorMessage.then && <FieldError>{errorMessage}</FieldError>}
      </>
    )

    return (
      <div>
        <ComponentType error={!!errorMessage} ref={this.takeRef} {...rest} />
        {!isErrorMessageHidden && errorMsg}
      </div>
    )
  }
}

ValidatedField.propTypes = {
  validatedFieldComponent: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.element,
    PropTypes.func,
  ]),
  errorMessage: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.instanceOf(Promise),
  ]),
  isErrorMessageHidden: PropTypes.bool,
}

ValidatedField.defaultProps = {
  validatedFieldComponent: Input,
  errorMessage: null,
  isErrorMessageHidden: false,
}

export default ValidatedField
