import { createSelector } from 'reselect'
import { selectCurrentPath as currentPathSelector } from 'selectors/location'
import { selectMailboxesById } from './selectMailboxesById'
import { _selectSortedIds } from './_selectSortedIds'
import { selectCurrentMailboxId } from './selectCurrentMailboxId'

export const selectCurrentMailbox = createSelector(
  selectMailboxesById,
  selectCurrentMailboxId,
  _selectSortedIds,
  currentPathSelector,
  (byId, mailboxId, sortedIds, path) => {
    if (!mailboxId && path && path.match(/^\/folders/)) {
      return null
    }
    if (!mailboxId) return null
    const found = byId[mailboxId]
    if (found) {
      return found
    }
    return null
  }
)
