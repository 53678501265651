import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { Button } from 'util/ui'
import SUI from 'shared/components/ui/SUI'

class ButtonWithDropdown extends React.PureComponent {
  static propTypes = {
    /* Whether the dropdown menu is disabled */
    disabled: PropTypes.bool,

    /* Dropdown content */
    dropdown: PropTypes.node.isRequired,

    /* Called when primary button clicked */
    onClick: PropTypes.func.isRequired,
  }

  static defaultProps = {
    disabled: false,
    headerText: undefined,
  }

  handleKeyDown = (e, obj) => {
    const { onClick, disabled } = this.props
    // HACK (jscheel): Something somewhere is preventing react from properly
    // interpreting enter key press on the button, even when we set the tabIndex
    // to make sure it was interactable. Space presses work as expected but enter
    // presses continue to be stubborn.
    if (
      !disabled &&
      e.which === 13 &&
      !e.metaKey &&
      !e.shiftKey &&
      !e.ctrlKey &&
      !e.altKey
    ) {
      onClick(e, obj)
    }
  }

  render() {
    const {
      children,
      className,
      disabled,
      dropdown,
      onClick,
      color,
    } = this.props

    return (
      <SUI>
        <Button.Group className={className} color={color}>
          <Button
            onClick={onClick}
            onKeyDown={this.handleKeyDown}
            tabIndex="0"
            disabled={disabled}
          >
            {children}
          </Button>
          {dropdown}
        </Button.Group>
      </SUI>
    )
  }
}

export default styled(ButtonWithDropdown)`
  /**
   * SUI overrides
   */

  &&& {
    margin: 0px !important;

    .button {
      line-height: 1.25 !important;
      padding: ${props =>
        `${props.theme.spacing['5']} ${props.theme.spacing['10']}`} !important;
      text-transform: none !important;

      /* fixes above large button padding interfering with dropdown button */
      &.dropdown {
        padding: ${props =>
          `${props.theme.spacing['5']} ${props.theme.spacing['7']}`} !important;
      }
    }

    .dropdown {
      /* The SUI wrappers bork the button group radii */
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;

      /* Hacks around button group + SUI wrapper issue */
      border-left: 1px solid hsla(0, 0%, 100%, 0.25) !important;
    }

    .dropdown > .dropdown.icon {
      margin: 0 !important;
    }
  }
`
