import { createSelector } from 'reselect'
import { intersection } from 'util/arrays'
import { _selectById } from './_selectById'

// Select only the common words across all mailboxes
// eslint-disable-next-line no-underscore-dangle
export const _selectCommonWords = createSelector(_selectById, mailboxes => {
  const names = Object.keys(mailboxes).map(id => mailboxes[id].name)
  const splitNames = names.map(name => name.split(' '))

  return intersection.apply(this, splitNames)
})
