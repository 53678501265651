import { Icon } from 'util/ui'
import { Tip } from 'shared/components/ui/Typography'

export default function FieldError({ children, ...rest }) {
  return (
    <Tip color="berry" {...rest}>
      <Icon name="times circle outline" />
      {children}
    </Tip>
  )
}
