// HOC that adds left-slide transition based on `open` prop.
//
import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import styled from '@emotion/styled'
import { getDisplayName } from 'util/hoc'

function withSlideTransition(WrappedComponent) {
  const WithSlideTransition = styled(({ appear, open, ...rest }) => {
    return (
      <CSSTransition
        appear={appear}
        in={open}
        classNames={'slide'}
        timeout={500}
        mountOnEnter
        unmountOnExit
      >
        <WrappedComponent {...rest} />
      </CSSTransition>
    )
  })`
    &.slide-enter,
    &.slide-appear {
      transform: translate(-150%);
    }

    &.slide-appear.slide-appear-active,
    &.slide-enter.slide-enter-active {
      transform: translate(0%);
      transition: transform 500ms ease-in-out;
    }

    &.slide-exit {
      transform: translate(0%);
    }

    &.slide-exit.slide-exit-active {
      transform: translate(-150%);
      transition: transform 500ms ease-in-out;
    }
  `

  WithSlideTransition.displayName = `WithSlideTransition(${getDisplayName(
    WrappedComponent
  )})`

  WithSlideTransition.propTypes = {
    // Is its open/visible. Transitions trigger on this prop changing.
    open: PropTypes.bool.isRequired,
  }

  WithSlideTransition.defaultProps = {
    open: true,
  }

  return WithSlideTransition
}

export default withSlideTransition
