import { useEffect } from 'react'
import styled from '@emotion/styled'
import { Label, Tooltip } from 'shared/ui'
import { Icon } from 'util/ui'

import ArrayOfStringsWithPrimaryRecord from './ArrayOfStringsWithPrimaryRecord'
import useManageArrayOfStringsWithPrimary from './useManageArrayOfStringsWithPrimary'

export default styled(ArrayOfStringsWithPrimary)`
  .RemoveButton {
    width: 43px;
  }
`

function ArrayOfStringsWithPrimary({
  className,
  maxRecords,
  noun,
  primaryName,
  onCountChange,
  records: initialRecords,
  secondaryName,
  title,
}) {
  const {
    doAdd,
    doRemove,
    doSetRecord,
    doSetPrimary,
    records,
  } = useManageArrayOfStringsWithPrimary(initialRecords, maxRecords)
  useEffect(onCountChange, [records.length, onCountChange])

  let primaryRendered = false
  return (
    <div className={className}>
      <Label as="div">
        {`${title} `}
        <Tooltip
          className="tooltipTrigger"
          as="span"
          position="right"
          tooltip={`The primary ${noun} is selected by clicking the radio button.`}
        >
          <Icon name="question circle outline" />
        </Tooltip>
      </Label>
      {records.map(({ isPrimary, value }, index) => {
        if (isPrimary) primaryRendered = true
        return (
          <ArrayOfStringsWithPrimaryRecord
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            doSetRecord={doSetRecord}
            doSetPrimary={doSetPrimary}
            doRemove={records.length > 1 || value !== '' ? doRemove : null}
            index={index}
            isPrimary={isPrimary}
            primaryName={primaryName}
            name={
              isPrimary
                ? `${primaryName}.content`
                : `${secondaryName}[${
                    primaryRendered ? index - 1 : index
                  }].content`
            }
            noun={noun}
            value={value}
          />
        )
      })}
      {records.length < maxRecords && (
        <div className="link" onClick={doAdd}>
          + Add {noun}
        </div>
      )}
    </div>
  )
}
