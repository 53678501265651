import React from 'react'
import Label from 'shared/ui/Label'

const MenuHeader = ({ className, children }) => {
  return (
    <Label className={className} size="large">
      {children}
    </Label>
  )
}

export default MenuHeader
