import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const SUI = ({ transparent, children, className, ...rest }) => {
  return (
    <div
      className={cn(className, 'sui', {
        'sui-transparent': transparent,
      })}
    >
      <div
        className={cn('sui-inner', {
          'sui-inner-transparent': transparent,
        })}
        {...rest}
      >
        {children}
      </div>
    </div>
  )
}

SUI.propTypes = {
  transparent: PropTypes.bool,
}

SUI.defaultProps = {
  transparent: true,
}

SUI.displayName = 'SUI'

export default SUI
