/* eslint-disable no-multi-assign */ // ok in reducers
import * as types from 'constants/action_types'
import { INSERT_CANNED_REPLY } from 'ducks/cannedReplies/types'
import * as modals from 'constants/modal_types'
import { TICKET_PAGE } from 'constants/pages'
import { MERGE_TICKET_STARTED, MERGE_TICKET_SUCCESS } from 'ducks/merging/types'
import { emptyObj } from 'util/objects'

const defaultState = {
  modalType: null,
  modalProps: {},
}
const reducers = {}

reducers[types.SHOW_MODAL] = (state, action) =>
  Object.assign({}, state, {
    modalType: action.data.modalType,
    modalProps: action.data.modalProps || {},
  })

reducers[types.HIDE_MODAL] = reducers[types.ASSIGN_TICKETS_TO_AGENT] = reducers[
  types.MANAGE_LABELS_END
] = reducers[types.INTEGRATIONS_ATTACH_TO_TICKET_SUCCESS] = reducers[
  INSERT_CANNED_REPLY
] = () => defaultState

// hides desktop dropdowns
reducers[MERGE_TICKET_STARTED] = reducers[MERGE_TICKET_SUCCESS] = reducers[
  types.LABELINGS_SAVED
] = () => defaultState

reducers[types.UPDATE_MODAL_PROPS] = (state, action) =>
  Object.assign({}, state, {
    modalProps: Object.assign({}, state.modalProps, action.data.modalProps),
  })

reducers[types.SNOOZE_TICKETS_REQUEST] = (state, action) => {
  const { snoozeOption } = action.data

  return Object.assign({}, state, {
    selectedSnoozeState: snoozeOption,
  })
}

reducers[types.SNOOZE_TICKETS_COMPLETE] = state =>
  Object.assign({}, state, { selectedSnoozeState: null })

reducers[types.GROUP_DRILL_DOWN] = (state, action) => {
  const { drilledDownGroupId } = action.data
  const modalProps = Object.assign({}, state.modalProps)
  modalProps.drilledDownGroupId = drilledDownGroupId

  return Object.assign({}, state, { modalProps })
}

reducers[types.ASSIGN_TICKET_TO_AGENT] = (state, action) => {
  const { agentId } = action.data
  const modalProps = Object.assign({}, state.modalProps)
  modalProps.selectedAgentId = agentId

  return Object.assign({}, state, { modalProps })
}

reducers[types.SET_TICKET_ASSIGNMENT_FILTER] = (state, action) => {
  const { term } = action.data
  const modalProps = Object.assign({}, state.modalProps)
  modalProps.ticketAssignmentFilter = term

  return Object.assign({}, state, { modalProps })
}

reducers[types.SET_TICKET_GROUP_ASSIGNMENT_FILTER] = (state, action) => {
  const { term } = action.data
  const modalProps = Object.assign({}, state.modalProps)
  modalProps.ticketGroupAssignmentFilter = term

  return Object.assign({}, state, { modalProps })
}

reducers[types.LIGHTBOX_NEXT] = state => {
  const { imageIndex, totalImages } = state.modalProps
  return {
    ...state,
    modalProps: {
      ...state.modalProps,
      imageIndex: imageIndex + 1 === totalImages ? totalImages : imageIndex + 1,
    },
  }
}

reducers[types.LIGHTBOX_PREVIOUS] = state => {
  const { imageIndex } = state.modalProps
  return {
    ...state,
    modalProps: {
      ...state.modalProps,
      imageIndex: Math.max(0, imageIndex - 1),
    },
  }
}

// We preserve our 'main' modal state (could be the labeling dropdown, or the
// manage labels modal), and add 'sub-states' (editing/creating/deleting) in
// modalProps.
reducers[types.BEGIN_EDIT_LABEL] = (state, { data: { label } }) => ({
  ...state,
  modalProps: {
    ...state.modalProps,
    isCreatingALabel: !label.id,
    isEditingALabel: !!label.id,
    label,
  },
})

reducers[types.CANCEL_EDIT_LABEL] = reducers[
  types.CREATE_LABEL_SUCCESS
] = reducers[types.UPDATE_LABEL_SUCCESS] = state => ({
  ...state,
  modalProps: {
    ...state.modalProps,
    isCreatingALabel: false,
    isEditingALabel: false,
    label: {},
  },
})

reducers[types.BEGIN_DELETE_LABEL] = (state, { data: { label } }) => ({
  ...state,
  modalProps: {
    ...state.modalProps,
    isDeletingALabel: true,
    label,
  },
})

reducers[types.CANCEL_DELETE_LABEL] = reducers[
  types.DELETE_LABEL_SUCCESS
] = state => ({
  ...state,
  modalProps: {
    ...state.modalProps,
    isDeletingALabel: false,
    label: {},
  },
})

reducers[types.CREATE_LABEL_REQUEST] = (state, { data: { name, color } }) => ({
  ...state,
  modalProps: {
    ...state.modalProps,
    label: {
      name,
      color,
    },
  },
})

reducers[types.LABEL_SELECTION_CLICK] = (state, { data }) => {
  const { applyAndClose } = data || {}
  if (!applyAndClose) return state
  return defaultState
}

reducers[types.MANAGE_LABELS_BEGIN] = () => ({
  modalType: modals.LABEL_MANAGE,
  modalProps: {},
})

reducers[types.INTEGRATIONS_INTEGRATION_SELECTED] = (state, action) => {
  return {
    modalType: modals.INTEGRATIONS,
    modalProps: action, // GR: not used?
  }
}

reducers[types.STORE_LATEST_SEARCH] = state => {
  if (state.modalType === modals.TICKET_PREVIEW) {
    return {
      ...state,
      modalType: null,
      modalProps: emptyObj,
    }
  }
  return state
}
reducers[TICKET_PAGE] = reducers[types.STORE_LATEST_SEARCH]

export default function reducer(state = defaultState, action) {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
