const asCursorManaged = (
  ExtendedComponent,
  options = { refPropertyName: 'inputRef' }
) => {
  return class CursorManagedComponent extends ExtendedComponent {
    _asCursorManagedIndex = null
    _asCursorManagedIsFocused = false

    // HACK (jscheel): Because semantic isn't consistent with it's naming,
    // we have to do this kind of nonsense.
    _asCursorManagedGetNodeRef() {
      const ref = this[options.refPropertyName]
      // HACK (jscheel): Semantic sometimes uses createRef API now. Massive breaking
      // API change, and they documented it as "no change to users".
      // eslint-disable-next-line no-prototype-builtins
      if (ref && ref.hasOwnProperty('current')) {
        return ref.current
      }
      return ref
    }

    componentWillReceiveProps(nextProps) {
      if (super.componentWillReceiveProps) {
        super.componentWillReceiveProps(nextProps)
      }
      if (
        this._asCursorManagedIndex === null &&
        nextProps.value !== this.props.value
      ) {
        const nodeRef = this._asCursorManagedGetNodeRef()
        if (nodeRef) {
          this._asCursorManagedIsFocused = document.activeElement === nodeRef
          this._asCursorManagedIndex = nodeRef.selectionStart
        }
      }
    }

    componentWillUpdate(nextProps, nextState) {
      if (super.componentWillUpdate) {
        super.componentWillUpdate(nextProps, nextState)
      }
      const nodeRef = this._asCursorManagedGetNodeRef()
      if (nodeRef) {
        this._asCursorManagedIsFocused = document.activeElement === nodeRef
        if (this._asCursorManagedIndex === null) {
          this._asCursorManagedIndex = nodeRef.selectionStart
        }
      }
    }

    componentDidUpdate(prevProps) {
      if (super.componentDidUpdate) {
        super.componentDidUpdate(prevProps)
      }
      const nodeRef = this._asCursorManagedGetNodeRef()
      if (nodeRef && this._asCursorManagedIndex !== null) {
        if (this._asCursorManagedIsFocused) {
          nodeRef.setSelectionRange(
            this._asCursorManagedIndex,
            this._asCursorManagedIndex
          )
        }
        this._asCursorManagedIndex = null
      }
    }
  }
}

export default asCursorManaged
