import { downcase } from 'util/strings'

export function buildSearchObject(folder, mailbox) {
  const searchObject = {}
  searchObject.folder = [folder.id]
  if (mailbox) {
    const mailboxName = downcase(mailbox.name)
    searchObject.mailbox = [mailboxName]
  }
  return searchObject
}
