import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import debug from 'debug'
import { capitalize } from 'util/strings'
import { Modal as SUIModal, Divider, Button } from 'util/ui'
import { getAnimationEndEventName } from 'util/animation'
import { Header, SubHeader } from 'shared/components/ui/Typography'
import SpacedItems from 'shared/components/ui/SpacedItems'
import themeVars from 'ui_theme/site/globals/site.variables'

import styles from './styles.less'

// NOTE (jscheel): We have to control the open state because SUI react doesn't
// actually follow its own documentation or code comments. SUI _says_ that the
// Confirm component manages the open and closed state, but it doesn't actually
// close the modal when you click the action buttons. In addition to this, we
// want to control our design a little more tightly than the SUI Confirm
// component allows.
class Modal extends React.PureComponent {
  static propTypes = {
    /** Pass value to take control of modal visibility yourself. If not set, modal controls its own visibility. */
    open: PropTypes.bool,
    /** Any text or component you want to act as a header */
    header: PropTypes.node,
    /** Any text or component you want to act as a header */
    headerTextAlign: PropTypes.oneOf(['left', 'center', 'right']),
    /** Any text or component you want to act as a subheader */
    subHeader: PropTypes.node,
    /** Any text or component you want to act as a content. NOTE: You do not need to use this shorthand property. You can simply add children to the component as well. Just be sure to choose one or the other. */
    content: PropTypes.node,
    /** Any text or component you want inside the confirm button. Can also be set to `false` if you want to remove the confirm button. */
    confirmButton: PropTypes.node,
    /** Any text or component you want inside the cancel button. Can also be set to `false` if you want to remove the cancel button. */
    cancelButton: PropTypes.node,
    /** Disable confirm button */
    confirmButtonDisabled: PropTypes.bool,
    /** Callback for confirm */
    onConfirm: PropTypes.func,
    /** Callback for cancel */
    onCancel: PropTypes.func,
    /** Callback for after the modal has opened (waits for animation to finish) */
    onOpen: PropTypes.func,
    /** Callback for after the modal has closed (waits for animation to finish) */
    onClose: PropTypes.func,
    /** Sets confirm button to warning color */
    dangerous: PropTypes.bool,
    /** Hides separator between action buttons and content, floats action buttons to the left, and increases the size of text in the content. */
    basic: PropTypes.bool,
    /** 'wide' is now deprecated, please use 'mobileWidth, tabletWidth, desktopWidth and wideDesktopWidth' instead.`
     *  Widen the modal content and center headers */
    wide: PropTypes.bool,
    /** Shorthand for the close icon. Closes the modal on click. */
    closeIcon: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.object,
      PropTypes.bool,
    ]),
    /** Class name for the content container */
    contentClassName: PropTypes.string,
    /** Should modal content scroll */
    scrolling: PropTypes.bool,

    // NOTE Duplicated the width array for story book to show options
    // correctly
    /** Container width at mobile breakpoint */
    mobileWidth: PropTypes.oneOf([
      'one',
      'two',
      'three',
      'four',
      'five',
      'six',
      'seven',
      'eight',
      'nine',
      'ten',
      'eleven',
      'twelve',
      'thirteen',
      'fourteen',
      'fifteen',
      'sixteen',
    ]),
    /** Container width at tablet breakpoint */
    tabletWidth: PropTypes.oneOf([
      'one',
      'two',
      'three',
      'four',
      'five',
      'six',
      'seven',
      'eight',
      'nine',
      'ten',
      'eleven',
      'twelve',
      'thirteen',
      'fourteen',
      'fifteen',
      'sixteen',
    ]),
    /** Container width at small desktop breakpoint */
    desktopWidth: PropTypes.oneOf([
      'one',
      'two',
      'three',
      'four',
      'five',
      'six',
      'seven',
      'eight',
      'nine',
      'ten',
      'eleven',
      'twelve',
      'thirteen',
      'fourteen',
      'fifteen',
      'sixteen',
    ]),
    /** Container width at wide desktop breakpoint */
    wideDesktopWidth: PropTypes.oneOf([
      'one',
      'two',
      'three',
      'four',
      'five',
      'six',
      'seven',
      'eight',
      'nine',
      'ten',
      'eleven',
      'twelve',
      'thirteen',
      'fourteen',
      'fifteen',
      'sixteen',
    ]),
  }

  static defaultProps = {
    open: undefined, // NOTE (jscheel): This must be undefined so that we can decide to use controlled or uncontrolled modal open states
    header: undefined,
    headerTextAlign: 'left',
    subHeader: undefined,
    content: undefined,
    confirmButton: 'Yes',
    cancelButton: 'Cancel',
    confirmButtonDisabled: false,
    onConfirm: undefined,
    onCancel: undefined,
    onOpen: undefined,
    onClose: undefined,
    dangerous: false,
    basic: false,
    wide: undefined,
    contentClassName: undefined,
    mobileWidth: undefined,
    tabletWidth: undefined,
    desktopWidth: undefined,
    wideDesktopWidth: undefined,
    closeIcon: true,
    scrolling: true,
  }

  constructor(props) {
    super(props)
    const {
      open,
      wide,
      mobileWidth,
      tabletWidth,
      desktopWidth,
      wideDesktopWidth,
    } = this.props
    if (wide === true || wide === false) {
      debug('use of the wide property is now deprecated')
    } else if (
      mobileWidth === undefined &&
      tabletWidth === undefined &&
      desktopWidth === undefined &&
      wideDesktopWidth === undefined
    ) {
      debug(
        'You need to specify mobileWidth, tabletWidth, desktopWidth and wideDesktopWidth for <Modal> components  '
      )
    }
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.open = !!open
  }

  state = {
    open: false,
    transitionDirection: 'in',
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== undefined && nextProps.open !== this.state.open) {
      if (nextProps.open) {
        this.onOpen()
      } else {
        this.onClose()
      }
    }
  }

  componentWillUnmount() {
    if (this.closeModalTimeout) {
      clearTimeout(this.closeModalTimeout)
    }
  }

  onOpen = (e, data) => {
    const { onOpen } = this.props
    this.open().then(() => {
      if (onOpen) {
        onOpen(e, data)
      }
    })
  }

  onClose = (e, data) => {
    const { onClose } = this.props
    this.close().then(() => {
      if (onClose) {
        onClose(e, data)
      }
    })
  }

  onCancel = (e, data) => {
    const { onCancel } = this.props
    this.close().then(() => {
      if (onCancel) {
        onCancel(e, data)
      }
    })
  }

  onConfirm = (e, data) => {
    const { onConfirm } = this.props
    this.close().then(() => {
      if (onConfirm) {
        onConfirm(e, data)
      }
    })
  }

  get widthClass() {
    const {
      mobileWidth,
      tabletWidth,
      desktopWidth,
      wideDesktopWidth,
      wide,
    } = this.props

    // Backward compatibility with the old "wide" property
    if (wide === true) {
      return [
        styles.mobileScreenTwelveWide,
        styles.tabletScreenTwelveWide,
        styles.smallMonitorTwelveWide,
        styles.largeMonitorTwelveWide,
      ].join(' ')
    } else if (
      wide === false ||
      (wide === undefined &&
        (mobileWidth === undefined &&
          tabletWidth === undefined &&
          desktopWidth === undefined &&
          wideDesktopWidth === undefined))
    ) {
      return [
        styles.mobileScreenEightWide,
        styles.tabletScreenEightWide,
        styles.smallMonitorEightWide,
        styles.largeMonitorEightWide,
      ].join(' ')
    }
    return [
      styles[`mobileScreen${capitalize(mobileWidth || 'sixteen')}Wide`],
      styles[`tabletScreen${capitalize(tabletWidth || 'sixteen')}Wide`],
      styles[`smallMonitor${capitalize(desktopWidth || 'sixteen')}Wide`],
      styles[`largeMonitor${capitalize(wideDesktopWidth || 'sixteen')}Wide`],
    ].join(' ')
  }

  get headerTextAlign() {
    const { wide, headerTextAlign } = this.props

    // Backward compatibility with the old "wide" property
    if (wide) {
      return 'center'
    }
    return headerTextAlign
  }

  close() {
    const {
      modalRef: { ref },
    } = this
    this.setState({ transitionDirection: 'out' })
    return new Promise(resolve => {
      const animationEndEventName = getAnimationEndEventName()
      if (!animationEndEventName) {
        resolve()
        return
      }
      // NOTE (jscheel): The animationend event is a bit finicky, so we add a
      // fallback timeout to make sure that the modal actually closes.
      this.closeModalTimeout = setTimeout(
        resolve,
        parseInt(themeVars.modalAnimationDuration, 10) * 1.2
      )
      let el = ref
      // eslint-disable-next-line no-prototype-builtins
      if (ref && ref.hasOwnProperty('current')) {
        el = ref.current
      }
      if (el) {
        el.addEventListener(animationEndEventName, resolve)
      }
    }).then(() => {
      clearTimeout(this.closeModalTimeout)
      this.setState({ open: false, transitionDirection: 'in' })
    })
  }

  open() {
    this.setState({ open: true, transitionDirection: 'in' })
    // NOTE (jscheel): Returning promise here for consistency with close method.
    return Promise.resolve()
  }

  // TODO (jscheel): Update to use emotionjs when it is merged in.
  subHeaderStyle() {
    return {
      marginTop: themeVars.absoluteMini,
      marginBottom: this.props.wide ? themeVars.absoluteHuge : 0,
    }
  }

  takeModalRef = node => {
    this.modalRef = node
  }

  render() {
    const {
      header,
      subHeader,
      content,
      confirmButton,
      cancelButton,
      confirmButtonDisabled,
      onConfirm,
      onCancel,
      onOpen,
      onClose,
      dangerous,
      basic,
      className,
      contentClassName,
      children,
      mobileWidth,
      tabletWidth,
      desktopWidth,
      wideDesktopWidth,
      wide,
      headerTextAlign,
      closeIcon,
      scrolling,
      ...rest
    } = this.props
    const { open, transitionDirection } = this.state
    if (content && children) {
      throw new Error(
        'Only define content shorthand prop or children, not both.'
      )
    }
    const headersPresent = header || subHeader
    const buttonsPreset = confirmButton || cancelButton
    return (
      // NOTE (jscheel): Make sure that `rest` is passed first, so that any
      // other props will override what's in `rest`.
      <SUIModal
        {...rest}
        open={open}
        onOpen={this.onOpen}
        onClose={this.onClose}
        size="fullscreen"
        closeIcon={closeIcon}
        closeOnDimmerClick={false}
        dimmer="inverted"
        className={cn(`transition fade ${transitionDirection}`, className)}
        ref={this.takeModalRef}
      >
        <div className={this.widthClass} style={{ textAlign: 'center' }}>
          {headersPresent && (
            <React.Fragment>
              {header && (
                <Header as="h1" size="medium">
                  {header}
                </Header>
              )}
              {subHeader && (
                <SubHeader as="h2" size="small" style={this.subHeaderStyle()}>
                  {subHeader}
                </SubHeader>
              )}
            </React.Fragment>
          )}
        </div>
        <SUIModal.Content
          scrolling={scrolling}
          className={cn(this.widthClass, contentClassName)}
        >
          {basic ? (
            <SubHeader size="small" as="div">
              {children || content}
            </SubHeader>
          ) : (
            children || content
          )}
        </SUIModal.Content>

        {buttonsPreset && (
          <React.Fragment>
            <Divider hidden={basic} className={this.widthClass} />
            <SpacedItems.Container
              direction="horizontal"
              gap="large"
              horizontalAlign={'center'}
              className={this.widthClass}
            >
              {confirmButton && (
                <SpacedItems.Item>
                  <Button
                    primary
                    negative={dangerous}
                    onClick={this.onConfirm}
                    size="large"
                    disabled={confirmButtonDisabled}
                  >
                    {confirmButton}
                  </Button>
                </SpacedItems.Item>
              )}
              {cancelButton && (
                <SpacedItems.Item>
                  <Button
                    basic
                    primary={!dangerous}
                    onClick={this.onCancel}
                    size="large"
                  >
                    {cancelButton}
                  </Button>
                </SpacedItems.Item>
              )}
            </SpacedItems.Container>
          </React.Fragment>
        )}
      </SUIModal>
    )
  }
}

export default Modal
