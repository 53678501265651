import { createSelector } from 'reselect'
import { parseQueryParams } from 'util/params'
import { selectMailboxesByEmail } from './selectMailboxesByEmail'

export const selectMailboxIdFromUrl = createSelector(
  selectMailboxesByEmail,
  mailboxes => {
    const params = parseQueryParams(window.location.search)
    return params.from && mailboxes[params.from] && mailboxes[params.from].id
  }
)
